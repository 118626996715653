import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@mui/material/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import ElectricBikeIcon from "@mui/icons-material/ElectricBike";
import BabyChangingStationIcon from "@mui/icons-material/BabyChangingStation";
import PedalBikeIcon from "@mui/icons-material/PedalBike";
import DoorSlidingIcon from "@mui/icons-material/DoorSliding";
import VerifiedIcon from "@mui/icons-material/Verified";
import { useHistory } from "react-router-dom";
import { Paper, Grid } from "@material-ui/core";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Typography from "@mui/material/Typography";

let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "scroll",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: isMobile ? "400px" : "800px",
    maxHeight: "100%",
    overflow: "scroll",
    position: "sticky",
  },
  head: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "2rem",
  },
}));

type ModalProps = {
  setOpenPhleboModel: Function;
  openPhlebotModel: any;
  getPhleboTimeline: any;
  phleboTimeline: any;
  bookingId: any;
  loading: any;
};

const CommentsModal2: React.FC<ModalProps> = ({
  setOpenPhleboModel,
  openPhlebotModel,
  getPhleboTimeline,
  phleboTimeline,
  bookingId,
  loading,
}) => {
  const classes = useStyles();

  const handleClose = () => {
    setOpenPhleboModel(false);
  };

  useEffect(() => {
    if (bookingId !== "") {
      getPhleboTimeline(bookingId);
    }
  }, [bookingId]);
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={openPhlebotModel}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openPhlebotModel}>
        <div className={classes.paper}>
          <>
            <h3 style={{ color: "#3f51b5" }}>Order Number {bookingId} </h3>

            {phleboTimeline.report_status === "none" ? (
              <p style={{ color: "#ff784e" }}>
                Your Booking is created ,Our Phlebo Will be reached out Soon.
              </p>
            ) : (
              ""
            )}

            {phleboTimeline.report_status === "pending" ? (
              <p style={{ color: "#ff784e" }}>Your Report is genrated Soon</p>
            ) : (
              ""
            )}
            {phleboTimeline.report_status === "partial" ? (
              <p style={{ color: "#ff784e" }}>
                Your Sample is Collected , Your report will be generated
                shortly.
              </p>
            ) : (
              ""
            )}
            {phleboTimeline.report_status === "consoldated" ? (
              <p style={{ color: "#ff784e" }}>
                Your Booking is created ,Our Phlebo Will be reached out Soon.
              </p>
            ) : (
              ""
            )}
            <Grid container spacing={3}>
             
                {phleboTimeline.booking_status !== "reschedule" ||
                phleboTimeline.booking_status !== "resampling" ? (
                  <>
                    <Timeline position="alternate">
                      <TimelineItem>
                        <TimelineOppositeContent
                          sx={{ m: "auto 0" }}
                          align="right"
                          variant="body2"
                          style={{ color: "#1b5e20" }}
                        >
                          {phleboTimeline?.created_at?.substring(11, 19)}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineDot style={{ color: "#4db6ac" }}>
                            <CreateNewFolderIcon />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: "12px", px: 2 }}>
                          <Typography
                            variant="h6"
                            component="span"
                            style={{ color: "#00bcd4" }}
                          >
                            Booking Created
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>
                      <TimelineItem>
                        <TimelineOppositeContent
                          sx={{ m: "auto 0" }}
                          variant="body2"
                          style={{ color: "#1b5e20" }}
                        >
                          {phleboTimeline?.verification_time?.substring(11, 19)}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot style={{ color: "#ff4081" }}>
                            <VerifiedIcon />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: "12px", px: 2 }}>
                          <Typography
                            variant="h6"
                            component="span"
                            style={{ color: "#2c387e" }}
                          >
                            Booking Verified
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>
                      <TimelineItem>
                        <TimelineOppositeContent
                          sx={{ m: "auto 0" }}
                          variant="body2"
                          style={{ color: "#1b5e20" }}
                        >
                          {phleboTimeline?.phlebo_assign_time?.substring(
                            11,
                            19
                          )}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot style={{ color: "#d500f9" }}>
                            <DeliveryDiningIcon />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: "12px", px: 2 }}>
                          <Typography
                            variant="h6"
                            component="span"
                            style={{ color: "#9c27b0" }}
                          >
                            Phlebo Assigned
                          </Typography>
                          <Typography style={{ color: "#c51162" }}>
                            {phleboTimeline?.phlebo?.fullname}
                            <p>{phleboTimeline?.phlebo?.phonenumber}</p>
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>
                      <TimelineItem>
                        <TimelineOppositeContent
                          sx={{ m: "auto 0" }}
                          variant="body2"
                          style={{ color: "#1b5e20" }}
                        >
                          {phleboTimeline?.phlebo_start_time?.substring(11, 19)}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot style={{ color: "#2196f3" }}>
                            <ElectricBikeIcon />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: "12px", px: 2 }}>
                          <Typography
                            variant="h6"
                            component="span"
                            style={{ color: "#c51162" }}
                          >
                            Phlebo Left For location
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>
                      <TimelineItem>
                        <TimelineOppositeContent
                          sx={{ m: "auto 0" }}
                          align="right"
                          variant="body2"
                          style={{ color: "#1b5e20" }}
                        >
                          {phleboTimeline?.phlebo_end_time?.substring(11, 19)}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot style={{ color: "#304ffe" }}>
                            <DoorSlidingIcon />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: "12px", px: 2 }}>
                          <Typography
                            variant="h6"
                            component="span"
                            style={{ color: "#2196f3" }}
                          >
                            Phlebo Reach Your location
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>
                      <TimelineItem>
                        <TimelineOppositeContent
                          sx={{ m: "auto 0" }}
                          align="right"
                          variant="body2"
                          style={{ color: "#1b5e20" }}
                        >
                          {phleboTimeline?.pickup_time}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot style={{ color: "#f73378" }}>
                            <BookmarkAddedIcon />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: "12px", px: 2 }}>
                          <Typography
                            variant="h6"
                            component="span"
                            style={{ color: "#1a237e" }}
                          >
                            Sample Collected
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>
                      <TimelineItem>
                        <TimelineOppositeContent
                          sx={{ m: "auto 0" }}
                          align="right"
                          variant="body2"
                          style={{ color: "#1b5e20" }}
                        >
                          {phleboTimeline?.sample_reached_time?.substring(
                            11,
                            19
                          )}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot style={{ color: "#3e2723" }}>
                            <BabyChangingStationIcon />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: "12px", px: 2 }}>
                          <Typography
                            variant="h6"
                            component="span"
                            style={{ color: "#304ffe" }}
                          >
                            Sample Recived At Lab
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>
                      <TimelineItem>
                        <TimelineOppositeContent
                          sx={{ m: "auto 0" }}
                          align="right"
                          variant="body2"
                          style={{ color: "#1b5e20" }}
                        >
                          {phleboTimeline?.report_time?.substring(11, 19)}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot style={{ color: "#4caf50" }}>
                            <AssignmentTurnedInIcon />
                          </TimelineDot>
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: "12px", px: 2 }}>
                          <Typography
                            variant="h6"
                            component="span"
                            style={{ color: "#1b5e20" }}
                          >
                            Report Generated
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>
                    </Timeline>
                  </>
                ) : (
                  ""
                )}
              {phleboTimeline.booking_status === "rescheduled" ? (
                <>
                  <Grid item xs={12} sm={6} md={6}>
                    <Timeline>
                      <TimelineItem>
                        <TimelineOppositeContent
                          sx={{ m: "auto 0" }}
                          align="right"
                          variant="body2"
                          style={{ color: "#1b5e20" }}
                        >
                          {phleboTimeline?.created_at?.substring(11, 19)}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineDot style={{ color: "#4db6ac" }}>
                            <CreateNewFolderIcon />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: "12px", px: 2 }}>
                          <Typography
                            variant="h6"
                            component="span"
                            style={{ color: "#00bcd4" }}
                          >
                            Booking Reschedule
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>
                      <TimelineItem>
                        <TimelineOppositeContent
                          sx={{ m: "auto 0" }}
                          variant="body2"
                          style={{ color: "#1b5e20" }}
                        >
                          {phleboTimeline?.verification_time?.substring(11, 19)}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot style={{ color: "#ff4081" }}>
                            <VerifiedIcon />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: "12px", px: 2 }}>
                          <Typography
                            variant="h6"
                            component="span"
                            style={{ color: "#2c387e" }}
                          >
                            Booking Verified
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>
                      <TimelineItem>
                        <TimelineOppositeContent
                          sx={{ m: "auto 0" }}
                          variant="body2"
                          style={{ color: "#1b5e20" }}
                        >
                          {phleboTimeline?.phlebo_assign_time?.substring(
                            11,
                            19
                          )}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot style={{ color: "#d500f9" }}>
                            <DeliveryDiningIcon />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: "12px", px: 2 }}>
                          <Typography
                            variant="h6"
                            component="span"
                            style={{ color: "#9c27b0" }}
                          >
                            Phlebo Assigned
                          </Typography>
                          <Typography style={{ color: "#c51162" }}>
                            {phleboTimeline?.phlebo?.fullname}
                            <p>{phleboTimeline?.phlebo?.phonenumber}</p>
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>
                      <TimelineItem>
                        <TimelineOppositeContent
                          sx={{ m: "auto 0" }}
                          variant="body2"
                          style={{ color: "#1b5e20" }}
                        >
                          {phleboTimeline?.phlebo_start_time?.substring(11, 19)}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot style={{ color: "#2196f3" }}>
                            <ElectricBikeIcon />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: "12px", px: 2 }}>
                          <Typography
                            variant="h6"
                            component="span"
                            style={{ color: "#c51162" }}
                          >
                            Phlebo Left For location
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>
                      <TimelineItem>
                        <TimelineOppositeContent
                          sx={{ m: "auto 0" }}
                          align="right"
                          variant="body2"
                          style={{ color: "#1b5e20" }}
                        >
                          {phleboTimeline?.phlebo_end_time?.substring(11, 19)}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot style={{ color: "#304ffe" }}>
                            <DoorSlidingIcon />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: "12px", px: 2 }}>
                          <Typography
                            variant="h6"
                            component="span"
                            style={{ color: "#2196f3" }}
                          >
                            Phlebo Reach Your location
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>
                      <TimelineItem>
                        <TimelineOppositeContent
                          sx={{ m: "auto 0" }}
                          align="right"
                          variant="body2"
                          style={{ color: "#1b5e20" }}
                        >
                          {phleboTimeline?.pickup_time}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot style={{ color: "#f73378" }}>
                            <BookmarkAddedIcon />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: "12px", px: 2 }}>
                          <Typography
                            variant="h6"
                            component="span"
                            style={{ color: "#1a237e" }}
                          >
                            Sample Collected
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>
                      <TimelineItem>
                        <TimelineOppositeContent
                          sx={{ m: "auto 0" }}
                          align="right"
                          variant="body2"
                          style={{ color: "#1b5e20" }}
                        >
                          {phleboTimeline?.sample_reached_time?.substring(
                            11,
                            19
                          )}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot style={{ color: "#3e2723" }}>
                            <BabyChangingStationIcon />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: "12px", px: 2 }}>
                          <Typography
                            variant="h6"
                            component="span"
                            style={{ color: "#304ffe" }}
                          >
                            Sample Recived At Lab
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>
                      <TimelineItem>
                        <TimelineOppositeContent
                          sx={{ m: "auto 0" }}
                          align="right"
                          variant="body2"
                          style={{ color: "#1b5e20" }}
                        >
                          {phleboTimeline?.report_time?.substring(11, 19)}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot style={{ color: "#4caf50" }}>
                            <AssignmentTurnedInIcon />
                          </TimelineDot>
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: "12px", px: 2 }}>
                          <Typography
                            variant="h6"
                            component="span"
                            style={{ color: "#1b5e20" }}
                          >
                            Report Generated
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>
                    </Timeline>
                  </Grid>
                </>
              ) : (
                ""
              )}

              {phleboTimeline.booking_status === "resampling" ? (
                <>
                  <Grid item xs={12} sm={6} md={6}>
                    <Timeline>
                      <TimelineItem>
                        <TimelineOppositeContent
                          sx={{ m: "auto 0" }}
                          align="right"
                          variant="body2"
                          style={{ color: "#1b5e20" }}
                        >
                          {phleboTimeline?.created_at?.substring(11, 19)}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineDot style={{ color: "#4db6ac" }}>
                            <CreateNewFolderIcon />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: "12px", px: 2 }}>
                          <Typography
                            variant="h6"
                            component="span"
                            style={{ color: "#00bcd4" }}
                          >
                            Booking Resampling
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>
                      <TimelineItem>
                        <TimelineOppositeContent
                          sx={{ m: "auto 0" }}
                          variant="body2"
                          style={{ color: "#1b5e20" }}
                        >
                          {phleboTimeline?.verification_time?.substring(11, 19)}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot style={{ color: "#ff4081" }}>
                            <VerifiedIcon />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: "12px", px: 2 }}>
                          <Typography
                            variant="h6"
                            component="span"
                            style={{ color: "#2c387e" }}
                          >
                            Booking Verified
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>
                      <TimelineItem>
                        <TimelineOppositeContent
                          sx={{ m: "auto 0" }}
                          variant="body2"
                          style={{ color: "#1b5e20" }}
                        >
                          {phleboTimeline?.phlebo_assign_time?.substring(
                            11,
                            19
                          )}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot style={{ color: "#d500f9" }}>
                            <DeliveryDiningIcon />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: "12px", px: 2 }}>
                          <Typography
                            variant="h6"
                            component="span"
                            style={{ color: "#9c27b0" }}
                          >
                            Phlebo Assigned
                          </Typography>
                          <Typography style={{ color: "#c51162" }}>
                            {phleboTimeline?.phlebo?.fullname}
                            <p>{phleboTimeline?.phlebo?.phonenumber}</p>
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>
                      <TimelineItem>
                        <TimelineOppositeContent
                          sx={{ m: "auto 0" }}
                          variant="body2"
                          style={{ color: "#1b5e20" }}
                        >
                          {phleboTimeline?.phlebo_start_time?.substring(11, 19)}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot style={{ color: "#2196f3" }}>
                            <ElectricBikeIcon />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: "12px", px: 2 }}>
                          <Typography
                            variant="h6"
                            component="span"
                            style={{ color: "#c51162" }}
                          >
                            Phlebo Left For location
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>
                      <TimelineItem>
                        <TimelineOppositeContent
                          sx={{ m: "auto 0" }}
                          align="right"
                          variant="body2"
                          style={{ color: "#1b5e20" }}
                        >
                          {phleboTimeline?.phlebo_end_time?.substring(11, 19)}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot style={{ color: "#304ffe" }}>
                            <DoorSlidingIcon />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: "12px", px: 2 }}>
                          <Typography
                            variant="h6"
                            component="span"
                            style={{ color: "#2196f3" }}
                          >
                            Phlebo Reach Your location
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>
                      <TimelineItem>
                        <TimelineOppositeContent
                          sx={{ m: "auto 0" }}
                          align="right"
                          variant="body2"
                          style={{ color: "#1b5e20" }}
                        >
                          {phleboTimeline?.pickup_time}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot style={{ color: "#f73378" }}>
                            <BookmarkAddedIcon />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: "12px", px: 2 }}>
                          <Typography
                            variant="h6"
                            component="span"
                            style={{ color: "#1a237e" }}
                          >
                            Sample Collected
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>
                      <TimelineItem>
                        <TimelineOppositeContent
                          sx={{ m: "auto 0" }}
                          align="right"
                          variant="body2"
                          style={{ color: "#1b5e20" }}
                        >
                          {phleboTimeline?.sample_reached_time?.substring(
                            11,
                            19
                          )}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot style={{ color: "#3e2723" }}>
                            <BabyChangingStationIcon />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: "12px", px: 2 }}>
                          <Typography
                            variant="h6"
                            component="span"
                            style={{ color: "#304ffe" }}
                          >
                            Sample Recived At Lab
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>
                      <TimelineItem>
                        <TimelineOppositeContent
                          sx={{ m: "auto 0" }}
                          align="right"
                          variant="body2"
                          style={{ color: "#1b5e20" }}
                        >
                          {phleboTimeline?.report_time?.substring(11, 19)}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot style={{ color: "#4caf50" }}>
                            <AssignmentTurnedInIcon />
                          </TimelineDot>
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: "12px", px: 2 }}>
                          <Typography
                            variant="h6"
                            component="span"
                            style={{ color: "#1b5e20" }}
                          >
                            Report Generated
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>
                    </Timeline>
                  </Grid>
                </>
              ) : (
                ""
              )}
            </Grid>
          </>
        </div>
      </Fade>
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  user: state.loginReducer.user,
});

export default connect(mapStateToProps, {})(CommentsModal2);
