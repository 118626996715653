
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
    withStyles,
    Theme,
    createStyles,
    makeStyles,
} from "@material-ui/core/styles";
import MessageIcon from "@material-ui/icons/Message";
import Button from '@mui/material/Button';
import { Grid, TextField, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from '@material-ui/core'
import Paper from "@material-ui/core/Paper";
import { getNotServiceableAreaList } from '../../actions/loginActions';
import { clickToCallBooking, getCities } from '../actions/OffLineSupportActions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CallIcon from "@material-ui/icons/Call";
import HealthDispostion from "./hotcallDisposition";
import { generateNonServiceableAreaFilterUrl } from '../../helpers/generateUrl'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            minWidth: 700,
        },
        root: {
            flexGrow: 0,

        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),

            ...theme.mixins.toolbar,
        },
        content: {
            width: "90%",
            flexGrow: 21,

        },
        tableContainer: {
            marginTop: "2rem",
            position: "sticky",
            top: "200px",
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            // width: "50%",
            // height: "50%",
        },
        head: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "2rem",
        },
    })
);
const StyledTableCell: any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#924A91",
            color: theme.palette.common.white,
            fontSize: "14px",
        },
        body: {
            fontSize: 14, fontWeight: 600
        },
    })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);

interface Props {
    notServiceableArea: any;
    getNotServiceableAreaList: any;
    clickToCallBooking: any
    getCities: any
    cities: any;
  
}

const LeadsTable: React.FC<Props> = ({
    notServiceableArea,
    getNotServiceableAreaList,
    clickToCallBooking,
    getCities,
    cities,
}) => {
    const history = useHistory()
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [leadId, setleadId] = React.useState<any>("")
    const [disableFilterBtn, setDisableFilterBtn] = useState<boolean>(false);
    const [open2, setOpen2] = useState<boolean>(false)
    const [locationMessageStatus, setLocationMessageStatus] = useState<any>([]);
    const [centerId, setCenterId] = useState<any>("");

   useEffect(()=>{
    getNotServiceableAreaList("")
   },[])
    
    



    useEffect(() => {
        if (disableFilterBtn) {
            setTimeout(() => {
                setDisableFilterBtn(false);
            }, 5000)
        }
    }, [disableFilterBtn])

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        if (newPage > page) {
            let url = notServiceableArea.links.next.split("?")[1];
            getNotServiceableAreaList(`?center_id=${centerId}&${url}`)
        } else if (newPage < page) {
            let url = notServiceableArea.links.previous.split("?")[1];
            getNotServiceableAreaList(url === undefined ? "" : `?center_id=${centerId}&${url}`)
        }
        setPage(newPage);
    };

    const filterHealthData = () => {
        setDisableFilterBtn(true);
        const body: any = {
            lead_id: leadId,
            location_request_sent: locationMessageStatus
        }
        const url = generateNonServiceableAreaFilterUrl(body).substring(2)
        getNotServiceableAreaList(`${url ? `center_id=${centerId}&${url}&page=1` : "page=1"}`)
        setPage(0)
    }
    const handleCallClick = (id: number, type: string) => {
        clickToCallBooking(id, type);
    };

    const handleModal = (leadId: number) => {
        setOpen2(true);
        setleadId(leadId);
    };
   
    return (
        <>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <h4 style={{ fontWeight: "bold" }}>Non Serviceable Area</h4>
                <Grid container spacing={1} direction="row" style={{ alignItems: "left" }}>
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            className="input"
                            name="data"
                            type="number"
                            label="Lead Id"
                            value={leadId}
                            variant="outlined"
                            onChange={(e) => setleadId(e.target.value)}
                            style={{ width: "100%" }}

                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Autocomplete
                            id="noTyping"
                            onChange={(event, newValue) => {
                                let sourceIds: any = [];
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    sourceIds = obj.map((item: any) => item.value);
                                }
                                setLocationMessageStatus(sourceIds);
                            }}
                            options={[
                                {
                                    text: "Yes",
                                    value: "true",
                                },
                                {
                                    text: "No",
                                    value: "false",
                                },

                            ]}
                            freeSolo
                            blurOnSelect
                            aria-required
                            limitTags={1}
                            multiple
                            getOptionLabel={(option: any) => option.text}

                            disableClearable
                            disableCloseOnSelect
                            renderInput={(params) => (
                                <TextField
                                    className="input"
                                    {...params}
                                    label="Location Message Status"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Button
                            variant="contained"
                            color="secondary"
                            fullWidth
                            onClick={filterHealthData}
                            disabled={disableFilterBtn}
                        >
                            Filter
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => history.push("/dashboard/os/notserviceablearea")}
                        >
                            Reset
                        </Button>
                    </Grid>
                </Grid>
                <div className="data-table" style={{ width: "100%", marginTop: "2rem" }}>
                    <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "540px" }}>

                        <Table stickyHeader aria-label="simple table">
                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableCell align="center">Lead Id</StyledTableCell>
                                    <StyledTableCell align="center">Click-To-Call</StyledTableCell>
                                    <StyledTableCell align="center">Action</StyledTableCell>
                                    <StyledTableCell align="center">Address  Name</StyledTableCell>
                                    {/* <StyledTableCell align="center">Latitude</StyledTableCell>
                                    <StyledTableCell align="center">Longitutde</StyledTableCell> */}
                                    <StyledTableCell align="center">Created By User</StyledTableCell>
                                    <StyledTableCell align="center">Location Request_sent  User</StyledTableCell>
                                    <StyledTableCell align="center">City Name</StyledTableCell>
                                    <StyledTableCell align="center">Center Name </StyledTableCell>
                                    <StyledTableCell align="center">Center Available </StyledTableCell>
                                    <StyledTableCell align="center">Eloc </StyledTableCell>
                                    <StyledTableCell align="center">Updated At</StyledTableCell>

                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {notServiceableArea && notServiceableArea?.results && notServiceableArea?.results?.length > 0 && notServiceableArea?.results?.map((data: any) => {

                                    return (
                                        <StyledTableRow>

                                            <StyledTableCell align="center" style={{ cursor: "pointer", color: "#2196f3" }}>
                                                {data?.lead_id !== undefined && data?.lead_id !== "undefined" && data.lead_id !== null ? data.lead_id : "NA"}
                                            </StyledTableCell>
                                            <StyledTableCell align="center" >
                                                {data?.lead_id !== undefined && data?.lead_id !== "undefined" && data.lead_id !== null ?
                                                    <div>
                                                        <MessageIcon style={{ cursor: "pointer", color: "gray" }}
                                                            onClick={() => handleModal(data?.lead_id)} />
                                                    </div> : "NA"}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {data?.lead_id !== undefined && data?.lead_id !== "undefined" && data.lead_id !== null ?
                                                    <CallIcon style={{ cursor: "pointer", color: "#2196f3" }}
                                                        onClick={() => handleCallClick(data?.lead_id, "call")}
                                                    /> : "NA"}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {data.address}
                                            </StyledTableCell>

                                            {/* <StyledTableCell align="center">
                                                {data.verified_lat !== null ? data.verified_lat : data.lat}
                                            </StyledTableCell>

                                            <StyledTableCell align="center">

                                                {data.verified_long !== null ? data.verified_long : data.long}
                                            </StyledTableCell> */}

                                            <StyledTableCell align="center">
                                                {data?.created_by_user}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">

                                                {data?.location_request_sent === true ? "Yes" : "No"}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {data?.city_name}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {data?.center_name}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">

                                                {data?.center_available === true ? "Yes" : "No"}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {data?.eloc}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {data?.updated_at}
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )
                                })}
                            </TableBody>
                            <TableFooter>
                                <TablePagination
                                    colSpan={3}
                                    count={notServiceableArea?.count || 0}
                                    rowsPerPageOptions={[]}
                                    rowsPerPage={notServiceableArea?.page_size || 20}

                                    page={page}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                />
                            </TableFooter>
                        </Table>

                    </TableContainer>


                </div>
            </main>
            <HealthDispostion
                open={open2}
                setOpen={setOpen2}
                leadId={leadId}
                getCities={getCities}
                cities={cities}
                bId={""}
            />
        </>
    );
};

const mapStateToProps = (state: any) => ({
    notServiceableArea: state.loginReducer.notServiceableArea,
});

export default connect(mapStateToProps, {
    getNotServiceableAreaList,
    clickToCallBooking
})(LeadsTable);
