import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from '@mui/material/Modal';
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button, MenuItem, Select, TextField, Container, Box, Grid } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { setCFreshComments } from "../../actions/OffLineSupportActions";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useEffect } from "react";
import { useRef } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: isMobile ? "400px" : "1200px",
    },
    head: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "2rem"
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));

type ModalProps = {
    open: boolean;
    leadId: number;
    setOpen: Function;
    setCFreshComments: any
    getCities: any
    cities: any
};

const CommentsModal2: React.FC<ModalProps> = ({
    open,
    leadId,
    setOpen,
    setCFreshComments,
    getCities = () => { },
    cities = [],
}) => {
    const classes = useStyles();

    const CATEGORY: any = [
        {
            id: 1,
            text: "Interested/Follow up",
            city: true,
            leadId: false,
            dateTime: true,
        },
        {
            id: 2,
            text: "Not Interested",
            city: true,
            query: false,
            dateTime: false,
        },
        {
            id: 3,
            text: "Order Booked",
            city: true,
            query: false,
            dateTime: false,
        },
        {
            id: 4,
            text: "Ringing",
            city: false,
            query: false,
            dateTime: false,
        },
        {
            id: 5,
            text: "Number Doesn't Exist",
            city: false,
            query: false,
            dateTime: false,
        },
        {
            id: 6,
            text: "By Mistake & Wrong Number",
            city: false,
            query: false,
            dateTime: false,
        },
        {
            id: 7,
            text: "Call Back",
            city: true,
            query: false,
            dateTime: true,
        },
        {
            id: 8,
            text: "Business Realated Call",
            city: true,
            query: false,
            dateTime: false,
        },
        {
            id: 9,
            text: "Spam Call",
            city: false,
            query: false,
            dateTime: false,
        },
        {
            id: 10,
            text: "Enquiry Call",
            city: true,
            query: false,
            dateTime: false,
        },
        // {
        //     id: 11,
        //     text: "CFresh",
        //     city: false,
        //     query: false,

        // },
        {
            id: 12,
            text: "Test Not Available",
            city: true,
            testName: false,
            query: false,
            dateTimeTime: false,
        },
        {
            id: 13,
            text: "Subscribed to Competitor",
            city: true,
            query: false,
            dateTime: false,
        },
        {
            id: 14,
            text: "Slot Issue",
            city: true,
            query: false,
            dateTime: false,
        },
        {
            id: 15,
            text: "Same Day Slot",
            city: true,
            query: false,
            dateTime: false,
        },
        {
            id: 16,
            text: "Already Booked",
            city: false,
            query: false,
            dateTime: false,
        },
        {
            id: 17,
            text: "Not Contactable",
            city: false,
            query: false,
            dateTime: false,
        },
        {
            id: 18,
            text: "Out of Station",
            city: true,
            query: false,
            dateTime: false,
        },

        {
            id: 19,
            text: "Out of Service Area",
            city: true,
            pincode: false,
            query: false,
            dateTime: false,
        },
        {
            id: 20,
            text: "Need Time to Think",
            city: true,
            query: false,
            dateTime: false,
        },
        {
            id: 21,
            text: "Email/Whatsapp Package",
            city: true,
            leadId: false,
            dateTime: false,
        },
        {
            id: 22,
            text: "Discount/Price Enquiry",
            city: true,
            leadId: false,
            dateTime: false,
        },
        {
            id: 23,
            text: "CS issue",
            city: false,
            leadId: true,
            dateTime: false,
        },
        {
            id: 24,
            text: "Call Not Reachable",
            city: false,
            leadId: false,
            dateTime: false,
        },
        {
            id: 25,
            text: "Call Later",
            city: true,
            leadId: false,
            dateTime: false,
        },
        {
            id: 26,
            text: "Call drop",
            city: true,
            leadId: false,
            dateTime: false,
        },
        {
            id: 27,
            text: "Call Disconnected",
            city: true,
            leadId: false,
            dateTime: false,
        },
        {
            id: 28,
            text: "Below <5 year patient",
            city: true,
            leadId: false,
            dateTime: false,
        },
        {
            id: 29,
            text: "DND",
            city: false,
            leadId: false,
            dateTime: false,
        },
        {
            id: 30,
            text: "Phlebo's Issue",
            city: false,
            leadId: true,
            dateTime: false,
        },
        {
            id: 31,
            text: "Reports Regarding Issue",
            city: false,
            leadId: true,
            dateTime: false,
        },
        {
            id: 32,
            text: "Health Advice",
            city: false,
            leadId: true,
            dateTime: false,
        },
        {
            id: 33,
            text: "Health Consultation",
            city: false,
            leadId: true,
            dateTime: false,
        },
        {
            id: 34,
            text: "Resampling",
            city: false,
            leadId: false,
            dateTime: false,
        },
        {
            id: 35,
            text: "Language Barrier",
            city: false,
            leadId: false,
            dateTime: false,
        },
        {
            id: 38,
            text: "Radiology",
            city: true,
            testName: false,
            query: false,
            dateTimeTime: false,
        },
    ]

    const [comment, setComment] = useState<string>("");
    // const [sub_category, setSubCategory] = React.useState<string>("none");
    const [category, setCategory] = React.useState<number>(0);
    // const [lead_comment, setLeadComment] = React.useState<string>("");
    const [city, setCity] = React.useState<number>(0);
    const [cityName, setCityName] = React.useState<string>("");
    const [status, setStatus] = useState("none")
    const [query, setQuery] = useState("none")
    const [testName, setTestname] = useState("")
    const [pincode, setPincode] = useState<number>()
    const [callDate, setCallDate] = useState("")
    const [callTime, setCallTime] = useState("")
    const history = useHistory()
    const timer = useRef<any>(0)



    const handleClose = () => {
        setOpen(false);
        // history.push("dashboard/os/Leadtable")

    };
    const bodyData = CATEGORY.filter((data: any) => data.id == category)
    const submitForm = (e: any) => {
        e.preventDefault()

        let body: any = {
            category: bodyData[0].id,
            sub_category: bodyData[0].text,
            lead_comment: leadId,
            comment,
            pincode,
            test: testName,
            city: city,
            status: status,
            query: query,
            followup_date: callDate,
            followup_time: callTime,
        };

        if (body['city'] === 0) {
            delete body['city'];
        }
        if (body['followup_date'] === "") {
            delete body['followup_date'];
        }
        if (body['followup_time'] === "") {
            delete body['followup_time'];
        }
        if (body['pincode'] === 0) {
            delete body['pincode'];
        }
        if (body['test'] === 0) {
            delete body['test'];
        }
        if (body['status'] === "none") {
            delete body['status'];
        }
        if (body['query'] === "none") {
            delete body['query'];
        }
        setCFreshComments(body);
        setComment("")
        setCategory(0)
        handleClose()
        // history.push("/dashboard/os");
    };

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <div className={classes.head}>
                            <p
                                style={{
                                    marginBottom: "1rem",
                                }}
                                id="transition-modal-title"
                            >
                                Select an options
                            </p>
                            <CloseIcon onClick={() => setOpen(false)} />
                        </div>
                        <form onSubmit={submitForm}>
                            <Select className="input"
                                name="action"
                                variant="outlined"
                                value={category}
                                style={{ width: "100%", marginBottom: "1rem" }}
                                onChange={(e) => setCategory(e.target.value as number)}
                                required
                            >
                                <MenuItem disabled value={0}>Please Select</MenuItem>
                                {CATEGORY.map((item: any) => {
                                    return (
                                        <MenuItem key={item.id} value={`${item.id}`}>{item.text}</MenuItem>
                                    )
                                })}
                            </Select>
                            <>
                                {bodyData[0] && bodyData[0].testName && (
                                    <TextField
                                        id="test"
                                        style={{ width: "100%", marginBottom: "1rem" }}
                                        label="Test name"
                                        variant="outlined"
                                        onChange={(e) => setTestname(e.target.value as any)}
                                        className="input" />
                                )}
                            </>
                            <>
                                {bodyData[0] && bodyData[0].pincode && (
                                    <TextField
                                        id="pincode"
                                        style={{ width: "100%", marginBottom: "1rem" }}
                                        label="Pincode"
                                        type="number"
                                        variant="outlined"
                                        onChange={(e) => setPincode(e.target.value as any)}
                                        inputProps={{ min: 99999, max: 999999 }}
                                        className="input" />
                                )}
                            </>
                            {bodyData[0] && bodyData[0].city && (
                                <Autocomplete
                                    id="city"
                                    onChange={(event, newValue) => {
                                        if (newValue) {
                                            let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                            setCity(obj.id)
                                        }
                                    }}
                                    options={cities}
                                    freeSolo
                                    blurOnSelect
                                    aria-required
                                    getOptionLabel={(option: any) => option.name}
                                    inputValue={cityName}
                                    onInputChange={(event, newInputValue) => {
                                        clearTimeout(timer.current)
                                        setCityName(newInputValue)
                                        timer.current = setTimeout(() => {
                                            getCities(newInputValue)
                                        }, 1000)
                                        if (newInputValue.length === 0) {
                                            setCity(0)
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            className="input"
                                            placeholder="City"
                                            variant="outlined"
                                            style={{ width: "100%", marginBottom: "1rem" }}
                                            required
                                        />
                                    )}
                                />
                            )}
                            {bodyData[0] && bodyData[0].dateTime && (
                                <Grid container spacing={3} direction="row" alignItems="center" justify="center" style={{ margin: "0.5rem" }}>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <TextField
                                            className="input"
                                            name="call_date"
                                            type="date"
                                            label={bodyData[0] && bodyData[0].text + " Date"}
                                            value={callDate}
                                            required={callDate?.length === 0 ? true : false}
                                            variant="outlined"
                                            onChange={(e) => setCallDate(e.target.value as string)}
                                            style={{ width: "100%" }}
                                            inputProps={{
                                                min: moment().format("YYYY-MM-DD"),
                                              }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <TextField
                                            id="time"
                                            label={bodyData[0] && bodyData[0].text + " Time"}
                                            type="time"
                                            value={callTime}
                                            defaultValue="07:30"
                                            className={classes.textField}
                                            required={callTime?.length === 0 ? true : false}

                                            onChange={(e) => setCallTime(e.target.value as string)}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            inputProps={{
                                                step: 300, // 5 min
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                            {bodyData[0] && bodyData[0].query && (
                                <>
                                    <Select className="input"
                                        name="action"
                                        variant="outlined"
                                        value={query}
                                        style={{ width: "100%", marginBottom: "1rem" }}
                                        onChange={(e) => setQuery(e.target.value as string)}
                                        required
                                    >
                                        <MenuItem disabled value={"none"}>Query Type</MenuItem>
                                        <MenuItem value={"CustomerLead"}>CustomerLead</MenuItem>
                                        <MenuItem value={"SupportExecutive"}>SupportExecutive</MenuItem>
                                        <MenuItem value={"SampleAccessioning"}>SampleAccessioning</MenuItem>
                                        <MenuItem value={"PhleboAdmin"}>PhleboAdmin</MenuItem>
                                        <MenuItem value={"Finance"}>Finance</MenuItem>
                                        {/* <MenuItem value={"Doctor"}>Doctor</MenuItem> */}
                                    </Select>
                                    <Select className="input"
                                        name="action"
                                        variant="outlined"
                                        value={status}
                                        style={{ width: "100%", marginBottom: "1rem" }}
                                        onChange={(e) => setStatus(e.target.value as string)}
                                        required
                                    >
                                        <MenuItem disabled value={"none"}>Selecct Status</MenuItem>
                                        <MenuItem value={"pending"}>Pending</MenuItem>
                                        {/* <MenuItem value={"confirmed"}>Confirmed</MenuItem>
                                    <MenuItem value={"cancelled"}>Cancelled</MenuItem> */}
                                    </Select>
                                </>
                            )}
                            <TextField
                                name="comment"
                                type="text"
                                multiline
                                rows={4}
                                value={comment}
                                className="input"
                                label="Comment"
                                variant="outlined"
                                placeholder="Comment"
                                onChange={(e) => setComment(e.target.value as string)}
                                style={{ width: "100%", marginBottom: "1rem" }}
                                required
                            />

                            <Button
                                fullWidth
                                variant="contained"
                                color="secondary"
                                style={{ height: "50px" }}
                                type="submit"
                                disabled={
                                    category === 0 ||
                                    comment === ""
                                }
                            >
                                Submit
                            </Button>
                        </form>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
};

const mapStateToProps = (state: any) => ({

});

export default connect(mapStateToProps, {
    setCFreshComments,
})(CommentsModal2);
