import React, {useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
} from "@material-ui/core";
import Button from '@mui/material/Button';
import { connect } from "react-redux";
import {
  getBookingById,
  postAddRemoveTest
} from "../../../actions/OffLineSupportActions";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem",
      width: "100%",
    },
    table: {
      maxWidth: "100%",
      margin: "auto",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
  },
  })
);
const StyledTableCell:any= withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#924A91",
            color: theme.palette.common.white,
            fontSize: "14px",
        },
        body: {
            fontSize: 14,
        },
    })
)(TableCell);
const StyledTableRow:any= withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);


interface Props {
  id: any;
  aid: any;
  type:any;
  postAddRemoveTest:any;
  getBookingById: any;
  booking: any;
  loading: boolean;
  packageDetails:any;
  setPackages:any;
}

const RemoveTest: React.FC<Props> = ({
  id,
  aid,
  type,
  getBookingById,
  booking,
  postAddRemoveTest,
  loading,
  packageDetails,
  setPackages,
}) => {
  const classes = useStyles();


useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getBookingById(id);
  }, []);
  useEffect(()=>{
    if(packageDetails){
      let packIds: any = [];
      packageDetails.map((item: any)=>{
        packIds.push(item.id);
      })
     
      setPackages(packIds);
    }
  },[packageDetails])
  const RemoveTests = async (bId: any, tId: any) => {
   
    const data: any = {
      id: aid === "aid" ? id : Number(aid),
      type: type,
      operation: "remove_package",
      packages: String(tId),
    };
    await postAddRemoveTest(data);
    getBookingById(id);
  };
  return (
    <div style={{ width: "100%" }} className="data-table">
      <form autoComplete="off" style={{ width: "100%", padding: 20 }}>
        <Grid container item xs={12}>
          <Grid container item xs={12}>
            <Grid item xs={12}>
              <TableContainer className={classes.tableContainer} component={Paper}>
              <Table stickyHeader aria-label="simple table">
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell>Name</StyledTableCell>
                      <StyledTableCell>Code</StyledTableCell>
                      <StyledTableCell>TAT</StyledTableCell>
                      <StyledTableCell align="center">Price</StyledTableCell>
                      <StyledTableCell>Action</StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {aid === "aid" ? (
                      <>
                        {booking?.packages &&
                          booking?.packages.map((pack: any, index: any) => {
                            return (
                              <TableRow
                                key={index}
                                style={{
                                  margin: "1rem 0",
                                  textAlign: "left",
                                }}
                              >
                                <StyledTableCell>{pack.name}</StyledTableCell>
                                <StyledTableCell>{pack.code}</StyledTableCell>
                                <StyledTableCell>{pack.tat}</StyledTableCell>
                                <StyledTableCell>{pack.price}</StyledTableCell>
                                <TableCell>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                      RemoveTests(
                                        booking?.id === undefined
                                          ? booking?.pk
                                          : booking?.id,
                                        pack.id
                                      );
                                    }}
                                  >
                                    Remove
                                  </Button>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </>
                    ) : (
                      <>
                        {booking.additional_members &&
                          booking.additional_members.length > 0 &&
                          booking.additional_members.map(
                            (member: any, index: number) => {
                              if (Number(member.id) === Number(aid)) {
                                return (
                                  member?.ccpackages &&
                                  member?.ccpackages.map(
                                    (pack: any, index: any) => {
                                      return (
                                        <TableRow
                                          key={index}
                                          style={{
                                            margin: "1rem 0",
                                            textAlign: "left",
                                          }}
                                        >
                                          <TableCell>{pack.name}</TableCell>
                                          <TableCell>{pack.code}</TableCell>
                                          <TableCell>{pack.tat}</TableCell>
                                          <TableCell>
                                            {pack.price}
                                          </TableCell>
                                          <TableCell>
                                            <Button
                                              variant="contained"
                                              color="primary"
                                              onClick={() => {
                                                RemoveTests(
                                                  booking?.id === undefined
                                                    ? booking?.pk
                                                    : booking?.id,
                                                  pack.id
                                                );
                                              }}
                                            >
                                              Remove
                                            </Button>
                                          </TableCell>
                                        </TableRow>
                                      );
                                    }
                                  )
                                );
                              }
                            }
                          )}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  booking: state.OffLineSupporteReducer.booking,
  loading: state.OffLineSupporteReducer.loading,
});

export default connect(mapStateToProps, {
  getBookingById,
  postAddRemoveTest,

})(RemoveTest);