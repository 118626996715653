import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
    Paper,
    Grid,
    TextField,
    Button,
    Container,
} from '@material-ui/core';
import Box from '@mui/material/Box';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { connect } from "react-redux";
import { getDispositions, getAgentList, getCities } from '../../actions/OffLineSupportActions'
import './filter.sass'
import { useHistory } from "react-router";
import Loader from "../../components/loader";import { generateDispositionUrl } from "../../../helpers/generateUrl";
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarExport } from "@material-ui/data-grid";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 0,
            //   width: "100%",
            //   marginTop: "20px",
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
            //   padding: theme.spacing(3),
        },
        card: {
            height: "100%",
            padding: theme.spacing(1),
        },
        card_grid: {
            // wordSpacing:3,
            justifyContent: 'space-between'
        },
        paper: {
            marginTop: "2rem",
            padding: "0.5rem 2rem",
            width: "100%",
        },
    })
);

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}

interface Props {
    getDispositions: any
    dispositions: any
    getAgentList: any
    getCities: any
    agentsList: any
    cities: any
     loading: boolean;
}

const CustomerLeadStateFilterPage: React.FC<Props> = ({
    loading,
    getDispositions,
    dispositions,
    getAgentList,
    getCities,
    agentsList,
    cities,
}) => {
    const classes = useStyles();

    const history = useHistory()

    const CATEGORY: any = [
        {
            id: 1,
            text: "Interested/Follow up",
            city: true,
            leadId: false
        },
        {
            id: 2,
            text: "Not Interested",
            city: true,
            query: false,
        },
        {
            id: 3,
            text: "Order Booked",
            city: true,
            query: false,
        },
        {
            id: 4,
            text: "Ringing",
            city: false,
            query: false,
        },
        {
            id: 5,
            text: "Number Doesn't Exist",
            city: false,
            query: false,
        },
        {
            id: 6,
            text: "By Mistake & Wrong Number",
            city: false,
            query: false,
        },
        {
            id: 7,
            text: "Call Back",
            city: true,
            query: false,
        },
        {
            id: 8,
            text: "Business Realated Call",
            city: true,
            query: false,
        },
        {
            id: 9,
            text: "Spam Call",
            city: false,
            query: false,
        },
        {
            id: 10,
            text: "Enquiry Call",
            city: true,
            query: false,
        },
        {
            id: 11,
            text: "CFresh",
            city: false,
            query: false,
        },
        {
            id: 12,
            text: "Test Not Available",
            city: true,
            query: false,
        },
        {
            id: 13,
            text: "Subscribed to Competitor",
            city: true,
            query: false,
        },
        {
            id: 14,
            text: "Slot Issue",
            city: true,
            query: false,
        },
        {
            id: 15,
            text: "Same Day Slot",
            city: true,
            query: false,
        },
        {
            id: 16,
            text: "Already Booked",
            city: false,
            query: false,
        },
        {
            id: 17,
            text: "Not Contactable",
            city: false,
            query: false,
        },
        {
            id: 18,
            text: "Out of Station",
            city: true,
            query: false,
        },
        {
            id: 19,
            text: "Out of Service Area",
            city: true,
            query: false,
        },
        {
            id: 20,
            text: "Need Time to Think",
            city: true,
            query: false,
        },
        {
            id: 21,
            text: "Email/Whatsapp Package",
            city: true,
            leadId: false
        },
        {
            id: 22,
            text: "Discount/Price Enquiry",
            city: true,
            leadId: false
        },
        {
            id: 23,
            text: "CS issue",
            city: false,
            leadId: true
        },
        {
            id: 24,
            text: "Call Not Reachable",
            city: false,
            leadId: false
        },
        {
            id: 25,
            text: "Call Later",
            city: true,
            leadId: false
        },
        {
            id: 26,
            text: "Call drop",
            city: true,
            leadId: false
        },
        {
            id: 27,
            text: "Call Disconnected",
            city: true,
            leadId: false
        },
        {
            id: 28,
            text: "Below <5 year patient",
            city: true,
            leadId: false
        },
        {
            id: 29,
            text: "DND",
            city: false,
            leadId: false
        },
        {
            id: 30,
            text: "Phlebo's Issue",
            city: false,
            leadId: true
        },
        {
            id: 31,
            text: "Reports Regarding Issue",
            city: false,
            leadId: true
        },
        {
            id: 32,
            text: "Health Advice",
            city: false,
            leadId: true
        },
        {
            id: 33,
            text: "Health Consultation",
            city: false,
            leadId: true
        },
        {
            id: 34,
            text: "Resampling",
            city: false,
            leadId: false
        },
        {
            id: 35,
            text: "Language Barrier",
            city: false,
            leadId: false
        },
    ]

    const [filtered, setFiltered] = useState(false)

    const [city, setCity] = useState<any>([])
    const [agent, setAgent] = useState<any>([])
    const [category, setCategory] = useState<any>([])

    const [start_date, setStart_date] = useState("")
    const [end_date, setEnd_date] = useState("")
    const [created_date, setCreated_date] = useState("")

    const timer = useRef<any>(0)

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', width: 200 },
        { field: 'by_user', headerName: 'Panel User', width: 200 },
        { field: 'city', headerName: 'City', width: 200 },
        { field: 'disposition', headerName: 'Disposition', width: 200 },
        { field: 'details', headerName: 'Details', width: 200 },
        { field: 'created_at', headerName: 'Created At', width: 200 },
    ]

    useEffect(() => {
        getAgentList()
        getCities()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const filterDisposition = () => {
        const body: any = {
           lead_call_status_id: category.join(","),
            city: city.join(","),
            agent: agent.join(","),
            start_date,
            end_date,
            created_date
        }
        const url = generateDispositionUrl(body).substring(2)
        getDispositions(`${url}&download=true`)
        setFiltered(true)
    }

    return (
        <>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Grid container spacing={3} direction="row" alignItems="center" justify="center" style={{ margin: "0" }}>
                    <Grid item xs={12} sm={6} md={4}>
                        <Autocomplete
                            id="city"
                            onChange={(event, newValue) => {
                                let sourceIds: any = []
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    sourceIds = obj.map((item: any) => item.id)
                                }
                                setCity(sourceIds)
                            }}
                            options={cities || []}
                            freeSolo
                            blurOnSelect
                            aria-required
                            limitTags={1}
                            multiple
                            getOptionLabel={(option: any) => option.name}
                            getOptionDisabled={(option: any) => {
                                return city.includes(option.id)
                            }}
                            disableClearable
                            disableCloseOnSelect
                            onInputChange={(event, newInputValue) => {
                                clearTimeout(timer.current)
                                timer.current = setTimeout(() => {
                                    getCities(newInputValue)
                                }, 1000)
                                if (newInputValue.length === 0) {
                                    setCity(0)
                                }
                            }}
                            renderInput={(params) => (
                                <TextField className="input"
                                    {...params}
                                    placeholder="City"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Autocomplete
                            id="agent"
                            onChange={(event, newValue) => {
                                let sourceIds: any = []
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    sourceIds = obj.map((item: any) => item.id)
                                }
                                setAgent(sourceIds)
                            }}
                            options={agentsList?.results||[]}
                            freeSolo
                            blurOnSelect
                            aria-required
                            limitTags={1}
                            multiple
                            getOptionLabel={(option: any) => option.user && option.user.name}
                            getOptionDisabled={(option: any) => {
                                return agent.includes(option.id)
                            }}
                            disableClearable
                            disableCloseOnSelect
                            onInputChange={(event, newInputValue) => {
                                clearTimeout(timer.current)
                                timer.current = setTimeout(() => {
                             getAgentList(`?code=${newInputValue.replace(" ","")}`)
                                }, 1000)
                                if (newInputValue.length === 0) {
                                    setAgent(0)
                                }
                            }}
                            renderInput={(params) => (
                                <TextField className="input"
                                    {...params}
                                    placeholder="Agent"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                />
                            )}
                        />
                    </Grid>
                    {/* <Grid item xs={12} sm={6} md={4}>
                        <Autocomplete
                            onChange={(event, newValue) => {
                                let sourceIds: any = []
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    sourceIds = obj.map((item: any) => item.id)
                                }
                                setCategory(sourceIds)
                            }}
                            multiple
                            id="checkboxes-tags-demo"
                            options={CATEGORY}
                            disableCloseOnSelect
                            limitTags={1}
                            getOptionLabel={(option: any) => option.text}
                            getOptionDisabled={(option: any) => {
                                return category.includes(option.id)
                            }}
                            disableClearable
                            renderInput={(params) => (
                                <TextField className="input"
                                    {...params}
                                    placeholder="Disposition"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                    required
                                />
                            )}
                        />
                    </Grid> */}
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            className="input"
                            name="start_date"
                            type="date"
                            label="Start Date"
                            value={start_date}
                            variant="outlined"
                            onChange={(e) => setStart_date(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            className="input"
                            name="end_date"
                            type="date"
                            label="End Date"
                            value={end_date}
                            variant="outlined"
                            onChange={(e) => setEnd_date(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            className="input"
                            name="created_date"
                            type="date"
                            label="Created Date"
                            value={created_date}
                            disabled={start_date !== "" || end_date !== ""}
                            variant="outlined"
                            onChange={(e) => setCreated_date(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Button
                            variant="contained"
                            color="secondary"
                            disabled={loading}
                            fullWidth
                            onClick={filterDisposition}
                        >
                            Filter
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Button
                            variant="contained"
                            color="primary"
                          
                            fullWidth
                            onClick={() => {
                                setFiltered(false)
                                history.push("/dashboard/os/dispositions/export")
                            }}
                        >
                            Reset
                        </Button>
                    </Grid>
                </Grid>
                <br />
                {loading ? <Loader /> : filtered && dispositions.results && dispositions.results.length > 0 && (
                    <Box>
                        <Container maxWidth={false}>
                            <Grid container spacing={3}>
                                <Paper elevation={15} className={classes.paper}>
                                    <div style={{ height: 700 }}>
                                        <DataGrid
                                            rowsPerPageOptions={[]}
                                            rows={dispositions && dispositions.results}
                                            columns={columns}
                                            components={{
                                                Toolbar: CustomToolbar,
                                            }}
                                            pageSize={50}
                                            rowCount={dispositions.count || 0}
                                            loading={loading}
                                        />
                                    </div>
                                </Paper>
                            </Grid>
                        </Container>
                    </Box>
                )}
            </main>
        </>
    );
};

const mapStateToProps = (state: any) => ({
    loading: state.OffLineSupporteReducer.loading,
    dispositions: state.OffLineSupporteReducer.dispositions,
    agentsList: state.OffLineSupporteReducer.agentsList,
    cities: state.OffLineSupporteReducer.cities,
})

export default connect(mapStateToProps, {
    getDispositions,
    getAgentList,
    getCities,
})(CustomerLeadStateFilterPage);
