import React, { useEffect } from "react";
import { connect } from "react-redux";
import Button from '@mui/material/Button';
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import MissedCallsList from "./MisssedCallsList";
import { getTodaysMissedCalls} from "../actions/OffLineSupportActions";
import TextField from "@material-ui/core/TextField";
import { generateRecordingUrl } from "../../helpers/generateUrl";
import {Container, Grid } from "@material-ui/core";
import Box from '@mui/material/Box';
import { useHistory } from "react-router-dom";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    toolbar: {
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    card: {
      height: "100%",
      padding: theme.spacing(1),
    },
    card_grid: {
      // wordSpacing:3,
      justifyContent: 'space-between'
    },
    tableIcon:{
      display:"center",
      justifyContent:"center"
    }
  })
);
interface Props {
 getTodaysMissedCalls: Function;
  panel_prop: any;
  agentsList: any;
  lead_source:any;
  getAgentList: any;
  todayMissedCallsList: any;
  getLeadSource:any;
  loading: any;
}

const QualityRecordingFilter: React.FC<Props> = ({
  getTodaysMissedCalls,
  todayMissedCallsList,
  loading,
}) => {
  const classes = useStyles();
  const history = useHistory()
  const [start_date, setStartDate] = React.useState<string>("");
  const [booking_id, setBookingid] = React.useState<any>("")
  const [lead, setLead] = React.useState<any>("")
  const [phonenumber, setPhonenumber] = React.useState<any>("")

  const handleStartDateChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setStartDate(event.target.value as string);
  };

  const submitForm = () => {
    let body = {
      start_date,
      booking_id,
      lead,
      phonenumber
    };
    let url = generateRecordingUrl(body).substring(2);
    getTodaysMissedCalls(`?${url}`);
  };

  useEffect(() => {
    getTodaysMissedCalls()
  }, []);
  
  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <h2 style={{paddingLeft: "40px"}}>Missed Calls  {todayMissedCallsList?.count > 0 && (<> - {todayMissedCallsList?.count}</>)}</h2>
        <Box>
          <Container maxWidth={false}>
            <Grid container spacing={3} direction="row" style={{ margin: "0" }}>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  className="input"
                  name="booking"
                  type="number"
                  placeholder="Booking Id"
                  value={booking_id}
                  variant="outlined"
                  onChange={(e) => setBookingid(e.target.value)}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  className="input"
                  name="lead"
                  type="number"
                  placeholder="Lead Id"
                  value={lead}
                  variant="outlined"
                  onChange={(e) => setLead(e.target.value)}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  className="input"
                  name="customernumber"
                  type="number"
                  placeholder="Phone Number"
                  value={phonenumber}
                  variant="outlined"
                  onChange={(e) => setPhonenumber(e.target.value)}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  className="input"
                  name="start_date"
                  type="date"
                  label="Missed Call Date"
                  value={start_date}
                  variant="outlined"
                  onChange={handleStartDateChange}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={loading}
                  fullWidth
                  onClick={submitForm}
                >
                  Filter
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <Button
                  variant="contained"
                  color="primary"
                
                  fullWidth
                  onClick={() => history.push("/dashboard/os/lead/missedCall")}
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
            <MissedCallsList
              todayMissedCallsList={todayMissedCallsList}
              getTodaysMissedCalls={getTodaysMissedCalls}
              loading={loading}
              showAllCollumn={true}
            />
          </Container>
        </Box>
      </main>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  loading: state.OffLineSupporteReducer.loading,
  todayMissedCallsList: state.OffLineSupporteReducer.todayMissedCallsList,
});

export default connect(mapStateToProps, { 
  getTodaysMissedCalls, 
})(
  QualityRecordingFilter
);
