import React, { useEffect } from "react";
import { connect } from "react-redux";
import Button from "@mui/material/Button";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import QualityRecordingTable from "../tables/recordings";
import {
  getAgentNewData,
  getAllRecordings,
} from "../actions/OffLineSupportActions";
import TextField from "@material-ui/core/TextField";
import { generateRecordingUrl } from "../../helpers/generateUrl";
import { Container, Grid } from "@material-ui/core";
import Box from "@mui/material/Box";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useRef } from "react";
import { useHistory } from "react-router-dom";
import "./index.sass";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      //   width: "100%",
      //   marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    card: {
      height: "100%",
      padding: theme.spacing(1),
    },
    card_grid: {
      // wordSpacing:3,
      justifyContent: "space-between",
    },
    tableIcon: {
      display: "center",
      justifyContent: "center",
    },
  })
);
interface Props {
  getAllRecordings: Function;
  panel_prop: any;
  agentsListnewdata: any;
  getAgentNewData: any;
  all_recordings: any;
  loading: any;
  recordingLoader: any;
  setTotalCount: any;
}

const QualityRecordingFilter: React.FC<Props> = ({
  getAllRecordings,
  getAgentNewData,
  agentsListnewdata,
  all_recordings,
  loading,
  recordingLoader,
  setTotalCount,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [agent, setAgent] = React.useState<number>(0);
  const [leadSource, setLeadSource] = React.useState<number>(0);
  const [start_date, setStartDate] = React.useState<string>("");
  const [end_date, setEndDate] = React.useState<string>("");
  const [direction, setDirection] = React.useState<string>("none");
  const [status, setStatus] = React.useState<string>("false");
  const [disposition, setDisposition] = React.useState<string>("none")
  const [did, setDid] = React.useState<string>("7969228300")
  const [category, setCategory] = React.useState<any>(0);
  const [booking_id, setBookingid] = React.useState<any>("");
  const [lead, setLead] = React.useState<any>("");
  const [phonenumber, setPhonenumber] = React.useState<any>("");
  const [usergroup, setUsergroup] = React.useState<any>("none");
  const [hangupClause, setHangupClause] = React.useState<any>("");
  const [usergroups, setUserGroups] = React.useState<any>("");
  const USERGROUP: any = [
    "CustomerExecutive",
    "CustomerSupport",
    "SupportExecutive",
    "VerificationExecutive",
    "PhleboAdmin",
    "CustomerLead",
    "Phlebos",
    "channelpartner",
    "Finance",
    "Doctor",
    "CollectionCenter",
    "Admin",
    "QVC",
    "SampleAccessioning",
  ];
  const CATEGORY: any = [
    {
      id: 1,
      text: "Interested/Follow up",
      city: true,
      leadId: false,
    },
    {
      id: 2,
      text: "Not Interested",
      city: true,
      query: false,
    },
    {
      id: 3,
      text: "Order Booked",
      city: true,
      query: false,
    },
    {
      id: 4,
      text: "Ringing",
      city: false,
      query: false,
    },
    {
      id: 5,
      text: "Number Doesn't Exist",
      city: false,
      query: false,
    },
    {
      id: 6,
      text: "By Mistake & Wrong Number",
      city: false,
      query: false,
    },
    {
      id: 7,
      text: "Call Back",
      city: true,
      query: false,
    },
    {
      id: 8,
      text: "Business Realated Call",
      city: true,
      query: false,
    },
    {
      id: 9,
      text: "Spam Call",
      city: false,
      query: false,
    },
    {
      id: 10,
      text: "Enquiry Call",
      city: true,
      query: false,
    },
    {
      id: 11,
      text: "CFresh",
      city: false,
      query: false,
    },
    {
      id: 12,
      text: "Test Not Available",
      city: true,
      query: false,
    },
    {
      id: 13,
      text: "Subscribed to Competitor",
      city: true,
      query: false,
    },
    {
      id: 14,
      text: "Slot Issue",
      city: true,
      query: false,
    },
    {
      id: 15,
      text: "Same Day Slot",
      city: true,
      query: false,
    },
    {
      id: 16,
      text: "Already Booked",
      city: false,
      query: false,
    },
    {
      id: 17,
      text: "Not Contactable",
      city: false,
      query: false,
    },
    {
      id: 18,
      text: "Out of Station",
      city: true,
      query: false,
    },
    {
      id: 19,
      text: "Out of Service Area",
      city: true,
      query: false,
    },
    {
      id: 20,
      text: "Need Time to Think",
      city: true,
      query: false,
    },
    {
      id: 21,
      text: "Email/Whatsapp Package",
      city: true,
      leadId: false,
    },
    {
      id: 22,
      text: "Discount/Price Enquiry",
      city: true,
      leadId: false,
    },
    {
      id: 23,
      text: "CS issue",
      city: false,
      leadId: true,
    },
    {
      id: 24,
      text: "Call Not Reachable",
      city: false,
      leadId: false,
    },
    {
      id: 25,
      text: "Call Later",
      city: true,
      leadId: false,
    },
    {
      id: 26,
      text: "Call drop",
      city: true,
      leadId: false,
    },
    {
      id: 27,
      text: "Call Disconnected",
      city: true,
      leadId: false,
    },
    {
      id: 28,
      text: "Below <5 year patient",
      city: true,
      leadId: false,
    },
    {
      id: 29,
      text: "DND",
      city: false,
      leadId: false,
    },
    {
      id: 30,
      text: "Phlebo's Issue",
      city: false,
      leadId: true,
    },
    {
      id: 31,
      text: "Reports Regarding Issue",
      city: false,
      leadId: true,
    },
    {
      id: 32,
      text: "Health Advice",
      city: false,
      leadId: true,
    },
    {
      id: 33,
      text: "Health Consultation",
      city: false,
      leadId: true,
    },
    {
      id: 34,
      text: "Resampling",
      city: false,
      leadId: false,
    },
    {
      id: 35,
      text: "Language Barrier",
      city: false,
      leadId: false,
    },
  ];

  const timer = useRef<any>(0);

  const handleStartDateChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setStartDate(event.target.value as string);
  };

  const handleEndDateChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setEndDate(event.target.value as string);
  };

  const submitForm = () => {
    let body = {
      panel_user: agent,
      usergroup: usergroups,
      start_date,
      end_date,
      direction,
      status,
      category,
      booking_id,
      lead,
      phonenumber,
      hangup_clause: hangupClause,
      did
    };
    let url = generateRecordingUrl(body).substring(2);
    getAllRecordings(`?${url}&disposition=${disposition === "none" ? "" : disposition}`);
  };

  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div style={{ marginLeft: "22px" }}>
          <h2>Offline Call Recording</h2>
        </div>
        <Box>
          <Container maxWidth={false}>
            <Grid container spacing={1} direction="row">
              <Grid item xs={12} sm={4} md={2}>
                <TextField
                  className="input"
                  name="lead"
                  type="number"
                  placeholder="Lead Id"
                  value={lead}
                  variant="outlined"
                  onChange={(e) => setLead(e.target.value)}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <TextField
                  className="input"
                  name="customernumber"
                  type="number"
                  placeholder="Phone Number"
                  value={phonenumber}
                  variant="outlined"
                  onChange={(e) => setPhonenumber(e.target.value)}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <Autocomplete
                  id="agentname"
                  onChange={(event, newValue) => {
                    if (newValue) {
                      let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                      setAgent(obj.id);
                    }
                  }}
                  options={agentsListnewdata?.results || []}
                  freeSolo
                  blurOnSelect
                  aria-required
                  getOptionLabel={(option: any) => option?.username}
                  onInputChange={(event, newInputValue) => {
                    clearTimeout(timer.current);
                    timer.current = setTimeout(() => {
                      getAgentNewData(`?code=${newInputValue}`);
                    }, 1000);
                    if (newInputValue.length === 0) {
                      setAgent(0);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      className="input"
                      {...params}
                      placeholder="Agent Name"
                      variant="outlined"
                      style={{ width: "100%", margin: "0", padding: "0" }}
                      required
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={4} md={2}>
                <TextField
                  className="input"
                  name="start_date"
                  type="date"
                  label="Start Date"
                  value={start_date}
                  variant="outlined"
                  onChange={handleStartDateChange}
                  style={{ width: "100%" }}
                  // disabled={booking_date !== ""}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <TextField
                  name="end_date"
                  type="date"
                  value={end_date}
                  className="input"
                  label="End Date"
                  variant="outlined"
                  onChange={handleEndDateChange}
                  style={{ width: "100%" }}
                  // disabled={booking_date !== ""}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <Select
                  className="input"
                  name="direction"
                  variant="outlined"
                  value={direction}
                  style={{ width: "100%", height: "40px" }}
                  onChange={(e) => setDirection(e.target.value as string)}
                >
                  <MenuItem value={"none"} disabled>
                    Direction
                  </MenuItem>
                  <MenuItem value={"incoming"}>Incoming</MenuItem>
                  <MenuItem value={"outbound-api"}>Outgoing</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <Select
                  className="input"
                  name="UserGroup"
                  variant="outlined"
                  value={usergroups || "none"}
                  style={{ width: "100%", height: "40px" }}
                  onChange={(e) => setUserGroups(e.target.value as string)}
                >
                  <MenuItem value={"none"}>User Group</MenuItem>
                  <MenuItem value={"OffLineSupport"}>OffLineSupport</MenuItem>
                  <MenuItem value={"Logistics"}>Logistics</MenuItem>
                  <MenuItem value={"CCPartner"}>CCPartner</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <Autocomplete
                  id="hangupClause"
                  onChange={(event, newValue) => {
                    let sourceIds: any = [];
                    if (newValue) {
                      let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                      setHangupClause(obj.value);
                    }
                  }}
                  options={[
                    {
                      text: "Agent Hangup",
                      value: "AgentHangup",
                    },
                    {
                      text: "User Hangup",
                      value: "UserHangup",
                    },
                  ]}
                  freeSolo
                  blurOnSelect
                  aria-required
                  getOptionLabel={(option: any) => option.text}
                  disableClearable
                  disableCloseOnSelect
                  renderInput={(params) => (
                    <TextField
                      className="input"
                      {...params}
                      placeholder="Hangup Clause"
                      variant="outlined"
                      style={{ width: "100%", margin: "0", padding: "0" }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <Select
                  className="input"
                  name="status"
                  variant="outlined"
                  value={status}
                  style={{ width: "100%", height: "40px" }}
                  onChange={(e) => setStatus(e.target.value as string)}
                >
                  <MenuItem value={"none"} disabled>
                    Call Picked
                  </MenuItem>
                  <MenuItem value={"true"}>Yes</MenuItem>
                  <MenuItem value={"false"}>No</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <Select
                  className="input"
                  variant="outlined"
                  value={disposition}
                  style={{ width: "100%", height: "40px" }}
                  onChange={(e) => setDisposition(e.target.value as string)}
                >
                  <MenuItem value="none" disabled>Disposition</MenuItem>
                  <MenuItem value="Not Connected">Not Connected</MenuItem>
                  <MenuItem value="Call Disconnected">
                    Call Disconnected
                  </MenuItem>
                  <MenuItem value="Call Connected">Call Connected</MenuItem>
                  <MenuItem value="Callback">Callback</MenuItem>
                  <MenuItem value="Ringing">Ringing</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <TextField
                  className="input"
                  name="virtual_number"
                  variant="outlined"
                  label="virtual Number"
                  value={did}
                  style={{ width: "100%", height: "40px" }}
                  onChange={(e) => setDid(e.target.value as string)}
                >
                </TextField>
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={loading}
                  fullWidth
                  onClick={submitForm}
                >
                  Filter
                </Button>
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => history.push("/dashboard/os/calls_recording")}
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
            <QualityRecordingTable
              all_recordings={all_recordings}
              getAllRecordings={getAllRecordings}
              recordingLoader={recordingLoader}
              setTotalCount={setTotalCount}
              loading={loading}
            />
          </Container>
        </Box>
      </main>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  loading: state.OffLineSupporteReducer.loading,
  agentsListnewdata: state.OffLineSupporteReducer.agentsListnewdata,
  all_recordings: state.OffLineSupporteReducer.all_recordings,
});

export default connect(mapStateToProps, {
  getAllRecordings,
  getAgentNewData,
})(QualityRecordingFilter);
