import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from '@mui/material/Modal';
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { useHistory, useParams, } from 'react-router-dom';
import { Button, Paper, Grid, TextField, MenuItem, Select, CircularProgress, InputLabel } from "@material-ui/core";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Autocomplete from "@mui/material/Autocomplete";
import {
    updateGrivance,
    getTicketCategories,
    getGrievance,
} from "../../actions/OffLineSupportActions";
let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "scroll",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: isMobile ? "400px" : "1200px",
        maxHeight: "600px",
        overflow: "scroll",
        position: "sticky",

    },
    head: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "2rem"
    }
}));

type ModalProps = {
    open: boolean;
    setOpen: Function;
    loading: any;
    updateGrivance: any;
    setGrevianceId: any;
    grevianceId: any;
    getTicketCategories: any;
    ticketCategories: any;
    getGrievance: any;
    fetchGrivance: any;

};

const CommentsModal2: React.FC<ModalProps> = ({
    open,
    setOpen,
    loading,
    updateGrivance,
    setGrevianceId,
    grevianceId,
    getTicketCategories,
    ticketCategories,
    getGrievance,
    fetchGrivance,
}) => {
    const classes = useStyles();
    const history = useHistory();
    const timer = useRef<any>(0);
    const { id } = useParams<{ id: string }>();
    const [status, setStatus] = React.useState<any>([]);
    const [remarks, setRemarks] = useState<any>("")
    const [category, setCategory] = useState<any>("");
    const [categoryName, setCategoryName] = useState<any>("");
    const [buttonType, setButtonType] = useState<boolean>(false)
    const [disposion, setDisposion] = useState<any>("");
    const [isEdit, setIsEdit] = useState<any>(false);
    const handleUpadte = async (e: any) => {
        const data: any = {
            status: status,
            remark: remarks,
            grievance_type: category,
        }
        await updateGrivance(data, grevianceId)
        history.push("/dashboard/os/grievance")
        setOpen(false);
        setStatus("");
        setRemarks("");
        setCategory("")
    }

    const handleClose = () => {
        setOpen(false);
        setButtonType(false)
        setStatus("");
        setRemarks("");
        setCategory("")
    };
    useEffect(() => {
        getTicketCategories()
    }, [])

    useEffect(() => {
        if (grevianceId !== null) {
            getGrievance(`${grevianceId}/`)
        }
    }, [grevianceId])

    useEffect(() => {
        if (fetchGrivance) {
            const list = fetchGrivance;
            setIsEdit(true);
            setCategory(list?.grievance_type);
            setCategoryName(list?.grievance_type_name || "")
            setStatus(list?.status);
            setRemarks(list?.remark)
        }

    }, [fetchGrivance])
    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (
            e.key === " " &&
            (!/[a-zA-Z0-9]/.test(e.currentTarget.value) ||
                !/[a-zA-Z0-9]/.test(e.currentTarget.value.slice(-1)))
        ) {
            e.preventDefault();
        }
    };
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classes.paper}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6} sm={6}>
                            <InputLabel htmlFor="status-select" style={{ fontWeight: "bold", alignItems: "center", marginTop: "-2px" }}>
                                Category
                            </InputLabel>
                            <Autocomplete
                                id="client_name"
                                disablePortal
                                onChange={(event, newValue) => {
                                    if (newValue) {
                                        let obj = JSON.parse(JSON.stringify(newValue, null, ""));
                                        setCategory(obj?.id);
                                    }
                                }}
                                options={ticketCategories.results || ""}
                                inputValue={categoryName || ""}
                                blurOnSelect
                                freeSolo
                                getOptionLabel={(option: any) => option?.title}
                                onInputChange={(event, newInputValue) => {
                                    setCategoryName(newInputValue);
                                    clearTimeout(timer.current);
                                    timer.current = setTimeout(() => {
                                        getTicketCategories(newInputValue);
                                    }, 1000);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className="input"
                                        label="Category"
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                        <Grid xs={12} sm={6} md={6}>
                            <InputLabel htmlFor="status-select" style={{ fontWeight: "bold" }}>
                                Select Status
                            </InputLabel>
                            <Select
                                className="input"
                                name="name"
                                label="Remarks"
                                value={status ? status : "none"}
                                variant="outlined"
                                style={{ width: "100%", margin: "0", height: "40px" }}
                                onChange={(e) => setStatus(e.target.value)}
                            >
                                <MenuItem value="none">Select Status</MenuItem>
                                <MenuItem value="In Progress">In Progress</MenuItem>
                                <MenuItem value="To Do">To Do</MenuItem>
                                <MenuItem value="Assigned to agent">Assigned to agent</MenuItem>
                                <MenuItem value="Done">Done</MenuItem>
                            </Select>
                        </Grid>
                        <Grid xs={12}>
                            <InputLabel htmlFor="status-select" style={{ fontWeight: "bold", alignItems: "center" }}>
                                Remarks
                            </InputLabel>
                            <TextareaAutosize
                                aria-label="minimum height"
                                minRows={3}
                                placeholder="Add Remarks.."
                                value={remarks}
                                required={status === "done"}
                                onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                                    setRemarks(e.target.value as string)
                                }
                                onKeyDown={handleKeyDown}
                                style={{ width: "100%", height: "40px" }}
                            />
                        </Grid>
                        <Grid xs={12} style={{ textAlign: "center" }}>
                            <Button
                                variant="contained"
                                color="secondary"
                                type="submit"
                                style={{ width: "170px", marginTop: "10px" }}
                                onClick={handleUpadte}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </Fade>
        </Modal>
    );
};

const mapStateToProps = (state: any) => ({
    loading: state.OffLineSupporteReducer.loading,
    ticketCategories: state.OffLineSupporteReducer.ticketCategories,
    fetchGrivance: state.OffLineSupporteReducer.fetchGrivance,


});

export default connect(mapStateToProps, {
    updateGrivance,
    getTicketCategories,
    getGrievance,
})(CommentsModal2);
