//Authentication
import React, { useState } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
// import Pusher from "pusher-js";
import Modal from "../components/Modal";
import MainLayout from "../Hoc/MainLayout";
import SnackBar from "../components/SnackBar";
import Login from "../containers/login";
import SecureStorage from "./SecureStorage";
import SecondModal from "../components/SecondModal";
import Dash from "../containers/dash";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import decode from "jwt-decode";
import { checkUser } from "../actions/loginActions";
import { messageBox } from "../actions/notificationAction";
import { connect } from "react-redux";
//Off Line Support
import OffLineAgentDash from "../containers/OffLineSupport-agent";
import OffLineSupportHome from "../OffLineSupport-Panel/components/Home/home";
import OffLineSupportBookingsPage from "../OffLineSupport-Panel/pages/filter";
import OffLineSupportBookingPage from "../OffLineSupport-Panel/components/Bookings/BookingPage";
import OffLineSupportViewBooking from "../OffLineSupport-Panel/components/Bookings/ViewBooking/index";
import OffLineSupportEditBooking from "../OffLineSupport-Panel/components/Bookings/EditBookingForm/EditBookingForm";
import OffLineSupportAddPackage from "../OffLineSupport-Panel/components/Bookings/EditBookingForm/addPackage";
import OffLineSupportRemovePackage from "../OffLineSupport-Panel/components/Bookings/EditBookingForm/removePackage";
import OffLineSupportStats from "../OffLineSupport-Panel/components/CallsStats";
import OffLineSupportComplaintsBooking from "../OffLineSupport-Panel/tables/queryTicketsBooking";
import OSComplaintsLeads from "../OffLineSupport-Panel/tables/queryTicketsLeads";
import OSLeadSearch from "../OffLineSupport-Panel/components/search/SearchResult";
import OSCallsTable from "../OffLineSupport-Panel/tables/callsTable";
import OSDisposition from "../OffLineSupport-Panel/pages/leadstatus/filter";
import OSDispositionExport from "../OffLineSupport-Panel/pages/leadstatus/export";
import OSSMSDetails from "../OffLineSupport-Panel/tables/smsDetails";
import OSEMAILDetails from "../OffLineSupport-Panel/tables/emailDetails";
import OSMissedCall from "../OffLineSupport-Panel/tables/missedCall";
import OffLineSupportCenterInfo from "../OffLineSupport-Panel/tables/centerTable";
import SupportExecutiveFillInfoPage from "../OffLineSupport-Panel/pages/fill-info";
import OSInbox from "../OffLineSupport-Panel/components/notificationInbox/index";
import OffLineSupportTestPackages from "../OffLineSupport-Panel/pages/testPackages1";
import OSAddAditionalFamily from "../OffLineSupport-Panel/components/Bookings/NewBooking/AddFamilyMember";
import SELabDetails from "../OffLineSupport-Panel/components/LabModal/labDetails";
// import OffLineSupportWithLeadwhatsapp from "../OffLineSupport-Panel/components/CRMChat/bookingWithLeadwhatsapp";
// import OffLineSupportCRMchat from "../OffLineSupport-Panel/components/CRMChat/whatsAppChat";
import SETatTimeBookingtable from "../OffLineSupport-Panel/pages/tatTimeBookingFilter";
import OffLineSupportPhleboBookingView from "../OffLineSupport-Panel/components/PhleboBookingPanel/BookingView";
import OffLineSupportPleboAdminCommentView from "../OffLineSupport-Panel/components/PhleboBookingPanel/CommentView";
import OSImagingEditBookingForm from "../OffLineSupport-Panel/components/Bookings/EditBookingForm/ImagingEditBooking";
import OSPaymentRefundInfo from "../OffLineSupport-Panel/pages/RrfundPayment/refundPaymentFilter";
import OSHelpDesk from "../OffLineSupport-Panel/pages/HelpDesk";
import OSReportNotDeliverd from "../OffLineSupport-Panel/pages/ConsolidateReportBooking/filter";
import OSFailedReport from "../OffLineSupport-Panel/tables/failedReport";
import OSEmployeePerformance from "../OffLineSupport-Panel/pages/Performance/performanceTable";
import OffLineSupportVerificationStatusTable from "../OffLineSupport-Panel/pages/verificationPage";
import OSCallDetails from "../OffLineSupport-Panel/tables/callDetails";
import osHotleads from "../OffLineSupport-Panel/tables/hotLeadTable";
import Views from "../OffLineSupport-Panel/pages/views";
import OSCRMchat from "../OffLineSupport-Panel/components/CRMChat/whatsAppChat";
import OSGrievance from "../OffLineSupport-Panel/pages/Grivance/grivance";
import OSLeadWhatsaap from "../OffLineSupport-Panel/components/CRMChat/bookingWithLeadwhatsapp";
import OSLabInActiveCity from "../OffLineSupport-Panel/tables/InActiveArea";

// Pusher Component
import PusherComponent from "../components/Pusher/index";

function Routes(props) {
  if (window) {
    var path = window.location.pathname;
    var query = window.location.search;
  }
  return (
    <BrowserRouter>
      <MainLayout>
        <SnackBar />
        <PusherComponent />
        <Switch>
          <Route path="/" exact component={Login} />
          <Route
            path="/dashboard"
            exact={false}
            component={() => {
              const token = SecureStorage.getItem("token");
              // const decoded = decode(token);
              if (token) {
                return (
                  <Switch>
                    <Route
                      path="/dashboard"
                      exact
                      component={() => <Dash path={path} query={query} />}
                    />
                    <Route
                      path="/dashboard/os"
                      exact={false}
                      component={() => {
                        return (
                          <Switch>
                            <OffLineAgentDash>
                              <Route
                                exact
                                path="/dashboard/os"
                                component={OffLineSupportHome}
                              />
                              <Route
                                exact
                                path="/dashboard/os/bookingList"
                                component={OffLineSupportBookingsPage}
                              />
                              <Route
                                exact
                                path="/dashboard/os/verificationStatusTable"
                                component={
                                  OffLineSupportVerificationStatusTable
                                }
                              />

                              <Route
                                exact
                                path="/dashboard/os/booking"
                                component={OffLineSupportBookingPage}
                              />
                              <Route
                                exact
                                path="/dashboard/os/labDetails"
                                component={SELabDetails}
                              />
                              <Route
                                exact
                                path="/dashboard/os/booking-view/:id"
                                component={OffLineSupportViewBooking}
                              />
                              <Route
                                exact
                                path="/dashboard/os/booking-edit/:id/edit"
                                component={OffLineSupportEditBooking}
                              />
                              <Route
                                exact
                                path="/dashboard/os/booking-edit/removepackage/:id"
                                component={OffLineSupportRemovePackage}
                              />
                              <Route
                                exact
                                path="/dashboard/os/booking-edit/addpackage/:id"
                                component={OffLineSupportAddPackage}
                              />
                              <Route
                                exact
                                path="/dashboard/os/calls/stats"
                                component={OffLineSupportStats}
                              />
                              <Route
                                exact
                                path="/dashboard/os/booking/tickets"
                                component={OffLineSupportComplaintsBooking}
                              />
                              <Route
                                exact
                                path="/dashboard/os/lead/tickets"
                                component={OSComplaintsLeads}
                              />
                              <Route
                                exact
                                path="/dashboard/os/lead/search"
                                component={OSLeadSearch}
                              />
                              <Route
                                exact
                                path="/dashboard/os/calls_recording"
                                component={OSCallsTable}
                              />
                              <Route
                                exact
                                path="/dashboard/os/dispositions"
                                component={OSDisposition}
                              />
                              <Route
                                exact
                                path="/dashboard/os/performance"
                                component={OSEmployeePerformance}
                              />
                              <Route
                                exact
                                path="/dashboard/os/call_details"
                                component={OSCallDetails}
                              />
                              <Route
                                exact
                                path="/dashboard/os/dispositions/export"
                                component={OSDispositionExport}
                              />
                              <Route
                                exact
                                path="/dashboard/os/lead/smsDetails"
                                component={OSSMSDetails}
                              />
                              <Route
                                path="/dashboard/os/booking-additionalFamily/:id/add"
                                exact
                                component={OSAddAditionalFamily}
                              />
                              <Route
                                exact
                                path="/dashboard/os/lead/emailDetails"
                                component={OSEMAILDetails}
                              />
                              <Route
                                exact
                                path="/dashboard/os/lead/missedCall"
                                component={OSMissedCall}
                              />
                              <Route
                                path="/dashboard/os/fill-info"
                                exact
                                component={SupportExecutiveFillInfoPage}
                              />
                              <Route
                                exact
                                path="/dashboard/os/centerInfo"
                                component={OffLineSupportCenterInfo}
                              />
                              <Route
                                exact
                                path="/dashboard/os/inbox"
                                component={OSInbox}
                              />
                              <Route
                                exact
                                path="/dashboard/os/helpdesk"
                                component={OSHelpDesk}
                              />
                              <Route
                                exact
                                path="/dashboard/os/testPackages"
                                component={OffLineSupportTestPackages}
                              />

                              {/* <Route
                                path="/dashboard/os/whatsapp_chat"
                                exact
                                component={OffLineSupportCRMchat}
                              /> */}
                              {/* <Route
                                exact
                                path="/dashboard/os/booking-with-leadwhatsapp/:id"
                                component={OffLineSupportWithLeadwhatsapp}
                              /> */}
                              <Route
                                exact
                                path="/dashboard/os/phleboBookingView"
                                component={OffLineSupportPhleboBookingView}
                              />

                              <Route
                                exact
                                path="/dashboard/os/timeline/:id/view"
                                component={OffLineSupportPleboAdminCommentView}
                              />

                              <Route
                                exact
                                path="/dashboard/os/tat_timeBooking"
                                component={SETatTimeBookingtable}
                              />
                              <Route
                                path="/dashboard/os/booking-imaging-edit/:id/edit"
                                exact
                                component={OSImagingEditBookingForm}
                              />
                              <Route
                                path="/dashboard/os/hotleads"
                                exact
                                component={osHotleads}
                              />
                              <Route
                                path="/dashboard/os/payment-refund"
                                exact
                                component={OSPaymentRefundInfo}
                              />

                              <Route
                                path="/dashboard/os/report_not_deliverd"
                                exact
                                component={OSReportNotDeliverd}
                              />
                              <Route
                                path="/dashboard/os/failed_report"
                                exact
                                component={OSFailedReport}
                              />
                              <Route
                                exact
                                path="/dashboard/os/views"
                                component={Views}
                              />
                              <Route
                                path="/dashboard/os/whatsapp_chat"
                                exact
                                component={OSCRMchat}
                              />
                              <Route
                                exact
                                path="/dashboard/os/grievance"
                                component={OSGrievance}
                              />
                               <Route
                                path="/dashboard/os/notserviceablearea"
                                exact
                                component={OSLabInActiveCity}
                              />
                            </OffLineAgentDash>
                          </Switch>
                        );
                      }}
                    />
                  </Switch>
                );
              } else {
                return <Redirect to="/" />;
              }
            }}
          />
        </Switch>
        {/* <Modal /> */}
        {/* <SecondModal /> */}
      </MainLayout>
    </BrowserRouter>
  );
}
export default connect(null, { checkUser, messageBox })(Routes);
