export const arr = [
 

  {
    role: "OffLineSupport",
    url: "os",
  },
  
  
];
