import React, { useEffect } from "react";
import { connect } from "react-redux";
import Modal from "@mui/material/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from '@mui/material/Button';
import { makeStyles } from "@material-ui/core/styles";
import {
  startWhatsappChat,
} from "../../../actions/loginActions";
import "./index.sass";
let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow:"scroll",
  },
  imageSM: {
    width: "60px",
    height: "60px",
    border: "2px solid #000 ",
    borderRadius: "50%",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: isMobile ? "400px" : "1200px",
  },
  imagePaper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[1],
  },
  table: {
    maxWidth: "100%",
  },
  tableHead: {
    background: "rgb(146, 74, 145)!important",
  },
  tableHeadCell: {
    color: "#fff",
    fontWeight: "bold",
    textAlign: "center",
  },
  tableContainer: {
    position: "sticky",
    top: "200px",
    overflow:"scroll",
  },
}));

type ModalProps = {
  open: boolean;
  setOpen: Function;
  leadId: number;
  startWhatsappChat: any;
};

const WhatsAppChatStartModal: React.FC<ModalProps> = ({
  open,
  setOpen,
  leadId,
  startWhatsappChat,
}) => {
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div style={{ backgroundColor: "white", display: "flex", flexDirection: "column", height: "18%", padding: "2%", border: "2px solid", borderRadius: "10px" }}>
            <h2>Do you want to start the chat with the customer?</h2>
            <div style={{ display: "flex", justifyContent: "space-around", marginTop: "20px" }}>
              <Button
                variant="contained" color="primary" style={{ width: "200px" }}
                onClick={() => {
                  setOpen(false);
                  startWhatsappChat(leadId);
                }}
              >
                YES
              </Button>
              <Button
                variant="contained" color="secondary" style={{ width: "200px" }}
                onClick={() => {
                  setOpen(false);
                }}
              >
                NO
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  user: state.loginReducer.user,
});

export default connect(mapStateToProps, {
  startWhatsappChat,
})(WhatsAppChatStartModal);