import React, { useState, useEffect } from 'react'
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Chip, Paper, withStyles, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from '@material-ui/core';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from '../../components/Loader2';
import moment from 'moment';
import { MessageRounded } from '@material-ui/icons';
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import { Grid, Select, MenuItem, TextField } from '@material-ui/core'
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        paper: {
            marginTop: "2rem",
            padding: "0.5rem 2rem",
            width: "100%",
        },
        table: {
            maxWidth: "100%",
            margin: "auto"
        },
        conlorChange: {
            background: "red"
        },
        tableContainer: {
            marginTop: "2rem",
            position: "sticky",
            top: "200px",
        },

    })
);
const StyledTableCell: any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#924A91",
            color: theme.palette.common.white,
            fontSize: "14px",
        },
        body: {
            fontSize: 14,
        },
    })
)(TableCell);
const StyledTableRow: any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);

interface Props {
     loading: boolean;
    paymentRefundList: any
    getRefundPaymentList: any
    setOpenRefundModal:any;
    setButtoneType:any;
    setCreatePaymentMode:any
    setCreateAmount:any
    setCreateRemarks:any
    setCreateBookingId:any
    setApiType:any
    setRefundId:any
    refundLoder:boolean,

}

const BookingsTable: React.FC<Props> = ({
    loading,
    paymentRefundList,
    getRefundPaymentList,
    setOpenRefundModal,
    setButtoneType,
    setCreatePaymentMode,
    setCreateAmount,
    setCreateRemarks,
    setCreateBookingId,
    setApiType,
    setRefundId,
    refundLoder
}) => {
    const classes = useStyles()
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        if (newPage > page) {
            let url = paymentRefundList.next;
            getRefundPaymentList(url.substring(url.indexOf("?")));
        } else if (newPage < page) {
            let url = paymentRefundList.previous;
            getRefundPaymentList(
                url.indexOf("?") === -1 ? "" : url.substring(url.indexOf("?"))
            );
        }
        setPage(newPage);
    };
    const handleUpdateRefund =(data:any)=>{
        setOpenRefundModal(true)
        setButtoneType("Update Refund")
        setCreatePaymentMode(data?.payment_mode)
        setCreateAmount(data?.amount)
        setCreateRemarks(data?.cs_remarks)
        setCreateBookingId(data?.booking)
        setRefundId(data?.id)
        setApiType("put")
    }


    return (
        <div style={{ width: "100%" }}>

            <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "560px" }}>
                {refundLoder ? <Loader /> : (
                    <Table stickyHeader aria-label="simple table">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell align="center">Update</StyledTableCell>
                                <StyledTableCell align="center">Booking Id</StyledTableCell>
                                <StyledTableCell align="center">Amount</StyledTableCell>
                                <StyledTableCell align="center">Status</StyledTableCell>
                                <StyledTableCell align="center">Payment_Mode</StyledTableCell>
                                <StyledTableCell align="center">CS_Remarks</StyledTableCell>
                                <StyledTableCell align="center">TL_Remarks</StyledTableCell>
                                <StyledTableCell align="center">Manager_Remarks</StyledTableCell>
                                <StyledTableCell align="center">Finance_Remarks</StyledTableCell>
                                <StyledTableCell align="center">Refund_Initiate_By</StyledTableCell>
                                {/* <StyledTableCell align="center">Refund_Initiate_Date</StyledTableCell> */}
                                <StyledTableCell align="center">Account_Holder_Name</StyledTableCell>
                                <StyledTableCell align="center">Account_Number</StyledTableCell>
                                <StyledTableCell align="center">Bank_Name</StyledTableCell>
                                <StyledTableCell align="center">Ifsc_Code</StyledTableCell>
                                <StyledTableCell align="center">Transfer_By</StyledTableCell>
                                <StyledTableCell align="center">Transfer_Date</StyledTableCell>
                                <StyledTableCell align="center">Approved_By_Manager</StyledTableCell>
                                <StyledTableCell align="center">Approved_By_Tl</StyledTableCell>
                                
                               

                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {paymentRefundList && paymentRefundList?.results && paymentRefundList?.results.length > 0 && paymentRefundList?.results.map((data: any, index: any) => {
                                return (
                                    <StyledTableRow key={index}>
                                        <StyledTableCell align="center">
                                            <Button
                                             style={{background:"#2196f3"}}
                                              variant="outlined"
                                               startIcon={<ModeEditIcon />}
                                               onClick={()=>handleUpdateRefund(data)}
                                               >
                                                Edit
                                            </Button>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {data?.booking || "-"}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {data?.amount || "-"}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {data?.status || "-"}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {data?.payment_mode || "-"}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {data?.cs_remarks || "-"}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {data?.tl_remarks || "-"}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {data?.manager_remarks || "-"}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {data?.finance_remarks || "-"}
                                        </StyledTableCell>
                                      
                                        <StyledTableCell align="center">
                                            {data?.refund_initiate_by_username || "-"}
                                        </StyledTableCell>
                                        {/* <StyledTableCell align="center">
                                            {data?.transfer_date.substring(0,10)}
                                        </StyledTableCell> */}
                                        <StyledTableCell align="center">
                                            {data?.account_holder_name || "-"}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {data?.account_number || "-"}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {data?.bank_name || "-"}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {data?.ifsc_code || "-"}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {data?.transfer_by_username || "-"}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {data?.transfer_date !== null ? data?.transfer_date.substring(0, 10) : "-"}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {data?.action_by_manager_username || "-"}
                                        </StyledTableCell> <StyledTableCell align="center">
                                            {data?.action_by_tl_username || "-"}
                                        </StyledTableCell>
                                       

                                    </StyledTableRow>
                                )
                            })}
                        </TableBody>
                        <TableFooter>
                            <StyledTableRow>
                                <TablePagination
                                    colSpan={40}
                                    count={paymentRefundList.count || 0}
                                    rowsPerPageOptions={[]}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                />
                            </StyledTableRow>
                        </TableFooter>
                    </Table>
                )}
            </TableContainer>


        </div>
    )
}

const mapStateToProps = (state: any) => ({


})

export default connect(mapStateToProps, {

})(BookingsTable);

