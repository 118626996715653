import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from '@mui/material/Modal';
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button, Paper, MenuItem, Select, TextField, Grid, TableCell, TableContainer, Table, withStyles, TableBody, TableHead, TableRow } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import {  raiseNewTicektDisposition } from '../../actions/OffLineSupportActions';
import { useEffect } from "react";
import Loader from '../Loader2';
import { Link } from 'react-router-dom';

let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: 'scroll',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: isMobile ? "400px" : "1200px",
        overflow: 'scroll'
    },
    head: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "2rem"
    },
    table: {
        maxWidth: "100%",
        margin: "auto",
        overflow: 'scroll'
    },
    tableContainer: {
        marginTop: "2rem",
        position: "sticky",
        top: "200px",
        overflow: 'scroll'

    },
}));
const StyledTableCell: any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#924A91",
            color: theme.palette.common.white,
            fontSize: "14px",
        },
        body: {
            fontSize: 14,
        },

    })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);

type ModalProps = {
    open: boolean;
    bookingId: number;
    getBookingQueryTickets: any;
    raiseNewTicektDisposition: any;
    bookingComplaints: any;
    leadId: number;
    setOpen: Function;
    loading: any;
    ticketLoader:boolean;

};

const CommentsModal2: React.FC<ModalProps> = ({
    open,
    bookingId,
    leadId,
    raiseNewTicektDisposition,
    getBookingQueryTickets,
    bookingComplaints,
    setOpen,
    loading,
    ticketLoader,

}) => {
    const classes = useStyles();

    const [query, setQuery] = useState("none")
    const [description, setDescription] = useState("")
    const [status, setStatus] = useState("none")
    const [newTicket, setNewTicket] = useState<boolean>(false)
    const [disposition, setDisposition] = useState<any>("")

    const handleClose = () => {
        setOpen(false);
        setNewTicket(false)
    };

    const raiseComplaint = () => {
        const body: any = {
            query: description,
            disposition: disposition,
            lead_id: leadId,
            booking_id: bookingId
        }
        if (disposition === "") {
            delete body.disposition
        }
        raiseNewTicektDisposition(body)
        setQuery("none")
        setDescription("")
    };

    useEffect(() => {
        if (bookingComplaints) {
            handleClose()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookingComplaints])
    const handleCross = () => {
        setOpen(false)
        setNewTicket(false)
    }

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <div className={classes.head}>
                            <h4
                                style={{
                                    marginBottom: "1rem",
                                }}
                                id="transition-modal-title"
                            >
                                Raise a query ticket
                            </h4>
                            <CloseIcon onClick={handleCross} />
                        </div>
                        {bookingComplaints?.results && bookingComplaints?.results.length > 0 && !newTicket ? (
                            <>
                                <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "500px" }}>
                                    {ticketLoader ? <Loader /> : (
                                        <Table stickyHeader aria-label="simple table">
                                            <TableHead>
                                                <StyledTableRow>
                                                    <StyledTableCell align="center">Booking Id</StyledTableCell>
                                                    <StyledTableCell align="center">Child Id</StyledTableCell>
                                                    <StyledTableCell align="center">Agent</StyledTableCell>
                                                    <StyledTableCell align="center">Created At</StyledTableCell>
                                                    <StyledTableCell align="center">Query Type</StyledTableCell>
                                                    <StyledTableCell align="center">Query Description</StyledTableCell>
                                                    <StyledTableCell align="center">Receiver Remarks</StyledTableCell>
                                                    <StyledTableCell align="center">Status</StyledTableCell>
                                                    <StyledTableCell align="center">View</StyledTableCell>
                                                </StyledTableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    bookingComplaints?.results && bookingComplaints?.results.length > 0 && bookingComplaints?.results.map((ticket: any, index: any) => {
                                                        return (
                                                            <StyledTableRow key={index}>
                                                                <StyledTableCell align="center">{ticket?.booking !== null ? (ticket?.booking?.id || ticket?.booking?.pk) : (ticket?.add_booking?.mainbooking)}</StyledTableCell>
                                                                <StyledTableCell align="center">{ticket?.booking === null ? (ticket?.add_booking.id) : ""}</StyledTableCell>
                                                                <StyledTableCell align="center">{ticket.panel_user && ticket.panel_user.name}</StyledTableCell>
                                                                <StyledTableCell align="center">{new Date(ticket.created_at).toLocaleString()}</StyledTableCell>
                                                                <StyledTableCell align="center">{ticket.querytype}</StyledTableCell>
                                                                <StyledTableCell align="center">{ticket.query}</StyledTableCell>
                                                                <StyledTableCell align="center">{ticket.receicer_remarks}</StyledTableCell>
                                                                <StyledTableCell align="center">{ticket.status}</StyledTableCell>
                                                                <StyledTableCell align="center">
                                                                    <Link to={`/dashboard/os/booking-view/${ticket?.booking !== null ? (ticket?.booking?.id || ticket?.booking?.pk) : (ticket?.add_booking?.mainbooking)}`}>
                                                                        <Button
                                                                            color="secondary"
                                                                            variant="contained"
                                                                        >
                                                                            View
                                                                        </Button>
                                                                    </Link>
                                                                </StyledTableCell>
                                                            </StyledTableRow>
                                                        )
                                                    })
                                                }
                                            </TableBody>
                                        </Table>
                                    )}
                                </TableContainer>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    style={{ height: "50px" }}
                                    onClick={() => setNewTicket(true)}
                                >Do You Want to Raise New Ticket</Button>
                            </>
                        ) : (
                            <Grid container direction="row" alignItems="flex-start" justify="space-between" spacing={3} style={{ margin: "1rem auto" }}>
                                {/* <Grid item xs={12} md={12}>
                                    <Select className="input"
                                        name="action"
                                        variant="outlined"
                                        value={query}
                                        style={{ width: "100%" }}
                                        onChange={(e) => setQuery(e.target.value as string)}
                                    >
                                        <MenuItem disabled value={"none"}>Query Type</MenuItem>
                                        <MenuItem value={"SampleAccessioning"}>SampleAccessioning</MenuItem>
                                        <MenuItem value={"CustomerSupport"}>CustomerSupport</MenuItem>
                                        <MenuItem value={"PhleboAdmin"}>PhleboAdmin</MenuItem>
                                        <MenuItem value={"RouteManager"}>Route Manager</MenuItem>
                                        <MenuItem value={"Doctor"}>Doctor</MenuItem>
                                    </Select>
                                </Grid> */}
                                {/* {query === "CustomerSupport" || query === "CustomerSupport" ? ( */}
                                <Grid item xs={12} >
                                <p>Ticket Type</p>
                                    <Select className="input"
                                        name="action"
                                        variant="outlined"
                                        value={disposition}
                                        style={{ width: "100%" }}
                                        onChange={(e) => setDisposition(e.target.value as string)}

                                    >
                                        <MenuItem value={"report_is_not_released_within_tat"}> Report Is Not Released Within TAT</MenuItem>
                                        <MenuItem value={"delay_sample_pickup_from_my_location"}>Delay Sample Pickup From My Location</MenuItem>
                                        <MenuItem value={"patient_details_changes_required_in_the_report"}>Patient Details Changes Required In The Report</MenuItem>
                                        <MenuItem value={"recharge_is_not_reflecting_in_the_ledger"}>Recharge Is Not Reflecting In The Ledger</MenuItem>
                
                                        <MenuItem value={"wrong_test_charge_in_the_ledger"}>Wrong test charge in the ledger</MenuItem>
                                        <MenuItem value={"portal_is_not_working"}>Portal is not working</MenuItem>
                                        <MenuItem value={"not_able_to_dowanload_the_report"}>Not Able To Dowanload The Report</MenuItem>
                
                                        <MenuItem value={"others"}>Others</MenuItem>
                                    </Select>
                                </Grid>
                                {/* ) : ""} */}

                                <Grid item xs={12}>
                                    <TextField
                                        name="comment"
                                        type="text"
                                        multiline
                                        rows={3}
                                        value={description}
                                        className="input"
                                        variant="outlined"
                                        placeholder="Description"
                                        onChange={(e) => setDescription(e.target.value as string)}
                                        style={{ width: "100%" }}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        style={{ height: "50px" }}
                                        onClick={raiseComplaint}
                                        disabled={description === ""}                                    >
                                        Submit
                                    </Button>
                                </Grid>
                            </Grid>
                        )}


                    </div>
                </Fade>
            </Modal>
        </div>
    );
};

const mapStateToProps = (state: any) => ({
    loading: state.OffLineSupporteReducer.loading,
    ticketLoader: state.OffLineSupporteReducer.ticketLoader
});

export default connect(mapStateToProps, {
    
    raiseNewTicektDisposition,
})(CommentsModal2);
