import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Loader from '../components/loader/index';
import { searchCall } from "../actions/OffLineSupportActions";
import { useHistory } from "react-router-dom";
import { ExitToAppRounded } from "@material-ui/icons";
import CommentsModal from "../components/Comments/comment";
import MessageIcon from "@material-ui/icons/Message";
import { Link } from "react-router-dom";
import ViewIcon from "@material-ui/icons/RemoveRedEye";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
    table: {
      minWidth: 700,
    },
  })
);


const StyledTableCell:any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow:any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface Props {
  getTodaysMissedCalls: any;
  todayMissedCallsList: any;
  loading: boolean;
  showAllCollumn: boolean
}

const MissedCallsList: React.FC<Props> = ({
  getTodaysMissedCalls,
  loading,
  todayMissedCallsList,
  showAllCollumn,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState<boolean>(false);
  const [leadId, setLeadId] = React.useState<number>(0);

  const viewLead = (id: any) => {
    let body = {
      search_by: "call_id",
      value: `${id}`,
    };
    dispatch(searchCall(body));
    history.push("/dashboard/os/lead/search");
  };
  const handleClick = (id: number) => {
    setOpen(true);
    setLeadId(id);
  };

  return (
    <>
      <div className="data-table" style={{ width: "100%", paddingTop: "20px" }}>

        <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "700px" }}>
          {loading ? <Loader /> : (

            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <StyledTableRow style={{ height: "50px", padding: "5px" }}>
                  {showAllCollumn && (
                    <StyledTableCell align="center">S No.</StyledTableCell>
                  )}
                  <StyledTableCell align="center">Lead ID</StyledTableCell>
                  <StyledTableCell align="center">Comment</StyledTableCell>
                  {showAllCollumn && (
                    <>
                      <StyledTableCell align="center">Username</StyledTableCell>
                      <StyledTableCell align="center">Usergroup</StyledTableCell>
                    </>
                  )}
                  <StyledTableCell align="center">Direction</StyledTableCell>
                  <StyledTableCell align="center">Incoming Call Time</StyledTableCell>
                  <StyledTableCell align="center">View</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {todayMissedCallsList.count > 0 ? (
                  todayMissedCallsList?.missedincomming?.map((row: any, index: number) => (
                    <StyledTableRow
                      key={index}
                    >
                      {showAllCollumn && (
                        <StyledTableCell component="th" scope="row">
                          {index + 1}
                        </StyledTableCell>
                      )}
                      <StyledTableCell align="center">
                        {row?.lead ? row?.lead : "NA"}
                        <ExitToAppRounded
                          onClick={() => viewLead(row?.lead)}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <MessageIcon onClick={() => handleClick(row?.lead)} />
                      </StyledTableCell>

                      {showAllCollumn && (
                        <>
                          <StyledTableCell align="center">
                            {row?.username ? row?.username : "NA"}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row?.usergroup ? row?.usergroup : "NA"}
                          </StyledTableCell>
                        </>
                      )}

                      <StyledTableCell align="center">
                        {row?.direction ? row?.direction : "NA"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row?.leadcalltime ? new Date(row?.leadcalltime).toLocaleString() : "NA"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Link
                          to={`/dashboard/os/fill-info?id=${row?.lead}`}
                        >
                          <ViewIcon />
                        </Link>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <h4 style={{ padding: '10px' }}>No Data Found</h4>
                )}
              </TableBody>
            </Table>
          )}
        </TableContainer>
        <CommentsModal open={open} setOpen={setOpen} leadId={leadId} />
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({

});

export default connect(mapStateToProps, { searchCall })(
  MissedCallsList
);
