export const generateCallStatusUrl = (body) => {
  const {
    lead_status,
    panel_user,
    provider,
    lead,
    qc_agent,
    start_date,
    end_date,
    direction,
    phonenumber,
  } = body;
  const lead_status_part = isSelectedNum(lead_status)
    ? `&lead_status=${lead_status}`
    : "";
  const panel_user_part = isSelectedNum(panel_user)
    ? `&panel_user=${panel_user}`
    : "";
  const provider_part = isSelectedNum(provider) ? `&provider=${provider}` : "";
  const lead_part = isSelectedNum(lead) ? `&lead=${lead}` : "";
  const phonenumber_part = isSelectedNum(phonenumber)
    ? `&phonenumber=${phonenumber}`
    : "";
  const qc_agent_part = isSelectedNum(qc_agent)
    ? `&quality_user=${qc_agent}`
    : "";
  const start_date_part = isSelected(start_date)
    ? `&start_date=${start_date}`
    : "";
  const end_date_part = isSelected(end_date) ? `&end_date=${end_date}` : "";
  const direction_part = isSelectedNum(direction)
    ? `&direction=${direction}`
    : "";

  const url = `?${lead_status_part}${panel_user_part}${provider_part}${lead_part}${qc_agent_part}${start_date_part}${end_date_part}${direction_part}${phonenumber_part}`;

  return url;
};

const isSelected = (field) => {
  if (
    field === "all" ||
    field === "" ||
    field === undefined ||
    field === null ||
    field === "none"
  ) {
    return false;
  } else {
    return true;
  }
};

const isSelectedNum = (field) => {
  if (
    field === 0 ||
    field === "" ||
    field === undefined ||
    field === null ||
    field === "none"
  ) {
    return false;
  } else {
    return true;
  }
};

export const generateCallsUrl = (body) => {
  const {
    city,
    lead_source,
    treatment,
    panel_user,
    priority,
    language,
    followup_date_start,
    followup_date_end,
    status,
    day,
  } = body;
  const city_part = isSelectedNum(city) ? `&city=${city}` : "";
  const source_part = isSelected(lead_source)
    ? `&lead_source=${lead_source}`
    : "";
  const treatment_part = isSelectedNum(treatment)
    ? `&treatment=${treatment}`
    : "";
  const panel_user_part = isSelectedNum(panel_user)
    ? `&panel_user=${panel_user}`
    : "";
  const priority_part = isSelected(priority) ? `&priority=${priority}` : "";
  const language_part = isSelected(language) ? `&language=${language}` : "";
  const followup_date_start_part = isSelected(followup_date_start)
    ? `&followup_date_start=${followup_date_start}`
    : "";
  const followup_date_end_part = isSelected(followup_date_end)
    ? `&followup_date_end=${followup_date_end}`
    : "";
  const status_part = isSelected(status) ? `&status=${status}` : "";
  const url = `?day=${day}${city_part}${source_part}${treatment_part}${panel_user_part}${priority_part}${language_part}${followup_date_start_part}${followup_date_end_part}${status_part}`;

  return url;
};

export const generateAppointmentsUrl = (body) => {
  const {
    appt_status,
    center,
    feedbackstatus,
    doctor,
    treatment,
    panel_user,
    priority,
    appt_date_start,
    appt_date_end,
    day,
    source,
    treatment_id,
    lead_id,
    feedback,
  } = body;
  const appt_status_part = isSelectedNum(appt_status)
    ? `&appt_status=${appt_status}`
    : "";
  const feedbackstatus_part = isSelectedNum(feedbackstatus)
    ? `&feedbackstatus=${feedbackstatus}`
    : "";
  const feedback_part = isSelectedNum(feedback) ? `&feedback=${feedback}` : "";
  const center_part = isSelectedNum(center) ? `&center=${center}` : "";
  const doctor_part = isSelectedNum(doctor) ? `&doctor=${doctor}` : "";
  const treatment_part = isSelectedNum(treatment)
    ? `&treatment=${treatment}`
    : "";
  const panel_user_part = isSelectedNum(panel_user)
    ? `&panel_user=${panel_user}`
    : "";
  const source_part = isSelectedNum(source) ? `&source=${source}` : "";
  const priority_part = isSelected(priority) ? `&priority=${priority}` : "";
  const appt_date_start_part = isSelected(appt_date_start)
    ? `&appt_date_start=${appt_date_start}`
    : "";
  const appt_date_end_part = isSelected(appt_date_end)
    ? `&appt_date_end=${appt_date_end}`
    : "";
  const treatment_id_part = isSelected(treatment_id)
    ? `&treatment_id=${treatment_id}`
    : "";
  const lead_id_part = isSelected(lead_id) ? `&lead_id=${lead_id}` : "";
  const day_part = isSelected(day) ? `day=${day}` : "";

  const url = `?${day_part}${appt_status_part}${center_part}${doctor_part}${treatment_part}${panel_user_part}${priority_part}${appt_date_start_part}${appt_date_end_part}${treatment_id_part}${lead_id_part}${feedback_part}${source_part}${feedbackstatus_part}`;

  return url;
};

export const generatePositiveCallsUrl = (body) => {
  const {
    city,
    lead_source,
    treatment,
    panel_user,

    followup_date_start,
    followup_date_end,

    day,
  } = body;
  const city_part = isSelectedNum(city) ? `&city=${city}` : "";
  const source_part = isSelected(lead_source)
    ? `&lead_source=${lead_source}`
    : "";
  const treatment_part = isSelectedNum(treatment)
    ? `&treatment=${treatment}`
    : "";
  const panel_user_part = isSelectedNum(panel_user)
    ? `&panel_user=${panel_user}`
    : "";

  const followup_date_start_part = isSelected(followup_date_start)
    ? `&followup_date_start=${followup_date_start}`
    : "";
  const followup_date_end_part = isSelected(followup_date_end)
    ? `&followup_date_end=${followup_date_end}`
    : "";
  const day_part = isSelected(day) ? `day=${day}` : "";

  const url = `?${day_part}${city_part}${source_part}${treatment_part}${panel_user_part}${followup_date_start_part}${followup_date_end_part}`;

  return url;
};
export const genrateLabDetailsFilter = (body) => {
  const { zone } = body;
  const lab_zone = isSelectedNum(zone) ? `&zone=${zone}` : "";
  const url = `?${lab_zone}`;

  return url;
};
export const genrateRefundPaymentFilter = (body) => {
  const {
    amount,
    status,
    payment_mode,
    account_holder_name,
    account_number,
    bank_name,
    booking,
  } = body;
  const refund_booking = isSelectedNum(booking) ? `&booking=${booking}` : "";
  const refund_amount = isSelectedNum(amount) ? `&amount=${amount}` : "";
  const refund_status = isSelectedNum(status) ? `&status=${status}` : "";
  const refund_payment_mode = isSelectedNum(payment_mode)
    ? `&payment_mode=${payment_mode}`
    : "";
  const refund_account_holder_name = isSelectedNum(account_holder_name)
    ? `&account_holder_name=${account_holder_name}`
    : "";
  const refund_account_number = isSelectedNum(account_number)
    ? `&account_number=${account_number}`
    : "";
  const refund_bank_name = isSelectedNum(bank_name)
    ? `&bank_name=${bank_name}`
    : "";
  const url = `?${refund_booking}${refund_amount}${refund_status}${refund_payment_mode}${refund_account_holder_name}${refund_account_number}${refund_bank_name}`;

  return url;
};
export const generateHealthDataFilterUrl = (body) => {
  const {
    booking,
    add_booking,
    customer_phonenumber,
    customer_name,
    test_parameter,
    start_date,
    end_date,
    package_id,
    normal,
  } = body;
  const health_booking = isSelectedNum(booking) ? `&booking=${booking}` : "";
  const health_add_booking = isSelectedNum(add_booking)
    ? `&add_booking=${add_booking}`
    : "";
  const health_normal = isSelectedNum(normal) ? `&normal=${normal}` : "";
  const health_customer_phonenumber = isSelectedNum(customer_phonenumber)
    ? `&customer_phonenumber=${customer_phonenumber}`
    : "";
  const health_customer_name = isSelectedNum(customer_name)
    ? `&customer_name=${customer_name}`
    : "";
  const health_test_parameter = isSelectedNum(test_parameter)
    ? `&test_parameter=${test_parameter}`
    : "";
  const health_start_date = isSelectedNum(start_date)
    ? `&start_date=${start_date}`
    : "";
  const health_end_date = isSelectedNum(end_date)
    ? `&end_date=${end_date}`
    : "";
  const health_package = isSelectedNum(package_id)
    ? `&package_id=${package_id}`
    : "";
  const url = `?${health_normal}${health_booking}${health_add_booking}${health_customer_phonenumber}${health_customer_name}${health_test_parameter}${health_start_date}${health_end_date}${health_package}`;

  return url;
};
export const genrateCenterInformationFilter = (body) => {
  const { city, area } = body;
  const center_city = isSelectedNum(city) ? `&city=${city}` : "";
  const center_area = isSelectedNum(area) ? `&area=${area}` : "";
  const url = `?${center_city}${center_area}`;

  return url;
};
export const genrateCarrerFilter = (body) => {
  const { email, name, job_profile, experience } = body;
  const carrer_email = isSelectedNum(email) ? `&email=${email}` : "";
  const carrer_name = isSelectedNum(name) ? `&name=${name}` : "";
  const carrer_jobProfile = isSelectedNum(job_profile)
    ? `&job_profile=${job_profile}`
    : "";
  const carrer_exprience = isSelectedNum(experience)
    ? `&experience=${experience}`
    : "";
  const url = `?${carrer_email}${carrer_name}${carrer_jobProfile}${carrer_exprience}`;
  return url;
};
export const genratePanelUserFiltr = (body) => {
  const {
    usergroup,
    is_active,
    tl,
    username,
    on_call,
    break_button,
    inbound_mode,
    is_imaging_lead,
    is_homedx_lead,
  } = body;
  const panel_usergroup = isSelectedNum(usergroup)
    ? `&usergroup=${usergroup}`
    : "";
  const panel_userStatus = isSelectedNum(is_active)
    ? `&is_active=${is_active}`
    : "";
  const panel_Break = isSelectedNum(break_button)
    ? `&break_button=${break_button}`
    : "";
  const panel_OnCall = isSelectedNum(on_call) ? `&on_call=${on_call}` : "";
  const panel_panelUser = isSelectedNum(tl) ? `&tl=${tl}` : "";
  const panel_username = isSelectedNum(username) ? `&username=${username}` : "";
  const inboundMode = isSelectedNum(inbound_mode)
    ? `&inbound_mode=${inbound_mode}`
    : "";
  const imagingLead = isSelectedNum(is_imaging_lead)
    ? `&is_imaging_lead=${is_imaging_lead}`
    : "";
  const homedxLead = isSelectedNum(is_homedx_lead)
    ? `&is_homedx_lead=${is_homedx_lead}`
    : "";
  const url = `?${panel_Break}${panel_OnCall}${panel_username}${panel_usergroup}${panel_userStatus}${panel_panelUser}${inboundMode}${imagingLead}${homedxLead}`;
  return url;
};
export const genrateUnregisterdBookingFilter = (body) => {
  const { multiple_booking } = body;
  const unregisterd_bookingId = isSelectedNum(multiple_booking)
    ? `&multiple_booking=${multiple_booking}`
    : "";
  const url = `?${unregisterd_bookingId}`;

  return url;
};

export const generateRecordingUrl = (body) => {
  const {
    lead_status,
    panel_user,
    usergroup,
    provider,
    lead,
    booking_id,
    source,
    qc_agent,
    start_date,
    end_date,
    direction,
    phonenumber,
    status,
    callToNumber,
    category,
    city,
    lead_source,
    call_sid,
    source_type,
    hangup_clause,
    did,
  } = body;
  const lead_status_part = isSelectedNum(lead_status)
    ? `&lead_status=${lead_status}`
    : "";
  const booking_call_sid = isSelectedNum(call_sid)
    ? `&call_sid=${call_sid}`
    : "";
  const booking_source = isSelectedNum(source)
    ? `&multiple_source=${source}`
    : "";
  const booking_city = isSelectedNum(city) ? `&multiple_city=${city}` : "";
  const booking_call_to = isSelectedNum(callToNumber)
    ? `&call_to=${callToNumber}`
    : "";
  const lead_lead_source = isSelectedNum(lead_source)
    ? `&lead_source=${lead_source}`
    : "";
  const lead_hangupClause = isSelectedNum(hangup_clause)
    ? `&hangup_clause=${hangup_clause}`
    : "";
  const panel_user_part = isSelectedNum(panel_user)
    ? `&panel_user=${panel_user}`
    : "";
  const provider_part = isSelectedNum(provider) ? `&provider=${provider}` : "";
  const recording_status = isSelectedNum(status)
    ? `&call_picked=${status}`
    : "";
  const recording_category = isSelectedNum(category)
    ? `&category=${category}`
    : "";
  const usergroup_category = isSelectedNum(usergroup)
    ? `&usergroup=${usergroup}`
    : "";
  const lead_part = isSelectedNum(lead) ? `&lead=${lead}` : "";
  const booking_part = isSelectedNum(booking_id)
    ? `&booking=${booking_id}`
    : "";
  const phonenumber_part = isSelectedNum(phonenumber)
    ? `&phonenumber=${phonenumber}`
    : "";
  const qc_agent_part = isSelectedNum(qc_agent)
    ? `&quality_user=${qc_agent}`
    : "";
  const start_date_part = isSelected(start_date)
    ? `&start_date=${start_date}`
    : "";
  const end_date_part = isSelected(end_date) ? `&end_date=${end_date}` : "";
  const direction_part = isSelectedNum(direction)
    ? `&direction=${direction}`
    : "";
  const booking_source_type = isSelected(source_type)
    ? `&source_type=${source_type}`
    : "";
  const recording_url_filter = did ? `&did=${did}` : "";
  const url = `?${lead_hangupClause}${booking_city}${booking_call_to}${booking_source}${lead_lead_source}${lead_status_part}${booking_part}${recording_category}${recording_status}${panel_user_part}${usergroup_category}${provider_part}${lead_part}${qc_agent_part}${start_date_part}${end_date_part}${direction_part}${phonenumber_part}${booking_call_sid}${booking_source_type}${recording_url_filter}`;

  return url;
};

export const generateBookingFilterUrl = (body) => {
  const {
    status,
    financeStatus,
    category,
    verificationStatus,
    source,
    agent,
    phlebo,
    city,
    start_date,
    end_date,
    booking_date,
    collection_date,
    name,
    phone,
    pickupAmount,
    pickup_status,
    pickup_done,
    bookingId,
    partner,
    user_group,
    slot,
    sample_registered,
    report_status,
    is_pickup_receive_amount,
    booking_type,
    due_date,
    due_start_date,
    due_end_date,
    assigned_status,
    createdAt,
    // lead_status,
    lead_call_status_id,
    route_manager,
    verified_by,
    created_date,
    journey,
    tat_status,
    client_refid,
    org_types,
    tl,
    collection_start_date,
    collection_end_date,
    barcode,
    doctor,
    disposition,
    report_not_status,
    lead_id,
    lead,
    labName,
    email,
    bucket,
    ticket_closed_date_start,
    ticket_closed_date_end,
  } = body;
  const booking_category =
    isSelectedNum(category) && category !== "0"
      ? `&multiple_disposition=${category}`
      : "";
  const booking_status = isSelectedNum(status)
    ? `&multiple_booking_status=${status}`
    : "";
  const booking_category_status = isSelectedNum(lead_call_status_id)
    ? `&lead_call_status_id=${lead_call_status_id}`
    : "";
  const booking_id = isSelectedNum(bookingId) ? `&booking_id=${bookingId}` : "";
  const booking_email = isSelectedNum(email) ? `&email=${email}` : "";
  const booking_bucket = isSelectedNum(bucket) ? `&bucket=${bucket}` : "";
  const booking_verified_by = isSelectedNum(verified_by)
    ? `&verified_by=${verified_by}`
    : "";
  const booking_report_not_status = isSelectedNum(report_not_status)
    ? `&status=${report_not_status}`
    : "";
  const booking_routemanager = isSelectedNum(route_manager)
    ? `&route_manager=${route_manager}`
    : "";
  const booking_tatStatus = isSelectedNum(tat_status)
    ? `&tat_status=${tat_status}`
    : "";
  const booking_clientRefId = isSelectedNum(client_refid)
    ? `&client_refid=${client_refid}`
    : "";
  const booking_disposition = isSelectedNum(disposition)
    ? `&disposition=${disposition}`
    : "";
  const booking_lead_id = isSelectedNum(lead_id) ? `&lead_id=${lead_id}` : "";
  const booking_labName = isSelectedNum(labName) ? `&lab=${labName}` : "";
  const booking_lead = isSelectedNum(lead) ? `&lead=${lead}` : "";
  const booking_phlebojourney = isSelectedNum(journey)
    ? `&journey=${journey}`
    : "";
  const booking_partner = isSelectedNum(partner)
    ? `&multiple_partner=${partner}`
    : "";
  const booking_user_group = isSelectedNum(user_group)
    ? `&multiple_usergroup=${user_group}`
    : "";
  const verification_status = isSelectedNum(verificationStatus)
    ? `&multiple_verification_status=${verificationStatus}`
    : "";
  const booking_source = isSelectedNum(source)
    ? `&multiple_source=${source}`
    : "";
  const booking_agent = isSelectedNum(agent) ? `&multiple_agent=${agent}` : "";
  const booking_doctor = isSelectedNum(doctor) ? `&doctor=${doctor}` : "";
  const booking_phlebo = isSelectedNum(phlebo)
    ? `&multiple_phlebo=${phlebo}`
    : "";
  const booking_ticketstart_date = isSelectedNum(ticket_closed_date_start)
    ? `&ticket_closed_date_start=${ticket_closed_date_start}`
    : "";
  const booking_ticketend_date = isSelectedNum(ticket_closed_date_end)
    ? `&ticket_closed_date_end=${ticket_closed_date_end}`
    : "";
  const booking_city = isSelectedNum(city) ? `&multiple_city=${city}` : "";
  const booking_collection_slots = isSelectedNum(slot)
    ? `&multiple_collection_slots=${slot}`
    : "";
  const booking_start_date = isSelected(start_date)
    ? `&start_date=${start_date}`
    : "";
  const booking_booking_date = isSelected(booking_date)
    ? `&booking_date=${booking_date}`
    : "";
  const booking_tl = isSelected(tl) ? `&tl=${tl}` : "";
  const booking_end_date = isSelected(end_date) ? `&end_date=${end_date}` : "";
  const booking_due_date = isSelected(due_date) ? `&due_date=${due_date}` : "";
  const booking_due_start_date = isSelected(due_start_date)
    ? `&due_start_date=${due_start_date}`
    : "";
  const booking_due_end_date = isSelected(due_end_date)
    ? `&due_end_date=${due_end_date}`
    : "";
  const booking_collection_date = isSelected(collection_date)
    ? `&collection_date=${collection_date}`
    : "";
  const booking_created_date = isSelected(created_date)
    ? `&created_date=${created_date}`
    : "";
  const booking_barcode = isSelected(barcode) ? `&barcode=${barcode}` : "";
  const booking_name = isSelectedNum(name) ? `&name=${name}` : "";
  const booking_phone = isSelectedNum(phone) ? `&phone=${phone}` : "";
  const booking_pickup_status = isSelectedNum(pickup_status)
    ? `&multiple_pickup_status=${pickup_status}`
    : "";
  const booking_pickup_done = isSelectedNum(pickup_done)
    ? `&pickup_done=${pickup_done}`
    : "";
  const booking_pickup_amount = isSelectedNum(pickupAmount)
    ? `&pickup_amount=${pickupAmount}`
    : "";
  const booking_finance_status = isSelectedNum(financeStatus)
    ? `&multiple_finance_status=${financeStatus}`
    : "";
  const booking_org_type = isSelectedNum(org_types)
    ? `&org_types=${org_types}`
    : "";
  const booking_booking_type = isSelectedNum(booking_type)
    ? `&booking_type=${booking_type}`
    : "";
  const booking_sample_registered = isSelectedNum(sample_registered)
    ? `&sample_registered=${sample_registered}`
    : "";
  const booking_report_status = isSelectedNum(report_status)
    ? `&multiple_report_status=${report_status}`
    : "";
  const booking_is_pickup_receive_amount = isSelectedNum(
    is_pickup_receive_amount
  )
    ? `&is_pickup_receive_amount=${is_pickup_receive_amount}`
    : "";
  const assignedStatus = isSelected(assigned_status)
    ? `&assigned_status=${assigned_status}`
    : "";
  const booking_collection_start_date = isSelected(collection_start_date)
    ? `&collection_start_date=${collection_start_date}`
    : "";
  const booking_collection_end_date = isSelected(collection_end_date)
    ? `&collection_end_date=${collection_end_date}`
    : "";
  const url = `?${booking_email}${booking_collection_start_date}${booking_collection_end_date}${booking_clientRefId}${booking_tatStatus}${booking_phlebojourney}${booking_verified_by}${booking_routemanager}${booking_category_status}${booking_tl}${booking_due_date}${booking_due_start_date}${booking_due_end_date}${booking_booking_type}${booking_is_pickup_receive_amount}${booking_report_status}${booking_sample_registered}${booking_user_group}${booking_collection_slots}${booking_finance_status}${booking_category}${booking_id}${booking_pickup_done}${booking_partner}${booking_pickup_status}${booking_pickup_amount}${booking_agent}${booking_name}${booking_phone}${booking_phlebo}${booking_city}${booking_source}${booking_start_date}${booking_end_date}${booking_collection_date}${booking_status}${booking_booking_date}${verification_status}${booking_lead_id}${assignedStatus}${booking_org_type}${booking_barcode}${booking_created_date}${booking_doctor}${booking_disposition}${booking_report_not_status}${booking_lead}${booking_labName}${booking_bucket}${booking_ticketstart_date}${booking_ticketend_date}`;
  // const url = `?${booking_routemanager}${booking_category_status}${booking_client_billid}${booking_tl}${booking_due_date}${booking_due_start_date}${booking_due_end_date}${booking_created_date}${booking_booking_type}${booking_is_pickup_receive_amount}${booking_report_status}${booking_sample_registered}${booking_user_group}${booking_collection_slots}${booking_finance_status}${booking_category}${booking_id}${booking_pickup_done}${booking_partner}${booking_pickup_status}${booking_pickup_amount}${booking_agent}${booking_name}${booking_phone}${booking_phlebo}${booking_city}${booking_source}${booking_start_date}${booking_end_date}${booking_collection_date}${booking_status}${booking_booking_date}${verification_status}${booking_lead_id}${assignedStatus}`;
  return url;
};
export const generateTransferdTicketFilterUrl = (body) => {
  const { booking, agent } = body;

  const ticket_booking_id = isSelectedNum(booking) ? `&booking=${booking}` : "";
  const ticket_booking_agent = isSelectedNum(agent) ? `&agent=${agent}` : "";

  const url = `?${ticket_booking_id}${ticket_booking_agent}`;
  // const url = `?${booking_routemanager}${booking_category_status}${booking_client_billid}${booking_tl}${booking_due_date}${booking_due_start_date}${booking_due_end_date}${booking_created_date}${booking_booking_type}${booking_is_pickup_receive_amount}${booking_report_status}${booking_sample_registered}${booking_user_group}${booking_collection_slots}${booking_finance_status}${booking_category}${booking_id}${booking_pickup_done}${booking_partner}${booking_pickup_status}${booking_pickup_amount}${booking_agent}${booking_name}${booking_phone}${booking_phlebo}${booking_city}${booking_source}${booking_start_date}${booking_end_date}${booking_collection_date}${booking_status}${booking_booking_date}${verification_status}${booking_lead_id}${assignedStatus}`;
  return url;
};
export const genrateCollectionCentrLedgerFilter = (body) => {
  const { status, center, start_date, end_date, ledger_type } = body;
  const collection_center = isSelectedNum(center)
    ? `&multiple_center=${center}`
    : "";
  const collection_start_date = isSelected(start_date)
    ? `&start_date=${start_date}`
    : "";
  const collection_end_date = isSelected(end_date)
    ? `&end_date=${end_date}`
    : "";
  const collection_status = isSelectedNum(status) ? `&status=${status}` : "";
  const collection_ledger_type = isSelectedNum(ledger_type)
    ? `&ledger_type=${ledger_type}`
    : "";
  const url = `?${collection_start_date}${collection_end_date}${collection_status}${collection_ledger_type}${collection_center}`;
  return url;
};
export const genrateRepeatedListFilter = (body) => {
  const { lead, start_date, end_date, created_at } = body;
  const repeated_start_date = isSelected(start_date)
    ? `&start_date=${start_date}`
    : "";
  const repeated_end_date = isSelected(end_date) ? `&end_date=${end_date}` : "";
  const repeated_lead = isSelectedNum(lead) ? `&lead=${lead}` : "";
  const repeated_created_at = isSelected(created_at)
    ? `&created_date=${created_at}`
    : "";
  const url = `?${repeated_start_date}${repeated_end_date}${repeated_lead}${repeated_created_at}`;
  return url;
};
export const genrateRemarksSmsSendUrl = (body) => {
  const {
    lead_id,
    template_id,
    sender_id,
    lead_start_date,
    lead_end_date,
    booking_start_date,
    booking_end_date,
    booking_status,
    lead_call_status,
    package_ids,
    number_of_chunks,
    interval_in_hours,
    scheduled_at,
  } = body;

  const remarks_sms_leadId = isSelected(lead_id) ? `&lead_id=${lead_id}` : "";
  const remarks_sms_template = isSelected(template_id)
    ? `&template_id=${template_id}`
    : "";
  const remarks_sms_senderId = isSelected(sender_id)
    ? `&sender_id=${sender_id}`
    : "";
  const remarks_sms_leadStartDate = isSelected(lead_start_date)
    ? `&lead_start_date=${lead_start_date}`
    : "";
  const remarks_sms_leadEndDate = isSelected(lead_end_date)
    ? `&lead_end_date=${lead_end_date}`
    : "";
  const remarks_sms_BookingStartDate = isSelected(booking_start_date)
    ? `&booking_start_date=${booking_start_date}`
    : "";
  const remarks_sms_BookingEndDate = isSelected(booking_end_date)
    ? `&booking_end_date=${booking_end_date}`
    : "";
  const remarks_sms_BookingStatus = isSelected(booking_status)
    ? `&booking_status=${booking_status}`
    : "";
  const remarks_sms_LeadStatus = isSelected(lead_call_status)
    ? `&lead_call_status=${lead_call_status}`
    : "";
  const remarks_sms_PackageId = isSelected(package_ids)
    ? `&package_ids=${package_ids}`
    : "";
  const remarks_number_of_chunks = isSelected(number_of_chunks)
    ? `&number_of_chunks=${number_of_chunks}`
    : "";
  const remarks_interval_in_hours = isSelected(interval_in_hours)
    ? `&interval_in_hours=${interval_in_hours}`
    : "";
  const remarks_scheduled_at = isSelected(scheduled_at)
    ? `&scheduled_at=${scheduled_at}`
    : "";
  const url = `?${remarks_sms_leadId}${remarks_sms_template}${remarks_sms_senderId}${remarks_sms_leadStartDate}${remarks_sms_leadEndDate}${remarks_sms_BookingStartDate}${remarks_sms_BookingEndDate}${remarks_sms_BookingStatus}${remarks_sms_LeadStatus}${remarks_sms_PackageId}${remarks_number_of_chunks}${remarks_interval_in_hours}${remarks_scheduled_at}`;
  return url;
};
export const genrateSampleHandoverFilter = (body) => {
  const {
    start_date,
    end_date,
    created_date,
    phlebo,
    booking,
    rider,
    id,
    barcode,
  } = body;
  const sampleHandover_start_date = isSelected(start_date)
    ? `&start_date=${start_date}`
    : "";
  const sampleHandover_barcode = isSelected(barcode)
    ? `&barcode=${barcode}`
    : "";
  const sampleHandover_end_date = isSelected(end_date)
    ? `&end_date=${end_date}`
    : "";
  const sampleHandover_created_at = isSelected(created_date)
    ? `&created_date=${created_date}`
    : "";
  const sampleHandover_phlebo = isSelectedNum(phlebo)
    ? `&phlebo=${phlebo}`
    : "";
  const sampleHandover_rider = isSelectedNum(rider) ? `&rider=${rider}` : "";
  const sampleHandover_rider_id = isSelectedNum(id) ? `&id=${id}` : "";
  const sampleHandover_booking_id = isSelectedNum(booking)
    ? `&booking=${booking}`
    : "";
  const url = `?${sampleHandover_barcode}${sampleHandover_rider_id}${sampleHandover_booking_id}${sampleHandover_start_date}${sampleHandover_end_date}${sampleHandover_created_at}${sampleHandover_phlebo}${sampleHandover_rider}`;
  return url;
};
export const genrateUnUploadedBookingFilter = (body) => {
  const { start_date, end_date, created_date } = body;
  const sampleHandover_start_date = isSelected(start_date)
    ? `&start_date=${start_date}`
    : "";
  const sampleHandover_end_date = isSelected(end_date)
    ? `&end_date=${end_date}`
    : "";
  const sampleHandover_created_at = isSelected(created_date)
    ? `&created_date=${created_date}`
    : "";
  const url = `?${sampleHandover_start_date}${sampleHandover_end_date}${sampleHandover_created_at}`;
  return url;
};
export const genrateCenterAddressPriceFilter = (body) => {
  const { center_address, package_id } = body;
  const centeraddress_center_address = isSelected(center_address)
    ? `&center_address=${center_address}`
    : "";
  const centeraddress_package_id = isSelected(package_id)
    ? `&package_id=${package_id}`
    : "";
  const url = `?${centeraddress_center_address}${centeraddress_package_id}`;
  return url;
};

export const generateLeadsUrl = (body) => {
  const {
    lead_call_status_id,
    start_date,
    end_date,
    createdAt,
    followUpDate,
    leadSource,
    priority,
    city,
    agent,
    inbound_mode,
    phone,
    id,
    tl,
    lead_form_call,
    lead,
  } = body;
  const leads_panelUser = isSelectedNum(tl) ? `&tl=${tl}` : "";
  const inboundMode = isSelectedNum(inbound_mode)
    ? `&inbound_mode=${inbound_mode}`
    : "";
  const leads_lead_id = isSelectedNum(lead) ? `&lead=${lead}` : "";
  const leads_lead_form_call = isSelectedNum(lead_form_call)
    ? `&lead_form_call=${lead_form_call}`
    : "";
  const leads_created_date = isSelected(createdAt)
    ? `&created_date=${createdAt}`
    : "";
  const leads_start_date = isSelected(start_date)
    ? `&start_date=${start_date}`
    : "";
  const leads_end_date = isSelected(end_date) ? `&end_date=${end_date}` : "";
  const leads_followUpDate = isSelected(followUpDate)
    ? `&followup_date=${followUpDate}`
    : "";
  const leads_phone = isSelected(phone) ? `&phone=${phone}` : "";
  const leads_lead_source = isSelected(leadSource)
    ? `&multiple_lead_source=${leadSource}`
    : "";
  const leads_priority = isSelected(priority)
    ? `&multiple_priority=${priority}`
    : "";
  const leads_id = isSelected(id) ? `&id=${id}` : "";
  const leads_category =
    isSelectedNum(lead_call_status_id) && lead_call_status_id !== "0"
      ? `&multiple_category_id=${lead_call_status_id}`
      : "";
  const leads_city = isSelectedNum(city) ? `&multiple_city=${city}` : "";
  const leads_agent = isSelectedNum(agent) ? `&multiple_agent=${agent}` : "";

  const url = `?${leads_lead_form_call}${leads_panelUser}${leads_phone}${leads_category}${leads_agent}${leads_start_date}${leads_end_date}${leads_city}${leads_id}${leads_created_date}${leads_followUpDate}${leads_lead_source}${leads_priority}${leads_lead_id}${inboundMode}`;

  return url;
};
export const generateRCFreshsUrl = (body) => {
  const {
    category,
    start_date,
    end_date,
    createdAt,
    followUpDate,
    leadSource,
    priority,
    city,
    agent,
    phone,
    id,
  } = body;
  const leads_created_date = isSelected(createdAt)
    ? `&created_date=${createdAt}`
    : "";
  const leads_start_date = isSelected(start_date)
    ? `&start_date=${start_date}`
    : "";
  const leads_end_date = isSelected(end_date) ? `&end_date=${end_date}` : "";
  const leads_followUpDate = isSelected(followUpDate)
    ? `&followup_date=${followUpDate}`
    : "";
  const leads_phone = isSelected(phone) ? `&phone=${phone}` : "";
  const leads_lead_source = isSelected(leadSource)
    ? `&multiple_lead_source=${leadSource}`
    : "";
  const leads_priority = isSelected(priority)
    ? `&multiple_priority=${priority}`
    : "";
  const leads_id = isSelected(id) ? `&lead_id=${id}` : "";
  const leads_category =
    isSelectedNum(category) && category !== "0"
      ? `&multiple_category=${category}`
      : "";
  const leads_city = isSelectedNum(city) ? `&multiple_city=${city}` : "";
  const leads_agent = isSelectedNum(agent) ? `&multiple_agent=${agent}` : "";

  const url = `?${leads_phone}${leads_category}${leads_agent}${leads_start_date}${leads_end_date}${leads_city}${leads_id}${leads_created_date}${leads_followUpDate}${leads_lead_source}${leads_priority}`;

  return url;
};

export const generateCallRecordingUrl = (body) => {
  const { status, lead, created_date, direction } = body;
  const call_status = isSelectedNum(status) ? `&call_picked=${status}` : "";
  const call_lead = isSelectedNum(lead) ? `&lead=${lead}` : "";
  const call_created_date = isSelected(created_date)
    ? `&created_date=${created_date}`
    : "";
  const direction_part = isSelectedNum(direction)
    ? `&direction=${direction}`
    : "";
  const url = `?${call_status}${call_lead}${call_created_date}${direction_part}`;
  return url;
};

export const generateWhatsappMessageUrl = (body) => {
  const { start_date, lead, created_date, end_date, panel_user, type } = body;
  const whatsapp_start_date = isSelectedNum(start_date)
    ? `&start_date=${start_date}`
    : "";
  const whatsapp_lead = isSelectedNum(lead) ? `&lead=${lead}` : "";
  const whatsapp_created_date = isSelected(created_date)
    ? `&created_date=${created_date}`
    : "";
  const whatsapp_end_date = isSelectedNum(end_date)
    ? `&end_date=${end_date}`
    : "";
  const whatsapp_panel_user = isSelectedNum(panel_user)
    ? `&panel_user=${panel_user}`
    : "";
  const whatsapp_type = isSelectedNum(type) ? `&type=${type}` : "";
  const url = `?${whatsapp_type}${whatsapp_panel_user}${whatsapp_start_date}${whatsapp_lead}${whatsapp_created_date}${whatsapp_end_date}`;
  return url;
};

export const generateDispositionUrl = (body) => {
  const { category, city, agent, start_date, end_date, created_date } = body;

  const leads_category =
    isSelectedNum(category) && category !== "0"
      ? `&multiple_disposition=${category}`
      : "";
  const leads_city = isSelectedNum(city) ? `&multiple_city=${city}` : "";
  const leads_agent = isSelectedNum(agent) ? `&multiple_agent=${agent}` : "";
  const leads_created_date = isSelected(created_date)
    ? `&created_date=${created_date}`
    : "";
  const leads_start_date = isSelected(start_date)
    ? `&start_date=${start_date}`
    : "";
  const leads_end_date = isSelected(end_date) ? `&end_date=${end_date}` : "";

  const url = `?${leads_category}${leads_agent}${leads_city}${leads_created_date}${leads_start_date}${leads_end_date}`;

  return url;
};
export const getCenterFilter = (body) => {
  const { centre_type_id, name } = body;
  const center_center = isSelectedNum(centre_type_id)
    ? `&centre_type_id=${centre_type_id}`
    : "";
  const center_center_type = isSelectedNum(name) ? `&name=${name}` : "";

  const url = `?${center_center}${center_center_type}`;

  return url;
};
export const generateBookingDispositionUrl = (body) => {
  const {
    category,
    city,
    agent,
    start_date,
    end_date,
    created_date,
    userGroup,
  } = body;

  const leads_category =
    isSelectedNum(category) && category !== "0"
      ? `&multiple_disposition=${category}`
      : "";
  const leads_city = isSelectedNum(city) ? `&multiple_city=${city}` : "";
  const leads_agent = isSelectedNum(agent) ? `&multiple_agent=${agent}` : "";
  const leads_created_date = isSelected(created_date)
    ? `&created_date=${created_date}`
    : "";
  const leads_start_date = isSelected(start_date)
    ? `&start_date=${start_date}`
    : "";
  const leads_end_date = isSelected(end_date) ? `&end_date=${end_date}` : "";
  const leads_usergroup = isSelected(userGroup)
    ? `&usergroup=${userGroup}`
    : "";

  const url = `?${leads_usergroup}${leads_category}${leads_agent}${leads_city}${leads_created_date}${leads_start_date}${leads_end_date}`;

  return url;
};
export const generateAttendenceFilterUrl = (body) => {
  const { phlebo, rider, start_date, end_date, created_date } = body;
  const attendence_phlebo = isSelectedNum(phlebo)
    ? `&multiple_phlebo=${phlebo}`
    : "";
  const attendence_rider = isSelectedNum(rider)
    ? `&multiple_rider=${rider}`
    : "";
  const attendence_start_date = isSelected(start_date)
    ? `&start_date=${start_date}`
    : "";
  const attendence_created_date = isSelected(created_date)
    ? `&created_date=${created_date}`
    : "";
  const attendence_end_date = isSelected(end_date)
    ? `&end_date=${end_date}`
    : "";

  const url = `?${attendence_rider}${attendence_phlebo}${attendence_start_date}${attendence_end_date}${attendence_created_date}`;

  return url;
};
export const generateCxFilterUrl = (body) => {
  const {
    status,
    verificationStatus,
    phlebo,
    city,
    start_date,
    end_date,
    booking_date,
    collection_date,
    name,
    phone,
    pickup_status,
  } = body;
  const booking_status = isSelectedNum(status)
    ? `&multiple_booking_status=${status}`
    : "";
  const booking_phlebo = isSelectedNum(phlebo)
    ? `&multiple_phlebo=${phlebo}`
    : "";
  const booking_city = isSelectedNum(city) ? `&multiple_city=${city}` : "";
  const booking_start_date = isSelected(start_date)
    ? `&start_date=${start_date}`
    : "";
  const booking_booking_date = isSelected(booking_date)
    ? `&booking_date=${booking_date}`
    : "";
  const booking_end_date = isSelected(end_date) ? `&end_date=${end_date}` : "";
  const booking_collection_date = isSelected(collection_date)
    ? `&collection_date=${collection_date}`
    : "";
  const booking_name = isSelectedNum(name) ? `&name=${name}` : "";
  const booking_phone = isSelectedNum(phone) ? `&phone=${phone}` : "";
  const booking_pickup_status = isSelectedNum(pickup_status)
    ? `&multiple_pickup_status=${pickup_status}`
    : "";
  const verification_status = isSelectedNum(verificationStatus)
    ? `&multiple_verification_status=${verificationStatus}`
    : "";

  const url = `?${booking_pickup_status}${booking_name}${booking_phone}${booking_phlebo}${booking_city}${booking_start_date}${booking_end_date}${booking_collection_date}${booking_status}${verification_status}${booking_booking_date}`;

  return url;
};
export const generateQueryTicketBookingFilterUrl = (body) => {
  const {
    bookingId,
    ticket_Status,
    query_type,
    created_date,
    start_date,
    end_date,
    lead,
    follow_up_start_date,
    follow_up_end_date,
    ticket_type,
    created_start_date,
    created_end_date,
    ticket_number,
    sub_ticket_type,
    agent,
  } = body;
  const booking_ticket_Status = isSelectedNum(ticket_Status)
    ? `&ticket_status=${ticket_Status}`
    : "";
  const booking_id = isSelectedNum(bookingId) ? `&booking_id=${bookingId}` : "";
  const booking_ticket_number = isSelectedNum(ticket_number)
    ? `&ticket_number=${ticket_number}`
    : "";
  const booking_agent = isSelectedNum(agent) ? `&agent=${agent}` : "";
  const bookig_lead_id = isSelectedNum(lead) ? `&lead=${lead}` : "";
  const booking_query_type = isSelectedNum(query_type)
    ? `&multiple_querytype=${query_type}`
    : "";
  const booking_ticket_type = isSelectedNum(ticket_type)
    ? `&ticket_type=${ticket_type}`
    : "";
  const booking_start_date = isSelected(start_date)
    ? `&start_date=${start_date}`
    : "";
  const booking_end_date = isSelected(end_date) ? `&end_date=${end_date}` : "";
  const booking_created_date = isSelected(created_date)
    ? `&created_date=${created_date}`
    : "";
  const booking_follow_up_start_date = isSelected(follow_up_start_date)
    ? `&follow_up_start_date=${follow_up_start_date}`
    : "";
  const booking_follow_up_end_date = isSelected(follow_up_end_date)
    ? `&follow_up_end_date=${follow_up_end_date}`
    : "";
  const booking_created_start_date = isSelected(created_start_date)
    ? `&created_start_date=${created_start_date}`
    : "";
  const booking_created_end_date = isSelected(created_end_date)
    ? `&created_end_date=${created_end_date}`
    : "";
  const booking_sub_ticket_type = isSelected(sub_ticket_type)
    ? `&sub_ticket_type=${sub_ticket_type}`
    : "";

  const url = `?${bookig_lead_id}${booking_query_type}${booking_ticket_Status}${booking_id}${booking_start_date}${booking_end_date}${booking_created_date}${booking_follow_up_start_date}${booking_follow_up_end_date}${booking_ticket_type}${booking_created_start_date}${booking_created_end_date}${booking_ticket_number}${booking_agent}${booking_sub_ticket_type}`;

  return url;
};
export const generateCurrentDayBookingFilterUrl = (body) => {
  const { city, booking_date, collection_date } = body;
  const booking_city = isSelectedNum(city) ? `&multiple_city=${city}` : "";
  const booking_booking_date = isSelected(booking_date)
    ? `&booking_date=${booking_date}`
    : "";
  const booking_collection_date = isSelected(collection_date)
    ? `&collection_date=${collection_date}`
    : "";
  const url = `?${booking_city}${booking_collection_date}${booking_booking_date}`;

  return url;
};
export const generateCityWisePhlebosFilterUrl = (body) => {
  const { city } = body;
  const booking_city = isSelectedNum(city) ? `&multiple_city=${city}` : "";

  const url = `?${booking_city}`;

  return url;
};
export const generateSmsFilterUrl = (body) => {
  const { booking, phonenumber } = body;
  const booking_id = isSelectedNum(booking) ? `&booking=${booking}` : "";
  const booking_phone = isSelectedNum(phonenumber)
    ? `&phonenumber=${phonenumber}`
    : "";
  const url = `?${booking_id}${booking_phone}`;

  return url;
};
export const generateEmailFilterUrl = (body) => {
  const { booking, email } = body;
  const booking_id = isSelectedNum(booking) ? `&booking=${booking}` : "";
  const booking_email = isSelectedNum(email) ? `&email=${email}` : "";
  const url = `?${booking_id}${booking_email}`;

  return url;
};
export const generatePincodeFilterUrl = (body) => {
  const { city, pincode } = body;
  const booking_city = isSelectedNum(city) ? `&multiple_city=${city}` : "";
  const booking_pincode = isSelected(pincode) ? `&search=${pincode}` : "";
  const url = `?${booking_city}${booking_pincode}`;

  return url;
};
export const generateBreakUrl = (body) => {
  const { agent, start_date, end_date, created_date } = body;

  const leads_agent = isSelectedNum(agent) ? `&panel_user=${agent}` : "";
  const leads_created_date = isSelected(created_date)
    ? `&created_date=${created_date}`
    : "";
  const leads_start_date = isSelected(start_date)
    ? `&start_date=${start_date}`
    : "";
  const leads_end_date = isSelected(end_date) ? `&end_date=${end_date}` : "";

  const url = `?${leads_agent}${leads_created_date}${leads_start_date}${leads_end_date}`;

  return url;
};

export const generateCityPriceFilter = (body) => {
  const { city, package_id } = body;
  const cityPrice_city = isSelectedNum(city) ? `&city=${city}` : "";
  const cityPrice_package = isSelectedNum(package_id)
    ? `&package_id=${package_id}`
    : "";

  const url = `?${cityPrice_city}${cityPrice_package}`;

  return url;
};

export const generateBlockedCardFilter = (body) => {
  const { package_id, Zone } = body;
  const blockedCard_package = isSelectedNum(package_id)
    ? `&package_id=${package_id}`
    : "";
  const blockedCard_Zone = isSelectedNum(Zone) ? `&Zone=${Zone}` : "";

  const url = `?${blockedCard_Zone}${blockedCard_package}`;

  return url;
};

export const generateReportFilterUrl = (body) => {
  const { start_date, end_date, name, phone, lead, bookingId, report_status } =
    body;

  const booking_id = isSelectedNum(bookingId) ? `&booking_id=${bookingId}` : "";
  const lead_id = isSelectedNum(lead) ? `&lead=${lead}` : "";
  const booking_start_date = isSelected(start_date)
    ? `&start_date=${start_date}`
    : "";
  const booking_end_date = isSelected(end_date) ? `&end_date=${end_date}` : "";
  const booking_name = isSelectedNum(name) ? `&name=${name}` : "";
  const booking_phone = isSelectedNum(phone) ? `&phone=${phone}` : "";

  const booking_report_status = isSelectedNum(report_status)
    ? `&multiple_report_status=${report_status}`
    : "";
  const url = `?${lead_id}${booking_report_status}${booking_id}${booking_name}${booking_phone}${booking_start_date}${booking_end_date}`;

  return url;
};

export const genrateTatTableFilter = (body) => {
  const {
    customer_name,
    id,
    lead,
    mobile_number,
    collection_date_start,
    collection_date_end,
    barcode,
    batch_id,
  } = body;

  const tatTable_customer_name = isSelected(customer_name)
    ? `&customer_name=${customer_name}`
    : "";
  const tatTable_booking_id = isSelectedNum(id) ? `&id=${id}` : "";
  const tatTable_barcode_id = isSelectedNum(barcode)
    ? `&barcode=${barcode}`
    : "";
  const tatTable_batch_id = isSelectedNum(batch_id)
    ? `&batch_id=${batch_id}`
    : "";
  const tatTable_lead_id = isSelectedNum(lead) ? `&lead=${lead}` : "";
  const tatTable_mobile_number = isSelected(mobile_number)
    ? `&mobile_number=${mobile_number}`
    : "";
  const tatTable_collection_date_start = isSelected(collection_date_start)
    ? `&collection_date_start=${collection_date_start}`
    : "";
  const tatTable_collection_date_end = isSelected(collection_date_end)
    ? `&collection_date_end=${collection_date_end}`
    : "";

  const url = `?${tatTable_customer_name}${tatTable_booking_id}${tatTable_mobile_number}${tatTable_lead_id}${tatTable_collection_date_start}${tatTable_collection_date_end}${tatTable_barcode_id}${tatTable_batch_id}`;
  return url;
};
export const generateConsalidateReportFilterUrl = (body) => {
  const {
    start_date,
    end_date,
    name,
    lead,
    bookingId,
    report_status,
    booking_type,
  } = body;

  const report_booking_id = isSelectedNum(bookingId)
    ? `&booking_id=${bookingId}`
    : "";
  const report_lead_id = isSelectedNum(lead) ? `&lead=${lead}` : "";
  const report_start_date = isSelected(start_date)
    ? `&start_date=${start_date}`
    : "";
  const report_end_date = isSelected(end_date) ? `&end_date=${end_date}` : "";
  const report_name = isSelectedNum(name) ? `&name=${name}` : "";
  const report_booking_type = isSelectedNum(booking_type)
    ? `&booking_type=${booking_type}`
    : "";

  const url = `?${report_booking_id}${report_lead_id}${report_start_date}${report_end_date}${report_name}${report_booking_type}`;
  return url;
};

export const genrateChatAnalyticsOverAllFilter = (body) => {
  const {
      target_date,
      group_by_panel_user,
      skills
  } = body;
  const chatAnalytics_target_date = isSelected(target_date) ? `&target_date=${target_date}` : "";
  const chatAnalytics_agent = isSelected(group_by_panel_user) ? `&group_by_panel_user=${group_by_panel_user}` : "";
  const chatAnalytics_skills= isSelected(skills) ? `&skills=${skills}` : "";

  const url = `?${chatAnalytics_target_date}${chatAnalytics_agent}${chatAnalytics_skills}`;
  return url;
};
export const generateNonServiceableAreaFilterUrl = (body) => {
  const {
      lead_id,
      location_request_sent
  } = body;
  const NonServiceableArea_lead = isSelectedNum(lead_id) ? `&lead_id=${lead_id}` : "";
  const NonServiceableArea_location_request_sent = isSelectedNum(location_request_sent) ? `&location_request_sent=${location_request_sent}` : "";
 
  const url = `?${NonServiceableArea_lead}${NonServiceableArea_location_request_sent}`;

  return url;
};

