import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableFooter,
    TablePagination,
    TableCell,
    TableBody,
    Grid,
    TextField,
    Button,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { connect } from "react-redux";
import { getDispositions, getAgentList, getCities } from '../../actions/OffLineSupportActions'
import './filter.sass'
import { useHistory } from "react-router";
import Loader from "../../components/loader";import { generateDispositionUrl } from "../../../helpers/generateUrl";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 0,
            //   width: "100%",
            //   marginTop: "20px",
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
            //   padding: theme.spacing(3),
        },
        card: {
            height: "100%",
            padding: theme.spacing(1),
        },
        card_grid: {
            // wordSpacing:3,
            justifyContent: 'space-between'
        },
    })
);

interface Props {
    getDispositions: any
    dispositions: any
    getAgentList: any
    getCities: any
    agentsList: any
    cities: any
     loading: boolean;
}

const CustomerLeadStateFilterPage: React.FC<Props> = ({
    loading,
    getDispositions,
    dispositions,
    getAgentList,
    getCities,
    agentsList,
    cities,
}) => {
    const classes = useStyles();

    const history = useHistory()

    const CATEGORY: any = [
        {
            id: 1,
            text: "Interested/Follow up",
            city: true,
            leadId: false
        },
        {
            id: 2,
            text: "Not Interested",
            city: true,
            query: false,
        },
        {
            id: 3,
            text: "Order Booked",
            city: true,
            query: false,
        },
        {
            id: 4,
            text: "Ringing",
            city: false,
            query: false,
        },
        {
            id: 5,
            text: "Number Doesn't Exist",
            city: false,
            query: false,
        },
        {
            id: 6,
            text: "By Mistake & Wrong Number",
            city: false,
            query: false,
        },
        {
            id: 7,
            text: "Call Back",
            city: true,
            query: false,
        },
        {
            id: 8,
            text: "Business Realated Call",
            city: true,
            query: false,
        },
        {
            id: 9,
            text: "Spam Call",
            city: false,
            query: false,
        },
        {
            id: 10,
            text: "Enquiry Call",
            city: true,
            query: false,
        },
        {
            id: 11,
            text: "CFresh",
            city: false,
            query: false,
        },
        {
            id: 12,
            text: "Test Not Available",
            city: true,
            query: false,
        },
        {
            id: 13,
            text: "Subscribed to Competitor",
            city: true,
            query: false,
        },
        {
            id: 14,
            text: "Slot Issue",
            city: true,
            query: false,
        },
        {
            id: 15,
            text: "Same Day Slot",
            city: true,
            query: false,
        },
        {
            id: 16,
            text: "Already Booked",
            city: false,
            query: false,
        },
        {
            id: 17,
            text: "Not Contactable",
            city: false,
            query: false,
        },
        {
            id: 18,
            text: "Out of Station",
            city: true,
            query: false,
        },
        {
            id: 19,
            text: "Out of Service Area",
            city: true,
            query: false,
        },
        {
            id: 20,
            text: "Need Time to Think",
            city: true,
            query: false,
        },
        {
            id: 21,
            text: "Email/Whatsapp Package",
            city: true,
            leadId: false
        },
        {
            id: 22,
            text: "Discount/Price Enquiry",
            city: true,
            leadId: false
        },
        {
            id: 23,
            text: "CS issue",
            city: false,
            leadId: true
        },
        {
            id: 24,
            text: "Call Not Reachable",
            city: false,
            leadId: false
        },
        {
            id: 25,
            text: "Call Later",
            city: true,
            leadId: false
        },
        {
            id: 26,
            text: "Call drop",
            city: true,
            leadId: false
        },
        {
            id: 27,
            text: "Call Disconnected",
            city: true,
            leadId: false
        },
        {
            id: 28,
            text: "Below <5 year patient",
            city: true,
            leadId: false
        },
        {
            id: 29,
            text: "DND",
            city: false,
            leadId: false
        },
        {
            id: 30,
            text: "Phlebo's Issue",
            city: false,
            leadId: true
        },
        {
            id: 31,
            text: "Reports Regarding Issue",
            city: false,
            leadId: true
        },
        {
            id: 32,
            text: "Health Advice",
            city: false,
            leadId: true
        },
        {
            id: 33,
            text: "Health Consultation",
            city: false,
            leadId: true
        },
        {
            id: 34,
            text: "Resampling",
            city: false,
            leadId: false
        },
        {
            id: 35,
            text: "Language Barrier",
            city: false,
            leadId: false
        },
    ]

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(50)

    const [city, setCity] = useState<any>([])
    const [agent, setAgent] = useState<any>([])
    const [category, setCategory] = useState<any>([])

    const [start_date, setStart_date] = useState("")
    const [end_date, setEnd_date] = useState("")
    const [created_date, setCreated_date] = useState("")

    const timer = useRef<any>(0)

    useEffect(() => {
        getDispositions()
        getAgentList()
        getCities()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const filterDisposition = () => {
        const body: any = {
           lead_call_status_id: category.join(","),
            city: city.join(","),
            agent: agent.join(","),
            start_date,
            end_date,
            created_date
        }
        const url = generateDispositionUrl(body).substring(2)
        getDispositions(`${url}`)
        setPage(0)
    }

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        if (newPage > page) {
            let url = dispositions.links.next;
            getDispositions(url.substring(url.indexOf("?") + 1));
        } else if (newPage < page) {
            let url = dispositions.links.previous;
            getDispositions(url.indexOf("?") === -1 ? "" : url.substring(url.indexOf("?") + 1));
        }
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };

    return (
        <>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Grid container spacing={3} direction="row" alignItems="center" justify="center" style={{ margin: "0" }}>
                    <Grid item xs={12} sm={6} md={4}>
                        <Autocomplete
                            id="city"
                            onChange={(event, newValue) => {
                                let sourceIds: any = []
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    sourceIds = obj.map((item: any) => item.id)
                                }
                                setCity(sourceIds)
                            }}
                            options={cities || []}
                            freeSolo
                            blurOnSelect
                            aria-required
                            limitTags={1}
                            multiple
                            getOptionLabel={(option: any) => option.name}
                            getOptionDisabled={(option: any) => {
                                return city.includes(option.id)
                            }}
                            disableClearable
                            disableCloseOnSelect
                            onInputChange={(event, newInputValue) => {
                                clearTimeout(timer.current)
                                timer.current = setTimeout(() => {
                                    getCities(newInputValue)
                                }, 1000)
                                if (newInputValue.length === 0) {
                                    setCity(0)
                                }
                            }}
                            renderInput={(params) => (
                                <TextField className="input"
                                    {...params}
                                    placeholder="City"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Autocomplete
                            id="agent"
                            onChange={(event, newValue) => {
                                let sourceIds: any = []
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    sourceIds = obj.map((item: any) => item.id)
                                }
                                setAgent(sourceIds)
                            }}
                            options={agentsList?.results||[]}
                            freeSolo
                            blurOnSelect
                            aria-required
                            limitTags={1}
                            multiple
                            getOptionLabel={(option: any) => option.user && option.user.name}
                            getOptionDisabled={(option: any) => {
                                return agent.includes(option.id)
                            }}
                            disableClearable
                            disableCloseOnSelect
                            onInputChange={(event, newInputValue) => {
                                clearTimeout(timer.current)
                                timer.current = setTimeout(() => {
                             getAgentList(`?code=${newInputValue.replace(" ","")}`)
                                }, 1000)
                                if (newInputValue.length === 0) {
                                    setAgent(0)
                                }
                            }}
                            renderInput={(params) => (
                                <TextField className="input"
                                    {...params}
                                    placeholder="Agent"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                />
                            )}
                        />
                    </Grid>
                    
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            className="input"
                            name="start_date"
                            type="date"
                            label="Start Date"
                            value={start_date}
                            variant="outlined"
                            onChange={(e) => setStart_date(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            className="input"
                            name="end_date"
                            type="date"
                            label="End Date"
                            value={end_date}
                            variant="outlined"
                            onChange={(e) => setEnd_date(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            className="input"
                            name="created_date"
                            type="date"
                            label="Created Date"
                            value={created_date}
                            disabled={start_date !== "" || end_date !== ""}
                            variant="outlined"
                            onChange={(e) => setCreated_date(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Button
                            variant="contained"
                            color="secondary"
                            disabled={loading}
                            fullWidth
                            onClick={filterDisposition}
                        >
                            Filter
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Button
                            variant="contained"
                            color="primary"
                          
                            fullWidth
                            onClick={() => history.push("/dashboard/os/dispositions")}
                        >
                            Reset
                        </Button>
                    </Grid>
                </Grid>
                <TableContainer component={Paper} style={{ margin: "1rem auto" }}>
                    {loading ? <Loader /> : (
                        <Table aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">ID</TableCell>
                                    <TableCell align="center">Panel User</TableCell>
                                    <TableCell align="center">Disposition</TableCell>
                                    <TableCell align="center">City</TableCell>
                                    <TableCell align="center">Details</TableCell>
                                    <TableCell align="center">Created At</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dispositions && dispositions.results && dispositions.results.length > 0 && dispositions.results.map((dis: any) => {
                                    return (
                                        <TableRow key={dis.id}>
                                            <TableCell align="center">{dis.id}</TableCell>
                                            <TableCell align="center">{dis.by_user}</TableCell>
                                            <TableCell align="center">{dis.disposition}</TableCell>
                                            <TableCell align="center">{dis.city}</TableCell>
                                            <TableCell align="center">{dis.details}</TableCell>
                                            <TableCell align="center">{new Date(dis.created_at).toLocaleString()}</TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                            <TableFooter>
                                <TablePagination
                                    colSpan={3}
                                    count={dispositions.count || 0}
                                    rowsPerPageOptions={[]}
                                    rowsPerPage={rowsPerPage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                />
                            </TableFooter>
                        </Table>
                    )}
                </TableContainer>
            </main>
        </>
    );
};

const mapStateToProps = (state: any) => ({
    loading: state.OffLineSupporteReducer.loading,
    dispositions: state.OffLineSupporteReducer.dispositions,
    agentsList: state.OffLineSupporteReducer.agentsList,
    cities: state.OffLineSupporteReducer.cities,
})

export default connect(mapStateToProps, {
    getDispositions,
    getAgentList,
    getCities,
})(CustomerLeadStateFilterPage);
