import React, { useEffect, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Container, Grid } from "@material-ui/core";
import Box from "@mui/material/Box";
import {
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import Relevent from "../components/Fill-Info";
import OtherUpdates from "../components/Other-Info";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { MessageRounded } from "@material-ui/icons";
import { getLeadDetails } from "../actions/OffLineSupportActions";
import Loader from "../components/loader";
import CommentHistory from "../components/comment-history";
import BookingComments from "../components/Comments/bookingComments";
import moment from "moment";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      //   width: "100%",
      //   marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    card: {
      height: "100%",
      padding: theme.spacing(1),
    },
    card_grid: {
      // wordSpacing:3,
      justifyContent: "space-between",
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem",
      width: "100%",
    },
    table: {
      maxWidth: "100%",
      margin: "auto",
    },
  })
);

interface Props {
  children: any;
  getLeadDetails: Function;
  lead_details: any;
  loading: boolean;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const OfflineFillInfoPage: React.FC<Props> = ({
  getLeadDetails,
  lead_details,
  loading,
}) => {
  const classes = useStyles();
  let query = useQuery();
  const [id, setId] = React.useState<any>(query.get("id"));
  const [bookingId, setBookingId] = useState<any>("");
  const [openModal, setopenModal] = useState<boolean>(false);
  // const [data, setData] = React.useState<object>({});
  useEffect(() => {
    getLeadDetails(query.get("id")!);
    setId(query.get("id")!);
    // setData(lead_details);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleClick = (bookingid: number) => {
    setopenModal(true);
    setBookingId(bookingid);
  };
  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Box>
          {/* <Box style={{
						display: 'flex',
						justifyContent: 'flex-end',
						margin: "10px"
					}}>
						<Link to="/dashboard/os/booking/new">
							<Button
								color="primary"
								variant="contained"
								style={{ margin: "5px" }}
							>
								New Booking
							</Button>
						</Link>

					</Box> */}
          <br />
          <Container maxWidth={false}>
            <Grid container spacing={3}>
              {!lead_details ? (
                <Loader />
              ) : Object.keys(lead_details).length > 0 ? (
                <>
                  <Relevent id={id} data={lead_details} />
                  <OtherUpdates id={id} data={lead_details} />
                  <div style={{ width: "100%" }}>
                    <Paper elevation={15} className={classes.paper}>
                      <TableContainer className={classes.table}>
                        {loading ? (
                          <Loader />
                        ) : (
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell align="center">Action</TableCell>
                                <TableCell align="center">Booking Id</TableCell>
                                <TableCell align="center">
                                  Booking Status
                                </TableCell>
                                <TableCell align="center">
                                  Verification Status
                                </TableCell>
                                <TableCell align="center">Comments</TableCell>
                                <TableCell align="center">
                                  Booking Date
                                </TableCell>
                                <TableCell align="center">Name</TableCell>
                                <TableCell align="center">Agent</TableCell>
                                <TableCell align="center">Age</TableCell>
                                <TableCell align="center">Gender</TableCell>
                                <TableCell align="center">Email</TableCell>
                                <TableCell align="center">
                                  Collection Date
                                </TableCell>
                                <TableCell align="center">
                                  Collection Time
                                </TableCell>
                                <TableCell align="center">Zone Name</TableCell>
                                <TableCell align="center">
                                  Mobile Number
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {lead_details?.bookings &&
                                lead_details?.bookings?.length > 0 &&
                                lead_details?.bookings?.map(
                                  (booking: any, index: any) => {
                                    return (
                                      <TableRow key={index}>
                                        <TableCell align="center">
                                          <Link
                                            to={`/dashboard/os/booking-view/${booking?.pk}`}
                                            style={{ textDecoration: "none" }}
                                          >
                                            <Button
                                              variant="contained"
                                              color="secondary"
                                            >
                                              View
                                            </Button>
                                          </Link>
                                        </TableCell>
                                        <TableCell align="center">
                                          {booking?.additional_members?.length >
                                          0 ? (
                                            <div>
                                              {booking?.pk}+
                                              <Chip
                                                label={`${booking?.additional_members?.length}`}
                                                style={{
                                                  background: "#ffeb3b",
                                                }}
                                              />
                                            </div>
                                          ) : (
                                            booking?.pk
                                          )}
                                        </TableCell>
                                        <TableCell align="center">
                                          <Chip
                                            label={`${booking?.booking_status}`}
                                            style={{
                                              background:
                                                booking?.booking_status ===
                                                "pending"
                                                  ? "#ffee58"
                                                  : booking?.booking_status ===
                                                    "confirmed"
                                                  ? "#00e676"
                                                  : booking?.booking_status ===
                                                    "cancelled"
                                                  ? "#ff3d00"
                                                  : "#d500f9",
                                            }}
                                          />
                                        </TableCell>
                                        <TableCell align="center">
                                          <Chip
                                            label={`${booking?.verification_status}`}
                                            style={{
                                              background:
                                                booking?.verification_status ===
                                                "pending"
                                                  ? "#ffee58"
                                                  : booking?.verification_status ===
                                                    "confirmed"
                                                  ? "#00e676"
                                                  : booking?.verification_status ===
                                                    "cancelled"
                                                  ? "#ff3d00"
                                                  : "#d500f9",
                                            }}
                                          />
                                        </TableCell>
                                        <TableCell align="center">
                                          <div>
                                            <MessageRounded
                                              onClick={() =>
                                                handleClick(booking?.pk)
                                              }
                                            />
                                          </div>
                                        </TableCell>
                                        <TableCell align="center">
                                          {booking?.booking_date}
                                        </TableCell>
                                        <TableCell align="center">
                                          {booking?.customer_name}
                                        </TableCell>
                                        <TableCell align="center">
                                          {(booking?.agent &&
                                            booking?.agent?.name) ||
                                            "NA"}
                                        </TableCell>
                                        <TableCell align="center">
                                          {booking?.customer_age}
                                        </TableCell>
                                        <TableCell align="center">
                                          {booking?.customer_gender}
                                        </TableCell>
                                        <TableCell align="center">
                                          {booking?.customer_email}
                                        </TableCell>
                                        <TableCell align="center">
                                          {booking?.collection_date}
                                        </TableCell>
                                        <TableCell align="center">
                                          {booking?.collection_slot &&
                                            moment(
                                              `${
                                                booking?.collection_slot?.slot?.split(
                                                  "-"
                                                )[0]
                                              }`,
                                              ["HH.mm.ss"]
                                            ).format("hh:mm A")}
                                          {" - "}
                                          {booking?.collection_slot &&
                                            moment(
                                              `${
                                                booking?.collection_slot?.slot?.split(
                                                  "-"
                                                )[1]
                                              }`,
                                              ["HH.mm.ss"]
                                            ).format("hh:mm A")}
                                        </TableCell>
                                        <TableCell align="center">
                                          {booking?.customer_areapincode?.area}
                                        </TableCell>
                                        <TableCell align="center">
                                          {booking?.customer_phonenumber}
                                        </TableCell>
                                      </TableRow>
                                    );
                                  }
                                )}
                            </TableBody>
                          </Table>
                        )}
                      </TableContainer>
                      {lead_details?.bookings &&
                        lead_details?.bookings?.length === 0 && (
                          <h6 style={{ textAlign: "center" }}>No Data Found</h6>
                        )}
                    </Paper>
                    <BookingComments
                      openModal={openModal}
                      setopenModal={setopenModal}
                      bookingId={bookingId}
                      setBookingId={setBookingId}
                    />
                  </div>
                </>
              ) : (
                "NA"
              )}
            </Grid>
          </Container>
          <br />
          <CommentHistory />
        </Box>
      </main>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  lead_details: state.OffLineSupporteReducer.lead_details,
  loading: state.OffLineSupporteReducer.loading,
});

export default connect(mapStateToProps, { getLeadDetails })(
  OfflineFillInfoPage
);
