import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
} from "@material-ui/core/styles";

import Button from "@mui/material/Button";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import Loader from "../components/Loader2";
import CallIcon from "@material-ui/icons/Call";
import {
  getHotleads,
  updateHotleads,
  clickToCallLead,
} from "../actions/OffLineSupportActions";
import { useHistory } from "react-router-dom";
import HotLeadStatusModal from "../components/Comments/statusHotLead";
import moment from "moment";
import {
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Select,
} from "@material-ui/core";
import Chip from "@mui/material/Chip";
import { height } from "@mui/system";
import { CALL_DISABLE_TIME } from "../../CallTimeDisable";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "left",
      color: theme.palette.text.secondary,
    },
    button: {
      display: "block",
      marginTop: theme.spacing(2),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    center: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    container: {
      height: "440px",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  })
);

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
      padding: "5px",
      textTransform: "capitalize",
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);
interface Props {
  getHotleads: any;
  hotleads: any;
  postRefund: any;
  loading: any;
  updateHotleads: any;
  clickToCallLead: any;
  match: any;
  booking: any;
}

const HotLeads: React.FC<Props> = ({
  getHotleads,
  hotleads,
  postRefund,
  loading,
  updateHotleads,
  clickToCallLead,
  match,
  booking,
}) => {
  const classes = useStyles();
  const bookingId = match.params.id;
  const [raiseComplaint, setRaiseComplaint] = useState(false);
  const [patientName, setPatientName] = React.useState<any>("");
  const [status, setStatus] = React.useState<any>("pending");
  const [totalPrice, setTotalPrice] = useState<any>();
  const [phoneNo, setPhoneNo] = useState<any>("");
  const [dealerCode, setDealerCode] = useState<any>("");

  const [fromDate, setFromDate] = React.useState<any>("");
  const [toDate, setToDate] = React.useState<any>("");
  const [callButton, setCallButton] = useState<boolean>(true);

  const history = useHistory();

  const handleBook = (val: any) => {
    history.push({
      pathname: "/dashboard/os/booking",
      state: val,
    });
  };
  const updateStatus = async (val: any, data: any) => {
    await updateHotleads(val.id, { status: data });
    getHotleads();
  };
  const [page, setPage] = useState(0);
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = hotleads.links && hotleads.links.next.split("?")[1];
      getHotleads(`?${url}`);
    } else if (newPage < page) {
      let url = hotleads.links && hotleads.links.previous.split("?")[1];
      getHotleads(`?${url}`);
    }
    setPage(newPage as number);
  };
  useEffect(() => {
    getHotleads();
  }, []);
  const filterForm = () => {
    getHotleads(
      `?${
        dealerCode ? "dealer_code=" + dealerCode + "&" : ""
      }start_date=${fromDate}&end_date=${toDate}&mobile=${phoneNo}&search=${patientName}&status=${status}`
    );
  };

  const handleCallClick = (lead: number, type: string) => {
    // setLeadId(id);
    setCallButton(false);
    clickToCallLead(`${lead}/hot_leads_to_center`, type);
    setTimeout(() => {
      setCallButton(true);
    }, CALL_DISABLE_TIME);
  };

  return (
    <div style={{ padding: "50px 0", width: "100%" }}>
      <Grid container style={{ margin: "25px auto" }}>
        <Grid container item xs={12}>
          <Typography variant="h4" component="h1" align="left">
            Hot Leads
          </Typography>
        </Grid>

        <Grid container xs={12}>
          <form
            autoComplete="off"
            style={{ width: "100%", margin: "15px 0px" }}
          >
            <Grid container xs={12} spacing={1}>
              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  className="input"
                  label="Dealer Code"
                  fullWidth
                  variant="outlined"
                  value={dealerCode}
                  onChange={(e) => setDealerCode(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  className="input"
                  label="  Phone No."
                  fullWidth
                  variant="outlined"
                  value={phoneNo}
                  onChange={(e) => setPhoneNo(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  className="input"
                  label="Name"
                  fullWidth
                  variant="outlined"
                  value={patientName}
                  onChange={(e) => setPatientName(e.target.value)}
                />
              </Grid>{" "}
              <Grid item xs={12} sm={6} md={2}>
                <Select
                  className="input"
                  label="Status"
                  fullWidth
                  variant="outlined"
                  placeholder="Status"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  style={{ height: "40px" }}
                >
                  <MenuItem value="pending">Pending</MenuItem>
                  <MenuItem value="confirmed">Confirmed</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  className="input"
                  name="fromDate"
                  label="From Date"
                  variant="outlined"
                  fullWidth
                  value={fromDate}
                  onChange={(e) => {
                    setFromDate(e.target.value);
                  }}
                  InputLabelProps={{ shrink: true, required: true }}
                  type="date"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  className="input"
                  name="toDate"
                  label="To Date"
                  variant="outlined"
                  fullWidth
                  value={toDate}
                  onChange={(e) => {
                    setToDate(e.target.value);
                  }}
                  InputLabelProps={{ shrink: true, required: true }}
                  type="date"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <Button
                  className="input"
                  variant="contained"
                  color="secondary"
                  onClick={filterForm}
                  size="large"
                  fullWidth
                  style={{ height: "100%" }}
                >
                  Filter
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <Button
                  className="input"
                  variant="contained"
                  color="primary"
                  onClick={() => history.push("/dashboard/os/hotleads")}
                  size="large"
                  fullWidth
                  style={{ height: "100%" }}
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
        <Grid container xs={12}>
          <div style={{ width: "100%" }} className="data-table">
            <TableContainer
              className={classes.tableContainer}
              component={Paper}
              style={{ maxHeight: "540px" }}
            >
              {loading ? (
                <Loader />
              ) : (
                <Table stickyHeader aria-label="simple table">
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell align="center">
                        <b>Sr No</b>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <b>Dealer Code</b>
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        <b>Source</b>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <b>Lead Date</b>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <b>Patient Name</b>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <b>Mobile Number</b>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <b>Packages</b>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <b>Verification Status</b>
                      </StyledTableCell>
                      {/* <StyledTableCell align="center">
                      <b>Action</b>
                    </StyledTableCell> */}
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {hotleads?.results &&
                      hotleads?.results.length > 0 &&
                      hotleads?.results.map((val: any, index: any) => {
                        return (
                          <StyledTableRow key={index}>
                            <StyledTableCell align="center">
                              {index + 1}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {val?.dealer_code}
                            </StyledTableCell>

                            <StyledTableCell align="center">
                              {val?.source}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {moment(val.created_at).format(
                                "DD-MM-YYYY HH:MM"
                              )}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {val?.name}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {/* {val?.mobile} */}
                              <Button
                                disabled={!callButton}
                                className="addDeck"
                                variant="contained"
                                color="primary"
                                onClick={() => handleCallClick(val?.id, "call")}
                                startIcon={<CallIcon />}
                              ></Button>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {val.packages &&
                                val.packages.length > 0 &&
                                val.packages.map((val1: any) => {
                                  return (
                                    <Chip
                                      label={val1?.name}
                                      size="small"
                                      variant="outlined"
                                    />
                                  );
                                })}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Button
                                variant={
                                  !raiseComplaint ? "contained" : "outlined"
                                }
                                style={{
                                  marginLeft: "1rem",
                                  marginBottom: "1rem",
                                  background: "#1874a5",
                                  color: "white",
                                }}
                                onClick={() =>
                                  setRaiseComplaint(!raiseComplaint)
                                }
                              >
                                Status
                              </Button>
                              {/* <TextField
                                select
                                variant="outlined"
                                size="small"
                                defaultValue={val.status}
                                onChange={(e) =>
                                  updateStatus(val, e.target.value)
                                }
                              >
                                <MenuItem value="confirmed">Confirmed</MenuItem>
                                <MenuItem value="reschedule">
                                  Reschedule
                                </MenuItem>
                                <MenuItem value="canceled">Canceled</MenuItem>
                              </TextField> */}
                            </StyledTableCell>
                            {/* <StyledTableCell align="center">
                            <Button
                              variant="contained"
                              color="primary"
                              type="submit"
                              onClick={() => {
                                handleBook(val);
                              }}
                            >
                              Create Booking
                            </Button>
                          </StyledTableCell> */}
                          </StyledTableRow>
                        );
                      })}
                  </TableBody>
                  <HotLeadStatusModal
                    open={raiseComplaint}
                    setOpen={setRaiseComplaint}
                    bookingId={Number(bookingId)}
                    totalPrice={totalPrice}
                    leadId={0}
                    booking={booking}
                    initiateRefund={undefined}
                  />
                  <TableFooter>
                    <TablePagination
                      rowsPerPageOptions={[]}
                      colSpan={3}
                      count={hotleads?.count}
                      rowsPerPage={hotleads?.page_size}
                      page={page}
                      onPageChange={handleChangePage}
                    />
                  </TableFooter>
                </Table>
              )}
            </TableContainer>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  hotleads: state.OffLineSupporteReducer.hotleads,
  loading: state.OffLineSupporteReducer.loading,
});

export default connect(mapStateToProps, {
  getHotleads,
  updateHotleads,
  clickToCallLead,
})(HotLeads);
