import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Pusher from "pusher-js";
import { connect } from "react-redux";
import moment from "moment";
import { useHistory } from "react-router";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { ACTIONS } from '../../interfaces/actionTypes/loginTypes';
import { Link } from "react-router-dom";
import SecureStorage from "../../config/SecureStorage";
var jwt = require('jsonwebtoken');
let userGroup = "";

function PusherComponent(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [localData, setLocalData] = useState(localStorage.notificationData);
  const [userDetail, setUserDetail] = React.useState({
    userName: null,
    authToken: null
  });

  useEffect(() => {
    const checkLoginInterval = setInterval(() => {
      // SecureStorage.setLocalData('authToken', authToken);
      // SecureStorage.setLocalData('userName', userName);
      const authToken = SecureStorage.getItem("token");
      const userName = SecureStorage.getItem("username");
      if (authToken && userName) {
        setUserDetail({
          authToken,
          userName
        });
        clearInterval(checkLoginInterval);
      }
    }, 1000);
  }, []);

  useEffect(() => {
    Notification.requestPermission()
      .then()
      .catch();
    if (userDetail.authToken && userDetail.userName) {
      const apiEndpoint = `${process.env.REACT_APP_API_PATH}api/v1/auth`
      Pusher.logToConsole = true;
      var pusher = new Pusher(`${process.env.REACT_APP_CALLPOPUP_KEY}`, {
        cluster: "ap2",
        forceTLS: true,
        // Channel authorization
        channelAuthorization: {
          endpoint: `${apiEndpoint}/pusher/auth/`,
          headers: {
            Authorization: `Token ${userDetail.authToken}`,
          },
        },
      });

      const userNameForPusher = userDetail?.userName.replace(/ /g, "")
      var channel = pusher.subscribe(`private-${userDetail?.userName}`);

      const decoded = jwt.decode(userDetail.authToken);
      userGroup = decoded?.user_group;

      if (decoded?.user_group === "OffLineSupport") {
        channel.bind("chat_assigned", function (data) {
          dispatch({ type: ACTIONS.CHAT_ASSIGNED_FROM_PUSHER, payload: data });
          NotificationManager.success(
            handleWhatsapp(data), ``,
            3000
          );
        });

        channel.bind("chat_unassigned", function (data) {
          dispatch({ type: ACTIONS.CHAT_UNASSIGNED_FROM_PUSHER, payload: data });
          NotificationManager.error(
            handleWhatsapp(data), ``,
            3000
          );
        });
      }

      if (decoded?.user_group === "OffLineSupport") {
        channel.bind("new_whatsapp", function (data) {
          dispatch({ type: ACTIONS.WHATSAPP_DATA_FROM_PUSHER_FOR_SL, payload: data });
          NotificationManager.info(
            handleWhatsapp(data), ``,
            3000
          );
        });
      }
    }
  }, [userDetail]);

  useEffect(() => {
    if (localData) {
      NotificationManager.success(
        handle(JSON.parse(localData)), ``,
        30000
      );
    }
  }, [])

  const handleWhatsapp = (data) => {
    return (
      <div>
        <p>
          <span style={{ color: "black" }}>Lead: {data?.id}</span>
          <br />
          <b> {data?.patient_name} {": "} {data?.message}</b>
          <br />
          <b style={{ color: "black" }}>
            {new Date(data?.latest_chat_created_at).toLocaleString()}
          </b>
        </p>
      </div>
    );
  };

  const handle = (data) => {
    var timestamp = data.timestamp
    var date1 = new Date(timestamp);
    var ComplateDate = date1.getDate() +
      "/" + (date1.getMonth() + 1) +
      "/" + date1.getFullYear() +
      " " + date1.getHours() +
      ":" + date1.getMinutes() +
      ":" + date1.getSeconds()
    return (
      <div onClick={() => localStorage.setItem("notificationData", "")}>
        <p><b>Lead: {data?.lead_id}</b><br />Camp: {data?.camp_name} ,Timestamp: {ComplateDate}</p>
        <div
          className="upper_panel"
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            marginTop: 20,
          }}
        >
          <Link
            className="btn"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgb(16, 185, 129)",
              height: 40,
              borderRadius: "20px",
              color: "white",
              width: "90%",
              fontSize: 14,
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            }}
            target="_blank"
            to={`/dashboard/os/fill-info?id=${data?.lead_id}`}
          >
            VISIT
          </Link>
        </div>
      </div>
    );
  };
 
  return (
    <span></span>
  );
};

export default connect(null, { })(PusherComponent);