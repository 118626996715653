import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
    Grid,
    TextField,
    MenuItem,
    Select
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { getRefundPaymentList, initiateRefund, updateInitiateRefund } from '../../actions/OffLineSupportActions'
import Loader from "../../components/loader";
import RefundTable from './refundPaymentTable'
import { genrateRefundPaymentFilter } from '../../../helpers/generateUrl'
import SearchIcon from '@mui/icons-material/Search';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import Button from '@mui/material/Button';
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import CircularProgress from "@material-ui/core/CircularProgress";
import Modal from '@mui/material/Modal';
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
let width = window.innerWidth;
let isMobile = width <= 500;
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 0,
            //   width: "100%",
            //   marginTop: "20px",
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
            //   padding: theme.spacing(3),
        },
        card: {
            height: "100%",
            padding: theme.spacing(1),
        },
        card_grid: {
            // wordSpacing:3,
            justifyContent: 'space-between'
        },
        modal: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            border: "2px solid #000",
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            width: isMobile ? "400px" : "800px",
        },
    })
);


interface Props {
    loading: boolean;
    getRefundPaymentList: any;
    paymentRefundList: any;
    initiateRefund: any;
    updateInitiateRefund: any
    refundLoder:boolean
}

const CustomerLeadFilterPage: React.FC<Props> = ({
    loading,
    getRefundPaymentList,
    paymentRefundList,
    initiateRefund,
    updateInitiateRefund,
    refundLoder
}) => {
    const classes = useStyles();
    const history = useHistory()
    const timer = useRef<any>(0)
    const [bookingId, setBookingId] = useState<any>("")
    const [amount, setAmount] = useState<any>("")
    const [accountNumber, setAccountNumber] = useState<any>("")
    const [accountHolderName, setAccountHolderName] = useState<any>("")
    const [bankName, setBankName] = useState<any>("none")
    const [status, setStatus] = useState<any>("none")
    const [paymentMode, setPaymentMode] = useState<any>("none")
    const [openRefundModal, setOpenRefundModal] = useState<boolean>(false);
    const [createBookingId, setCreateBookingId] = useState<any>("")
    const [createAmount, setCreateAmount] = useState<any>("")
    const [createPaymentMode, setCreatePaymentMode] = useState<any>("")
    const [createRemarks, setCreateRemarks] = useState<any>("")
    const [refundId, setRefundId] = useState<any>("")
    const [buttonType, setButtoneType] = useState<any>("")
    const [apiType, setApiType] = useState<any>("")

    useEffect(() => {
        getRefundPaymentList()
    }, [])


    const filterBookings = () => {
        const body: any = {
            amount,
            status,
            payment_mode: paymentMode,
            account_holder_name: accountHolderName,
            account_number: accountNumber,
            bank_name: bankName,
            booking: bookingId
        }
        const url = genrateRefundPaymentFilter(body).substring(2)
        getRefundPaymentList(url ? `?${url}` : "");
        // setPage(0)
    }
    const handleInitiateRefund = () => {
        setOpenRefundModal(true)
        setButtoneType("Create Refund")
        setApiType("post")
    }

    const handleClose = () => {
        setOpenRefundModal(false)
    }

    const submitForm = async (e: any) => {
        e.preventDefault();
        const body = {
            booking: Number(createBookingId),
            amount: Number(createAmount),
            payment_mode: createPaymentMode,
            cs_remarks: createRemarks
        }
        if (apiType === "post") {
            await initiateRefund(body)
        }
        else if (apiType === "put") {
            await updateInitiateRefund(refundId, body)
        }
        setOpenRefundModal(false)
        setCreatePaymentMode("")
        setCreateAmount("")
        setCreateRemarks("")
        setCreateBookingId("")
        getRefundPaymentList()
    };

    return (
        <>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <h2>Payment Refund</h2>
                <Grid container spacing={3} direction="row" style={{ justifyContent: "flex-end" }}>
                    <Grid item xs={12} sm={4} md={2}>
                        <Button
                            variant="contained"
                            style={{ background: "#1de9b6" }}
                            fullWidth
                            onClick={handleInitiateRefund}

                            startIcon={<AccountBalanceWalletIcon />}
                        >
                            initiate Refund
                        </Button>
                    </Grid>
                </Grid>
                <Grid container spacing={1} direction="row" style={{ alignItems: "left" }}>
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            className="input"
                            name="name"
                            type="number"
                            onWheel={(e: any) => e.target.blur()}
                            label="Booking Id"
                            value={bookingId}
                            variant="outlined"
                            onChange={(e) => setBookingId(e.target.value)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            className="input"
                            name="name"
                            type="number"
                            onWheel={(e: any) => e.target.blur()}
                            label="Amount"
                            value={amount}
                            variant="outlined"
                            onChange={(e) => setAmount(e.target.value)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            className="input"
                            name="name"
                            type="number"
                            onWheel={(e: any) => e.target.blur()}
                            label="Account Number"
                            value={accountNumber}
                            variant="outlined"
                            onChange={(e) => setAccountNumber(e.target.value)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            className="input"
                            name="name"
                            type="text"
                            label="Account Holder Name"
                            value={accountHolderName}
                            variant="outlined"
                            onChange={(e) => setAccountHolderName(e.target.value)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Select
                            className="input"
                            name="bankName"

                            label="Bank Name"
                            variant="outlined"
                            value={bankName}
                            style={{ width: "100%", margin: "0", height: "40px" }}
                            onChange={(e) => setBankName(e.target.value as string)}
                        >
                            <MenuItem disabled value="none">Select Bank Name</MenuItem>
                            <MenuItem value="Bank of Baroda">Bank of Baroda</MenuItem>
                            <MenuItem value="Bank of India">Bank of India</MenuItem>
                            <MenuItem value="Bank of Maharashtra">Bank of Maharashtra</MenuItem>
                            <MenuItem value="Canara Bank">Canara Bank</MenuItem>
                            <MenuItem value="Central Bank of India">Central Bank of India</MenuItem>
                            <MenuItem value="Indian Bank">Indian Bank</MenuItem>
                            <MenuItem value="Others">Other</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Select
                            className="input"
                            name="status"

                            label="Status"
                            variant="outlined"
                            value={status}
                            style={{ width: "100%", margin: "0", height: "40px" }}
                            onChange={(e) => setStatus(e.target.value as string)}
                        >
                            <MenuItem value="none">Select Status</MenuItem>
                            <MenuItem value="RefundInitiate">Refund Initiate</MenuItem>
                            <MenuItem value="TLApproved">TL Approved</MenuItem>
                            <MenuItem value="AccountLinkSent">Account Link Sent</MenuItem>
                            <MenuItem value="AccountNumberAccepted">Account Number Accepted</MenuItem>
                            <MenuItem value="FinanceApproved">Finance Approved</MenuItem>
                            <MenuItem value="RefundCompleted">Refund Completed</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Select
                            className="input"
                            name="paymentMode"

                            label="Payment Mode"
                            variant="outlined"
                            value={paymentMode}
                            style={{ width: "100%", margin: "0", height: "40px" }}
                            onChange={(e) => setPaymentMode(e.target.value as string)}
                        >
                            <MenuItem value="none">Select Payment Mode</MenuItem>
                            <MenuItem value="online">Online </MenuItem>
                            <MenuItem value="upi">UPI</MenuItem>
                            <MenuItem value="wallet">Wallet</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Button
                            variant="contained"
                            color="secondary"
                            disabled={loading}
                            fullWidth
                            onClick={filterBookings}
                            startIcon={<SearchIcon />}
                        >
                            Filter
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Button
                            variant="contained"
                            color="primary"

                            fullWidth
                            startIcon={<RestartAltIcon />}
                            onClick={() => history.push("/dashboard/os/payment-refund")}
                        >
                            Reset
                        </Button>
                    </Grid>
                </Grid>
                <RefundTable
                    loading={loading}
                    paymentRefundList={paymentRefundList}
                    getRefundPaymentList={getRefundPaymentList}
                    setOpenRefundModal={setOpenRefundModal}
                    setButtoneType={setButtoneType}
                    setCreatePaymentMode={setCreatePaymentMode}
                    setCreateAmount={setCreateAmount}
                    setCreateRemarks={setCreateRemarks}
                    setCreateBookingId={setCreateBookingId}
                    setApiType={setApiType}
                    setRefundId={setRefundId}
                    refundLoder={refundLoder}
                />
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={openRefundModal}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={openRefundModal}>
                        <div className={classes.paper}>
                            <h4 style={{ fontWeight: "bold" }}>{buttonType === "Create Refund" ? "INITIATE REFUND" : "UPDATE REFUND"}</h4>
                            <form className="modal-form chat-form" onSubmit={submitForm}>
                                <Grid container spacing={3} direction="row" style={{ alignItems: "left" }}>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <TextField
                                            className="input"
                                            name="name"
                                            type="number"
                                            onWheel={(e: any) => e.target.blur()}
                                            label="Booking Id"
                                            value={createBookingId}
                                            variant="outlined"
                                            onChange={(e) => setCreateBookingId(e.target.value)}
                                            style={{ width: "100%" }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <TextField
                                            className="input"
                                            name="name"
                                            type="number"
                                            onWheel={(e: any) => e.target.blur()}
                                            label="Amount"
                                            value={createAmount}
                                            variant="outlined"
                                            onChange={(e) => setCreateAmount(e.target.value)}
                                            style={{ width: "100%" }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <TextField
                                            className="input"
                                            name="paymentMode"
                                            select
                                            label="Payment Mode"
                                            variant="outlined"
                                            value={createPaymentMode}
                                            style={{ width: "100%", margin: "0" }}
                                            onChange={(e) => setCreatePaymentMode(e.target.value as string)}
                                        >
                                            <MenuItem value="online">Online </MenuItem>
                                            <MenuItem value="upi">UPI</MenuItem>
                                            <MenuItem value="wallet">Wallet</MenuItem>
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <TextareaAutosize
                                            aria-label="minimum height"
                                            minRows={3}
                                            placeholder="Add Remarks.."
                                            value={createRemarks}
                                            onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                                                setCreateRemarks(e.target.value as string)
                                            }
                                            style={{ width: "100%", marginRight: "10px" }}
                                        />
                                    </Grid>
                                </Grid>
                                <Button variant="contained" color="success" type="submit">
                                    {buttonType}
                                </Button>
                            </form>
                        </div>
                    </Fade>
                </Modal>
            </main>
        </>
    );
};

const mapStateToProps = (state: any) => {

    return {
        loading: state.OffLineSupporteReducer.loading,
        paymentRefundList: state.OffLineSupporteReducer.paymentRefundList,
        refundLoder: state.OffLineSupporteReducer.refundLoder,
    }
}

export default connect(mapStateToProps, {
    getRefundPaymentList,
    initiateRefund,
    updateInitiateRefund
})(CustomerLeadFilterPage);
