import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  createStyles,
  makeStyles,
  useTheme,
  Theme,
  fade,
} from "@material-ui/core/styles";
import GpsOffIcon from '@mui/icons-material/GpsOff';
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import BookIcon from "@mui/icons-material/Book";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MicNoneIcon from "@mui/icons-material/MicNone";
import WhatshotIcon from "@material-ui/icons/Whatshot";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import QuizIcon from "@mui/icons-material/Quiz";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { logout, panelUserLogout } from "../../actions/loginActions";
import { useDispatch } from "react-redux";
import LocalFireDepartmentTwoToneIcon from "@mui/icons-material/LocalFireDepartmentTwoTone";
import HomeIcon from "@material-ui/icons/Home";
import TableRowsIcon from "@mui/icons-material/TableRows";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Tooltip,
} from "@material-ui/core";
import Button from "@mui/material/Button";
import EqualizerRoundedIcon from "@material-ui/icons/EqualizerRounded";
import {
  AddBoxTwoTone,
  ConfirmationNumberRounded,
  Dashboard,
  CloudUpload,
} from "@material-ui/icons";
import PhoneAndroid from "@material-ui/icons/PhoneAndroid";
import TextsmsIcon from "@material-ui/icons/Textsms";
import CallMissedIcon from "@material-ui/icons/CallMissed";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import { searchCall } from "../actions/OffLineSupportActions";
import FreeBreakfastIcon from "@material-ui/icons/FreeBreakfast";
import BreakModal from "../components/Comments/breakModal";
import FilterCenterFocusIcon from "@material-ui/icons/FilterCenterFocus";
import AllInboxIcon from "@material-ui/icons/AllInbox";
import CollectionsBookmarkIcon from "@material-ui/icons/CollectionsBookmark";
import {
  changePassword,
  getUserDetails,
  getFirebaseNotification,
  getNotificationMessage,
} from "../actions/OffLineSupportActions";
import { useHistory } from "react-router";
import SmsFailedIcon from "@mui/icons-material/SmsFailed";
import PaymentsIcon from "@mui/icons-material/Payments";
import Fade from "@material-ui/core/Fade";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";
import Popover from "@material-ui/core/Popover";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import InvertColorsIcon from "@material-ui/icons/InvertColors";
import EmergencyRecordingIcon from "@mui/icons-material/EmergencyRecording";
import TransferWithinAStationIcon from "@material-ui/icons/TransferWithinAStation";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import DeveloperModeIcon from "@material-ui/icons/DeveloperMode";
import NewUpdateModal from "../components/Comments/newUpdate";
import BugReportIcon from "@mui/icons-material/BugReport";
import LoyaltyIcon from "@material-ui/icons/Loyalty";
import PreviewIcon from "@mui/icons-material/Preview";
import HowToRegIcon from "@mui/icons-material/HowToReg";
const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    title: {
      flexGrow: 1,
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: "none",
    },
    drawer: {
      color: "#fff",
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      backgroundColor: "#924A91",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    dashbord: {
      color: "#fff",
      fontSize: "18px",
      fontWeight: "normal",
      padding: "20px 0px 10px 10px",
    },
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "inherit",
    },
    popover: {
      pointerEvents: "none",
    },
    paper: {
      padding: theme.spacing(1),
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "12ch",
        "&:focus": {
          width: "20ch",
        },
      },
    },
  })
);
interface Props {
  getUserDetails: any;
  userDetails: any;
  getFirebaseNotification: any;
  getNotificationMessage: any;
  breakResponse: any;
  notificationMessage: any;
  passwordStatus: boolean;
}
const SupportExecutive: React.FC<Props> = ({
  getUserDetails,
  getNotificationMessage,
  userDetails,
  notificationMessage,
  breakResponse,
  passwordStatus,
  getFirebaseNotification,
}) => {
  let history = useHistory();
  let dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState<string>("");
  const [changePasswordModal, setchangePasswordModal] = React.useState(false);
  const [oldPassword, setOldPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [option, setOption] = React.useState<string>("call_id");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [openModal, setOpenModal] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [notificationData, setnotificationData] = useState("");
  const [heights, setheights] = useState(window.innerHeight);
  const openPopover = Boolean(anchorEl);
  const [openRightMenu, setOpenRightMenu] = React.useState<boolean>(false);
  const [currentNotification, setCurrentNotification] = React.useState<any>([]);
  const [anchorElUpdate, setAnchorElUpdate] = React.useState(null);
  const id = open ? "transitions-popper" : undefined;

  const handleClick = (event: any) => {
    setOpenRightMenu(true);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setOpenRightMenu(false);
  };
  useEffect(() => {
    getUserDetails();
    setOpenModal(
      breakResponse &&
      breakResponse?.results &&
      breakResponse?.results.length > 0 &&
      breakResponse &&
      breakResponse?.results[0]?.break_button
    );
  }, []);
  useEffect(() => {
    if (!passwordStatus) {
      setchangePasswordModal(true);
    }
  }, [passwordStatus]);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
    // getNotificationMessage(`?is_read=false`)
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const updatePassword = async () => {
    const body: any = {
      old_password: oldPassword,
      new_password: newPassword,
      confirm_password: confirmPassword,
    };
    await dispatch(changePassword(body));
    setchangePasswordModal(false);
    dispatch(logout());
  };
  const handleValueChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setValue(event.target.value as string);
  };
  const submitForm = (e: any) => {
    e.preventDefault();
    let body = {
      search_by: option,
      value,
    };
    dispatch(searchCall(body));
    history.push("/dashboard/os/lead/search");
  };
  const handleBreakButton = () => {
    setOpenModal(true);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleWindowSizeChange = () => {
    setheights(window.innerHeight);
  };
  const handleNotification = () => {
    history.push("/dashboard/os/inbox");
  };

  const handleLogout = () => {
    dispatch(panelUserLogout());
    dispatch(logout());
  };
  const handleUpdateDetails = (event: any) => {
    setAnchorElUpdate(anchorElUpdate ? null : event.currentTarget);
  };

  const location = useLocation();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar style={{ backgroundColor: "#924A91" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            Redcliffelabs
          </Typography>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <form onSubmit={submitForm}>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                value={value}
                onChange={handleValueChange}
                inputProps={{ "aria-label": "search" }}
              />
            </form>
          </div>
          <div>
            <Button
              variant="text"
              onClick={(e) => handleClick(e)}
              size="medium"
              endIcon={<KeyboardArrowDownIcon />}
              style={{ color: "#fff" }}
            >
              {userDetails &&
                userDetails.profile &&
                userDetails.profile.username}
            </Button>
            <Menu
              id="fade-menu"
              open={openRightMenu}
              onClose={handleClose}
              TransitionComponent={Fade}
              anchorEl={anchorEl}
            >
              <MenuItem onClick={() => setchangePasswordModal(true)}>
                Change Password
              </MenuItem>
              <MenuItem onClick={() => handleLogout()}>Logout</MenuItem>
            </Menu>
          </div>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-owns={openPopover ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            onClick={handleNotification}
            color="inherit"
          >
            <NotificationsActiveIcon />
          </IconButton>
          <IconButton>
            <DeveloperModeIcon
              aria-describedby={id}
              onClick={handleUpdateDetails}
            />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <Dialog
          open={changePasswordModal}
          onClose={() => setchangePasswordModal(false)}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Change Password</DialogTitle>
          <DialogContent>
            <TextField
              id="name"
              placeholder="Old Password"
              type="password"
              fullWidth
              style={{
                width: "100%",
                margin: "0.3rem auto",
                padding: "14px !important",
              }}
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
            <TextField
              id="name"
              placeholder="New Password"
              type="password"
              fullWidth
              style={{
                width: "100%",
                margin: "0.3rem auto",
                padding: "14px !important",
              }}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <TextField
              id="name"
              placeholder="Confirm Password"
              type="password"
              fullWidth
              style={{
                width: "100%",
                margin: "0.3rem auto",
                padding: "14px !important",
              }}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => updatePassword()} color="primary">
              Update
            </Button>
            <Button
              onClick={() => setchangePasswordModal(false)}
              color="primary"
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <div className={classes.toolbar}>
          <span className={classes.dashbord}>Dashboard</span>
          <IconButton onClick={handleDrawerClose} style={{ color: "#fff" }}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          <Link to="/dashboard/os">
            <ListItem button>
              <Tooltip title="Home" arrow>
                <ListItemIcon>
                  <HomeIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Home" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/os/bookingList">
            <ListItem button>
              <Tooltip title="Booking List" arrow>
                <ListItemIcon>
                  <TableRowsIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Booking List" />
            </ListItem>
          </Link>
        </List>
        {userDetails?.profile?.executive_type === "Ticketing" ? (
          <>
            <List>
              <Link to="/dashboard/os/booking/tickets">
                <ListItem button>
                  <Tooltip title="Booking Tickets" arrow>
                    <ListItemIcon>
                      <ConfirmationNumberRounded style={{ color: "#924A91" }} />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText primary="Booking Tickets" />
                </ListItem>
              </Link>
            </List>
          </>
        ) : (
          ""
        )}
        <List>
          <Link to="/dashboard/os/verificationStatusTable">
            <ListItem button>
              <Tooltip title="Verification List" arrow>
                <ListItemIcon>
                  <VerifiedUserIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Verification List" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/os/booking">
            <ListItem button>
              <Tooltip title="New Booking" arrow>
                <ListItemIcon>
                  <AddBoxTwoTone style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="New Booking" />
            </ListItem>
          </Link>
        </List>

        <List>
          <Link to="/dashboard/os/centerInfo">
            <ListItem button>
              <Tooltip title="Collection Center Information" arrow>
                <ListItemIcon>
                  <FilterCenterFocusIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Collection Center Information" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/os/hotleads">
            <ListItem button>
              <Tooltip title="Hot Leads" arrow>
                <ListItemIcon>
                  <LocalFireDepartmentTwoToneIcon
                    style={{ color: "#924A91" }}
                  />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Hot Leads" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/os/inbox">
            <ListItem button>
              <Tooltip title="Inbox" arrow>
                <ListItemIcon>
                  <AllInboxIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Inbox" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/os/grievance">
            <ListItem button>
              <Tooltip title="Grievance" arrow>
                <ListItemIcon>
                  <BugReportIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Grievance" />
            </ListItem>
          </Link>
        </List>
        {/* <List>
          <Link to="/dashboard/os/report_not_deliverd">
            <ListItem button>
              <Tooltip title="Reports-Tat-Breach" arrow>
                <ListItemIcon>
                  <BugReportIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Reports-Tat-Breach" />
            </ListItem>
          </Link>
        </List> */}
        <List>
          <Link to="/dashboard/os/testPackages">
            <ListItem button>
              <Tooltip title="Test Packages" arrow>
                <ListItemIcon>
                  <InvertColorsIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Test Packages" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/os/calls_recording">
            <ListItem button>
              <Tooltip title="Offline Call Recording" arrow>
                <ListItemIcon>
                  <EmergencyRecordingIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Offline Call Recording" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/os/notserviceablearea">
            <ListItem button>
              <Tooltip title="Not Serviceable Area" arrow>
                <ListItemIcon>
                  <GpsOffIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Not Serviceable Area" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/os/views">
            <ListItem button>
              <Tooltip title="Views" arrow>
                <ListItemIcon>
                  <PreviewIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Views" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/os/whatsapp_chat">
            <ListItem button>
              <Tooltip title="Whatsapp Chat" arrow>
                <ListItemIcon>
                  <WhatsAppIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Whatsapp Chat" />
            </ListItem>
          </Link>
        </List>

        <Divider />
        <Popover
          id="mouse-over-popover"
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          open={openPopover}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          {notificationMessage &&
            notificationMessage?.results &&
            notificationMessage?.results?.length > 0 &&
            notificationMessage?.results.map((data: any) => {
              return <Typography>{data?.notification || ""}</Typography>;
            })}
        </Popover>
        <List>
          <ListItem button onClick={() => handleLogout()}>
            <Tooltip title="Logout" arrow>
              <ListItemIcon>
                <ExitToAppIcon style={{ color: "#924A91" }} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>
      <BreakModal openModal={openModal} setOpenModal={setOpenModal} />
      <NewUpdateModal
        anchorElUpdate={anchorElUpdate}
        setAnchorElUpdate={setAnchorElUpdate}
        id={id}
      />
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  userDetails: state.OffLineSupporteReducer.userDetails,
  breakResponse: state.OffLineSupporteReducer.breakResponse,
  passwordStatus: state.loginReducer.passwordStatus,
  notificationMessage: state.OffLineSupporteReducer.notificationMessage,
});

export default connect(mapStateToProps, {
  getUserDetails,
  getFirebaseNotification,
  getNotificationMessage,
})(SupportExecutive);
