import React, { useState, useEffect, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Card,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
  Checkbox,
} from "@material-ui/core";
import {
  getPackage,
  updateBooking,
  getBookingById,
  getCoupons,
  getAvailableSlots,
  getCities,
  getSubCenterInfo,
  getPinCode,
  getImagingTimeslots,
  getMultiplePackage,
  getDisplayCities,
  getMapMyIndiaAccessToken,
  checkGeoFenceArea,
  checkGeoFenchMapMyIndia,
} from "../../../actions/OffLineSupportActions";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import Button from '@mui/material/Button';
import "./index.sass";
import { connect } from "react-redux";
import moment from "moment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { useHistory } from "react-router";
import { Chip } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { GENDER_CHOICE } from "../../constant";
import { GoogleMapsAPI } from "../../../../helpers/client-config";
import Geocode from "react-geocode";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Loader from "../../Loader2";
Geocode.setApiKey("AIzaSyBPjDlsHYhWtjt3FxgRPnTMXN-qJZ9hz0s");
Geocode.enableDebug();

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 10px 10px",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      padding: "20px 20px 20px 20px",
      marginTop: "20px",
      width: "100%",
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    head: {
      textAlign: "center",
      fontWeight: "normal",
      marginBottom: "2rem",
    },
    packageLink: {
      color: "#ffffff",
      textDecoration: "none",
    },
    slotContainer: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
    },
    slot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      margin: "0.5rem",
    },
    selectedSlot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      background: "linear-gradient(60deg, cyan, #00dfff, cyan)",
      cursor: "pointer",
      margin: "0.5rem",
    },
    chips: {
      display: "flex",
    },
    chip: {
      margin: 2,
      background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
      maxWidth: "130px",
    },
  })
);

interface Props {
  getPackage: any;
  packageList: any;
  couponList: any;
  getCoupons: any;
  updateBooking: any;
  match: any;
  booking: any;
  getBookingById: any;
  booking_slots: any;
  getAvailableSlots: any;
  loading: boolean;
  getCities: any;
  cities: any;
  getSubCenterInfo: any;
  subCenter: any;
  getPinCode: any;
  getImagingTimeslots: any;
  pin_code: any;
  imagingTimeSlots: any;
  getMultiplePackage: any;
  multiplePackage: any;
  getDisplayCities: any;
  displayCities: any;
  checkGeoFenceArea: any;
  checkGeoFenceStatus: boolean;
  checkGeoFenchMapMyIndia: any;
  geoFencesMapMyIndia: any;
  getMapMyIndiaAccessToken: any;
  mapmyindiaAccessToken: any;
}

const EditBookingForm: React.FC<Props> = ({
  packageList,
  getPackage,
  couponList,
  getCoupons,
  updateBooking,
  match,
  booking,
  getBookingById,
  booking_slots,
  getAvailableSlots,
  getCities,
  cities,
  subCenter,
  loading,
  getSubCenterInfo,
  getPinCode,
  getImagingTimeslots,
  imagingTimeSlots,
  getMultiplePackage,
  multiplePackage,
  getDisplayCities,
  displayCities,
  checkGeoFenceArea,
  checkGeoFenceStatus,
  checkGeoFenchMapMyIndia,
  geoFencesMapMyIndia,
  getMapMyIndiaAccessToken,
  mapmyindiaAccessToken,
  pin_code,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const bookingId = match.params.id;

  useEffect(() => {
    getBookingById(`${bookingId}`);
  }, [bookingId]);

  const [open, setOpen] = React.useState(false);
  const [collection_date, setCollection_date] = useState<String>(
    booking.collection_date
  );
  const [booking_date, setBooking_date] = useState<string>(
    booking.booking_date
  );
  const [selectedSlot, setSelectedSlot] = useState<Number>(
    booking.collection_slot && booking.collection_slot.id
  );
  const [customer_name, setCustomer_name] = useState<String>(
    booking.customer_name
  );
  const [customer_designation, setCustomer_designation] = useState<string>();
  const [designation, setDesignation] = useState<String>();

  const [customer_age, setCustomer_age] = useState<String>(
    booking.customer_age
  );
  const [customer_gender, setCustomer_gender] = useState<string>();
  const [customer_phonenumber, setCustomer_phonenumber] = useState<String>(
    booking.customer_phonenumber
  );
  const [customer_alternatenumber, setCustomer_alternatenumber] =
    useState<String>(booking.customer_altphonenumber);
  const [customer_whatsapppnumber, setCustomer_whatsapppnumber] =
    useState<String>(booking.customer_whatsapppnumber);
  const [customer_email, setCustomer_email] = useState<String>(
    booking.customer_email
  );

  const [customer_longitude, setCustomerLongitude] = useState<any>(
    booking?.customer_longitude
  );
  const [customer_latitude, setCustomerLatitude] = useState<any>(
    booking?.customer_latitude
  );
  const [center_longitude, setCenterLongitude] = useState<any>("");
  const [center_latitude, setCenterLatitude] = useState<any>("");
  const [customer_areapincode, setCustomer_areapincode] = useState<Number>(
    booking?.customer_areapincode?.id
  );
  const [customer_address, setCustomer_address] = useState<String>(
    booking.center_address && booking.center_address.address
  );
  const [customer_city, setCustomer_city] = useState<String>("");
  const [customer_aadhar, setCustomer_aadhar] = useState<String>(
    booking.customer_aadhar
  );
  const [passport_number, setPassportNumber] = useState<String>(
    booking.passport_number
  );
  const [adharPassport, setAdharPassport] = useState(
    booking.customer_aadhar !== "" ? "customer_aadhar" : "passport_number"
  );
  const [packageDetails, setPackageDetails] = useState<any>(booking.packages);
  const [cityDisplayName, setCityDisplayname] = useState<any>(
    booking.display_name
  );
  const [packageKey, setPackageKey] = useState<any>(0);
  const [redDisc, setRedDisc] = useState<any>(0);
  const [packages, setPackages] = useState<Number[]>(
    booking?.packages &&
      booking.packages.map((pack: any) => {
        return pack.id;
      })
  );
  const [addrErrorMessage, setAddrErrorMessage] = useState<string>("");
  const [coupon, setCoupon] = useState<Number>(0);
  const [couponCode, setCouponCode] = useState<string>("");
  const [price, setPrice] = useState<any>(
    booking.discounted_price && booking.discounted_price.total_price_package
  );
  const [discount, setDiscount] = useState<any>(
    booking.discounted_price && booking.discounted_price.counpon_discount
  );
  const [prevDiscount, setPrevDiscount] = useState<any>(
    booking.discounted_price && booking.discounted_price.counpon_discount
  );
  const [totalPrice, setTotalPrice] = useState<any>(
    booking.discounted_price && booking.discounted_price.final_total_price
  );
  const [extraCharge, setExtraCharge] = useState<boolean>(
    booking.discounted_price && booking.discounted_price.phlebo_cost !== 0
  );
  const [cityName, setCityName] = useState<any>("");
  const [cityId, setCityId] = useState<any>(booking && booking?.cityid);
  const [centerName, setCentername] = useState<String>(booking?.center?.name);
  const [centerId, setCenterId] = useState<any>(booking?.center?.id);
  const [centerKey, setCenterKey] = useState<any>(0);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [mobileValidation, setMobileValidation] = useState({
    customer_alternatenumber: true,
    customer_phonenumber: true,
    customer_whatsapppnumber: true,
  });

  const [subCenterId, setSubCenterId] = useState<any>(
    booking?.center_address?.id
  );
  const [customer_landmark, setCustomer_landmark] = useState<String>(
    booking.customer_landmark || ""
  );
  const [subCenterDetails, setSubCenterDetails] = useState(
    booking.center_address
  );
  const [allPackage, setAllPackaage] = useState<boolean>(false);
  const [imgTimeSlots, setImgTimeSlots] = useState<any>([]);
  const [subCenterData, setSubCenterData] = useState<any>([]);
  const [centerMessage, setCenterMessage] = useState<string>("");
  const [singlePackage, setSinglePackage] = useState<any>({});
  const [couponMessage, setCouponMessage] = useState<string>("");
  const [tncMessage, setTncMessage] = useState<string>("");
  const [redCashCheck, setRedCashCheck] = useState<boolean>(false);
  const [isPaneOpen, setPaneOpen] = useState<boolean>(false);
  const [suggestedAddress, setSuggestedAddres] = useState<any>([]);
  const [mapAddressObject, setMapAddressObject] = useState<any>({});
  const [accessToken, setAccessToken] = useState<any>("");
  const [testType, setTestType] = useState<string>(booking?.packages&&booking?.packages[0].type);
  const timer = useRef<any>(0);

  useEffect(() => {
    if (booking) {
      setCollection_date(booking?.collection_date);
      setBooking_date(booking?.booking_date);
      setSelectedSlot(booking.collection_slot && booking.collection_slot.id);
      setCustomer_name(booking?.customer_name);
      setCustomer_age(booking.customer_age);
      setCustomer_phonenumber(booking.customer_phonenumber);
      setCustomer_alternatenumber(booking.customer_altphonenumber);
      setCustomer_whatsapppnumber(booking.customer_whatsapppnumber);
      setCustomer_email(booking.customer_email);
      setCustomer_landmark(
        booking?.customer_landmark
      );
      setCustomer_address(booking.customer_address && booking.customer_address);
      setCustomer_aadhar(booking.customer_aadhar);
      setAdharPassport(booking.customer_aadhar !== "" ? "customer_aadhar" : "passport_number");
      setPackageDetails(booking.packages || []);
      setPackages(
        booking.packages &&
        booking.packages.map((pack: any) => {
          return pack.id;
        })
      );
      setSubCenterId(
        booking?.center_address?.id
      );
      setTestType(booking?.packages&&booking?.packages[0]?.type);
      setPrice(booking.discounted_price && booking.discounted_price.total_price_package);
      setDiscount(booking.discounted_price && booking.discounted_price.counpon_discount);
      setTotalPrice(booking.discounted_price && booking.discounted_price.final_total_price);
      setExtraCharge(booking.discounted_price && booking.discounted_price.phlebo_cost !== 0);
      setCityName(booking?.display_name);
      setCityId(booking && booking.cityid);
      getPinCode(
        booking?.customer_areapincode &&
        `${booking?.customer_areapincode?.pincode}&show_code=true`
      );
      setSelectedSlot(booking?.collection_slot && booking?.collection_slot?.id);
      setCustomerLongitude(
        booking?.customer_longitude
      );
      setCustomerLatitude(
        booking?.customer_latitude
      );
      setSubCenterDetails(
        booking?.center_address
      );
    }

  }, [booking])

  useEffect(() => {
    if (booking?.coupon_applied) {
      setCoupon(booking?.coupon_applied.id);
      setCouponCode(booking?.coupon_applied?.name);
    }
    if (booking?.designation && booking?.customer_gender) {
      setCustomer_designation(booking?.designation);
      const findGender = GENDER_CHOICE.filter(
        (el: any) => el.db_name == booking?.designation
      );
      if (
        booking?.designation == "Master" &&
        booking?.customer_gender == "male"
      ) {
        setDesignation("Baby Boy");
      } else if (
        booking?.designation == "Master" &&
        booking?.customer_gender == "female"
      ) {
        setDesignation("Baby Girl");
      } else {
        findGender.length > 0 && setDesignation(findGender[0].display_name);
      }
      setCustomer_gender(booking?.customer_gender);
    }
    getDisplayCities();
  }, [booking]);

  useEffect(() => {
    getCoupons("?org_type=imaging");
    getCities("", "?is_imaging=true");
    getMapMyIndiaAccessToken();
  }, []);
  useEffect(() => {
    if (subCenterId !== "" && packages !== undefined) {
      getImagingTimeslots(
        `?packages=${packages}&collection_date=${collection_date}&center_address=${subCenterId}`
      );
    }
  }, [subCenterId]);
  useEffect(() => {
    if (subCenterId !== "") {
      setImgTimeSlots(imagingTimeSlots);
    }
  });
  useEffect(() => {
    if (testType !== undefined) {
      if (allPackage) {
        getPackage(`org_type=imaging&type=${testType}`);
      } else {
        getPackage(`center_address=${subCenterId}&org_type=imaging&type=${testType}`);
      }
    }
  }, [allPackage]);

  useEffect(() => {
    if (packages !== undefined) {
      getSubCenterInfo(
        `?imaging=true&customer_latitude=${customer_latitude}&customer_longitude=${customer_longitude}&package=${packages}&display_name=${cityName}`
      );
    }
  }, []);
  useEffect(() => {
    if (packages !== undefined) {
      getSubCenterInfo(
        `?imaging=true&customer_latitude=${customer_latitude}&customer_longitude=${customer_longitude}&package=${packages}&display_name=${cityName}`
      );
    }
  }, [packages]);

  const handlePackages = (pack: any) => {
    setPackageDetails(pack);
    let packIds: any = [];
    pack &&
      pack.length > 0 &&
      pack.map((item: any) => {
        packIds.push(item.id);
        setPackages(packIds);
      });
    // multiplePackage && multiplePackage.results && multiplePackage.results.length > 0 && multiplePackage.results.map((item: any) => {
    //     if (item.package_center_address_prices === null) {
    //         accumulatedPrice = accumulatedPrice + item.offer_price
    //     }
    //     else {
    //         accumulatedPrice = accumulatedPrice + item.package_center_address_prices.offer_price
    //     }

    //     if (accumulatedPrice < min_phlebo_cost) {
    //         setExtraCharge(false)
    //     } else {
    //         setExtraCharge(false)
    //     }
    // }
    // )
    // setPrice(Math.floor(accumulatedPrice))
    // setDiscount(0)
    // setTotalPrice(Math.floor(accumulatedPrice))
    // setCoupon(0)
    // setCouponCode("")
  };
  useEffect(() => {
    const min_phlebo_cost =
      packageList &&
      packageList?.results &&
      packageList?.results.length > 0 &&
      packageList?.results[0].min_booking_amount_for_phlebo;
    let accumulatedPrice: any = 0;
    multiplePackage &&
      multiplePackage.results &&
      multiplePackage.results.length > 0 &&
      multiplePackage.results.map((item: any) => {
        if (item.package_center_address_prices === null) {
          accumulatedPrice = accumulatedPrice + item.offer_price;
        } else {
          accumulatedPrice =
            accumulatedPrice + item.package_center_address_prices.offer_price;
        }

        if (accumulatedPrice < min_phlebo_cost) {
          setExtraCharge(false);
        } else {
          setExtraCharge(false);
        }
      });
    setPrice(Math.floor(accumulatedPrice));
    setDiscount(0);
    setTotalPrice(Math.floor(accumulatedPrice));
    setCoupon(0);
    setCouponCode("");
  }, [multiplePackage]);

  useEffect(() => {
    if (extraCharge) {
      setTotalPrice(Math.floor(price - discount + 200));
    } else {
      setTotalPrice(Math.floor(price - discount));
    }
  }, [price, discount, extraCharge, multiplePackage]);

  useEffect(() => {
    if (totalPrice < 0) {
      setTotalPrice(0);
    }
  }, [totalPrice, multiplePackage]);

  const applyCoupon = () => {
    if (couponCode === "") {
      setCouponCode("");
      setCoupon(0);
      setDiscount(0);
      return;
    }
    const appliedCoupon =
      couponList?.results?.find((coup: any) => coup.id === coupon) ||
      couponList?.results?.find((coup: any) => coup.code === couponCode);
    if (appliedCoupon && appliedCoupon?.minimum_price < totalPrice) {
      setCoupon(appliedCoupon?.id);
      if (appliedCoupon?.discount_type.toLowerCase() === "percentage") {
        if (appliedCoupon?.is_red_cash) {
          setDiscount(Math.floor(price));
        } else {
          setDiscount(Math.floor((price * appliedCoupon?.discount) / 100));
        }
      }
      if (appliedCoupon?.discount_type?.toLowerCase() === "amount") {
        if (appliedCoupon.is_red_cash) {
          setDiscount(0);
        } else {
          setDiscount(Math.floor(appliedCoupon?.discount));
        }
      }
    } else {
      setCouponCode("");
      setCoupon(0);
      setDiscount(0);
    }
  };
 const verifyMobile = (type: any) => {
    if(type==="mobile"){
      if(parseInt(customer_phonenumber&&customer_phonenumber[0])<6||customer_phonenumber&&customer_phonenumber.length<10){
        setMobileValidation((prev: any) => ({
              ...prev,
              customer_phonenumber: false,
        }));}
    }
    if(type==="alternate"){
      if(parseInt(customer_alternatenumber&&customer_alternatenumber[0])<6||customer_alternatenumber&&customer_alternatenumber.length<10){
        setMobileValidation((prev: any) => ({
              ...prev,
              customer_alternatenumber: false,
          }));}
    }
    if(type=== "whatsapp" ){
      if(parseInt(customer_whatsapppnumber&&customer_whatsapppnumber[0])<6||customer_whatsapppnumber&&customer_whatsapppnumber.length<10){
        setMobileValidation((prev: any) => ({
              ...prev,
              customer_whatsapppnumber: false,
            }));}
    }
  };
  const submitForm = async (e: any) => {
    e.preventDefault();
    const data: any = {
      booking_date: moment(new Date()).format("YYYY-MM-DD"),
      collection_date: collection_date,
      collection_slot: selectedSlot,
      customer_name: customer_name,
      customer_age: customer_age,
      customer_gender: customer_gender,
      customer_email: customer_email,
      customer_phonenumber: customer_phonenumber,
      customer_altphonenumber: customer_alternatenumber,
      customer_whatsapppnumber: customer_whatsapppnumber,
      customer_address: customer_landmark,
      customer_landmark: customer_landmark,
      customer_longitude: customer_longitude,
      customer_latitude: customer_latitude,
      customer_areapincode: customer_areapincode,
      customer_aadhar: customer_aadhar,
      passport_number: passport_number === null ? "" : passport_number,
      packages: packages,
      coupon_applied: coupon,
      designation: customer_designation,
      center: centerId,
      display_name: cityName,
      center_address: subCenterId,
    };

    if (data["coupon_applied"] === 0 || couponCode === "") {
      delete data["coupon_applied"];
    }

    // if (removeCoupn && !data['coupon_applied']) {
    //     data['remove_coupon'] = "true"
    // }
    await updateBooking(data, bookingId);
    history.push(`/dashboard/os/booking-view/${bookingId}`);
  };
  const changeCollectionDate = (e: any) => {
    setSelectedSlot(0);
    setCollection_date(e.target.value as string);
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAdharPassport((event.target as HTMLInputElement).value);
  };

  const titleSelection = (title: string) => {
    const findGender = GENDER_CHOICE.filter(
      (el: any) => el.display_name == title
    );
    setDesignation(title);
    setCustomer_designation(findGender[0].db_name);
    setCustomer_gender(findGender[0].gender);
  };

  const handleSubCenter = (val: any) => {
    setSubCenterDetails(val)
    setCenterId(val.center.id);
    setSubCenterId(val.id);
    setCenterLatitude(val.latitude);
    setCenterLongitude(val.logitude);
    setCustomer_address(val.address);
    setCustomer_areapincode(parseInt(val.location.id));
    setCustomer_city(val.location.city);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleresetPackage = () => {
    setSubCenterId("");
    setSubCenterDetails({});
    setPackageDetails([]);
    setPackages([]);
    setPackageKey(selectedSlot);
    setSelectedSlot(0);
    setAllPackaage(true);
    setOpen(false);
    setImgTimeSlots([]);
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (subCenterId !== "" && packages !== undefined) {
      getMultiplePackage(
        `?multiple_ids=${packages}&center_address=${subCenterId}&imaging=true&org_type=imaging`
      );
    }
  }, [packages, subCenterId]);
  useEffect(() => {
    if (subCenter) {
      setSubCenterData(subCenter);
    }
  }, [subCenter]);
  useEffect(() => {
    // if (packages.length > 0) {
    //   if (subCenterData.length === undefined) {
    //     setCenterMessage("No Center Available for this package");
    //   } else {
    //     setCenterMessage("");
    //   }
    // }
  }, [subCenterData]);

  useEffect(() => {
    if (geoFencesMapMyIndia?.latitude && geoFencesMapMyIndia?.longitude) {
      setCustomerLatitude(geoFencesMapMyIndia?.latitude);
      setCustomerLongitude(geoFencesMapMyIndia?.longitude);
      setAddrErrorMessage("")
    }else if(geoFencesMapMyIndia?.latitude===null|| geoFencesMapMyIndia?.longitude===null){
      setAddrErrorMessage("Please select correct address")
    }
  }, [geoFencesMapMyIndia]);

  useEffect(() => {
    if (mapmyindiaAccessToken?.accesstoken) {
      setAccessToken(mapmyindiaAccessToken?.accesstoken);
    }
  }, [mapmyindiaAccessToken]);

  useEffect(() => {
    if (mapAddressObject?.eLoc) {
      getPinCode(mapAddressObject?.addressTokens?.pincode);
      setCustomer_landmark(mapAddressObject?.placeAddress);
      checkGeoFenchMapMyIndia(mapAddressObject?.eLoc, "NA");
    }
  }, [mapAddressObject]);

  useEffect(() => {
    if (pin_code?.results && pin_code?.results.length > 0) {
      setCityId(pin_code?.results[0].cityid);
    }
  }, [pin_code]);

  const locationAutoCompleteSearch = (val: string) => {
    if (val.length > 2) {
      setSuggestedAddres([]);
      const urlForMap = `https://atlas.mapmyindia.com/api/places/search/json?query=${val}&access_token=${accessToken}&tokenizeAddress=true`;
      return new Promise(function (resolve, reject) {
        const id = "_" + Math.round(10000 * Math.random());
        const callbackName = "jsonp_callback_" + "data" + id;
        window[callbackName] = (data: any) => {
          delete window[callbackName];
          const ele = document.getElementById(id);
          ele?.parentNode?.removeChild(ele);
          resolve(data?.suggestedLocations);
          setSuggestedAddres(data?.suggestedLocations);
        };
        const src = urlForMap + "&callback=" + callbackName;
        const script = document.createElement("script");
        script.src = src;
        script.id = id;
        script.addEventListener("error", reject);
        (
          document.getElementsByTagName("head")[0] ||
          document.body ||
          document.documentElement
        ).appendChild(script);
      });
    }
  };
  document.querySelectorAll("#noTyping").forEach(el=>{
    el.addEventListener("keydown", function(e){
      e.preventDefault()
    })
  })
 
  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <div className={classes.contentHeader}>
        <h2>EDIT IMAGING BOOKING</h2>
      </div>
      {Object.keys(booking).length === 0 ? (
        <Loader />
      ) : (
        <Paper className={classes.paper} elevation={15}>
          <form onSubmit={submitForm}>
            <Grid container spacing={3} style={{ margin: "auto" }}>
              <Grid item xs={12} md={6}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <p>Booking date</p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <input
                      className="input"
                      name="collection_date"
                      type="date"
                      value={booking_date || ""}
                      style={{
                        width: "100%",
                        margin: "0",
                        padding: "18.5px 14px",
                        height:"40px"
                      }}
                      required
                      disabled
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <p>Scanning date</p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <input
                      className="input"
                      name="collection_date"
                      type="date"
                      value={
                        (collection_date && collection_date.toString()) || ""
                      }
                      // onChange={(e) => setCollection_date(e.target.value as string)}
                      onChange={(e) => changeCollectionDate(e)}
                      style={{
                        width: "100%",
                        margin: "0",
                        padding: "18.5px 14px",
                        height:"40px"
                      }}
                      // min={`${new Date().getFullYear()}-${(new Date().getMonth() + 1) < 10 ? `0${(new Date().getMonth() + 1)}` : (new Date().getMonth() + 1)}-${new Date().getDate() < 10 ? `0${new Date().getDate()}` : new Date().getDate()}`}
                      required
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <p>City Name</p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      id="cityname"
                      onChange={(event, newValue) => {
                        let cName: any = "";
                        if (newValue) {
                          let obj = JSON.parse(
                            JSON.stringify(newValue, null, " ")
                          );
                          cName = obj;
                        }
                        setCityName(cName);
                      }}
                      options={displayCities.cities || []}
                      freeSolo
                      blurOnSelect
                      inputValue={cityName ? cityName : ""}
                      onInputChange={(event, newInputValue) => {
                        setCityName(newInputValue);
                      }}
                      aria-required
                      limitTags={1}
                      getOptionLabel={(option: any) => option}
                      getOptionDisabled={(option: any) => {
                        const cName = displayCities.cities.indexOf(
                          booking.display_name
                        );
                      
                        return option === displayCities.cities[cName];
                      }}
                      disableCloseOnSelect
                      renderInput={(params) => (
                        <TextField
                          className="input"
                          {...params}
                          label="City Name"
                          variant="outlined"
                          style={{ width: "100%", margin: "0", padding: "0" }}
                          inputProps={{
                            ...params.inputProps,
                            onKeyDown: (e) => {
                                  if (e.key === 'Enter') {
                                    e.stopPropagation();
                                  }
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <p>Customer Address</p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      id="colony-area-sector"
                      onChange={(event, newValue) => {
                        if (newValue) {
                          let obj = JSON.parse(
                            JSON.stringify(newValue, null, " ")
                          );
                          setMapAddressObject(obj);
                        }
                      }}
                      options={suggestedAddress ? suggestedAddress : []}
                      freeSolo
                      value={{"placeAddress": customer_landmark}}
                      blurOnSelect
                      aria-required
                      disabled
                      getOptionLabel={(option: any) => `${option?.placeAddress}`}
                      filterOptions={(option: any) => option}
                      onInputChange={(event, newInputValue) => {
                        clearTimeout(timer.current);
                        timer.current = setTimeout(() => {
                          locationAutoCompleteSearch(newInputValue);
                        }, 500);
                      }}
                      renderInput={(params) => (
                        <TextField
                          className="input"
                          {...params}
                          placeholder="Search Address"
                          variant="outlined"
                          style={{ width: "100%", margin: "0", padding: "0" }}
                          required
                        />
                      )}
                    
                    />
                  </Grid>
                  {addrErrorMessage !== "" ? (
                  <div style={{ color: "red", fontSize: "12px" }}>
                    {addrErrorMessage}
                  </div>
                ) : (
                  ""
                )}
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <p>Test Type</p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                  <Select
                        className="input"
                        name="testType"
                        disabled
                        variant="outlined"
                        value={testType ? testType : ""}
                        style={{ width: "100%", margin: "0", height:"40px" }}
                        onChange={(e) => setTestType(e.target.value as string)}
                      >
                        
                        <MenuItem value="XRay">X-Ray</MenuItem>
                        <MenuItem value="MRI">MRI</MenuItem>
                        <MenuItem value="USG">USG</MenuItem>
                        <MenuItem value="PET">PET SCAN</MenuItem>
                        <MenuItem value="NUC">NUCLEAR</MenuItem>
                        <MenuItem value="DEXA">DEXA</MenuItem>
                        <MenuItem value="MAM">Mammography</MenuItem>
                        <MenuItem value="CAR">Cardio</MenuItem>
                        <MenuItem value="NEU">Neurology</MenuItem>
                        <MenuItem value="CT">CT</MenuItem>
                        <MenuItem value="CBCT">CBCT</MenuItem>
                        <MenuItem value="PFT">PFT</MenuItem>
                        <MenuItem value="Audiology">Audiology</MenuItem>
                        <MenuItem value="Gastroenterology">Gastroenterology</MenuItem>
                        <MenuItem value="Gyne">GYNE</MenuItem>
                      </Select>
                  </Grid>
                  </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <p>Test Name</p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      id="package"
                      key={packageKey}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          let packs: any = [];
                          newValue.map((val: any) => {
                            let obj = JSON.parse(JSON.stringify(val, null, " "));
                            packs.push(obj);
                          });
                          handlePackages(packs);
                        }
                      }}
                      multiple
                      options={packageList?.results || []}
                      value={packageDetails}
                      disableClearable
                      limitTags={0}
                      getOptionLabel={(option: any) =>
                        option.name && option.package_center_address_prices
                          ? option.name.toString() +
                          ", " +
                          option.package_center_address_prices.tat_time :
                          option.name + ", " + option.tat_time 
                      }
                      getOptionDisabled={(option: any) => {
                        const packID = packageDetails.map((pack: any) => pack.id);
                      
                        return packID.includes(option.id);
                      }}
                      onInputChange={(event, newInputValue) => {
                        clearTimeout(timer.current);
                        timer.current = setTimeout(() => {
                          getPackage(
                            allPackage
                              ? `org_type=imaging&code=${newInputValue}&type=${testType}`
                              : `center_address=${subCenterId}&org_type=imaging&code=${newInputValue}&type=${testType}`
                          );
                        }, 1000);
                      }}
                      freeSolo
                      disableCloseOnSelect
                      renderOption={(option, { selected }) => (
                        <React.Fragment>
                          <Checkbox
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {
                            option.name && option.package_center_address_prices
                            ? option.name.toString() +
                              ", " +
                              option.package_center_address_prices.tat_time
                            : option.name + ", " + option.tat_time
                          }
                        </React.Fragment>
                      )}
                      // renderTags={(value: string[], getTagProps) =>
                      //   value.map((option: any, index: number) => (
                      //     <Chip
                      //       variant="default"
                      //       color="primary"
                      //       label={
                      //         option.name && option.package_center_address_prices
                      //           ? option.name.toString() +
                      //             ", " +
                      //             option.package_center_address_prices.tat_time
                      //           : option.name + ", " + option.tat_time
                      //       }
                      //       {...getTagProps({ index })}
                      //     />
                      //   ))
                      // }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Package"
                          variant="outlined"
                          style={{ width: "100%", margin: "0", padding: "0" }}
                          className="input"
                          inputProps={{
                            ...params.inputProps,
                            onKeyDown: (e) => {
                                  if (e.key === 'Enter') {
                                    e.stopPropagation();
                                  }
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <p>Center</p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      id="subcenter"
                      key={centerKey}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          let obj = JSON.parse(
                            JSON.stringify(newValue, null, " ")
                          );
                          handleSubCenter(obj as String);
                        }
                      }}
                      options={subCenterData.length > 0 ? subCenterData : []}
                      freeSolo
                      blurOnSelect
                      value={subCenterDetails?.address ? subCenterDetails : {address : ""}}
                      onInputChange={(event, newInputValue) => {
                        clearTimeout(timer.current);
                        timer.current = setTimeout(() => {
                          getSubCenterInfo(
                            `?imaging=true&name=${newInputValue === "undefined" ? "" : newInputValue}&customer_latitude=${customer_latitude}&customer_longitude=${customer_longitude}&package=${packages}&display_name=${cityName}`
                          );
                        }, 1000);
                      }}
                      getOptionLabel={(option: any) =>
                        `${option?.address}${option?.distance?`,Distance : ${Math.trunc(option?.distance)} KM`:""}`
                      }
                      getOptionDisabled={(option: any) => {
                        const packID = subCenterDetails?.id;
                        return option?.id;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Sub Center"
                          variant="outlined"
                          style={{ width: "100%", margin: "0", padding: "0" }}
                          className="input"
                          required
                          inputProps={{
                            ...params.inputProps,
                            onKeyDown: (e) => {
                                  if (e.key === 'Enter') {
                                    e.stopPropagation();
                                  }
                            },
                          }}
                        />
                      )}
                      disabled={packages?.length === 0}
                    />
                    <p style={{ color: "red" }}>{centerMessage}</p>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card
                  className="sampleCollectionTimeImaging"
                  style={{ height: "375px!important", overflow: "auto" }}
                >
                  <p style={{ marginBottom: "1rem" }}>Scanning Time</p>
                  <div className={classes.slotContainer}>
                    {imgTimeSlots &&
                      imgTimeSlots?.results &&
                      imgTimeSlots?.results?.length > 0 &&
                      imgTimeSlots.results.map((slot: any) => {
                        return (
                          imgTimeSlots.results.length > 0 && (
                            <Card
                              key={slot.id}
                              className={
                                slot.id === selectedSlot
                                  ? classes.selectedSlot
                                  : classes.slot
                              }
                              onClick={() => setSelectedSlot(slot.id as Number)}
                            >
                              <p
                                style={{
                                  textAlign: "center",
                                  color: "black",
                                  fontSize: "14px",
                                }}
                              >
                                {slot.start_time} onwards
                              </p>
                              <p style={{ textAlign: "center", color: "green" }}>
                                {" "}
                                {/* Availabe slots: {slot.available_slots} */}
                              </p>
                            </Card>
                          )
                        );
                      })}
                  </div>
                </Card>
                <Grid item xs={12} md={6}>
                  <p>
                    {" "}
                    <span
                      onClick={handleClickOpen}
                      style={{ color: "#ab003c", cursor: "pointer" }}
                    >
                      click here
                    </span>{" "}
                    to View all Package
                  </p>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="flex-end"
              spacing={3}
              style={{ margin: "1rem auto", background: "#eff" }}
            >
              <Grid item xs>
                <p>Title</p>
                <Select
                  className="input"
                  name="customer_designation"
                  variant="outlined"
                  value={designation || ""}
                  style={{ width: "100%", margin: "0", height:"40px" }}
                  onChange={(e) => titleSelection(e.target.value as string)}
                  required
                >
                  <MenuItem value="Mrs">Mrs</MenuItem>
                  <MenuItem value="Miss">Miss</MenuItem>
                  <MenuItem value="Ms">Ms</MenuItem>
                  <MenuItem value="Smt">Smt</MenuItem>
                  <MenuItem value="Mr">Mr</MenuItem>
                  <MenuItem value="Baby Boy">Baby Boy</MenuItem>
                  <MenuItem value="Baby Girl">Baby Girl</MenuItem>
                </Select>
              </Grid>
              <Grid item xs>
                <p>Customer Name</p>
                <TextField
                  name="customer_name"
                  type="text"
                  placeholder="Enter Name"
                  value={customer_name}
                  className="input"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ width: "100%", margin: "0" }}
                  onChange={(e) => setCustomer_name(e.target.value.replace(/[^A-Za-z- ']/, '') as String)}
                  required
                />
              </Grid>
              <Grid item xs>
                <p>Age</p>
                <TextField
                  name="customer_age"
                  type="number"
                  placeholder="Enter Age"
                  value={customer_age}
                  className="input"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ width: "100%", margin: "0" }}
                  inputProps={{ min: 0, max: 150 }}
                  onChange={(e) => {
                  let ageValue = parseInt(e.target.value);
                  if (ageValue > 150) ageValue = 150;
                  if (ageValue < 0) ageValue = 0;
                  e.target.value = ageValue.toString()
                  setCustomer_age(e.target.value as any)
                  }}
                  required
                />
              </Grid>
              <Grid item xs>
                <p>Gender</p>
                <TextField
                  className="input"
                  name="customer_gender"
                  variant="outlined"
                  value={customer_gender === "male" ? "Male" : customer_gender === "female" ? "Female" : ""}
                  style={{ width: "100%", margin: "0", padding: "0", height:"40px" }}
                  onChange={(e) => setCustomer_gender(e.target.value as string)}
                  required
                  disabled
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={3}
              direction="row"
              justify="center"
              alignItems="center"
              style={{ margin: "1rem auto" }}
            >
              <Grid item xs={12} md={5}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <p>Mobile Number</p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="customer_phonenumber"
                      type="text"
                      value={customer_phonenumber}
                      placeholder="Enter Mobile Number"
                      className="input"
                      variant="outlined"
                      disabled
                      style={{ margin: "0", width: "100%" }}
                      onChange={(e) =>
                        setCustomer_phonenumber(e.target.value as String)
                      }
                      onBlur={() => verifyMobile("mobile")}
                      onFocus={() => {
                        setMobileValidation((prev: any) => ({
                          ...prev,
                          customer_phonenumber: true,
                        }));
                      }}
                      helperText={
                        !mobileValidation.customer_phonenumber &&
                        "Incorrect Phone Number"
                      }
                      required
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <p>Alternate Mobile Number</p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="customer_alternatenumber"
                      type="text"
                      value={customer_alternatenumber}
                      placeholder="Enter Alternate Mobile Number"
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                      onChange={(e) =>
                        setCustomer_alternatenumber(e.target.value as String)
                      }
                      required
                      onBlur={() => verifyMobile("whatsapp")}
                      onFocus={() => {
                        setMobileValidation((prev: any) => ({
                          ...prev,
                          customer_alternatenumber: true,
                        }));
                      }}
                      helperText={
                        !mobileValidation.customer_whatsapppnumber &&
                        "Incorrect Alternate Number"
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <p>Email</p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="customer_email"
                      type="email"
                      placeholder="Enter Email"
                      value={customer_email}
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                      onChange={(e) =>
                        setCustomer_email(e.target.value as String)
                      }
                      required
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <p>Whatsapp Number</p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="customer_whatsapppnumber"
                    type="text"
                      placeholder="Enter Whatsapp Number"
                      value={customer_whatsapppnumber}
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                      onChange={(e) =>
                        setCustomer_whatsapppnumber(e.target.value as String)
                      }
                      onBlur={() => verifyMobile("mobile")}
                      onFocus={() => {
                        setMobileValidation((prev: any) => ({
                          ...prev,
                          customer_whatsapppnumber: true,
                        }));
                      }}
                      helperText={
                        !mobileValidation.customer_whatsapppnumber &&
                        "Incorrect Whatsapp Number"
                      }
                      required
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">Aadhaar/Passport</FormLabel>
                      <RadioGroup
                        aria-label="Choose"
                        name="adharpassport"
                        value={adharPassport}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value="customer_aadhar"
                          control={<Radio />}
                          label="Aadhaar Number"
                        />
                        <FormControlLabel
                          value="passport_number"
                          control={<Radio />}
                          label="Passport Number"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      className="input"
                      name="customer_aadhar"
                      type="text"
                      placeholder={
                        adharPassport === "customer_aadhar"
                          ? "Enter Aadhaar Number"
                          : "Enter Passport Number"
                      }
                      value={
                        adharPassport === "customer_aadhar"
                          ? customer_aadhar
                          : passport_number
                      }
                      style={{ margin: "0", width: "100%" }}
                      variant="outlined"
                      onInput={(e: any) => {
                        e.target.value = adharPassport === "customer_aadhar" ? (e.target.value)
                          .toString()
                          .slice(0, 12) : (e.target.value)
                          .toString()
                          .slice(0, 10)
                      }}
                      onChange={(e) =>
                      adharPassport === "customer_aadhar" ?
                          setCustomer_aadhar(e.target.value.replace(/[^0-9]/, '') as String) : setPassportNumber(e.target.value.replace(/[^A-Z0-9]/, '') as String)
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={7} style={{ padding: "10px" }}>
                <div className="price-details-new-booking">
                  <table style={{ width: "100%", margin: "0" }}>
                    <thead>
                      <tr>
                        <td className="cell" align="center">
                          S No.
                        </td>
                        <td className="cell" align="center">
                          Name
                        </td>
                        <td className="cell" align="center">
                          Package
                        </td>
                        <td className="cell" align="center">
                          Price
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="cell" align="center">
                          1
                        </td>
                        <td className="cell" align="left">
                          {customer_designation} {customer_name}
                        </td>
                        <td className="cell" align="left">
                          {multiplePackage &&
                            multiplePackage?.results &&
                            multiplePackage?.results?.length > 0 &&
                            multiplePackage?.results?.map(
                              (packageItem: any, index: any) => {
                                return (
                                  <ul>
                                    <li>
                                      <a
                                        onClick={() => {
                                          setSinglePackage(packageItem);
                                          setPaneOpen(true);
                                        }}
                                        className={classes.packageLink}
                                      >
                                        {packageItem?.name}
                                        <br /> DESCRIPTION :{" "}
                                        {packageItem?.description}
                                        <br />
                                        TaT :{" "}
                                        {packageItem?.package_center_address_prices
                                          ? packageItem
                                              ?.package_center_address_prices
                                              ?.tat_time
                                          : packageItem?.tat_time}
                                        <br />
                                        Price :{" "}
                                        {packageItem?.package_center_address_prices
                                          ? packageItem
                                              ?.package_center_address_prices
                                              ?.offer_price
                                          : packageItem?.offer_price}
                                      </a>
                                    </li>
                                  </ul>
                                );
                              }
                            )}
                        </td>
                        <td className="cell" align="center">
                          ₹ {Math.floor(totalPrice)}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={4}>
                          <table style={{ width: "100%" }}>
                            <tr>
                              <td
                                className="cell"
                                align="left"
                                rowSpan={3}
                                colSpan={2}
                                style={{ width: "60%" }}
                                valign="top"
                              >
                                <Grid container spacing={1} direction="row">
                                  <Grid item xs={12}>
                                    <p>Enter Coupon</p>
                                  </Grid>
                                  <Grid item xs={8}>
                                    <Autocomplete
                                      id="coupon"
                                      onChange={(event, newValue) => {
                                        if (newValue) {
                                          let obj = JSON.parse(
                                            JSON.stringify(newValue, null, " ")
                                          );
                                          setCoupon(obj?.id as Number);
                                          setCouponCode(obj?.code as string);
                                        }
                                      }}
                                      inputValue={couponCode}
                                      options={couponList?.results || []}
                                      freeSolo
                                      blurOnSelect
                                      onInputChange={(event, newInputValue) => {
                                        clearTimeout(timer.current);
                                        setCouponCode(newInputValue);
                                        timer.current = setTimeout(() => {
                                          getCoupons(
                                            `?code=${newInputValue}&org_type=imaging`
                                          );
                                        }, 1000);
                                        if (newInputValue === "") {
                                          setCoupon(0);
                                          setDiscount(0);
                                          setTncMessage("");
                                          setCouponMessage("");
                                        }
                                      }}
                                      getOptionLabel={(option: any) =>
                                        option?.code
                                      }
                                      getOptionDisabled={(option: any) =>
                                        option?.minimum_price > totalPrice
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          className="input"
                                          {...params}
                                          placeholder="Enter Coupon"
                                          variant="outlined"
                                          style={{
                                            width: "100%",
                                            margin: "0",
                                            padding: "0",
                                          }}
                                          inputProps={{
                                            ...params.inputProps,
                                            onKeyDown: (e) => {
                                                  if (e.key === 'Enter') {
                                                    e.stopPropagation();
                                                  }
                                            },
                                          }}
                                        />
                                      )}
                                    />
                                  </Grid>
                                  <Grid item xs={4}>
                                    <Button
                                      variant="contained"
                                      fullWidth
                                      color="primary"
                                      disabled={redCashCheck}
                                      onClick={applyCoupon}
                                    >
                                      Apply
                                    </Button>
                                  </Grid>
                                  <p
                                    style={{
                                      color: "#8e24aa",
                                      fontSize: "14px",
                                      fontWeight: "bold",
                                      alignItems: "center",
                                    }}
                                  >
                                    {couponMessage} <br />
                                    {tncMessage}
                                  </p>
                                </Grid>
                              </td>
                              <td
                                className="cell"
                                align="right"
                                style={{ width: "20%" }}
                              >
                                Discount
                              </td>
                              <td
                                className="cell"
                                align="center"
                                style={{ width: "20%" }}
                              >
                                ₹ {Math.floor(discount)+prevDiscount}
                              </td>
                            </tr>
                            {/* <tr>
                              <td
                                className="cell"
                                align="right"
                                style={{ width: "20%" }}
                              >
                                RedCash Discount
                              </td>
                              <td
                                className="cell"
                                align="center"
                                style={{ width: "20%" }}
                              >
                                ₹ {redDisc > 0 ? Math.floor(redDisc) : 0}
                              </td>
                            </tr> */}

                            <tr>
                              <td className="cell" align="right">
                                <h4 className="totalPrice">Total Price</h4>
                              </td>
                              <td className="cell" align="center">
                                <h4 className="totalPrice">
                                  ₹{" "}
                                  {redDisc > 0
                                    ? Math.floor(totalPrice) - Math.floor(redDisc)-Math.floor(prevDiscount)
                                    : Math.floor(totalPrice)-Math.floor(prevDiscount)}
                                </h4>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={3}
              direction="row"
              justify="center"
              alignItems="center"
              style={{ margin: "1rem auto" }}
            >
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <h3
                    style={{
                      borderTop: "dotted 1px #cccc",
                      paddingTop: "10px",
                      fontSize: "24px",
                      fontWeight: "bold",
                    }}
                  >
                    CENTER ADDRESS
                  </h3>
                </Grid>
                <Grid container spacing={3} style={{ margin: "1rem auto" }}>
                  <Grid item xs={12} md={3}>
                    <Grid item xs={12} style={{ padding: "0" }}>
                      <p>Center Address</p>
                    </Grid>
                    <Grid item xs={12} style={{ padding: "0" }}>
                      <TextField
                        className="input"
                        name="customer_address"
                        type="text"
                        placeholder="Enter address"
                        value={customer_address}
                        disabled
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Grid item xs={12} style={{ padding: "0" }}>
                      <p>Center Longitude</p>
                    </Grid>
                    <Grid item xs={12} style={{ padding: "0" }}>
                      <TextField
                        className="input"
                        name="customer_longitude"
                        type="text"
                        placeholder="Enter Longitude"
                        value={customer_longitude}
                        disabled
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Grid item xs={12} style={{ padding: "0" }}>
                      <p>Center Latitude</p>
                    </Grid>
                    <Grid item xs={12} style={{ padding: "0" }}>
                      <TextField
                        className="input"
                        name="customer_latitude"
                        type="text"
                        placeholder="Enter Latitude"
                        value={customer_latitude}
                        disabled
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <div className={classes.buttonContainer}>
              <Button
                color="primary"
                type="submit"
                variant="contained"
                disabled={loading || !selectedSlot || packages.length === 0 || !mobileValidation.customer_alternatenumber || !mobileValidation.customer_phonenumber || !mobileValidation.customer_whatsapppnumber}
              >
                Update booking
              </Button>
            </div>
          </form>
        </Paper>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {" "}
          Do You Want To Change Package
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ color: "#ab003c" }}
          >
            For Changing Package You have to select center and time slot as well{" "}
            <br />
            Our center and Time Slot is mapped according to Package
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Disagree
          </Button>
          <Button onClick={handleresetPackage} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </main>
  );
};
const mapStateToProps = (state: any) => ({
  pin_code: state.OffLineSupporteReducer.pin_code,
  packageList: state.OffLineSupporteReducer.packageList,
  couponList: state.OffLineSupporteReducer.couponList,
  booking: state.OffLineSupporteReducer.booking,
  booking_slots: state.OffLineSupporteReducer.booking_slots,
  loading: state.OffLineSupporteReducer.loading,
  cities: state.OffLineSupporteReducer.cities,
  subCenter: state.OffLineSupporteReducer.subCenter,
  imagingTimeSlots: state.OffLineSupporteReducer.imagingTimeSlots,
  multiplePackage: state.OffLineSupporteReducer.multiplePackage,
  displayCities: state.OffLineSupporteReducer.displayCities,
  checkGeoFenceStatus: state.OffLineSupporteReducer.checkGeoFenceStatus,
  geoFencesMapMyIndia: state.OffLineSupporteReducer.geoFencesMapMyIndia,
  mapmyindiaAccessToken: state.OffLineSupporteReducer.mapmyindiaAccessToken,
});

export default connect(mapStateToProps, {
  getPackage,
  updateBooking,
  getCoupons,
  getBookingById,
  getAvailableSlots,
  getCities,
  getSubCenterInfo,
  getPinCode,
  getImagingTimeslots,
  getMultiplePackage,
  getDisplayCities,
  checkGeoFenceArea,
  checkGeoFenchMapMyIndia,
  getMapMyIndiaAccessToken,
})(EditBookingForm);
