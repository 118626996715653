import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@mui/material/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button, MenuItem, Select, TextField, Grid } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useEffect } from "react";

import { updateRecordingDispostion } from "../../actions/OffLineSupportActions"

let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: isMobile ? "400px" : "1200px",
    },
    head: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "2rem",
    },
}));

type ModalProps = {
    open: boolean;
    setOpen: Function;
    updateRecordingDispostion: any;
    uploadedUrl: any
    dispositions: any;
};

const CommentsModal2: React.FC<ModalProps> = ({
    open,
    setOpen,
    updateRecordingDispostion,
    uploadedUrl,
    dispositions,
}) => {
    const classes = useStyles();
    const [disposition, setDisposition] = useState<string>("none");
    const [comment, setComment] = useState<any>("")
    const blockCharInPhoneField = (e: React.KeyboardEvent<HTMLDivElement>) =>
        ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

    const handleClose = () => {
        setDisposition("");
        setOpen("");
    };

    const updatedispostion = async () => {
        // Access uploadedUrl from props
        const id = uploadedUrl;

        const data: any = {
            disposition: disposition,
            remarks: comment,
        };

        await updateRecordingDispostion(id, data);
        setOpen(false);
    }
    useEffect(() => {
        if (dispositions) {
            setDisposition(dispositions);
        }
    }, [dispositions])


    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <div className={classes.head}>
                            <h4
                                style={{
                                    marginBottom: "1rem",
                                }}
                                id="transition-modal-title"
                            >
                                Update Disposition Status
                            </h4>
                            <CloseIcon onClick={() => setOpen(false)} />
                        </div>
                        <Grid
                            container
                            direction="row"
                            alignItems="flex-start"
                            justify="space-between"
                            spacing={3}
                            style={{ margin: "1rem auto" }}
                        >
                            <Grid item xs={12} md={12}>
                                <p>Select Status</p>
                                <Select
                                    className="input"
                                    name="action"
                                    variant="outlined"
                                    value={disposition}
                                    style={{ width: "100%" }}
                                    onChange={(e) => setDisposition(e.target.value as string)}
                                >
                                    <MenuItem value="Not Connected">Not Connected</MenuItem>
                                    <MenuItem value="Call Disconnected">Call Disconnected</MenuItem>
                                    <MenuItem value="Call Connected">Call Connected</MenuItem>
                                    <MenuItem value="Callback">Callback</MenuItem>
                                    <MenuItem value="Ringing">Ringing</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <p>Remarks</p>
                                <textarea
                                    aria-label="Please add Remarks"
                                    rows={3}
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value as string)}
                                    placeholder="Please add Remarks"
                                    style={{ width: "100%", height: "70px" }} />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    style={{ height: "50px" }}
                                    onClick={updatedispostion}
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
};

const mapStateToProps = (state: any) => ({

});

export default connect(mapStateToProps, {
    updateRecordingDispostion,
})(CommentsModal2);
