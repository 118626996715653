import React, { useEffect, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Card,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import Button from "@mui/material/Button";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  createAdditionalBooking,
  updateAdditionalBooking,
  getBookingById,
  getPinCode,
  resetBookings,
  clickToCallBooking,
  updateBooking,
  getAgentList,
  assignDoctor,
  getTimeslots,
  getCollectionSlot,
  getPackage,
  getPaymentSendLink,
  getPaymentReSendLink,
  getPaymentCancelLink,
  getSmsReportSend,
  getSmsDetails,
  getEmailDetails,
  getSMSLink,
  getReportStatus,
  getReportDownload,
  getBookingQueryTickets,
} from "../../../actions/OffLineSupportActions";
import Loader from "../../Loader2";
import RemoveTest from "./removePackage";
import AddTest from "./addPackage";
import moment from "moment";
import CallIcon from "@material-ui/icons/Call";
import { useRef } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import RaiseComplaintModal from "../../Comments/raiseTicketModal";
import { GridCloseIcon } from "@material-ui/data-grid";
import { generateEmailFilterUrl } from "../../../../helpers/generateUrl";
import ReportModal from "../../Comments/reportStatusModal";
import EmailSMSDetailsModal from "../../Comments/emailSmsDetailsModal";
import Invoice from "../../Comments/invoiceModel";
import { CALL_DISABLE_TIME } from "../../../../CallTimeDisable";
import "./index.sass";
import {
  CITY,
  LOCALITY,
  ADDRESS_LINE1,
  ADDRESS_LINE2,
  LANDMARK,
  PINCODE,
} from "../../constant";
import { H2Tool } from "react-bootstrap-editor";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import LoadingButton from "@mui/lab/LoadingButton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    contentHeader: {
      width: "100%",
      padding: "10px 10px 40px 10px",
    },
    leftContentHeader: {
      width: "12%",
      float: "left",
    },
    rightContentHeader: {
      width: "88%",
      float: "right",
      textAlign: "right",
      paddingRight: "20px",
    },
    contentTable: {
      width: "100%",
      padding: "2px 0px 10px 0px",
      marginTop: "40px",
      clear: "both",
    },

    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem",
      width: "100%",
    },
    heading: {
      fontSize: "1.3rem",
      marginTop: "1rem",
    },
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    slotContainer: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
    },
    slot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      margin: "0.5rem",
    },
    selectedSlot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      background: "linear-gradient(60deg, cyan, #00dfff, cyan)",
      cursor: "pointer",
      margin: "0.5rem",
    },
    tableHead: {
      fontWeight: "bold",
      textTransform: "capitalize",
      textAlign: "center",
    },
  })
);

interface Props {
  match: any;
  booking: any;
  getBookingById: any;
  getPinCode: any;
  pin_code: any;
  partnerList: any;
  getPartners: any;
  resetBookings: any;
  clickToCallBooking: any;
  updateBooking: any;
  getAgentList: any;
  agentsList: any;
  assignDoctor: any;
  getTimeslots: any;
  getCollectionSlot: any;
  timeslots: any;
  getPackage: any;
  packageList: any;
  getPaymentSendLink: any;
  getPaymentReSendLink: any;
  getPaymentCancelLink: any;
  getSmsDetails: any;
  getEmailDetails: any;
  emailDetails: any;
  smsDetails: any;
  paymentSendLink: any;
  paymentReSendLink: any;
  paymentCancelLink: any;
  createAdditionalBooking: any;
  updateAdditionalBooking: any;
  getSMSLink: any;
  getSmsReportSend: any;
  additional_booking: any;
  getReportStatus: any;
  reportStatus: any;
  bookingCall: any;
  getReportDownload: any;
  reportDownload: any;
  loading: boolean;
  bookingComplaints: any;
  getBookingQueryTickets: any;
  bookingListLoader: boolean;
}

const ViewBooking: React.FC<Props> = ({
  match,
  booking,
  getBookingById,
  getPinCode,
  pin_code,
  resetBookings,
  clickToCallBooking,
  updateBooking,
  getAgentList,
  agentsList,
  assignDoctor,
  getTimeslots,
  getCollectionSlot,
  getSmsDetails,
  getEmailDetails,
  emailDetails,
  smsDetails,
  timeslots,
  getPackage,
  packageList,
  createAdditionalBooking,
  getSMSLink,
  updateAdditionalBooking,
  additional_booking,
  getPaymentSendLink,
  getPaymentReSendLink,
  getPaymentCancelLink,
  paymentSendLink,
  paymentCancelLink,
  getReportStatus,
  reportStatus,
  bookingCall,
  getReportDownload,
  reportDownload,
  getSmsReportSend,
  getBookingQueryTickets,
  bookingComplaints,
  loading,
  bookingListLoader,
}) => {
  const classes = useStyles();
  const [raiseComplaint, setRaiseComplaint] = useState(false);
  const [cancelAlert, setCancelAlert] = useState(false);
  const [assignBooking, setAssignBooking] = useState(false);
  const [doctor, setDoctor] = useState(0);
  const timer = useRef<any>(0);
  const [customer_designation, setCustomerDesignation] = useState<String>(
    booking.designation || ""
  );
  const [cityId, setCityId] = useState<any>("");
  const [timeSlots, setTimeSlots] = useState<Array<Object>>([]);
  const [selectedSlot, setSelectedSlot] = useState<Number>(
    booking.collection_slot && booking.collection_slot.id
  );
  const [customer_name, setCustomer_name] = useState<any>("");
  const [pickup_status, setPickup_status] = useState("");
  const [customer_age, setCustomer_age] = useState<any>("");
  const [customer_gender, setCustomer_gender] = useState<any>("male");
  const [customer_aadhar, setCustomer_aadhar] = useState<any>("");
  const [packageDetails, setPackageDetails] = useState<any>([]);
  const [reportSendStatus, setReportSendStatus] = useState(false);
  const [id, setId] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = useState(false);
  const [openEmailSMSModal, setOpenEmailSMSModal] = useState(false);
  const [openReportModal, setReportModal] = useState(false);
  const [tempTotalPrice, setTempTotalPrice] = useState<number>(0);
  const [coupon, setCoupon] = useState<Number>(0);
  const [couponCode, setCouponCode] = useState<string>("");
  const [price, setPrice] = useState<any>(0);
  const [personId, setPersoneId] = React.useState("none");
  const [extraCharge, setExtraCharge] = useState<boolean>(
    booking.discounted_price && booking.discounted_price.phlebo_cost !== 0
  );
  const [totalPrice, setTotalPrice] = useState<any>(
    booking.discounted_price && booking.discounted_price.final_total_price
  );
  const [memberPackageID, setMemberPackageID] = useState<string[]>([]);
  const [billId, setBillId] = useState<any>("");
  const [additionalBillId, setAdditionalBillId] = useState<any>("");
  const [openInvoiceModel, setOpenInvoiceModel] = useState(false);
  const [openAddModal, setOpenAddModal] = useState<any>(false);
  const [openRemoveModal, setOpenRemoveModal] = useState<any>(false);
  const [bId, setBId] = useState<any>("");
  const [aId, setAId] = useState<any>("");
  const [type, setType] = useState<any>("");
  const [booking_status, setBooking_status] = useState<any>("");
  const [callButton, setCallButton] = useState<boolean>(true);
  const [is_price_hide, setIsPriceHode] = useState<boolean>(
    booking?.partner?.is_price_hide
  );
  const [memberDetails, setMemberDetails] = useState<any>();
  const [additionalBookingStatus, setAdditionalBookingStatus] =
    useState<any>("");
  useEffect(() => {
    setIsPriceHode(booking?.partner?.is_price_hide);
  }, [booking]);

  useEffect(() => {
    if (raiseComplaint) {
      getBookingQueryTickets(`?booking_id=${bookingId}`)
    }
  }, [raiseComplaint]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
    getSlots();
  };

  const checkNormalPackage = (packages: any) => {
    let isNormalPackage = false;
    packages &&
      packages.length > 0 &&
      packages.map((item: any) => {
        if (!item.is_addon) {
          isNormalPackage = true;
        }
      });
    return isNormalPackage;
  };

  const handlePackages = (customePackage: any, reason: any) => {
    let originalPackageId = new Array();
    packageDetails.length > 0 &&
      packageDetails.map((item: any) => {
        originalPackageId.push(item.id as string);
      });
    setPackageDetails(customePackage);
    let accumulatedPrice: any = 0;
    let updatePackageId = new Array();
    if (customePackage && customePackage.length > 0) {
      let atLeastOneNormalPackage = checkNormalPackage(customePackage);
      customePackage.map((item: any) => {
        updatePackageId.push(item.id);
        const packageExits = memberPackageID.find((el) => el == item.id);
        if (!packageExits) {
          if (
            atLeastOneNormalPackage &&
            item.is_addon &&
            item.addon_price &&
            item.addon_price > 0
          ) {
            accumulatedPrice = accumulatedPrice + item.addon_price;
          } else {
            if (booking.org_type === "imaging") {
              if (item.package_center_address_prices === null) {
                accumulatedPrice = accumulatedPrice + item.offer_price;
              } else {
                accumulatedPrice =
                  accumulatedPrice +
                  item.package_center_address_prices.offer_price;
              }
            } else {
              if (item.package_city_prices === null) {
                accumulatedPrice = accumulatedPrice + item.offer_price;
              } else {
                accumulatedPrice =
                  accumulatedPrice + item.package_city_prices.offer_price;
              }
            }
          }
        } else {
          if (reason === "remove-option") {
            updatePackageId.push(item.id);
          }
        }
      });
    }

    let familyPrice: number = Math.floor(accumulatedPrice);
    let totalPriceWithFamily = tempTotalPrice + familyPrice;
    setTotalPrice(totalPriceWithFamily);
    setPrice(totalPriceWithFamily);
    if (
      reason === "remove-option" &&
      updatePackageId?.length > 0 &&
      originalPackageId?.length > 0
    ) {
      // let difference = originalPackageId.filter(
      //   (x) => !updatePackageId.includes(x)
      // );
      //removePackage(customePackage, difference);
    } else if (reason === "remove-option" && updatePackageId?.length == 0) {
      removePackage(customePackage, []);
    }
  };

  const removePackage = (customePackage: any, updateArr: any) => {
    if (
      customePackage.length == 0 &&
      memberPackageID.length > 0 &&
      packageList.results?.length > 0
    ) {
      let accumulatedPrice = 0;
      memberPackageID.map((row) => {
        const findPackage = packageList?.results.filter(
          (el: any) => el.id == row
        );
        if (findPackage.length > 0) {
          if (findPackage[0]?.package_city_prices) {
            accumulatedPrice =
              (accumulatedPrice +
                findPackage[0]?.package_city_prices?.offer_price) |
              0;
          } else {
            accumulatedPrice =
              (accumulatedPrice + findPackage[0]?.offer_price) | 0;
          }
        }
      });
      if (accumulatedPrice > 0) {
        let familyPrice: number = Math.floor(accumulatedPrice);
        let totalPriceWithFamily = totalPrice - familyPrice;
        setMemberPackageID([]);
        //setTempTotalPrice(totalPriceWithFamily);
        setPrice(totalPriceWithFamily);
        setTotalPrice(totalPriceWithFamily);
      }
    } else if (
      customePackage.length > 0 &&
      memberPackageID.length > 0 &&
      updateArr.length > 0 &&
      packageList?.results?.length > 0
    ) {
      if (updateArr?.length > 0) {
        let accumulatedPrice = 0;
        let removePackageId = "";
        updateArr.map((row: any) => {
          const findPackage = packageList.results.filter(
            (el: any) => el.id == row
          );

          if (findPackage.length > 0) {
            removePackageId = findPackage[0].id;
            if (findPackage[0]?.package_city_prices) {
              accumulatedPrice =
                (accumulatedPrice +
                  findPackage[0]?.package_city_prices?.offer_price) |
                0;
            } else {
              accumulatedPrice =
                (accumulatedPrice + findPackage[0]?.offer_price) | 0;
            }
          }
        });
        if (accumulatedPrice > 0) {
          let familyPrice: number = Math.floor(accumulatedPrice);
          let totalPriceWithFamily = tempTotalPrice - familyPrice;
          if (removePackageId) {
            let tempArray = new Array();
            memberPackageID.map((row) => {
              if (removePackageId != row) {
                tempArray.push(row);
              }
            });
            setMemberPackageID(tempArray);
          }
          //setTempTotalPrice(totalPriceWithFamily);
          setPrice(totalPriceWithFamily);
          setTotalPrice(totalPriceWithFamily);
        }
      }
    }
  };
  const handleAddTest = (bid: any, aid: any, type: any) => {
    setOpenAddModal(true);
    setBId(bid);
    setType(type);
    setAId(aid);
  };
  const handleRemoveTest = (bid: any, aid: any, type: any) => {
    setOpenRemoveModal(true);
    setBId(bid);
    setType(type);
    setAId(aid);
  };
  const handleCloseAddModal = () => {
    // history.push(`/dashboard/coradmin/booking-view/${booking?.pk}`);
    setOpenRemoveModal(false);
    setOpenAddModal(false);
    getBookingById(`${bookingId}`);
  };

  useEffect(() => {
    booking?.coupon_applied && setCoupon(booking?.coupon_applied.id);
    booking?.coupon_applied && setCouponCode(booking?.coupon_applied?.name);
    booking?.discounted_price &&
      setTotalPrice(booking.discounted_price?.final_total_price);
    booking?.discounted_price &&
      setTempTotalPrice(booking.discounted_price?.final_total_price);
    booking?.discounted_price &&
      setPrice(booking.discounted_price?.final_total_price);
    booking?.cityid && setCityId(booking?.cityid);
    booking?.cityid && getPackage(`city_id=${cityId}`);
  }, [booking, getPackage]);

  useEffect(() => {
    resetBookings();
    getAgentList(`?&usergroup=Doctor`);
  }, []);

  useEffect(() => {
    const slots = timeslots && timeslots.results;
    setTimeSlots(slots && slots[0].zone_data && slots[0].zone_data.time_slots);
  }, [timeslots]);

  const bookingId = match.params.id;
  useEffect(() => {
    getBookingById(`${bookingId}`);
  }, [bookingId, additional_booking, booking.plink_id]);

  const getArea = (pincode: String, colDate: any) => {
    const zone =
      pin_code.results &&
      pin_code.results.find((code: any) => code.pincode === pincode);
    getTimeslots(colDate, zone && zone.zone_data && zone.zone_data.id);
  };

  const getSlots = () => {
    getArea(
      booking.customer_areapincode && booking.customer_areapincode.pincode,
      booking.collection_date && booking.collection_date
    );
  };

  useEffect(() => {
    getPinCode(
      booking.customer_areapincode &&
        `${booking.customer_areapincode.pincode}&show_code=true`
    );
    setSelectedSlot(booking.collection_slot && booking.collection_slot.id);
  }, [booking]);

  const handleCallClick = (id: number, type: string) => {
    setCallButton(false);
    clickToCallBooking(id, type);
    setTimeout(() => {
      setCallButton(true);
    }, CALL_DISABLE_TIME);
  };

  const history = useHistory();
  // const cancelHandler = async () => {
  //     const data: any = {
  //         booking_status: "cancelled",
  //     };
  //     await updateBooking(data, bookingId);
  //     history.push(`/dashboard/os/bookings`);
  // };

  const cancelHandler = () => {
    const data: any = {
      booking_status: "cancelled",
    };
    updateBooking(data, bookingId);
    history.push(`/dashboard/os`);
  };

  const handleAssignDoctor = () => {
    const data: any = {
      doctor,
      booking: Number(bookingId),
    };
    assignDoctor(data);
    setAssignBooking(false);
  };

  const addFamilyMemberHandler = async () => {
    const body: any = {
      customer_aadhar,
      customer_age,
      customer_gender,
      customer_name,
      collection_slot: selectedSlot,
      designation: customer_designation,
      packages: packageDetails.map((pack: any) => {
        return pack.id;
      }),
    };
    await createAdditionalBooking(booking.pk, body);
    setOpen(false);
    setCustomer_aadhar("");
    setCustomer_age("");
    setCustomer_gender("male");
    setCustomer_name("");
    setPackageDetails([]);
    setId(0);
  };

  const updateFamilyMemberHandler = async () => {
    const body: any = {
      customer_aadhar,
      customer_age,
      customer_gender,
      customer_name,
      // pickup_status: pickup_status,
      collection_slot: selectedSlot,
      designation: customer_designation,

      packages: packageDetails.map((pack: any) => {
        return pack.id;
      }),
    };
    if (booking?.center?.center_type === "corporate") {
      delete body.packages;
    }
    await updateAdditionalBooking(id, body);
    setOpen(false);
    setCustomer_aadhar("");
    setCustomer_age("");
    setCustomer_gender("male");
    setCustomer_name("");
    setPackageDetails([]);
    setId(0);
  };

  const handleEditMember = (member: any) => {
    setMemberDetails(member);
    setCustomer_aadhar(member.customer_aadhar);
    setCustomer_age(member.customer_age);
    setCustomer_gender(member.customer_gender);
    setCustomer_name(member.customer_name);
    setPackageDetails(member.packages);
    setBooking_status(member.booking_status);
    setAdditionalBookingStatus(member?.booking_status);
    // setPickup_status(member.pickup_status)
    setSelectedSlot(booking.collection_slot.id);
    setId(member.id);
    handleOpen();
    setEdit(true);
    let pacakgeId = new Array();
    member?.packages &&
      member.packages.map((memberPackage: any) => {
        pacakgeId.push(memberPackage.id as string);
      });
    setMemberPackageID(pacakgeId);
  };

  const handlePaymentSend = () => {
    getPaymentSendLink(booking.pk);
    alert("Payment Link Send Successfully");
    setTimeout(() => {
      getBookingById(`${bookingId}`);
    }, 2000);
  };
  const handleRefreshPaymentSend = () => {
    getPaymentSendLink(booking.pk);
    alert("Fresh Payment Link Send Successfully");
    setTimeout(() => {
      getBookingById(`${bookingId}`);
    }, 2000);
  };
  const handlePaymentReSend = () => {
    getPaymentReSendLink(booking.pk);
    alert("Payment Link ReSend Successfully");
  };
  const handlePaymentCancel = () => {
    getPaymentCancelLink(booking.pk);
    alert("Payment Link Cancel Successfully");
  };
  const handleSendReportSms = () => {
    getSmsReportSend(`${booking.pk}/booking`);
    setReportSendStatus(true);
    alert("Report SMS Send Successfully");
  };
  const handleEmailSMSDetails = () => {
    const body: any = {
      booking: bookingId,
    };
    const url = generateEmailFilterUrl(body).substring(2);
    getEmailDetails(`${url}`);
    getSmsDetails(`${url}`);
    setOpenEmailSMSModal(true);
    // setPage(0)
  };
  const handleSMSSend = () => {
    getSMSLink(booking.pk);
    alert("SMS Sent Successfully");
  };

  useEffect(() => {
    if (personId !== "none") {
      if (bookingId == personId && billId !== null) {
        if (billId !== null) {
          getReportDownload(`${bookingId}/`);
        } else {
          alert("Bill Id is Not Genrated");
        }
      } else if (bookingId != personId && additionalBillId !== null) {
        if (additionalBillId !== null) {
          getReportDownload(`${personId}/?additional_id=true`);
        } else {
          alert("Family Member Bill Id is Not Genrated");
          setAdditionalBillId("");
        }
      }
    }
  }, [personId]);

  const handleCancelBooking = () => {
    const data: any = {
      booking_status: "cancelled",
    };
    updateBooking(data, bookingId);
    history.push(`/dashboard/os`);
  };

  const handleReportStatus = () => {
    if (personId !== "none") {
      if (bookingId == personId) {
        if (billId !== null) {
          getReportStatus(`?booking_id=${bookingId}`);
          setReportModal(true);
        } else {
          alert("Bill Id is Not Genrated");
        }
      } else if (bookingId != personId) {
        if (additionalBillId !== null) {
          getReportStatus(`?add_member_booking=${personId}`);
          setReportModal(true);
        } else {
          alert("Family Member Bill Id is Not Genrated");
        }
      }
    }
  };

  const handleCancelButton = async () => {
    const body = {
      booking_status: "cancelled",
    };
    await updateAdditionalBooking(id, body);
    setOpen(false);
    setCustomer_aadhar("");
    setCustomer_age("");
    setCustomer_gender("male");
    setCustomer_name("");
    setPackageDetails([]);
    setId(0);
  };

  const handleChange = (event: any, data: any) => {
    if (event.target.value == bookingId) {
      setLoadingButton(true);
      setBillId(data?.bill_id);
      setTimeout(() => {
        setLoadingButton(false);
      }, 8000);
    } else {
      setLoadingButton(true);
      setTimeout(() => {
        setLoadingButton(false);
      }, 8000);
      const additionDetails = data?.additional_members.find(
        (bId: any) => bId.id === event.target.value
      );
      setAdditionalBillId(additionDetails.bill_id);
    }
    setPersoneId(event.target.value);
  };
  const [loadingButton, setLoadingButton] = useState<boolean>(false);

  const handleDownloadReport = (report: any) => {
    if (report.message === "Your report is not ready yet") {
      alert(report.message);
    } else {
      var b64 = report.allReportDetails.reportDetails;
      var bin = window.atob(b64);
      var link = document.createElement("a");
      link.download = `${report.allReportDetails.Patient_Name}-${report.allReportDetails.Age}.pdf`;
      link.href = "data:application/octet-stream;base64," + b64;
      link.click();
    }
  };

  const handleAddFamily = (id: any) => {
    history.push(`/dashboard/os/booking-additionalFamily/${id}/add`);
  };

  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div className={classes.contentHeader}>
          <div className={classes.leftContentHeader}>
            <h6> Booking (ID: {bookingId})</h6>
          </div>
          <div className={classes.rightContentHeader}>
          {booking.report_status === "consolidate" ? (
              ""
            ) : booking.booking_status === "oldresampling" ? (
              ""
            ) : (
              <Link
                to={`/dashboard/os/${
                  booking.org_type == "homedx"
                    ? "booking-edit"
                    : "booking-imaging-edit"
                }/${booking.pk}/edit`}
                style={{ textDecoration: "none" }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ marginLeft: "1rem", marginBottom: "1rem" }}
                >
                  Edit Booking
                </Button>
              </Link>
            )}
            {booking.report_status !== "consolidate" &&
            booking?.booking_type === "b2c" ? (
              <Link
                to={`/dashboard/os/booking-additionalFamily/${booking.pk}/add`}
                style={{ textDecoration: "none" }}
              >
                <Button
                  variant="contained"
                  disabled={booking.report_status === "consolidate"}
                  style={{
                    marginLeft: "1rem",
                    marginBottom: "1rem",
                    backgroundColor: "#7b1fa2",
                  }}
                >
                  Add Family Member
                </Button>
              </Link>
            ) : (
              ""
            )}

            <Button
              onClick={() => handleSendReportSms()}
              disabled={
                booking.report_status === "pending" ||
                booking.report_status === "none" ||
                booking.report_status === null
              }
              variant="contained"
              style={{
                marginLeft: "1rem",
                marginTop: "-10px",
                backgroundColor: "#f5e76c",
              }}
            >
              Send Report
            </Button>
            {/* {booking.booking_status === "oldresampling" || booking.booking_status === "resampling" ? "" : (
              <Button
                disabled={
                  booking?.receivable_amount === 0 ||
                  booking.plink_id !== null ||
                  booking.pickup_receive_amount !== null
                }
                onClick={() => handlePaymentSend()}
                variant="contained"
                style={{
                  marginLeft: "1rem",
                  marginBottom: "1rem",
                  backgroundColor: "#62f57a",
                }}
              >
                Send Payment Link
              </Button>
            )} */}
            {/* {booking.booking_status === "oldresampling" || booking.booking_status === "resampling" ? "" : (

              <Button
                disabled={booking.plink_id === null}
                onClick={() => handleRefreshPaymentSend()}
                variant="contained"
                style={{
                  marginLeft: "1rem",
                  marginBottom: "1rem",
                  backgroundColor: "#62f57a",
                }}
              >
                Fresh Payment Link
              </Button>
            )} */}

            {/* {booking.booking_status === "oldresampling" || booking.booking_status === "resampling" ? "" : (
              <>
                <Button
                  variant={!raiseComplaint ? "contained" : "outlined"}
                  color="primary"
                  style={{ marginLeft: "1rem", marginBottom: "1rem" }}
                  onClick={() => setRaiseComplaint(!raiseComplaint)}
                >
                  Raise a Ticket
                </Button>
              </>

            )} */}

            {booking.report_status === "partial" ||
            booking.report_status === "consolidate" ? (
              <Button
                variant="contained"
                color="primary"
                style={{
                  marginLeft: "1rem",
                  marginBottom: "1rem",
                  backgroundColor: "#9c27b0",
                }}
                onClick={() => setOpenInvoiceModel(!openInvoiceModel)}
                disabled={booking.patientId == "null"}
              >
                Invoice
              </Button>
            ) : (
              ""
            )}
            <Select
              className="input"
              name="priority"
              variant="outlined"
              style={{
                width: "17%",
                marginLeft: "1rem",
                marginBottom: "1rem",
                alignItems: "flex-start",
                height: "40px",
                paddingRight: '20px'
              }}
              value={personId}
              onChange={(e: any) => handleChange(e, booking)}
            >
              <MenuItem disabled selected value={"none"}>
                Customer Name
              </MenuItem>
              <MenuItem value={booking?.pk}>{booking?.customer_name}</MenuItem>
              {booking.additional_members &&
                booking.additional_members.length > 0 &&
                booking.additional_members.map((data: any) => {
                  return (
                    <MenuItem value={data?.id}>{data?.customer_name}</MenuItem>
                  );
                })}
            </Select>
            <Button
              onClick={handleReportStatus}
              variant="contained"
              disabled={personId === "none"}
              style={{
                marginLeft: "1rem",
                marginBottom: "1rem",
                backgroundColor: "#ffbf00",
              }}
            >
              REPORT STATUS
            </Button>

            {/* <Button
              onClick={() => handleDownloadReport(reportDownload)}
              variant="contained"
              style={{ marginLeft: "1rem", marginBottom: "1rem", backgroundColor: "#2962ff" }}>
              Download Report
            </Button> */}
            <LoadingButton
              disabled={
                personId === "none" ||
                booking.report_status === "na" ||
                booking.report_status == "pending" ||
                booking.report_status == "none" ||
                booking.report_status == null
              }
              onClick={() => handleDownloadReport(reportDownload)}
              loading={loadingButton}
              style={{
                marginLeft: "1rem",
                marginBottom: "1rem",
                background: "#90caf9",
              }}
              loadingPosition="start"
              startIcon={<DownloadForOfflineIcon />}
              variant="outlined"
            >
              Download Report
            </LoadingButton>
          </div>
        </div>
        <div className={classes.contentTable}>
          <Dialog
            open={cancelAlert}
            onClose={() => setCancelAlert(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Are you sure!!</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Your booking with booking Id: {bookingId} will be cancelled.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setCancelAlert(false)} color="primary">
                Discard
              </Button>
              <Button onClick={() => cancelHandler()} color="primary" autoFocus>
                Proceed
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={openAddModal}>
            <Grid container md={12} style={{ padding: "15px 20px 0 20px" }}>
              <Grid item xs={6} style={{ textAlign: "left" }}>
                <h4>Test Addon</h4>
              </Grid>
              <Grid item xs={6} style={{ textAlign: "right" }}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleCloseAddModal}
                >
                  Close
                </Button>
              </Grid>
            </Grid>

            <AddTest id={bId} aid={aId} type={type} />
          </Dialog>
          <Dialog open={openRemoveModal}>
            <Grid container md={12} style={{ padding: "15px 20px 0 20px" }}>
              <Grid item xs={6} style={{ textAlign: "left" }}>
                <h4>Remove Test</h4>
              </Grid>
              <Grid item xs={6} style={{ textAlign: "right" }}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleCloseAddModal}
                >
                  Close
                </Button>
              </Grid>
            </Grid>

            <RemoveTest id={bId} aid={aId} type={type} />
          </Dialog>

          {booking?.center?.center_type == "corporate" ? (
            <Dialog fullScreen open={open} onClose={handleClose}>
              <AppBar className={classes.appBar}>
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                  >
                    <GridCloseIcon />
                  </IconButton>
                  <Typography variant="h6" className={classes.title}>
                    Edit Family Member
                  </Typography>
                  {edit ? (
                    <Button
                      autoFocus
                      color="inherit"
                      onClick={updateFamilyMemberHandler}
                      disabled={packageDetails?.length == 0}
                    >
                      Save
                    </Button>
                  ) : (
                    <Button
                      autoFocus
                      color="inherit"
                      onClick={addFamilyMemberHandler}
                    >
                      Save
                    </Button>
                  )}
                </Toolbar>
              </AppBar>
              <Grid
                container
                spacing={3}
                style={{ margin: "1rem auto", padding: "0 1rem" }}
              >
                <Grid item xs={12} md={6}>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} md={4}>
                      <p>Salutation*</p>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <Select
                        className="input"
                        name="customer_designation"
                        variant="outlined"
                        value={customer_designation}
                        style={{ width: "100%", margin: "0" }}
                        onChange={(e) =>
                          setCustomerDesignation(e.target.value as String)
                        }
                        required
                      >
                        <MenuItem value={"Mr"}>Mr</MenuItem>
                        <MenuItem value={"Mrs"}>Mrs</MenuItem>
                        <MenuItem value={"Master"}>Master</MenuItem>
                        <MenuItem value={"Miss"}>Miss</MenuItem>
                        <MenuItem value={"Smt"}>Smt</MenuItem>
                        <MenuItem value={"Dr."}>Dr.</MenuItem>
                        <MenuItem value={"Baby or Just Born"}>
                          Baby or Just Born
                        </MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} md={4}>
                      <p>Customer Name</p>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <input
                        className="input"
                        name="collection_date"
                        type="name"
                        placeholder="Customer Name"
                        value={customer_name}
                        onChange={(e) => setCustomer_name(e.target.value)}
                        style={{
                          width: "100%",
                          margin: "0",
                          padding: "18.5px 14px",
                        }}
                        required
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} md={4}>
                      <p>Customer Aadhar</p>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <input
                        className="input"
                        name="collection_date"
                        type="number"
                        placeholder="Customer Aadhar"
                        value={customer_aadhar}
                        onChange={(e) => setCustomer_aadhar(e.target.value)}
                        style={{
                          width: "100%",
                          margin: "0",
                          padding: "18.5px 14px",
                        }}
                        required
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} md={4}>
                      <p>Customer Age</p>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <input
                        className="input"
                        name="collection_date"
                        type="number"
                        placeholder="Customer Age"
                        value={customer_age}
                        onChange={(e) => setCustomer_age(e.target.value)}
                        style={{
                          width: "100%",
                          margin: "0",
                          padding: "18.5px 14px",
                        }}
                        required
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} md={4}>
                      <p>Customer Gender</p>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <Select
                        className="input"
                        name="customer_gender"
                        variant="outlined"
                        value={customer_gender}
                        style={{ width: "100%", margin: "0", padding: "0" }}
                        onChange={(e) => setCustomer_gender(e.target.value)}
                        required
                      >
                        <MenuItem selected hidden value={"none"}>
                          Please Select
                        </MenuItem>
                        <MenuItem value={"male"}>Male</MenuItem>
                        <MenuItem value={"female"}>Female</MenuItem>
                        <MenuItem value={"other"}>Other</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs>
                      <p style={{ textAlign: "center", fontWeight: "bold" }}>
                        Add Test
                      </p>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ height: 54 }}
                        fullWidth
                        onClick={() =>
                          handleAddTest(
                            booking.id === undefined ? booking.pk : booking.id,
                            id,
                            "add_member"
                          )
                        }
                      >
                        Test Addon
                      </Button>
                    </Grid>
                    <Grid item xs>
                      <p style={{ textAlign: "center", fontWeight: "bold" }}>
                        Remove Test
                      </p>
                      <Button
                        variant="contained"
                        color="secondary"
                        style={{ height: 54 }}
                        fullWidth
                        onClick={() =>
                          handleRemoveTest(
                            booking.id === undefined ? booking.pk : booking.id,
                            id,
                            "add_member"
                          )
                        }
                      >
                        Test Remove
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Card
                    className="sampleCollectionTimeFamily"
                    style={{
                      height: "428px !important",
                      overflow: "auto",
                      backgroundColor: "#33bfff",
                    }}
                  >
                    <h3
                      style={{
                        marginBottom: "1rem",
                        backgroundColor: "#00b0ff",
                        fontWeight: "bold",
                        height: "60px",
                        color: "white",
                        textAlign: "center",
                      }}
                    >
                      Sample Collection Time
                    </h3>
                    <div className={classes.slotContainer}>
                      {timeSlots &&
                        timeSlots.length > 0 &&
                        timeSlots.map((slot: any) => {
                          return (
                            slot.available_slots > 0 && (
                              <Card
                                key={slot.id}
                                className={
                                  slot.id === selectedSlot
                                    ? classes.selectedSlot
                                    : classes.slot
                                }
                                style={{ cursor: "crosshair" }}
                              >
                                <p
                                  style={{
                                    textAlign: "center",
                                    color: "black",
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {slot?.start_time1hr}-{slot?.end_time1hr}
                                </p>

                                <p
                                  style={{
                                    textAlign: "center",
                                    color: "green",
                                    fontWeight: "bold",
                                    fontSize: "17px",
                                  }}
                                >
                                  {" "}
                                  Availabe slots: {slot.available_slots}
                                </p>
                              </Card>
                            )
                          );
                        })}
                    </div>
                  </Card>
                </Grid>
                <Grid container spacing={3} style={{ margin: "1rem auto" }}>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    style={{ padding: "1rem", justifyContent: "right" }}
                  >
                    <div className="priceDetails" style={{ padding: "1rem" }}>
                      <table style={{ width: "100%", margin: "0" }}>
                        <thead>
                          <tr>
                            <td className="cell">Customer Name</td>
                            <td className="cell">Packages</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="cell">{customer_name}</td>
                            <td className="cell">
                              <ul>
                                {packageDetails.map((pack: any, index: any) => {
                                  return (
                                    <li key={index}>
                                      {pack.name}
                                      <br />
                                      DESCRIPTION : {pack.description}
                                      <br />
                                      TaT:{" "}
                                      {pack.package_city_prices
                                        ? pack.package_city_prices.tat_time
                                        : pack.tat}
                                      <br />
                                      Price:{" "}
                                      {pack.package_city_prices
                                        ? pack.package_city_prices.offer_price
                                        : pack.price}
                                    </li>
                                  );
                                })}
                              </ul>
                            </td>
                          </tr>
                          {booking.additional_members &&
                            booking.additional_members.map((val: any) => {
                              if (val.id !== id) {
                                return (
                                  <tr>
                                    <td className="cell">
                                      {val.customer_name}
                                    </td>
                                    <td className="cell">
                                      <ul>
                                        {val.ccpackages.map(
                                          (pack: any, index: any) => {
                                            return (
                                              <li key={index}>
                                                {pack.name}
                                                <br />
                                                DESCRIPTION : {pack.description}
                                                <br />
                                                TaT: {pack.tat}
                                                <br />
                                                Price: {pack.price}
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    </td>
                                  </tr>
                                );
                              }
                            })}

                          <tr>
                            <td className="cell">{booking.customer_name}</td>
                            <td className="cell">
                              <ul>
                                {booking.packages &&
                                  booking.packages.map(
                                    (pack: any, index: any) => {
                                      return (
                                        <li key={index}>
                                          {pack.name}
                                          <br />
                                          DESCRIPTION : {pack.description}
                                          <br />
                                          TaT:{" "}
                                          {pack.package_city_prices
                                            ? pack.package_city_prices.tat_time
                                            : pack.tat}
                                          <br />
                                          Price:{" "}
                                          {is_price_hide
                                            ? "XXX"
                                            : pack.package_city_prices
                                            ? pack.package_city_prices
                                                .offer_price
                                            : pack.price}
                                        </li>
                                      );
                                    }
                                  )}
                              </ul>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    style={{ padding: "1rem", justifyContent: "right" }}
                  >
                    <div
                      className="priceDetails"
                      style={{ padding: "1rem 1rem 0 1rem" }}
                    >
                      <h6 className="totalPrice">
                        Price: ₹ {is_price_hide ? "XXX" : Math.floor(price)}{" "}
                        {is_price_hide ? "XXX" : extraCharge ? "+ 200" : ""}
                      </h6>
                      <h6 className="totalPrice">
                        Total Amount: ₹{" "}
                        {is_price_hide === true ? "XXX" : totalPrice}
                      </h6>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Dialog>
          ) : (
            <Dialog fullScreen open={open} onClose={handleClose}>
              <AppBar className={classes.appBar}>
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                  >
                    <GridCloseIcon />
                  </IconButton>
                  <Typography variant="h6" className={classes.title}>
                    Edit Family Member
                  </Typography>
                  {edit ? (
                    <Button
                      autoFocus
                      color="inherit"
                      onClick={updateFamilyMemberHandler}
                      disabled={packageDetails?.length == 0}
                    >
                      Save
                    </Button>
                  ) : (
                    <Button
                      autoFocus
                      color="inherit"
                      onClick={addFamilyMemberHandler}
                    >
                      Save
                    </Button>
                  )}
                </Toolbar>
              </AppBar>
              <Grid
                container
                spacing={3}
                style={{ margin: "1rem auto", padding: "0 1rem" }}
              >
                <Grid item xs={12} md={6}>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} md={4}>
                      <p>Title</p>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <Select
                        className="input"
                        name="customer_designation"
                        variant="outlined"
                        value={customer_designation}
                        style={{ width: "100%", margin: "0" }}
                        onChange={(e) =>
                          setCustomerDesignation(e.target.value as String)
                        }
                        required
                      >
                        <MenuItem value={"Mr"}>Mr</MenuItem>
                        <MenuItem value={"Mrs"}>Mrs</MenuItem>
                        <MenuItem value={"Master"}>Master</MenuItem>
                        <MenuItem value={"Miss"}>Miss</MenuItem>
                        <MenuItem value={"Smt"}>Smt</MenuItem>
                        <MenuItem value={"Dr."}>Dr.</MenuItem>
                        <MenuItem value={"Baby or Just Born"}>
                          Baby or Just Born
                        </MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} md={4}>
                      <p>Customer Name</p>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <input
                        className="input"
                        name="collection_date"
                        type="name"
                        placeholder="Customer Name"
                        value={customer_name}
                        onChange={(e) => setCustomer_name(e.target.value)}
                        style={{
                          width: "100%",
                          margin: "0",
                          padding: "18.5px 14px",
                        }}
                        required
                      />
                    </Grid>
                  </Grid>
                  {/* <Grid container spacing={3} alignItems="center">
                  <Grid item xs={12} md={4}>
                    <p>Customer Aadhaar </p>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <input
                      className="input"
                      name="collection_date"
                      type="number"
                      placeholder="Customer Aadhaar "
                      value={customer_aadhar}
                      onChange={(e) => setCustomer_aadhar(e.target.value)}
                      style={{
                        width: "100%",
                        margin: "0",
                        padding: "18.5px 14px",
                      }}
                      required
                    />
                  </Grid>
                </Grid> */}
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} md={4}>
                      <p>Packages</p>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <Autocomplete
                        id="package"
                        key="1"
                        onChange={(event, newValue: any, reason: any) => {
                          let changeReason = !!reason && reason ? reason : "";
                          if (newValue) {
                            let packs: any = [];
                            newValue.map((val: any) => {
                              let obj = JSON.parse(
                                JSON.stringify(val, null, " ")
                              );
                              packs.push(obj);
                            });
                            handlePackages(packs, changeReason);
                          }
                        }}
                        multiple
                        options={packageList.results}
                        value={packageDetails}
                        disableClearable
                        limitTags={0}
                        getOptionLabel={(option: any) =>
                          option.name && option.name.toString()
                        }
                        getOptionDisabled={(option: any) => {
                          const packID =
                            packageDetails &&
                            packageDetails.map((pack: any) => pack.id);
                          return packID.includes(option.id);
                        }}
                        onInputChange={(event, newInputValue) => {
                          clearTimeout(timer.current);
                          timer.current = setTimeout(() => {
                            {
                              booking.org_type === "imaging"
                                ? getPackage(
                                    `center_address=${booking?.center_address.id}&org_type=imaging`
                                  )
                                : getPackage(
                                    `city_id=${cityId}&code=${newInputValue}`
                                  );
                            }
                          }, 1000);
                        }}
                        freeSolo
                        disableCloseOnSelect
                        renderTags={(value: string[], getTagProps) =>
                          value.map((option: any, index: number) => (
                            <Chip
                              variant="default"
                              color="primary"
                              label={option.name && option?.name.toString()}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Package"
                            variant="outlined"
                            style={{ width: "100%", margin: "0", padding: "0" }}
                            className="input"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} md={4}>
                      <p>Customer Age</p>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <input
                        className="input"
                        name="collection_date"
                        type="number"
                        placeholder="Customer Age"
                        value={customer_age}
                        onChange={(e) => setCustomer_age(e.target.value)}
                        style={{
                          width: "100%",
                          margin: "0",
                          padding: "18.5px 14px",
                        }}
                        required
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} md={4}>
                      <p>Customer Gender</p>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <Select
                        className="input"
                        name="customer_gender"
                        variant="outlined"
                        value={customer_gender}
                        style={{ width: "100%", margin: "0", padding: "0" }}
                        onChange={(e) => setCustomer_gender(e.target.value)}
                        required
                      >
                        <MenuItem selected hidden value={"none"}>
                          Please Select
                        </MenuItem>
                        <MenuItem value={"male"}>Male</MenuItem>
                        <MenuItem value={"female"}>Female</MenuItem>
                        <MenuItem value={"other"}>Other</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} md={4}>
                      <Button
                        disabled={additionalBookingStatus === "cancelled"}
                        variant="contained"
                        color="secondary"
                        onClick={handleCancelButton}
                      >
                        Cancel Booking
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Card
                    className="sampleCollectionTimeFamily"
                    style={{ height: "450px !important", overflow: "auto" }}
                  >
                    <p style={{ marginBottom: "1rem" }}>
                      Sample Collection Time
                    </p>
                    <div className={classes.slotContainer}>
                      {timeslots &&
                        timeslots?.results &&
                        timeslots.results.length > 0 &&
                        timeslots.results.map((slot: any) => {
                          return (
                            <Card
                              key={slot.id}
                              className={
                                slot.id === selectedSlot
                                  ? classes.selectedSlot
                                  : classes.slot
                              }
                              onClick={() => setSelectedSlot(slot.id as Number)}
                            >
                              <p
                                style={{
                                  textAlign: "center",
                                  color: "black",
                                  fontSize: "14px",
                                }}
                              >
                                {slot.start_time}-{slot.end_time}
                              </p>
                              {/* <p style={{ textAlign: "center", color: "black" }}>{moment(`${slot.start_time}`, ["HH.mm.ss"]).format("hh:mm A")} - {moment(`${slot.end_time}`, ["HH.mm.ss"]).format("hh:mm A")}</p> */}
                            </Card>
                          );
                        })}
                    </div>
                  </Card>
                </Grid>
                <Grid container spacing={3} style={{ margin: "1rem auto" }}>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    style={{ padding: "1rem", justifyContent: "right" }}
                  >
                    <div className="priceDetails" style={{ padding: "1rem" }}>
                      <table style={{ width: "100%", margin: "0" }}>
                        <thead>
                          <tr>
                            <td className="cell">Customer Name</td>
                            <td className="cell">Packages</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="cell">{customer_name}</td>
                            <td className="cell">
                              <ul>
                                {packageDetails.map((pack: any, index: any) => {
                                  return (
                                    <li key={index}>
                                      {pack.name}
                                      <br />
                                      DESCRIPTION : {pack.description}
                                      <br />
                                      TaT:{" "}
                                      {pack.package_city_prices
                                        ? pack.package_city_prices.tat_time
                                        : pack.tat_time}
                                      <br />
                                      Price:{" "}
                                      {pack.package_city_prices
                                        ? pack.package_city_prices.offer_price
                                        : pack.offer_price}
                                    </li>
                                  );
                                })}
                              </ul>
                            </td>
                          </tr>
                          <tr>
                            <td className="cell">{booking.customer_name}</td>
                            <td className="cell">
                              <ul>
                                {booking.packages &&
                                  booking.packages.map(
                                    (pack: any, index: any) => {
                                      return (
                                        <li key={index}>
                                          {pack.name}
                                          <br />
                                          DESCRIPTION : {pack.description}
                                          <br />
                                          TaT:{" "}
                                          {pack.package_city_prices
                                            ? pack.package_city_prices.tat_time
                                            : pack.tat_time}
                                          <br />
                                          Price:{" "}
                                          {pack.package_city_prices
                                            ? pack.package_city_prices
                                                .offer_price
                                            : pack.offer_price}
                                        </li>
                                      );
                                    }
                                  )}
                              </ul>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    style={{ padding: "1rem", justifyContent: "right" }}
                  >
                    <div
                      className="priceDetails"
                      style={{ padding: "1rem 1rem 0 1rem" }}
                    >
                      <h6 className="totalPrice">
                        Price: ₹ {Math.floor(price)}{" "}
                        {extraCharge ? "+ 200" : ""}
                      </h6>
                      <h6 className="totalPrice">
                        Total Amount: ₹ {totalPrice}
                      </h6>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Dialog>
          )}

          <RaiseComplaintModal
            open={raiseComplaint}
            setOpen={setRaiseComplaint}
            bookingId={Number(bookingId)}
            getBookingQueryTickets={getBookingQueryTickets}
            bookingComplaints={bookingComplaints}
            leadId={0}
          />
          {assignBooking && (
            <Paper className={classes.paper} elevation={15}>
              <h3 className={classes.heading} style={{ textAlign: "center" }}>
                Assign Booking To Doctor
              </h3>
              <Grid
                container
                direction="row"
                alignItems="flex-start"
                justify="space-between"
                spacing={3}
                style={{ margin: "1rem auto" }}
              >
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    id="doctor"
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        setDoctor(obj.id);
                      }
                    }}
                    options={agentsList.results || []}
                    freeSolo
                    blurOnSelect
                    aria-required
                    getOptionLabel={(option: any) =>
                      option.user && option.user.name
                    }
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getAgentList(`?${newInputValue}&usergroup=Doctor`);
                      }, 1000);
                      if (newInputValue.length === 0) {
                        setDoctor(0);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        placeholder="Doctor Name"
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={doctor === 0}
                    onClick={handleAssignDoctor}
                  >
                    Assign
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          )}
          <Paper className={classes.paper} elevation={15}>
            {bookingListLoader ? (
              <Loader />
            ) : (
              <>
                <Grid
                  container
                  direction="row"
                  alignItems="flex-start"
                  justify="space-between"
                  spacing={3}
                  style={{ margin: "1rem auto" }}
                >
                  {booking.booking_status === "oldresampling" ? (
                    <h4 style={{ color: "red" }}>
                      You Can't Edit Resampling Booking{" "}
                    </h4>
                  ) : (
                    ""
                  )}
                  <Grid
                    container
                    direction="row"
                    alignItems="flex-start"
                    justify="space-between"
                    spacing={3}
                    style={{ margin: "1rem auto" }}
                  >
                    <Grid item xs={6} sm={4} md={4}>
                      <h3
                        style={{
                          paddingTop: "10px",
                          fontSize: "24px",
                          fontWeight: "bold",
                        }}
                      >
                        {booking?.org_type === "homedx"
                          ? "HOME-DX BOOKING DETAILS"
                          : "IMAGING BOOKING DETAILS"}
                      </h3>
                    </Grid>
                    <Grid item xs={6} sm={4} md={4}>
                      <h3
                        style={{
                          paddingTop: "10px",
                          fontSize: "24px",
                          fontWeight: "bold",
                        }}
                      >
                        {booking?.centre_address?.name
                          ? "CENTRE : " +
                            String(booking?.centre_address?.name).toUpperCase()
                          : ""} (CENTRE CODE: {booking?.centre_address?.code})
                      </h3>
                    </Grid>
                    <Grid item xs={6} sm={4} md={4}>
                      <h3
                        style={{
                          paddingTop: "10px",
                          fontSize: "24px",
                          fontWeight: "bold",
                          textAlign: "right",
                        }}
                      >
                        BOOKING (ID: {bookingId})
                      </h3>
                    </Grid>
                  </Grid>
                  <Grid item xs={6} md={4} style={{ textAlign: "center" }}>
                    <h6>Collection Date</h6>
                    <p style={{ fontWeight: "normal" }}>
                      {booking.collection_date}
                    </p>
                  </Grid>
                  {/* <Grid item xs={6} md={4} style={{textAlign:"center"}}>
                    <h6>Pincode</h6>
                    <p style={{ fontWeight: "normal" }}>
                      {booking.customer_areapincode &&
                        booking.customer_areapincode.pincode}
                    </p>
                  </Grid> */}
                  {/* <Grid item xs={6} md={4}>
                    <h6>Area Name</h6>
                    <p style={{ fontWeight: "normal" }}>
                      {booking.customer_areapincode &&
                        booking.customer_areapincode.area}
                    </p>
                  </Grid> */}
                  <Grid item xs={6} md={4} style={{ textAlign: "center" }}>
                    <h6>Collection Time</h6>
                    <p style={{ fontWeight: "normal" }}>
                      {booking.collection_slot &&
                        moment(
                          `${booking.collection_slot.slot.split("-")[0]}`,
                          ["HH.mm.ss"]
                        ).format("hh:mm A")}
                      {" - "}
                      {booking.org_type == "homedx"
                        ? booking.collection_slot &&
                          moment(
                            `${booking.collection_slot.slot.split("-")[1]}`,
                            ["HH.mm.ss"]
                          ).format("hh:mm A")
                        : "Onwards"}
                    </p>
                  </Grid>
                </Grid>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.tableHead}>
                          S No.
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                          Customer Name
                        </TableCell>
                        <TableCell className={classes.tableHead}>Age</TableCell>
                        <TableCell className={classes.tableHead}>
                          Gender
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                          Package Name
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                          Partner OrderId
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                          Aadhaar No
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                          Edit
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow key={1}>
                        <TableCell align="center">1.</TableCell>
                        <TableCell align="center">{`${
                          booking.designation || ""
                        } ${booking.customer_name}`}</TableCell>
                        <TableCell align="center">
                          {booking.customer_age}
                        </TableCell>
                        <TableCell align="center">
                          {booking.customer_gender}
                        </TableCell>
                        <TableCell align="center">
                          <ul>
                            {booking.packages &&
                              booking.packages.map((pack: any, index: any) => {
                                return (
                                  <li
                                    key={index}
                                    style={{
                                      margin: "1rem 0",
                                      textAlign: "left",
                                    }}
                                  >
                                    {/* {pack.name}, {pack.tat_time} */}
                                    {pack.name && pack.package_city_prices
                                      ? pack.name.toString() +
                                        ", " +
                                        pack.package_city_prices.tat_time
                                      : pack.name +
                                        (pack.tat_time
                                          ? ", " + pack.tat_time
                                          : "")}
                                  </li>
                                );
                              })}
                          </ul>
                        </TableCell>
                        <TableCell align="center">
                          {booking.partner && booking.partner.id}
                        </TableCell>
                        <TableCell align="center">
                          {booking.customer_aadhar || ""}
                        </TableCell>
                        <TableCell align="center">
                          {/* {booking.booking_status === "oldresampling"&& 
                          booking.report_status === "consolidate" ? (
                            ""
                          ) : ( */}
                           {booking.report_status === "consolidate" ? (
                            ""
                           ) :
                          booking.booking_status === "oldresampling" ? (
                            ""
                          ) : (
                            <Link
                              to={`/dashboard/os/${
                                booking.org_type == "homedx"
                                  ? "booking-edit"
                                  : "booking-imaging-edit"
                              }/${booking.pk}/edit`}
                              style={{ textDecoration: "none" }}
                            >
                              <Button
                                color="primary"
                                variant="text"
                              >
                                Edit
                              </Button>
                            </Link>
                          )}
                        </TableCell>
                      </TableRow>
                      {booking.additional_members &&
                        booking.additional_members.length > 0 &&
                        booking.additional_members.map(
                          (member: any, index: number) => {
                            return (
                              <TableRow key={index + 1}>
                                <TableCell align="center">
                                  {index + 2}.
                                </TableCell>
                                <TableCell align="center">
                                  {`${member.designation || ""} ${
                                    member.customer_name
                                  }`}
                                </TableCell>
                                <TableCell align="center">
                                  {member.customer_age}
                                </TableCell>
                                <TableCell align="center">
                                  {member.customer_gender}
                                </TableCell>
                                <TableCell align="center">
                                  <ul>
                                    {member.packages &&
                                      member.packages.map(
                                        (pack: any, index: any) => {
                                          return (
                                            <li
                                              key={index}
                                              style={{
                                                margin: "0 0 1rem 0",
                                                textAlign: "left",
                                              }}
                                            >
                                              {/* {pack.name}, {pack.price} */}
                                              {pack.name &&
                                              pack.package_city_prices
                                                ? pack.name.toString() +
                                                  ", " +
                                                  pack.package_city_prices
                                                    .tat_time
                                                : pack.name +
                                                  ", " +
                                                  pack.tat_time}
                                            </li>
                                          );
                                        }
                                      )}
                                  </ul>
                                </TableCell>
                                <TableCell align="center"></TableCell>
                                <TableCell align="center">
                                  {member.customer_aadhar}
                                </TableCell>
                                <TableCell align="center">
                                {booking.booking_status ===
                                  "oldresampling" && booking.report_status ===
                                  "consolidate"  ? (
                                    ""
                                  ) : (
                                    <Button
                                      color="primary"
                                      variant="text"
                                      disabled={
                                        booking.report_status === "consolidate"
                                      }
                                      onClick={() => handleEditMember(member)}
                                    >
                                      Edit
                                    </Button>
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          }
                        )}
                      <TableRow></TableRow>

                      <TableRow>
                        <TableCell colSpan={12}>
                          <h6
                            style={{
                              textAlign: "right",
                              padding: "0rem",
                              fontSize: "14px",
                              fontWeight: "bold",
                            }}
                          >
                            Price.{" "}
                            {booking.discounted_price &&
                              booking.discounted_price.total_price_package &&
                              Math.floor(
                                booking.discounted_price.total_price_package
                              )}
                            /-
                          </h6>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={12}>
                          <h6
                            style={{
                              textAlign: "right",
                              padding: "0rem",
                              fontSize: "14px",
                              fontWeight: "bold",
                            }}
                          >
                            Phlebo Cost.{" "}
                            {booking.discounted_price &&
                              booking.discounted_price.phlebo_cost &&
                              Math.floor(booking.discounted_price.phlebo_cost)}
                            /-
                          </h6>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={12}>
                          <h6
                            style={{
                              textAlign: "right",
                              padding: "0rem",
                              fontSize: "14px",
                              fontWeight: "bold",
                            }}
                          >
                            Discount.{" "}
                            {booking.discounted_price &&
                              booking.discounted_price.counpon_discount &&
                              Math.floor(
                                booking.discounted_price.counpon_discount
                              )}
                            /-
                          </h6>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={12}>
                          <h6
                            style={{
                              textAlign: "right",
                              padding: "0rem",
                              fontSize: "14px",
                              fontWeight: "bold",
                            }}
                          >
                            RedCash Discount.{" "}
                            {booking.redcash_discounted_amount}
                            /-
                          </h6>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                      {booking?.discounted_price?.hard_copy_cost !== 0 ? (
                        <>
                        <TableCell colSpan={12}>
                          <h6
                            style={{
                              textAlign: "right",
                              padding: "0rem",
                              fontSize: "14px",
                              fontWeight: "bold",
                            }}
                          >
                            Hard Copy Cost.{" "}
                            {booking?.discounted_price?.hard_copy_cost}
                            /-
                          </h6>
                        </TableCell>
                        </>
                      ) : ""}
                      </TableRow>

                      <TableRow>
                        <TableCell colSpan={12}>
                          <h6
                            style={{
                              textAlign: "right",
                              padding: "1rem",
                              fontSize: "20px",
                              fontWeight: "bold",
                            }}
                          >
                            Total Amount: Rs.{" "}
                            {booking.discounted_price &&
                              booking.discounted_price.final_total_price &&
                              Math.floor(
                                booking.discounted_price.final_total_price
                              ) - Math.floor(booking.redcash_discounted_amount)}
                            /-
                          </h6>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <Grid
                  container
                  spacing={4}
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                  style={{ margin: "1rem auto" }}
                >
                  <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                    <Grid item xs={12} md={5}>
                      <p>AgentName</p>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <TextField
                        name="agentName"
                        type="text"
                        value={(booking.agent && booking.agent.name) || ""}
                        disabled
                        className="input"
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                  {/* {booking?.org_type === "homedx" && booking?.center?.center_type !== "collection_center" ||
                    booking?.center?.center_type !== "direct_client" ? (
                    <Grid
                      direction="row"
                      justify="space-around"
                      alignItems="center"
                      container
                      item
                      xs={12}
                      md={6}
                      spacing={3}
                    >
                      <Grid item xs={12} md={5}>
                        <p>Phlebo Name</p>
                      </Grid>
                      <Grid item xs={12} md={7}>
                        <TextField
                          name="phleboname"
                          type="text"
                          value={(booking?.phlebo && booking?.phlebo.name) || ""}
                          disabled
                          className="input"
                          variant="outlined"
                          style={{ margin: "0", width: "100%" }}
                        />
                      </Grid>
                    </Grid>
                  ) : ""} */}
                  {/* {booking?.org_type === "homedx" && booking?.center?.center_type !== "collection_center" ||
                    booking?.center?.center_type !== "direct_client" ? (
                    <Grid
                      direction="row"
                      justify="space-around"
                      alignItems="center"
                      container
                      item
                      xs={12}
                      md={6}
                      spacing={3}
                    >
                      <Grid item xs={12} md={5}>
                        <p>
                          Phlebo
                          {booking.phlebo && booking.phlebo.name ? (
                            <CallIcon
                              onClick={() => handleCallClick(bookingId, "phlebo")}
                            />
                          ) : (
                            ""
                          )}
                        </p>
                      </Grid>
                      <Grid item xs={12} md={7}>
                        <TextField
                          name="phlebo Number"
                          type="text"
                          value={(booking.phlebo && booking.phlebo.phonenumber) || ""}
                          disabled
                          className="input"
                          variant="outlined"
                          style={{ margin: "0", width: "100%" }}
                        />
                      </Grid>
                    </Grid>
                  ) : ""} */}
                  <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                    <Grid item xs={12} md={5}>
                      <p>
                        Mobile Number
                        <CallIcon
                          onClick={() => handleCallClick(bookingId, "call")}
                        />
                      </p>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <TextField
                        name="customer_phonenumber"
                        type="text"
                        value={booking.customer_phonenumber || ""}
                        disabled
                        className="input"
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                    <Grid item xs={12} md={5}>
                      <p>
                        Alternate Mobile Number
                        <CallIcon
                          onClick={() => handleCallClick(bookingId, "contact")}
                        />
                      </p>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <TextField
                        name="customer_alternatenumber"
                        type="text"
                        value={booking.customer_altphonenumber || ""}
                        disabled
                        className="input"
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                    <Grid item xs={12} md={5}>
                      <p>Email</p>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <TextField
                        name="customer_email"
                        type="email"
                        disabled
                        value={booking.customer_email || ""}
                        className="input"
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                    <Grid item xs={12} md={5}>
                      <p>
                        Whatsapp Number
                        <CallIcon
                          onClick={() => handleCallClick(bookingId, "whatsapp")}
                        />
                      </p>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <TextField
                        name="customer_whatsapppnumber"
                        type="text"
                        disabled
                        value={booking.customer_whatsapppnumber || ""}
                        className="input"
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                    <Grid item xs={12} md={5}>
                      <p>Passport Number</p>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <TextField
                        name="Passport"
                        type="text"
                        disabled
                        value={booking.passport_number || ""}
                        className="input"
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                    <Grid item xs={12} md={5}>
                      <p>Finance Amount</p>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <TextField
                        name="financeamount"
                        type="text"
                        value={booking.finance_amount || ""}
                        disabled
                        className="input"
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                    <Grid item xs={12} md={5}>
                      <p>Finance Status</p>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <TextField
                        name="financestatus"
                        type="text"
                        value={booking.finance_status || ""}
                        disabled
                        className="input"
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                    <Grid item xs={12} md={5}>
                      <p>Finance Remarks</p>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <TextField
                        name="financeremark"
                        type="text"
                        value={booking.finance_remarks || ""}
                        disabled
                        className="input"
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                    <Grid item xs={12} md={5}>
                      <p>Book for</p>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <TextField
                        name="book_for"
                        type="text"
                        value={booking?.book_for || ""}
                        disabled={true}
                        className="input"
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {booking?.center?.center_type !== "collection_center" &&
                booking?.center?.center_type !== "direct_client" ? (
                  <>
                    <Grid
                      container
                      spacing={4}
                      direction="row"
                      justify="flex-start"
                      alignItems="center"
                      style={{ margin: "1rem auto" }}
                    >
                      <Grid item xs={12}>
                        <h3
                          style={{
                            borderTop: "dotted 1px #cccc",
                            paddingTop: "10px",
                            fontSize: "24px",
                            fontWeight: "bold",
                          }}
                        >
                          Address
                        </h3>
                      </Grid>
                      <Grid
                        direction="row"
                        justify="space-around"
                        alignItems="center"
                        container
                        item
                        xs={12}
                        md={6}
                        spacing={3}
                      >
                        <Grid item xs={12} md={5}>
                          <p>{LOCALITY}</p>
                        </Grid>
                        <Grid item xs={12} md={7}>
                          <TextField
                            name="customer_landmark"
                            type="text"
                            value={booking?.customer_landmark || ""}
                            disabled
                            className="input"
                            variant="outlined"
                            style={{ margin: "0", width: "100%" }}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        direction="row"
                        justify="space-around"
                        alignItems="center"
                        container
                        item
                        xs={12}
                        md={6}
                        spacing={3}
                      >
                        <Grid item xs={12} md={5}>
                          <p>{ADDRESS_LINE1}</p>
                        </Grid>
                        <Grid item xs={12} md={7}>
                          <TextField
                            name="customer_address"
                            type="text"
                            value={
                              (booking?.customer_address &&
                                booking?.customer_address) ||
                              ""
                            }
                            disabled
                            className="input"
                            variant="outlined"
                            style={{ margin: "0", width: "100%" }}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        direction="row"
                        justify="space-around"
                        alignItems="center"
                        container
                        item
                        xs={12}
                        md={6}
                        spacing={3}
                      >
                        <Grid item xs={12} md={5}>
                          <p>{ADDRESS_LINE2}</p>
                        </Grid>
                        <Grid item xs={12} md={7}>
                          <TextField
                            name="customer_address"
                            type="text"
                            value={
                              (booking?.address_line2 &&
                                booking?.address_line2) ||
                              ""
                            }
                            disabled
                            className="input"
                            variant="outlined"
                            style={{ margin: "0", width: "100%" }}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        direction="row"
                        justify="space-around"
                        alignItems="center"
                        container
                        item
                        xs={12}
                        md={6}
                        spacing={3}
                      >
                        <Grid item xs={12} md={5}>
                          <p>{LANDMARK}</p>
                        </Grid>
                        <Grid item xs={12} md={7}>
                          <TextField
                            name="customer_address"
                            type="text"
                            value={
                              (booking?.landmark && booking?.landmark) || ""
                            }
                            className="input"
                            variant="outlined"
                            style={{ margin: "0", width: "100%" }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid
                      container
                      spacing={4}
                      direction="row"
                      justify="flex-start"
                      alignItems="center"
                      style={{ margin: "1rem auto" }}
                    >
                      <Grid item xs={12}>
                      <div>
                        <h3
                          style={{
                            borderTop: "dotted 1px #cccc",
                            paddingTop: "10px",
                            fontSize: "24px",
                            fontWeight: "bold",
                          }}
                        >
                          Centre Address &nbsp;&nbsp;
                        {booking.centre_address ? (
                             <Button
                             disabled={!callButton}
                             className="addDeck"
                             variant="contained"
                             color="primary"
                             onClick={() => handleCallClick(bookingId, "center")}
                             startIcon={<CallIcon />}
                           ></Button>
                            // <CallIcon
                            //   onClick={() => handleCallClick(bookingId, "center")}
                            // />
                          ) : (
                            ""
                          )}
                        </h3>
                        </div>

                        <b>
                          {booking?.centre_address
                            ? booking?.centre_address?.address +
                              ", " +
                              booking?.centre_address?.area +
                              ", " +
                              booking?.centre_address?.city +
                              " - " +
                              booking?.centre_address?.pincode +
                              ", " +
                              booking?.centre_address?.state
                            : ""}
                        </b>
                        <br />
                        <b>
                          Phone No. :{" "}
                          {booking?.centre_address
                            ? booking?.centre_address?.mobile
                            : ""}
                        </b>
                      </Grid>
                    </Grid>
                  </>
                )}

                <Grid
                  container
                  spacing={4}
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                  style={{ margin: "1rem auto" }}
                >
                  {booking.payment_image &&
                    booking.payment_image.map((image: any) => {
                      return (
                        <Grid
                          key={image.id}
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          lg={3}
                          spacing={3}
                        >
                          <img
                            src={image.image}
                            alt="payment"
                            style={{ width: "100%", aspectRatio: "1/1" }}
                          />
                        </Grid>
                      );
                    })}
                </Grid>
              </>
            )}
          </Paper>
          <ReportModal
            loading={loading}
            openReportModal={openReportModal}
            setReportModal={setReportModal}
            reportStatus={reportStatus}
          />

          <EmailSMSDetailsModal
            loading={loading}
            openEmailSMSModal={openEmailSMSModal}
            setOpenEmailSMSModal={setOpenEmailSMSModal}
            emailDetails={emailDetails}
            smsDetails={smsDetails}
            setOpen={setOpen}
            open={false}
          />
          <Invoice
            loading={loading}
            openInvoiceModel={openInvoiceModel}
            setOpenInvoiceModel={setOpenInvoiceModel}
            bookingId={bookingId}
            booking={booking}
          />
        </div>
      </main>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  booking: state.OffLineSupporteReducer.booking,
  agentsList: state.OffLineSupporteReducer.agentsList,
  pin_code: state.OffLineSupporteReducer.pin_code,
  timeslots: state.OffLineSupporteReducer.timeslots,
  packageList: state.OffLineSupporteReducer.packageList,
  additional_booking: state.OffLineSupporteReducer.additional_booking,
  paymentSendLink: state.OffLineSupporteReducer.paymentSendLink,
  paymentReSendLink: state.OffLineSupporteReducer.paymentReSendLink,
  paymentCancelLink: state.OffLineSupporteReducer.paymentCancelLink,
  emailDetails: state.OffLineSupporteReducer.emailDetails,
  smsDetails: state.OffLineSupporteReducer.smsDetails,
  reportStatus: state.OffLineSupporteReducer.reportStatus,
  bookingCall: state.OffLineSupporteReducer.bookingCall,
  reportDownload: state.OffLineSupporteReducer.reportDownload,
  bookingComplaints: state.OffLineSupporteReducer.bookingComplaints,
  loading: state.OffLineSupporteReducer.loading,
  bookingListLoader: state.OffLineSupporteReducer.bookingListLoader,
});

export default connect(mapStateToProps, {
  getBookingById,
  getPinCode,
  resetBookings,
  updateBooking,
  getAgentList,
  assignDoctor,
  getTimeslots,
  getPackage,
  createAdditionalBooking,
  updateAdditionalBooking,
  getPaymentSendLink,
  getPaymentReSendLink,
  getPaymentCancelLink,
  clickToCallBooking,
  getSmsDetails,
  getEmailDetails,
  getSmsReportSend,
  getSMSLink,
  getReportStatus,
  getReportDownload,
  getBookingQueryTickets,
})(ViewBooking);
