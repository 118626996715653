import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@mui/material/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from '@mui/material/Button';
import { Grid } from "@material-ui/core";
import { holdTheChat,updateLeadDetails } from "../../actions/OffLineSupportActions";

let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "scroll",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: isMobile ? "400px" : "800px",
    maxHeight: "600px",
    overflow: "scroll",
    position: "sticky",
  },
  head: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "2rem",
  },
}));

type ModalProps = {
  setOpenResolvedChatModal: Function;
  openResolvedChatModal: any;
  setLeadId: any;
  leadId: any;
  holdTheChat: any;
  holdTheChatData: any;
  updateLeadDetails:any;
};

const CommentsModal2: React.FC<ModalProps> = ({
  setOpenResolvedChatModal,
  openResolvedChatModal,
  setLeadId,
  leadId,
  holdTheChat,
  holdTheChatData,
  updateLeadDetails,
}) => {
  const classes = useStyles();
  const [checkedWay, setCheckedWay] = useState<any>("");
  const [checkedHold, setCheckedHold] = useState<any>("");

  const handleClose = () => {
    setOpenResolvedChatModal(false);
    setCheckedWay("");
  };

  useEffect(() => {
    if (openResolvedChatModal === false) {
      setCheckedWay("")
    }
  }, [openResolvedChatModal])

  const handleHoldChat = async () => {
    if(checkedWay === "yes")
    {
      await holdTheChat(leadId);
      setOpenResolvedChatModal(false);
    }
    else{
      const data: any = {
        chat_status:checkedHold
      }
      await updateLeadDetails(data,leadId);
      setOpenResolvedChatModal(false);
    }
  };

  const handleCancelHoldChat = () => {
    setOpenResolvedChatModal(false)
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={openResolvedChatModal}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openResolvedChatModal}>
        <div className={classes.paper}>
          <>
            {/* <h3>Issue Resolved</h3> */}
            <Grid container spacing={3} style={{ margin: "0px" }}>
              <Grid item xs={12} style={{ margin: "0px" }}>
              <div className="checkList" style={{ display: "flex" }}>
                <div style={{ fontSize: "18px", fontWeight: "bolder" }}>Did the issue got resolved:</div>
                <div style={{ display: "flex" }}>
                  <div style={{ paddingLeft: "20px" }}>
                    <input value={"yes"} type="checkbox" checked={checkedWay === "yes" ? true : false} onChange={(e) => {!e.target.checked ? setCheckedWay("") : setCheckedWay(e.target.value);}} />
                    <span style={{ paddingLeft: "4px" }}>YES</span>
                  </div>
                  <div style={{ paddingLeft: "20px" }}>
                    <input value={"no"} type="checkbox" checked={checkedWay === "no" ? true : false} onChange={(e) => {!e.target.checked ? setCheckedWay("") : setCheckedWay(e.target.value);}} />
                    <span style={{ paddingLeft: "4px" }}>NO</span>
                  </div>
                </div>
              </div>
              <div className="checkList" style={{ display: "flex",marginTop:"20px" }}>
                <div style={{ fontSize: "18px", fontWeight: "bolder" }}>Mark Chat On Hold:</div>
                <div style={{ display: "flex" }}>
                  <div style={{ paddingLeft: "20px" }}>
                    <input value={"hold"} type="checkbox" checked={checkedHold === "hold" ? true : false} onChange={(e) => {!e.target.checked ? setCheckedHold("") : setCheckedHold(e.target.value);}} />
                    <span style={{ paddingLeft: "4px" }}>YES</span>
                  </div>
                  <div style={{ paddingLeft: "20px" }}>
                    <input value={"no"} type="checkbox" checked={checkedHold === "no" ? true : false} onChange={(e) => {!e.target.checked ? setCheckedHold("") : setCheckedHold(e.target.value);}} />
                    <span style={{ paddingLeft: "4px" }}>NO</span>
                  </div>
                </div>
              </div>
              </Grid>
              <Button
                variant="contained"
                style={{
                  marginTop: "20px",
                  marginLeft: "14px",
                }}
                color="primary"
                onClick={() => handleHoldChat()}
                disabled={checkedWay === "yes" ? false : true||checkedHold === "yes" ? false : true}
              >
                SUBMIT
              </Button>
              <Button
                variant="contained"
                style={{
                  marginTop: "20px",
                  marginLeft: "14px",
                }}
                color="secondary"
                onClick={handleCancelHoldChat}
              >
                Cancel
              </Button>
            </Grid>
          </>
        </div>
      </Fade>
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  holdTheChatData: state.OffLineSupporteReducer.holdTheChatData,
});

export default connect(mapStateToProps, {
  holdTheChat,
  updateLeadDetails,
})(CommentsModal2);