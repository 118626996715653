import React from "react";
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";

import "./index.sass";
import LoginForm from "../../components/LoginForm";
import { login,getUserDetails} from "../../actions/loginActions";

export default function Login() {
  const dispatch = useDispatch();
  const history = useHistory()
  const { loading, loadingOTP } = useSelector((state: any) => state.loginReducer);

  const handleForm = async (form: object) => {
    try {
     await dispatch(login(form))
    //  dispatch(getUserDetails())
     history.push('/dashboard')
    } catch (error) {
      return error;
    }

  };
  return (
    <div className="login-container">
      <div className="mainHeading"> 
        {/* <h1>Redcliffe Labs </h1>
        <p>Healthy India ki trusted labs</p> */}
 <img src="https://redcliffelabs.com/_next/image?url=https%3A%2F%2Fstatic-redcliffelabs.s3.amazonaws.com%2Fmedia%2Fgallary-file%2FNone%2F762d53ff-c9ea-45e1-ab34-2b75f0bb0754.webp&w=256&q=75" alt="" style={{width:"350px"}}/>
      <p style={{fontSize:"30px",marginLeft:"3rem",color:""}}>Healthy India Ki Trusted Lab</p>
      </div>
      <div className="login-container__form">
        <LoginForm loading={loading} loadingOTP={loadingOTP} handleForm={handleForm} />
      </div>
    </div>
  );
}