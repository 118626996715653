import React from "react";
import { Grid } from "@material-ui/core";
import "./index.sass";
import { useEffect } from "react";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { connect } from "react-redux";
import Loader from "../loader/index";
import moment from "moment";
const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 21,
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem",
      width: "100%",
    },
  })
);

interface Props {
  userStats: any;
  loading: any;
}

const CallsStatsPage: React.FC<Props> = ({ userStats, loading }) => {
  const classes = useStyles();

  return (
    <>
      <main className={classes.content}>
        <TableContainer
          component={Paper}
          style={{ minHeight: "300px", width: "100%" }}
        >
          {loading ? (
            <Loader />
          ) : (
            <Table
              stickyHeader
              aria-label="simple table"
              style={{ width: "100%" }}
            >
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Department : {userStats?.executive_type}</StyledTableCell>

                  <StyledTableCell></StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell style={{ fontWeight: "bold", textTransform: "uppercase" }}>Login Time</StyledTableCell>

                  <StyledTableCell >
                    Date: {userStats?.login_time?.substring(0, 10)}{" "}
                    Time :{userStats?.login_time?.substring(11, 19)}{" "}
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell style={{ fontWeight: "bold", textTransform: "uppercase" }}>Login Hour</StyledTableCell>

                  <StyledTableCell >
                    {parseFloat(userStats?.hours_since_login).toFixed(2)}
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell style={{ fontWeight: "bold", textTransform: "uppercase" }}>Break Time</StyledTableCell>

                  <StyledTableCell>
                    {parseFloat(userStats?.total_break_time).toFixed(2)}
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell style={{ fontWeight: "bold", textTransform: "uppercase" }}>Ideal Time</StyledTableCell>

                  <StyledTableCell>
                    {parseFloat(userStats?.total_ideal_time).toFixed(2)}

                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell style={{ fontWeight: "bold", textTransform: "uppercase" }}>Talk Time</StyledTableCell>

                  <StyledTableCell>{userStats?.total_talktime}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                </StyledTableRow>
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </main>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.OffLineSupporteReducer.loading,
});

export default connect(mapStateToProps, {})(CallsStatsPage);
