import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@mui/material/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import {
  Button,
  MenuItem,
  TextField,
  Select,
  Grid,
  Checkbox,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useEffect } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useHistory } from "react-router-dom";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { updateTicketStatus } from "../../actions/OffLineSupportActions";
let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "scroll",
    position: "sticky",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: isMobile ? "400px" : "1200px",
    overflow: "scroll",
    position: "sticky",
  },
  head: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "2rem",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

type ModalProps = {
  openTicketModal: any;
  setOpenTicketModal: any;
  bookingComplaints: any;
  ticketIndividualDetails: any;
  getAvailableSlots: any;
  updateTicketStatus: any;
  collectionDate: any;
  packageList: any;
  ticketDetails: any;
  getPackage: any;
  setCollectionDate: any;
  zoneId: any;
  getBookingQueryTickets: any;
  booking_slots: any;
  filterData: any;
};

const CommentsModal2: React.FC<ModalProps> = ({
  openTicketModal,
  setOpenTicketModal,
  ticketIndividualDetails,
  updateTicketStatus,
  bookingComplaints,
  packageList,
  getPackage,
  ticketDetails,
  collectionDate,
  setCollectionDate,
  getAvailableSlots,
  getBookingQueryTickets,
  booking_slots,
  zoneId,
  filterData,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [disposition, setDisposition] = useState<any>("");
  const [subDisposition, setSubDisposition] = useState<any>("");
  const [receiptNumber, setReceiptNumber] = useState<any>("");
  const [modeOfPayment, setModeOfPayment] = useState<any>("");
  const [documentType, setDocumentType] = useState<any>("");
  const [subDispositionData, setSubDispostionData] = useState<any>([]);
  const [query, setQuery] = useState<any>("");
  const [packageId, setPackageId] = useState<any>([]);
  const [slotKey, setSlotKey] = useState<any>(0);
  const [amount, setAmount] = useState<any>(0);
  const [packageKey, setPackageKey] = useState<any>(0);
  const [awbNumber, setAwbNumber] = useState<any>("");
  const [slot, setSlot] = useState<any>("");
  const [calldate, setCallDate] = useState<any>("");
  const [callTime, setCallTime] = useState<any>("");
  const [file1, setFile1] = React.useState<any>("");
  const timer = useRef<any>(0);

  const handleClose = () => {
    setOpenTicketModal(false);
    // getBookingQueryTickets()
    getBookingQueryTickets(filterData === "" ? "" : `?${filterData}`);
    //  history.push("/dashboard/os/booking/tickets")
  };
  useEffect(() => {
    if (disposition !== "") {
      const selecterDisposiotion =
        bookingComplaints.suggested_disposition.filter(
          (data: any) => data.disposition === disposition
        );
      setSubDispostionData(selecterDisposiotion[0]?.sub_disposition);
    }
  }, [disposition]);
  useEffect(() => {
    if (
      bookingComplaints?.resampling_packages &&
      bookingComplaints?.resampling_packages.length > 0
    ) {
      setPackageId(
        bookingComplaints?.resampling_packages.map((val: any) => val.id)
      );
    }
  }, [bookingComplaints]);

  const submitComplaint = () => {
    const searchTerm1 = ";";
    const searchTerm2 = "/";
    const indexOfFirst = file1.indexOf(searchTerm1);
    const indexOfSecond = file1.indexOf(searchTerm2);
    let str = file1.substring(indexOfSecond + 1, indexOfFirst);
    const body = {
      query: query,
      action_disposition: disposition,
      action_sub_disposition: subDisposition,
      packages: packageId,
      collection_date: collectionDate === "" ? null : collectionDate,
      collection_slot: slot,
      ticket_details: [
        {
          mode_of_payment: modeOfPayment,
          receipt_number: receiptNumber,
          amount: amount,
          document: file1,
          ext: str,
        },
      ],
    };
    const body1 = {
      query: query,
      action_disposition: disposition,
      action_sub_disposition: subDisposition,
      awb_number: awbNumber,
      packages: packageId,
      collection_date: collectionDate === "" ? null : collectionDate,
      collection_slot: slot,
      ticket_details: [
        {
          document_type: documentType,
          document: file1,
          ext: str,
        },
      ],
    };
    const body2 = {
      query: query,
      action_disposition: disposition,
      awb_number: awbNumber,
      action_sub_disposition: subDisposition,
      packages: packageId,
      collection_date: collectionDate === "" ? null : collectionDate,
      collection_slot: slot,
    };
    // if (subDisposition==="Refund Approved") {
    //   if (amount===0) {
    //     alert("Please Fill Amount.");
    //     return false;
    //   }
    //   else if (modeOfPayment==="") {
    //     alert("Please Select Mode of Payment.");
    //     return false;
    //   }
    //   else if (receiptNumber==="") {
    //     alert("Please Fill Receipt Number.");
    //     return false;
    //   }
    //   else if (file1?.length===0) {
    //     alert("Please Upload Receipt.");
    //     return false;
    //   }
    //   else  updateTicketStatus(body, ticketIndividualDetails?.id)
    // }  else
    if (body1.awb_number === "") {
      delete body1["awb_number"];
    }
    if (body2.awb_number === "") {
      delete body2["awb_number"];
    }
    if (subDisposition === "Upload Document") {
      if (documentType === "") {
        alert("Please Select The Document Type.");
        return false;
      } else if (file1?.length === 0) {
        alert("Please Upload Receipt.");
        return false;
      } else if (
        str !== "png" &&
        str !== "jpg" &&
        str !== "jpeg" &&
        str !== "pdf"
      ) {
        alert("Please Select The File in JPG/PNG/JPEG/PDF Format.");
        return false;
      } else updateTicketStatus(body1, ticketIndividualDetails?.id);
    } else {
      updateTicketStatus(body2, ticketIndividualDetails?.id);
    }
    getBookingQueryTickets();
    setDisposition("");
    setAmount(0);
    setModeOfPayment("");
    setDocumentType("");
    setReceiptNumber("");
    setSubDisposition("");
    setSubDispostionData([]);
    setQuery("");
    setOpenTicketModal(false);
    history.push("/dashboard/os/booking/tickets");
    // window.location.reload()
  };

  const handleCollectionDate = (e: any) => {
    setCollectionDate(e.target.value);
    setSlotKey(e.target.value.substring(8, 10));
  };

  const fileSelectedHandler1 = async (e: React.ChangeEvent<{ files: any }>) => {
    const base64 = await convertBase64(e.target.files[0]);
    setFile1(base64);
  };

  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  console.log(disposition, "disposition");

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openTicketModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openTicketModal}>
          <div className={classes.paper}>
            <div className={classes.head}>
              <h4
                style={{
                  marginBottom: "1rem",
                }}
                id="transition-modal-title"
              >
                Action
              </h4>
              <CloseIcon onClick={() => handleClose()} />
            </div>
            <Grid
              container
              direction="row"
              alignItems="flex-start"
              justify="space-between"
              spacing={3}
              style={{ margin: "1rem auto" }}
            >
              <Grid item xs={12} md={6}>
                <FormControl style={{ width: "100%" }} className="input">
                  <InputLabel id="test-select-label">Disposition</InputLabel>
                  <Select
                    className="input"
                    name="disposition"
                    label="Disposition"
                    variant="outlined"
                    value={disposition}
                    labelId="test-select-label"
                    style={{ width: "100%", height: "40px" }}
                    onChange={(e) => setDisposition(e.target.value as string)}
                  >
                    {bookingComplaints?.suggested_disposition &&
                      bookingComplaints?.suggested_disposition.length > 0 &&
                      bookingComplaints?.suggested_disposition.map(
                        (data: any) => {
                          return (
                            <MenuItem value={data?.disposition}>
                              {data?.disposition}
                            </MenuItem>
                          );
                        }
                      )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl style={{ width: "100%" }} className="input">
                  <InputLabel>Sub Disposition</InputLabel>
                  <Select
                    className="input"
                    name="subdisposition"
                    label="Sub Disposition"
                    variant="outlined"
                    disabled={disposition === ""}
                    value={subDisposition}
                    style={{ width: "100%", height: "40px" }}
                    onChange={(e) =>
                      setSubDisposition(e.target.value as string)
                    }
                  >
                    {subDispositionData &&
                      subDispositionData !== undefined &&
                      subDispositionData.map((data: any) => {
                        return <MenuItem value={data}>{data}</MenuItem>;
                      })}
                  </Select>
                </FormControl>
              </Grid>
              {/* {subDisposition==="Refund Approved" ? (
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="Amount"
                      type="number"
                      label="Amount"
                      value={amount}
                      className="input"
                      variant="outlined"
                      style={{ width: "100%", height: "56px" }}
                      onChange={(e) => setAmount(e.target.value)}
                      required
                    />
                  </Grid>
              ) : ""
              } */}
              {/* {subDisposition==="Refund Approved" ? (
                  <Grid item xs={12} md={6}>
                    <FormControl style={{ width: "100%" }} className="input">
                      <InputLabel>Mode of Payment</InputLabel>
                      <Select
                          name="ModeofPayment"
                          label="Mode of Payment"
                          value={modeOfPayment}
                          className="input"
                          variant="outlined"
                          style={{ width: "100%", margin: "0", height: "40px" }}
                          onChange={(e) => setModeOfPayment(e.target.value as string)}
                          required
                      >
                          <MenuItem value="paytm">Paytm</MenuItem>
                          <MenuItem value="google_pay">Google Pay</MenuItem>
                          <MenuItem value="phone_pay">Phone Pay</MenuItem>
                          <MenuItem value="neft">NEFT</MenuItem>
                          <MenuItem value="imps">IMPS</MenuItem>
                          <MenuItem value="other_upi">Other UPI</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
              ) : ""
              } */}
              {disposition === "dispatched" ? (
                <Grid item xs={12} md={6}>
                  <TextField
                    name="comment"
                    type="text"
                    value={awbNumber}
                    className="input"
                    variant="outlined"
                    label="AwbNumber"
                    onChange={(e) => setAwbNumber(e.target.value as string)}
                    style={{ width: "100%" }}
                    required
                  />
                </Grid>
              ) : (
                ""
              )}
              {subDisposition === "Upload Document" ? (
                <Grid item xs={12} md={6}>
                  <FormControl style={{ width: "100%" }} className="input">
                    <InputLabel>Document Type</InputLabel>
                    <Select
                      name="DocumentType"
                      label="Document Type"
                      value={documentType}
                      className="input"
                      variant="outlined"
                      style={{ width: "100%", margin: "0", height: "40px" }}
                      onChange={(e) =>
                        setDocumentType(e.target.value as string)
                      }
                      required
                    >
                      <MenuItem value="aadhaar_card">Aadhaar Card</MenuItem>
                      <MenuItem value="pan_card">Pan Card</MenuItem>
                      <MenuItem value="voter_id">Voter ID</MenuItem>
                      <MenuItem value="usg_ultrasound_report">
                        USG Ultrasound Report
                      </MenuItem>
                      <MenuItem value="others">Others</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              ) : (
                ""
              )}
              {subDisposition === "Upload Document" ? (
                <Grid item xs={12} sm={6} md={6} style={{ padding: "1px" }}>
                  <sup
                    style={{
                      fontSize: "11px",
                      marginLeft: "15px",
                      // marginTop: "15px",
                      background: "#fff",
                      color: "rgba(0, 0, 0, 0.54)",
                      letterSpacing: "0.00938em",
                    }}
                  >
                    Upload Receipt
                  </sup>
                  <input
                    className="input"
                    style={{
                      width: "100%",
                      marginTop: "-15px",
                      padding: "6px 14px",
                      border: "1px solid rgba(0, 0, 0, 0.23)",
                      borderRadius: "5px",
                    }}
                    type="file"
                    name="file"
                    id="file"
                    multiple
                    required
                    onChange={fileSelectedHandler1}
                  />
                </Grid>
              ) : (
                ""
              )}
              {/* {subDisposition==="Refund Approved" ? (
                  <Grid item xs={12} md={6}>
                    <FormControl style={{ width: "100%" }} className="input">
                      <InputLabel>Receipt Number</InputLabel>
                    <TextField
                      name="ReceiptNumber"
                      type="text"
                      label="Receipt Number"
                      value={receiptNumber}
                      className="input"
                      variant="outlined"
                      onInput={(e: any) => {
                        e.target.value = (e.target.value)
                          .toString()
                          .slice(0, 100);
                      }}
                      style={{ width: "100%", height: "56px" }}
                      onChange={(e) => setReceiptNumber(e.target.value as string)}
                      required
                    />
                    </FormControl>
                  </Grid>
              ) : ""
              } */}
              {disposition === "Resampling Booking Created" &&
              subDisposition === "Resampling Booking Created" ? (
                <>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="collectionDate"
                      type="date"
                      value={collectionDate}
                      className="input"
                      label="Collection Date*"
                      variant="outlined"
                      //    disabled={queryStatus === "confirmed"}
                      inputProps={{
                        min: `${new Date().getFullYear()}-${
                          new Date().getMonth() + 1 < 10
                            ? `0${new Date().getMonth() + 1}`
                            : new Date().getMonth() + 1
                        }-${
                          new Date().getDate() < 10
                            ? `0${new Date().getDate()}`
                            : new Date().getDate()
                        }`,
                      }}
                      onChange={(e: any) => handleCollectionDate(e)}
                      style={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      id="slot"
                      key={slotKey}
                      onChange={(event, newValue) => {
                        let sourceIds: any = "";
                        if (newValue) {
                          let obj = JSON.parse(
                            JSON.stringify(newValue, null, " ")
                          );
                          setSlot(obj.id);
                        }
                      }}
                      options={booking_slots || []}
                      freeSolo
                      blurOnSelect
                      aria-required
                      limitTags={1}
                      //    disabled={
                      //      collectionDate === "" || queryStatus === "confirmed"
                      //    }
                      getOptionLabel={(option: any) =>
                        option?.available_slots > 0
                          ? option?.start_time +
                            "-" +
                            option?.end_time +
                            "Available Slot :" +
                            option?.available_slots
                          : ""
                      }
                      disableClearable
                      disableCloseOnSelect
                      // onInputChange={(event, newInputValue) => {
                      //   clearTimeout(timer.current);
                      //   timer.current = setTimeout(() => {
                      //     getAvailableSlots(newInputValue);
                      //   }, 1000);
                      //   if (newInputValue.length === 0) {
                      //     setSlot("");
                      //   }
                      // }}
                      renderInput={(params) => (
                        <TextField
                          className="input"
                          {...params}
                          label="Collection Slot"
                          variant="outlined"
                          style={{ width: "100%", margin: "0", padding: "0" }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      id="package"
                      key={packageKey}
                      // onChange={(event, newValue) => {
                      //   if (newValue) {
                      //     let packs: any = [];
                      //     newValue.map((val: any) => {
                      //       let obj = JSON.parse(
                      //         JSON.stringify(val, null, " ")
                      //       );
                      //       packs.push(obj.id);
                      //     });
                      //     setPackageId(packs);
                      //   }
                      // }}
                      multiple
                      disableClearable
                      options={bookingComplaints?.resampling_packages || []}
                      value={bookingComplaints?.resampling_packages || []}
                      freeSolo
                      disabled
                      disableCloseOnSelect
                      //    disabled={queryStatus === "confirmed"}
                      aria-required
                      getOptionLabel={(option: any) =>
                        option?.name + ", " + option?.code
                      }
                      renderInput={(params) => (
                        <TextField
                          className="input"
                          {...params}
                          placeholder="Package"
                          variant="outlined"
                          style={{ width: "100%", margin: "0", padding: "0" }}
                          inputProps={{
                            ...params.inputProps,
                            onKeyDown: (e) => {
                              if (e.key === "Enter") {
                                e.stopPropagation();
                              }
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                </>
              ) : (
                ""
              )}
              <Grid item xs={12} sm={6} md={12}>
                <TextareaAutosize
                  maxRows={6}
                  aria-label="maximum height"
                  placeholder="Please write query....."
                  value={query}
                  onChange={(e) => setQuery(e.target.value as string)}
                  style={{ width: 1100, height: 80 }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={{ height: "50px" }}
                  disabled={
                    disposition === "" || subDisposition === "" || query === ""
                  }
                  onClick={submitComplaint}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {
  updateTicketStatus,
})(CommentsModal2);
