import { ACTIONS, BookingTypes } from "../interfaces/actionTypes/OffLineSupportType";

type InitialState = {
  pin_code: Array<any>;
  cities: Array<any>;
  areaList: Array<any>;
  agentsList: Array<any>;
  packageList: Array<any>;
  familyPackageList: Array<any>;
  familyPackageBookingList: Array<any>;
  phleboList: Array<any>;
  partnerList: Array<any>;
  couponList: Array<any>;
  locationVerified: Array<any>;
  createBooking: Object;
  sentFailedReportById: Object;
  createPilotBooking: Object;
  holdTheChatData: Array<any>;
  createFamilyPackageBooking: Object;
  booking: Object;
  todays_positive_calls: Object;
  ticketComments: Array<any>
  newbookingList: Array<any>
  allRedtechTicketsUsingLeadData: Array<any>;
  lead_details: Object;
  leadById: Object;
  signOutData: Array<any>;
  manualreportsend: Array<any>;
  leads: Array<any>;
  failedReportById: Object;
  reportSendManual: Array<any>;
  bookingList: Array<any>;
  dispositionList: Array<any>;
  communicationWhatsapp: Array<any>;
  sendLocationOnWhatsapp: Array<any>;
  comment: any;
  bookingsComment: any;
  lead_id: number;
  latestBookingUsingLeadData: Array<any>;
  read_call_details: object;
  all_recordings: object;
  failedReport: Array<any>;
  queueNumber: Object;
  whatsappComments: object;
  whatsappCommentsSend: object;
  whatsappCommentsByLead: object;
  individualWhatsappChat: Array<any>;
  callStats: object;
  complaint: object;
  complaints: object;
  lead_source: any;
  booking_slots: object;
  search_results: object;
  additional_booking: object;
  call_status: object;
  all_lead: object;
  topRcFreshLeads: object;
  topCfreshLeads: object;
  latest_lead: object;
  sameNumberBooking: boolean;
  loading: boolean;
  leadAddressLoading: boolean;
  bookingIdLoader: boolean;
  biomarkerLoader: boolean;
  loadingWhatsapp: boolean;
  breakResponse: Array<any>;
  panelBreak: Array<any>;
  paymentSendLink: Array<any>;
  paymentReSendLink: Array<any>;
  paymentCancelLink: Array<any>;
  smsDetails: Array<any>;
  emailDetails: Array<any>;
  smsReportSend: Array<any>;
  centerInfo: Array<any>;
  sendSMSLink: Array<any>;
  notificationMessage: Array<any>;
  reportStatus: Array<any>;
  unmaskedNumber: Array<any>;
  userDetails: Array<any>;
  labDetails: Array<any>;
  notificationType: Array<any>;
  zoneList: Object;
  usersData: Object;
  checkGeoFenceStatus: boolean;
  geoFencesMapMyIndia: Array<any>;
  mapmyindiaAccessToken: Array<any>;
  addressDetailsLatLong: Array<any>;
  todayMissedCallsList: Array<any>;
  repeatedLead: Array<any>;
  reportDownload: Array<any>;
  callNotification: Array<any>;
  repetedLead: Array<any>;
  timeslots: Array<any>;
  createImagingBooking: Array<any>;
  redCoin: Array<any>;
  subCenter: Array<any>;
  imagingTimeSlots: Array<any>;
  imaginBooking: Array<any>;
  leadStatus: Array<any>;
  multiplePackage: Array<any>;
  displayCities: Array<any>;
  prescription: Array<any>;
  doctorInfo: Array<any>;
  imagingTicket: Array<any>;
  imaginraiseTicket: Array<any>;
  imagingTicketcount: Array<any>;
  createIMGBookingCustom: Array<any>;
  createWhatsappTemplate: Array<any>;
  ticketData: Array<any>;
  ticketCategories: Array<any>;
  ticketSubCategories: Array<any>;
  redtechcomment: Array<any>;
  cancelHoldBooking: Array<any>;
  qualityScoringReport: Array<any>;
  get_quality_scoring_report_for_backup: Array<any>;
  ltvLead: Object;
  userStats: Object;
  ceCenterForCC: Array<any>;
  whatsappTemplate: Array<any>;
  disposition: Array<any>;
  geoLoading: boolean;
  bookingListLoader: boolean;
  qualityScoreLoader: boolean
  qualityScoreBackupLoader: boolean;
  csTicketLoading: boolean;
  Prescriptionloader: boolean;
  geoCheckError: string
  geoCheckLatitude: number;
  geoCheckZoneId: number
  geoCheckLongitude: number
  geoCheckCityId: string
  bookingComplaints: Array<any>
  geoFencesMapMyIndiaForCustomer: Array<any>;
  geoLoadingforcustomer: boolean;
  geoCheckErrorForCustomer: string;
  biomarkerScreening: Array<any>;
  biomarkerUltasound: Array<any>;
  markerAdditionMember: Array<any>;
  crmLeadAddress: Array<any>;
  crmPostLeadAddress: Array<any>;
  packageTags: Array<any>;
  packageCategory: Array<any>;
  whatsappMessageStatus: Array<any>;
  referralCurrentBalance: Array<any>;
  couponReferralValidation: Array<any>;
  loadingIndividualChat: boolean;
  leadSubscriptionsData: Array<any>;
  dashboardPrescription: Array<any>;
  newLeadDetails: Array<any>;
  leadPrescription: Array<any>;
  autoSelectedPackageReducer: Array<any>;
  autoSelectedLoader: boolean;
  startWhatsappChatMessage: Array<any>;
  helthdataLoading: boolean;
  reportData: Array<any>;
  parameterCount: Array<any>;
  healthTestParameter: Array<any>;
  additionalBookingListWithTatTime: Array<any>;
  agentsListnewdata: Array<any>;
  get_all_booking_exports: Array<any>;
  verificationBookingList: Array<any>;
  bookingJourney: Array<any>;
  bookingoverview: Array<any>;
  ccpackageList: Array<any>;
  centreData: Array<any>;
  labCredential: Array<any>;
  collectionSlot: Array<any>;
  paymentInformation: Array<any>;
  verifiedbylist: Array<any>;
  phleboTimeline: Array<any>;
  dispositions: Object;
  hotleads: Array<any>;
  panelUserList: Array<any>;
  teamLeadList: Array<any>;
  performanceData: Array<any>;
  performanceLoading: boolean;
  refundLoder: boolean;
  paymentRefundList: Array<any>;
  ticketCommentsForDocuments: Array<any>;
  verificationBookingDetails: Array<any>;
  views: Array<any>;
  viewsDropdown: Array<any>;
  addRemove: Object;
  bookingCall: Array<any>;
  fetchGrivance: Array<any>;
};

const initialState: InitialState = {
  userStats: {},
  ltvLead: {},
  pin_code: [],
  cities: [],
  areaList: [],
  agentsList: [],
  phleboList: [],
  packageList: [],
  subCenter: [],
  familyPackageList: [],
  familyPackageBookingList: [],
  partnerList: [],
  couponList: [],
  locationVerified: [],
  createBooking: {},
  createPilotBooking: {},
  createFamilyPackageBooking: {},
  booking: {},
  ticketComments: [],
  lead_details: {},
  leadById: {},
  read_call_details: {},
  todays_positive_calls: {},
  all_recordings: {},
  whatsappComments: {},
  whatsappCommentsSend: {},
  whatsappCommentsByLead: {},
  additional_booking: {},
  callStats: {},
  complaint: {},
  reportSendManual: [],
  complaints: {},
  leads: [],
  bookingList: [],
  failedReport: [],
  comment: {},
  failedReportById: {},
  bookingsComment: {},
  lead_id: 0,
  lead_source: {},
  booking_slots: {},
  search_results: {},
  call_status: {},
  all_lead: {},
  topRcFreshLeads: {},
  topCfreshLeads: {},
  latest_lead: {},
  breakResponse: [],
  panelBreak: [],
  sameNumberBooking: false,
  loading: false,
  leadAddressLoading: false,
  bookingIdLoader: false,
  biomarkerLoader: false,
  loadingWhatsapp: false,
  paymentSendLink: [],
  paymentReSendLink: [],
  paymentCancelLink: [],
  imaginBooking: [],
  holdTheChatData: [],
  smsDetails: [],
  emailDetails: [],
  smsReportSend: [],
  centerInfo: [],
  sendSMSLink: [],
  notificationMessage: [],
  reportStatus: [],
  manualreportsend: [],
  unmaskedNumber: [],
  userDetails: [],
  labDetails: [],
  notificationType: [],
  createImagingBooking: [],
  zoneList: {},
  usersData: {},
  checkGeoFenceStatus: false,
  geoFencesMapMyIndia: [],
  mapmyindiaAccessToken: [],
  addressDetailsLatLong: [],
  todayMissedCallsList: [],
  repeatedLead: [],
  reportDownload: [],
  leadStatus: [],
  callNotification: [],
  repetedLead: [],
  timeslots: [],
  redCoin: [],
  imagingTimeSlots: [],
  multiplePackage: [],
  displayCities: [],
  prescription: [],
  doctorInfo: [],
  allRedtechTicketsUsingLeadData: [],
  imagingTicket: [],
  imaginraiseTicket: [],
  imagingTicketcount: [],
  individualWhatsappChat: [],
  createIMGBookingCustom: [],
  createWhatsappTemplate: [],
  ticketData: [],
  ticketCategories: [],
  ticketSubCategories: [],
  redtechcomment: [],
  cancelHoldBooking: [],
  qualityScoringReport: [],
  get_quality_scoring_report_for_backup: [],
  ceCenterForCC: [],
  whatsappTemplate: [],
  communicationWhatsapp: [],
  sendLocationOnWhatsapp: [],
  geoLoading: false,
  disposition: [],
  geoCheckError: "",
  geoCheckCityId: "",
  geoCheckLatitude: 0,
  geoCheckZoneId: 0,
  geoCheckLongitude: 0,
  bookingComplaints: [],
  bookingListLoader: false,
  latestBookingUsingLeadData: [],
  qualityScoreLoader: false,
  qualityScoreBackupLoader: false,
  csTicketLoading: false,
  geoFencesMapMyIndiaForCustomer: [],
  geoLoadingforcustomer: false,
  geoCheckErrorForCustomer: "",
  biomarkerScreening: [],
  biomarkerUltasound: [],
  markerAdditionMember: [],
  newbookingList: [],
  crmLeadAddress: [],
  crmPostLeadAddress: [],
  packageTags: [],
  packageCategory: [],
  loadingIndividualChat: false,
  Prescriptionloader: false,
  sentFailedReportById: {},
  whatsappMessageStatus: [],
  queueNumber: {},
  dispositionList: [],
  referralCurrentBalance: [],
  signOutData: [],
  couponReferralValidation: [],
  leadSubscriptionsData: [],
  dashboardPrescription: [],
  newLeadDetails: [],
  leadPrescription: [],
  autoSelectedPackageReducer: [],
  autoSelectedLoader: false,
  startWhatsappChatMessage: [],
  helthdataLoading: false,
  reportData: [],
  healthTestParameter: [],
  parameterCount: [],
  additionalBookingListWithTatTime: [],
  agentsListnewdata: [],
  get_all_booking_exports: [],
  verificationBookingList: [],
  bookingJourney: [],
  bookingoverview: [],
  ccpackageList: [],
  centreData: [],
  labCredential: [],
  paymentInformation: [],
  collectionSlot: [],
  verifiedbylist: [],
  phleboTimeline: [],
  dispositions: {},
  hotleads: [],
  panelUserList: [],
  teamLeadList: [],
  performanceData: [],
  performanceLoading: false,
  paymentRefundList: [],
  refundLoder: false,
  ticketCommentsForDocuments: [],
  verificationBookingDetails: [],
  views: [],
  viewsDropdown: [],
  addRemove: {},
  bookingCall: [],
  fetchGrivance: [],
};

const BookingReducer = (state = initialState, action: BookingTypes) => {
  switch (action.type) {
    case ACTIONS.GET_ALL_LEADS:
      return {
        ...state,
        all_lead: action.payload,
        loading: false,
      };
    case ACTIONS.SET_ALL_BOOKING_LOADING:
      return {
        ...state,
        bookingListLoader: action.payload,
      };
    case ACTIONS.SET_QUALITYSCORE_LOADING:
      return {
        ...state,
        qualityScoreLoader: action.payload,
      };
    case ACTIONS.SET_QUALITYSCOREBACKUP_LOADING:
      return {
        ...state,
        qualityScoreBackupLoader: action.payload,
      };
    case ACTIONS.SET_CSTICKET_LOADING:
      return {
        ...state,
        csTicketLoading: action.payload,
      };
    case ACTIONS.GET_USER_STATS:
      return {
        ...state,
        userStats: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TOP_RC_FRESH_LEADS:
      return {
        ...state,
        topRcFreshLeads: action.payload,
        loading: false,
      };
    case ACTIONS.GET_RED_COIN:
      return {
        ...state,
        redCoin: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TOP_C_FRESH_LEADS:
      return {
        ...state,
        topCfreshLeads: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LATEST_LEAD:
      return {
        ...state,
        latest_lead: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PIN_CODE:
      return {
        ...state,
        pin_code: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CITIES:
      return {
        ...state,
        cities: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_CALL:
      return {
        ...state,
        loading: false,
        lead_id: action.payload,
      };
    case ACTIONS.GET_RECORDINGS:
      return {
        ...state,
        all_recordings: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEAD_SOURCE:
      return {
        ...state,
        lead_source: action.payload,
        loading: false,
      };
    case ACTIONS.GET_AREA:
      return {
        ...state,
        areaList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PHLEBOS:
      return {
        ...state,
        phleboList: action.payload,
        loading: false,
      };
    case ACTIONS.SEARCH_CALL:
      return {
        ...state,
        search_results: action.payload,
        loading: false,
      };
    case ACTIONS.GET_POSTIVE_CALLS_COMMENTS:
      return {
        ...state,
        todays_positive_calls: action.payload,
        loading: false,
      };
    case ACTIONS.SET_COMMENTS:
      return {
        ...state,
        comment: action.payload,
        loading: false,
      };
    case ACTIONS.ADD_COMMENT:
      return {
        ...state,
        comments: action.payload,
        loading: false,
      };
    case ACTIONS.ADD_POSTIVE_CALLS_COMMENT:
      return {
        ...state,
        todays_positive_calls: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEAD_DETAILS:
      return {
        ...state,
        lead_details: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SUB_CENTER_INFORMATION:
      return {
        ...state,
        subCenter: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEADS:
      return {
        ...state,
        leads: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEAD_DISPOSITION_LIST:
      return {
        ...state,
        dispositionList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEAD_BY_ID:
      return {
        ...state,
        leadById: action.payload,
        loading: false,
      };
    case ACTIONS.LEAD_DETAILS_RESET:
      return {
        ...state,
        leadById: {},
        lead_details: {},
        leads: [],
        crmPostLeadAddress: [],
        loadingIndividualChat: false,
        individualWhatsappChat: [],
        communicationWhatsapp: [],
        failedReport: [],
        loading: false,
      };
    case ACTIONS.BOOKING_RESET:
      return {
        ...state,
        createBooking: {},
        bookingList: [],
        redCoin: [],
        booking: {},
        booking_slots: {},
        lead_details: {},
        referralCurrentBalance: [],
        loading: false,
        bookingIdLoader: false,
      };
    case ACTIONS.GET_AGENTS:
      return {
        ...state,
        agentsList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PACKAGES:
      return {
        ...state,
        packageList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PACKAGES_TAGS:
      return {
        ...state,
        packageTags: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PACKAGES_CATEGORY:
      return {
        ...state,
        packageCategory: action.payload,
        loading: false,
      };
    case ACTIONS.GET_FAMILY_PACKAGES:
      return {
        ...state,
        familyPackageList: action.payload,
        loading: false,
      };

    case ACTIONS.GET_FAMILY_PACKAGES_BOOKING:
      return {
        ...state,
        familyPackageBookingList: action.payload,
        loading: false,
      };
    case ACTIONS.POST_IMAGING_BOOKING_DISPOSITION:
      return {
        ...state,
        imaginBooking: action.payload,
        loading: false,
      };

    case ACTIONS.GET_PARTNERS:
      return {
        ...state,
        partnerList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_COUPONS:
      return {
        ...state,
        couponList: action.payload,
        loading: false,
      };
    case ACTIONS.LOCATION_VERIFIED:
      return {
        ...state,
        locationVerified: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_BOOKING:
      return {
        ...state,
        createBooking: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_PILOT_BOOKING:
      return {
        ...state,
        createPilotBooking: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_IMAGING_BOOKING:
      return {
        ...state,
        createIMGBooking: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_WHATSAPP_TEMPLATE:
      return {
        ...state,
        createWhatsappTemplate: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_IMAGING_BOOKING_CUSTOM:
      return {
        ...state,
        createIMGBookingCustom: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_FAMILY_PACKAGES_BOOKING:
      return {
        ...state,
        createFamilyPackageBooking: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BOOKING_LIST:
      return {
        ...state,
        bookingList: action.payload,
        bookingListLoader: false,
      };
    case ACTIONS.GET_BOOKING_BY_ID:
      return {
        ...state,
        booking: action.payload,
        bookingIdLoader: false,
      };
    case ACTIONS.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case ACTIONS.SET_LEAD_ADDRESS_LOADER:
      return {
        ...state,
        leadAddressLoading: action.payload,
      };
    case ACTIONS.SET_BOOKING_ID_LOADER:
      return {
        ...state,
        bookingIdLoader: action.payload,
      };
    case ACTIONS.SET_BIOMARKER_LOADER:
      return {
        ...state,
        biomarkerLoader: action.payload,
      };
    case ACTIONS.GET_BOOKINGS_COMMENTS:
      return {
        ...state,
        bookingsComment: action.payload,
        loading: false,
      };
    case ACTIONS.ADD_BOOKINGS_COMMENT:
      return {
        ...state,
        bookingsComment: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEAD_DISPOSITION_LIST:
      return {
        ...state,
        dispositionList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_WHATSAPP_COMMENTS:
      return {
        ...state,
        whatsappComments: action.payload,
        loadingWhatsapp: false,
      };
    case ACTIONS.REDTECH_TICKETS_USING_LEAD:
      return {
        ...state,
        allRedtechTicketsUsingLeadData: action.payload,
        loading: false,
      };
    case ACTIONS.SET_LOADING_WHATSAPP:
      return {
        ...state,
        loadingWhatsapp: action.payload,
      };
    case ACTIONS.SET_GEO_LOADING:
      return {
        ...state,
        geoLoading: action.payload,
      };
    case ACTIONS.GET_WHATSAPP_COMMENTS_BY_LEAD:
      return {
        ...state,
        whatsappCommentsByLead: action.payload,
        loadingIndividualChat: false,
      };
    case ACTIONS.SEND_WHATSAPP_COMMENTS:
      return {
        ...state,
        whatsappCommentsSend: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CALL_STATS:
      return {
        ...state,
        callStats: action.payload,
        loading: false,
      };
    case ACTIONS.RAISE_COMPLAINT:
      return {
        ...state,
        complaint: action.payload,
        loading: false,
      };
    case ACTIONS.GET_COMPLAINTS:
      return {
        ...state,
        complaints: action.payload,
        loading: false,
      };
    case ACTIONS.ASSIGN_DOCTOR:
      return {
        ...state,
        success: true,
        loading: false,
      };
    case ACTIONS.SEND_FEEDBACK:
      return {
        ...state,
        success: true,
        loading: false,
      };
    case ACTIONS.GET_AVAILABLE_SLOTS:
      return {
        ...state,
        booking_slots: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_ADDITIONAL_BOOKING:
      return {
        ...state,
        additional_booking: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_ADDITIONAL_BOOKING:
      return {
        ...state,
        additional_booking: action.payload,
        loading: false,
      };
    case ACTIONS.SAME_NUMBER_BOOKING:
      return {
        ...state,
        sameNumberBooking: action.payload,
        loading: false,
      };
    case ACTIONS.GET_INDIVIDUAL_WHATSAPP_CHAT:
      return {
        ...state,
        individualWhatsappChat: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BREAK_RESPONSE:
      return {
        ...state,
        breakResponse: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PANEL_BREAK:
      return {
        ...state,
        panelBreak: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SEND_LOCATION_REQUEST:
      return {
        ...state,
        communicationWhatsapp: action.payload,
        loading: false,
      };
    case ACTIONS.SEND_LOCATION_REQUEST:
      return {
        ...state,
        sendLocationOnWhatsapp: action.payload,
        loading: false,
      };
    case ACTIONS.HOLD_THE_CHAT:
      return {
        ...state,
        holdTheChatData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PAYMENT_SEND_LINK:
      return {
        ...state,
        paymentSendLink: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PAYMENT_RESEND_LINK:
      return {
        ...state,
        paymentReSendLink: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PAYMENT_CANCEL_LINK:
      return {
        ...state,
        paymentCancelLink: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SMS_DETAILS:
      return {
        ...state,
        smsDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_EMAIL_DETAILS:
      return {
        ...state,
        emailDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SMS_REPORT_SEND:
      return {
        ...state,
        smsReportSend: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CENTER_INFORMATION:
      return {
        ...state,
        centerInfo: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SMS_LINK:
      return {
        ...state,
        sendSMSLink: action.payload,
        loading: false,
      };
    case ACTIONS.GET_FIREBASE_NOTIFICATION:
      return {
        ...state,
        notificationMessage: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEAD_STATUS:
      return {
        ...state,
        leadStatus: action.payload,
        loading: false,
      };

    case ACTIONS.GET_REPORT_STATUS:
      return {
        ...state,
        reportStatus: action.payload,
        loading: false,
      };
    case ACTIONS.GET_UNMASKED_NUMBER:
      return {
        ...state,
        unmaskedNumber: action.payload,
        loading: false,
      };

    case ACTIONS.GET_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LTV_LEAD:
      return {
        ...state,
        ltvLead: action.payload,
        loading: false,
      };
    case ACTIONS.GET_REPORT_SEND_MANUAL:
      return {
        ...state,
        reportSendManual: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LAB_DETAILS:
      return {
        ...state,
        labDetails: action.payload,
        loading: false,
      };
    case ACTIONS.SIGN_OUT_IN_WHATSAPP_CHAT:
      return {
        ...state,
        signOutData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ZONE:
      return {
        ...state,
        zoneList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_USER_DATA:
      return {
        ...state,
        usersData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_NOTIFICATION_TYPE:
      return {
        ...state,
        notificationType: action.payload,
        loading: false,
      };
    case ACTIONS.CHECK_GEOFENCE_AREA:
      return {
        ...state,
        checkGeoFenceStatus: action.payload,
        loading: false,
      };
    case ACTIONS.CHECK_GEOFENCE_AREA_MAMPMYINDIA_FOR_CUSTOMER_ADDRESS:
      return {
        ...state,
        geoFencesMapMyIndiaForCustomer: action.payload,
        // loading: false,
        geoLoadingforcustomer: false,
        geoCheckErrorForCustomer: "",
      };
    case ACTIONS.SET_GEO_LOADING_FOR_CUSTOMER:
      return {
        ...state,
        geoLoadingforcustomer: action.payload,
      };
    case ACTIONS.SET_GEO_ERROR_FOR_CUSTOMER:
      return {
        ...state,
        geoCheckErrorForCustomer: action.payload,
      };
    case ACTIONS.CHECK_GEOFENCE_AREA_MAMPMYINDIA:
      return {
        ...state,
        geoFencesMapMyIndia: action.payload,
        geoLoading: false,
        geoCheckError: "",
        geoCheckCityId: "",
        geoCheckZoneId: 0,
        geoCheckLatitude: 0,
        geoCheckLongitude: 0,
      };
    case ACTIONS.GET_MAMPMYINDIA_ACCESS_TOKEN:
      return {
        ...state,
        mapmyindiaAccessToken: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ADDRESS_DETAILS_LAT_LONG:
      return {
        ...state,
        addressDetailsLatLong: action.payload,
        loading: false,
      };
    case ACTIONS.GET_WHATSAPP_MESSAGE_STATUS:
      return {
        ...state,
        whatsappMessageStatus: action.payload,
        // loading: false,
      };
    case ACTIONS.SEND_REPORT_BY_ID:
      return {
        ...state,
        manualreportsend: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TODAYS_MISSED_CALLS:
      return {
        ...state,
        todayMissedCallsList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_REPEATED_LEAD:
      return {
        ...state,
        repeatedLead: action.payload,
        loading: false,
      };
    case ACTIONS.GET_REPORT_DOWNLOAD:
      return {
        ...state,
        reportDownload: action.payload,
        loading: false,
      };
    case ACTIONS.GET_NOTIFICATION_MESSAGE:
      return {
        ...state,
        callNotification: action.payload,
        loading: false,
      };
    case ACTIONS.GET_REPEATED_LEADS:
      return {
        ...state,
        repetedLead: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TIMESLOTS:
      return {
        ...state,
        timeslots: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CRM_LEAD_ADDRESS:
      return {
        ...state,
        crmLeadAddress: action.payload,
        loading: false,
      };
    case ACTIONS.GET_IMAGING_TIMESLOTS:
      return {
        ...state,
        imagingTimeSlots: action.payload,
        loading: false,
      };
    case ACTIONS.GET_MULTIPLE_PACKAGES:
      return {
        ...state,
        multiplePackage: action.payload,
        loading: false,
      };
    case ACTIONS.GET_DISPLAY_CITIES:
      return {
        ...state,
        displayCities: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PRESCRIPTION_DATA:
      return {
        ...state,
        prescription: action.payload,
        loading: false,
      };
    case ACTIONS.GET_DOCTOR_INFO_BY_ID:
      return {
        ...state,
        doctorInfo: action.payload,
        loading: false,
      };
    case ACTIONS.GET_RED_TECH_COMMENT:
      return {
        ...state,
        redtechcomment: action.payload,
        loading: false,
      };
    case ACTIONS.GET_IMAGING_TICKET:
      return {
        ...state,
        imagingTicket: action.payload,
        loading: false,
      };

    case ACTIONS.GET_IMAGING_RAISE_TICKET:
      return {
        ...state,
        imaginraiseTicket: action.payload,
        loading: false,
      };
    case ACTIONS.GET_IMAGING_TICKET_COUNT:
      return {
        ...state,
        imagingTicketcount: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TICKET_DATA:
      return {
        ...state,
        ticketData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_NEW_BOOKING_LIST:
      return {
        ...state,
        newbookingList: action.payload,
        bookingListLoader: false,
      };
    case ACTIONS.GET_TICKET_CATEGORIES:
      return {
        ...state,
        ticketCategories: action.payload,
        loading: false,
      };

    case ACTIONS.GET_TICEKT_SUB_CATEGORIES:
      return {
        ...state,
        ticketSubCategories: action.payload,
        loading: false,
      };
    case ACTIONS.GET_HOLD_CANCEL_BOOKING:
      return {
        ...state,
        cancelHoldBooking: action.payload,
        loading: false,
      };
    case ACTIONS.GET_QUALITY_SCORING_REPORT:
      return {
        ...state,
        qualityScoringReport: action.payload,
        qualityScoreLoader: false,
      };
    case ACTIONS.GET_QUALITY_SCORING_REPORT_FOR_BACKUP:
      return {
        ...state,
        get_quality_scoring_report_for_backup: action.payload,
        qualityScoreBackupLoader: false,
      };
    case ACTIONS.GET_CE_CENTER_FOR_CC:
      return {
        ...state,
        ceCenterForCC: action.payload,
        loading: false,
      };
    case ACTIONS.GET_WHATSAPP_TEMPLATE:
      return {
        ...state,
        whatsappTemplate: action.payload,
        loading: false,
      };
    case ACTIONS.SET_GEO_LOADING:
      return {
        ...state,
        geoLoading: action.payload,
      };
    case ACTIONS.SET_GEO_CHECK_ERROR:
      return {
        ...state,
        geoCheckError: action.payload,
      };
    case ACTIONS.SET_GEO_CHECK_CITYID:
      return {
        ...state,
        geoCheckCityId: action.payload,
      };
    case ACTIONS.SET_GEO_CHECK_ZONEID:
      return {
        ...state,
        geoCheckZoneId: action.payload,
      };
    case ACTIONS.SET_GEO_CHECK_LATITUDE:
      return {
        ...state,
        geoCheckLatitude: action.payload,
      };
    case ACTIONS.SET_GEO_CHECK_LONGITUDE:
      return {
        ...state,
        geoCheckLongitude: action.payload,
      };
    case ACTIONS.GET_BOOKING_COMPLAINTS:
      return {
        ...state,
        bookingComplaints: action.payload,
        csTicketLoading: false,
      };
    case ACTIONS.UPDATE_DISPOSITION:
      return {
        ...state,
        disposition: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TICKET_COMMENTS:
      return {
        ...state,
        ticketComments: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BIOMARKER_SCREENING:
      return {
        ...state,
        biomarkerScreening: action.payload,
        biomarkerLoader: false,
      };
    case ACTIONS.GET_BIOMARKER_ULTASOUND:
      return {
        ...state,
        biomarkerUltasound: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ADDITIONAL_MEMBER:
      return {
        ...state,
        markerAdditionMember: action.payload,
        loading: false,
      };
    case ACTIONS.POST_CRM_LEAD_ADDRESS:
      return {
        ...state,
        crmPostLeadAddress: action.payload,
        leadAddressLoading: false,
      };
    case ACTIONS.BIOMARKER_RESET:
      return {
        ...state,
        biomarkerScreening: {},
      };
    case ACTIONS.GET_REFERRAL_CURRENT_BALANCE:
      return {
        ...state,
        referralCurrentBalance: action.payload,
        loading: false,
      };
    case ACTIONS.GET_COUPON_REFERRAL_VALIDATION:
      return {
        ...state,
        couponReferralValidation: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEAD_SUBSCRIPTIONS_DATA:
      return {
        ...state,
        leadSubscriptionsData: action.payload,
        loading: false,
      };
    case ACTIONS.SET_LOADING_INDIVIDUAL_CHAT:
      return {
        ...state,
        loadingIndividualChat: action.payload,
      };
    case ACTIONS.LATEST_BOOKING_USING_LEAD:
      return {
        ...state,
        latestBookingUsingLeadData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_FAILED_REPORT_DATA:
      return {
        ...state,
        failedReport: action.payload,
        loading: false,
      };
    case ACTIONS.QUEUE_NUMBER:
      return {
        ...state,
        queueNumber: action.payload,
        loading: false,
      };
    case ACTIONS.GET_FAILED_REPORT_DATA_BY_ID:
      return {
        ...state,
        failedReportById: action.payload,
        loading: false,
      };
    case ACTIONS.SENT_REPORT_DATA_BY_ID:
      return {
        ...state,
        sentFailedReportById: action.payload,
        loading: false,
      };
    case ACTIONS.GET_DASHBOARD_PRESCRIPTION:
      return {
        ...state,
        dashboardPrescription: action.payload,
        loading: false,
      };
    case ACTIONS.GET_NEW_LEAD_DETAILS:
      return {
        ...state,
        newLeadDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEAD_PRESCRIPTION_DATA:
      return {
        ...state,
        leadPrescription: action.payload,
        Prescriptionloader: false,
      };
    case ACTIONS.SET_LEAD_PRESCRIPTION_LOADING:
      return {
        ...state,
        Prescriptionloader: false,
      };
    case ACTIONS.GET_PACKAGES_AUTO_SELECTED:
      return {
        ...state,
        autoSelectedPackageReducer: action.payload,
        autoSelectedLoader: false,
      };
    case ACTIONS.SET_AUTOSELECTED_LOADING:
      return {
        ...state,
        autoSelectedLoader: false,
      };
    case ACTIONS.START_WHATSAPP_CHAT:
      return {
        ...state,
        startWhatsappChatMessage: false,
        loading: false,
      };
    case ACTIONS.GET_PACKAGES_AUTO_SELECTED:
      return {
        ...state,
        autoSelectedPackageReducer: action.payload,
        autoSelectedLoader: false,
      };
    case ACTIONS.GET_REPORT_DATA:
      return {
        ...state,
        reportData: action.payload,
        helthdataLoading: false,
      };
    case ACTIONS.SET_HELTHDATA_LOADING:
      return {
        ...state,
        helthdataLoading: false,
      };
    case ACTIONS.GET_HEALTH_TEST_PARAMETER:
      return {
        ...state,
        healthTestParameter: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PARAMETER_COUNT:
      return {
        ...state,
        parameterCount: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ADDITIONAL_BOOKING_LIST_WITH_TAT_TIME:
      return {
        ...state,
        additionalBookingListWithTatTime: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BOOKING_LIST_WITH_TAT_TIME:
      return {
        ...state,
        bookingListWithTatTime: action.payload,
        loading: false,
      };
    case ACTIONS.GET_AGENTS_NEW_DATA:
      return {
        ...state,
        agentsListnewdata: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ALL_BOOKING_EXPORTS:
      return {
        ...state,
        get_all_booking_exports: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BOOKING_VERIFICATION_LIST:
      return {
        ...state,
        verificationBookingList: action.payload,
        bookingListLoader: false,
      };
    case ACTIONS.GET_BOOKING_JOURNEY_DETAILS:
      return {
        ...state,
        bookingJourney: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BOOKING_OVERVIEW:
      return {
        ...state,
        bookingoverview: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CC_PACKAGES:
      return {
        ...state,
        ccpackageList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CENTER:
      return {
        ...state,
        centreData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LAB_CREDENTIAL:
      return {
        ...state,
        labCredential: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PAYMENT_INFORMATION:
      return {
        ...state,
        paymentInformation: action.payload,
        loading: false,
      };
    case ACTIONS.GET_COLLECTION_SLOT:
      return {
        ...state,
        collectionSlot: action.payload,
        loading: false,
      };
    case ACTIONS.GET_VERIFIED_BY_LIST:
      return {
        ...state,
        verifiedbyList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PHLEBO_TIMELINE:
      return {
        ...state,
        phleboTimeline: action.payload,
        loading: false,
      };
    case ACTIONS.GET_DISPOSITIONS:
      return {
        ...state,
        dispositions: action.payload,
        loading: false,
      };
    case ACTIONS.GET_HOTLEADS:
      return {
        ...state,
        hotleads: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PANEL_USER_LIST_DATA:
      return {
        ...state,
        panelUserList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PANEL_USER_LIST_DATA_TEAM_LEAD:
      return {
        ...state,
        teamLeadList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PERFORMANCE_DATA:
      return {
        ...state,
        performanceData: action.payload,
        performanceLoading: false,
      };
    case ACTIONS.SET_PERFORMANCE_LOADING:
      return {
        ...state,
        performanceLoading: action.payload,

      };
    case ACTIONS.GET_PAYMENT_REFUND_LIST:
      return {
        ...state,
        paymentRefundList: action.payload,
        refundLoder: false,
      };
    case ACTIONS.SET_REFUND_LOADING:
      return {
        ...state,
        refundLoder: action.payload,
      };
    case ACTIONS.GET_TICKET_COMMENTS_FOR_DOCUMENTS:
      return {
        ...state,
        ticketCommentsForDocuments: action.payload,
        loading: false,
      };
    case ACTIONS.GET_VERIFICATION_BOOKING_DETAILS:
      return {
        ...state,
        verificationBookingDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SF_VIEWS:
      return {
        ...state,
        views: action.payload,
      };
    case ACTIONS.GET_SF_VIEWS_DROPDOWN:
      return {
        ...state,
        viewsDropdown: action.payload,
        loading: false,
      };
    case ACTIONS.POST_ADD_REMOVE_TEST:
      return {
        ...state,
        addRemove: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BOOKING_CALLS:
      return {
        ...state,
        bookingCall: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PACKAGES_DATA:
      return {
        ...state,
        packageList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_GRIEVANCE:
      return {
        ...state,
        fetchGrivance: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default BookingReducer;
