import React, { useState,useEffect } from 'react'
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Chip, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow,withStyles } from '@material-ui/core';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import {getNotificationMessage} from '../../actions/OffLineSupportActions'
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        paper: {
            marginTop: "2rem",
            padding: "0.5rem 2rem",
            width: "100%",
        },
        table: {
            maxWidth: "100%",
            margin: "auto"
        },
           buttonContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
        },
         toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        tableContainer: {
            marginTop: "2rem",
            position: "sticky",
            top: "200px",
          },
    })
);

const StyledTableCell:any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow:any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface Props {
    getNotificationMessage:any;
    notificationMessage:any;
  
}

const MessageTable: React.FC<Props> = ({
    getNotificationMessage,
    notificationMessage,
   
}) => {
    const classes = useStyles()
    // const [bookingId, setBookingId] = useState<number>(0);
    const [messageType,setMessageType]=useState<any>(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    
       useEffect(()=>{
        getNotificationMessage(`?is_read=${messageType}`)
    },[messageType])
    const handleUnReadMessage=()=>{
        setMessageType(false)
    }
    const handleReadMessage=()=>{
        setMessageType(true)
    }
     const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        if (newPage > page) {
            let url = notificationMessage.links.next.split("?")[1];
            getNotificationMessage(`?${url}`)
            
        } else if (newPage < page) {
            let url = notificationMessage.links.previous.split("?")[1];
            getNotificationMessage(`?${url}`)
      
        }
        setPage(newPage);
    };

    return (
        <div style={{ width: "100%" }}>
            <div className={classes.toolbar} />
            <div className={classes.buttonContainer}>
                <Button variant={!messageType?"contained":"outlined"} onClick={() => handleUnReadMessage()} color="success" style={{ marginLeft: "1rem", marginBottom: "1rem"}}>UnRead Message</Button>
                <Button variant={messageType?"contained":"outlined"} onClick={() => handleReadMessage()} color="success"  style={{ marginLeft: "1rem", marginBottom: "1rem" }}>Read Message</Button>
            </div>
            {/* <Paper elevation={15} className={classes.paper}> */}
            <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "700px" }}>
            <Table stickyHeader aria-label="simple table">
                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableCell align="center">User Name</StyledTableCell>
                                    <StyledTableCell align="center">Mobile Number</StyledTableCell>
                                    <StyledTableCell align="center"> Notification</StyledTableCell>
                                    <StyledTableCell align="center">Created At</StyledTableCell>
                                    
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {notificationMessage&&notificationMessage.results&&notificationMessage.results.length>0&&notificationMessage.results.map((data:any,index:any)=>{
                                    return(
                                         <StyledTableRow key={index}>
                                        <StyledTableCell align="center">{data.sender&&data.sender.username}</StyledTableCell>
                                        <StyledTableCell align="center">{data.sender&&data.sender.phonenumber}</StyledTableCell>
                                        <StyledTableCell align="center">{data.notification}</StyledTableCell>
                                        <StyledTableCell align="center">{new Date(data.created_at).toLocaleString()}</StyledTableCell>
                                        </StyledTableRow>
                                    )
                                })}
                            </TableBody>
                            <TableFooter>
                                    <TablePagination
                                        colSpan={3}
                                        count={notificationMessage.count || 0}
                                        rowsPerPageOptions={[]}
                                        rowsPerPage={rowsPerPage}
                                        // onChangeRowsPerPage={handleChangeRowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                    />
                                </TableFooter>
                        </Table>
                </TableContainer>
            {/* </Paper> */}
            
        </div>
    )
}

const mapStateToProps = (state: any) => ({
notificationMessage: state.OffLineSupporteReducer.notificationMessage,
})

export default connect(mapStateToProps, {
getNotificationMessage
})(MessageTable);

