import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@mui/material/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button, MenuItem, Select, TextField, Grid } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useEffect } from "react";

let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: isMobile ? "400px" : "1200px",
  },
  head: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "2rem",
  },
}));

type ModalProps = {
  open: boolean;
  bookingId: number;
  complaint: any;
  leadId: number;
  setOpen: Function;
  totalPrice: number;
  initiateRefund: any;
  booking: any;
};

const CommentsModal2: React.FC<ModalProps> = ({
  open,
  bookingId,
  leadId,
  complaint,
  setOpen,
  totalPrice,
  initiateRefund,
  booking,
}) => {
  const classes = useStyles();

  const [query, setQuery] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState("");
  const [disposition, setDisposition] = useState<string>("none");
  const [verificationRemark, setVerificationRemark] = useState<string>("none");
  const [createBookingId, setCreateBookingId] = useState<any>(bookingId);
  const [createAmount, setCreateAmount] = useState<any>(totalPrice);
  const [bookingType, setBookingType] = useState<any>();
  const [startTime, setStartTime] = React.useState<any>();
  const blockCharInPhoneField = (e: React.KeyboardEvent<HTMLDivElement>) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

  const handleClose = () => {
    setDisposition("");
    setOpen("");
  };

  useEffect(() => {
    setCreateAmount(totalPrice);
  }, [totalPrice]);

  useEffect(() => {
    setBookingType(booking?.booking_type);
  }, [booking]);

  const raiseComplaint = () => {
    const type =
      bookingId !== 0 ? "booking_ticket" : leadId !== 0 ? "lead_ticket" : "";
    const id = bookingId !== 0 ? bookingId : leadId !== 0 ? leadId : 0;

    const body1: any = {
      status: "pending",
      querytype: query,
      query: description,
      disposition,
      verificationRemark,
    };
    const body2: any = {
      amount: Number(createAmount),
      booking: createBookingId,
      cs_remarks: description,
    };
    if (disposition === "") {
      delete body1.disposition;
    }
    if (verificationRemark === "") {
      delete body1.verificationRemark;
    }
    if (disposition !== "refund_query" && verificationRemark !== "canceled") {
      // raiseNewComplaint(type, body1, id)
      setDisposition("none");
      setQuery("");
      setDescription("");
    } else initiateRefund(body2);
    setQuery("none");
    setDescription("");
    setDisposition("");
    handleClose();
  };

  useEffect(() => {
    if (complaint) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [complaint]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div className={classes.head}>
              <h4
                style={{
                  marginBottom: "1rem",
                }}
                id="transition-modal-title"
              >
                Update Verification Status
              </h4>
              <CloseIcon onClick={() => setOpen(false)} />
            </div>
            <Grid
              container
              direction="row"
              alignItems="flex-start"
              justify="space-between"
              spacing={3}
              style={{ margin: "1rem auto" }}
            >
              <Grid item xs={12} md={12}>
                <p>Verification Status</p>
                <Select
                  className="input"
                  name="action"
                  variant="outlined"
                  value={disposition}
                  style={{ width: "100%" }}
                  onChange={(e) => setDisposition(e.target.value as string)}
                >
                  <MenuItem value="confirmed">Confirmed</MenuItem>
                  <MenuItem value="reschedule">Reschedule</MenuItem>
                  <MenuItem value="canceled">Canceled</MenuItem>
                </Select>
              </Grid>
              {disposition === "reschedule" ? (
                <>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      className="input"
                      name="start_date"
                      type="date"
                      label="Start Date"
                      value={startDate}
                      variant="outlined"
                      onChange={(e) => setStartDate(e.target.value as string)}
                      style={{ width: "100%" }}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      label="Start Time"
                      variant="outlined"
                      className="input"
                      type="time"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      required
                      value={startTime}
                      onChange={(e) => setStartTime(e.target.value)}
                    />
                  </Grid>
                </>
              ) : (
                ""
              )}
              {disposition === "canceled" ? (
                <>
                  <Grid item xs={12} sm={6} md={4}>
                    <Select
                      className="input"
                      name="action"
                      variant="outlined"
                      value={verificationRemark}
                      style={{ width: "100%" }}
                      onChange={(e) =>
                        setVerificationRemark(e.target.value as string)
                      }
                    >
                      <MenuItem value="Customer_did_not_picked_the_call">
                        Customer did not picked the call
                      </MenuItem>
                      <MenuItem value="Customer_Out_Of_Station">
                        Customer Out Of Station
                      </MenuItem>
                      <MenuItem value="Customer_Not_Interested">
                        Customer Not Interested
                      </MenuItem>
                      <MenuItem value="Collection_Center_Facing_Tech_Problem_while_Updating_Status">
                        Collection Center Facing Tech Problem while Updating
                        Status
                      </MenuItem>
                      <MenuItem value="Sample_will_be_Collected_Today">
                        Sample will be Collected Today
                      </MenuItem>
                      <MenuItem value="Already_Sample_Collected">
                        Already Sample Collected
                      </MenuItem>
                      <MenuItem value="Booking_Need_To_Reschedule">
                        Booking Need To Reschedule
                      </MenuItem>
                      <MenuItem value="Collection_Center_Behaviour_was_not_appropriate_with_customer">
                        Collection Center Behaviour was not appropriate with
                        customer
                      </MenuItem>
                      <MenuItem value="Collection_Center_was_not_operational_at_Sample_Collection_Time">
                        Collection Center was not operational at Sample
                        Collection Time
                      </MenuItem>
                      <MenuItem value="Sample_Collected_by_Another_Lab">
                        Sample Collected by Another Lab
                      </MenuItem>
                      <MenuItem value="Invalid_Wrong_Cont_No">
                        Invalid/Wrong Cont. No
                      </MenuItem>
                    </Select>
                  </Grid>
                </>
              ) : (
                ""
              )}
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={{ height: "50px" }}
                  onClick={raiseComplaint}
                  disabled={
                    disposition === "" || (startDate === "" && startTime === "")
                  }
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  complaint: state.OffLineSupporteReducer.complaint,
});

export default connect(mapStateToProps, {})(CommentsModal2);
