export const BOOKING_STATUS = [
        {
            text: "Pending",
            value: "pending",
          },
          {
            text: "Confirmed",
            value: "confirmed",
          },
          {
            text: "Cancelled",
            value: "cancelled",
          },
          {
            text: "Rescheduled",
            value: "rescheduled",
          },
          {
            text: "Resampling",
            value: "resampling",
          },
          {
            text: "Sample Not Received Due To Payment Information",
            value: "Sample Not Received Due To Payment Information",
          },
          {
            text: "Sample Received",
            value: "sample received",
          },
          {
            text: "Partial Received",
            value: "partial received",
          },
          {
            text: "Order booked",
            value: "order booked",
          },
          {
            text: "Verified",
            value: "verified",
          },
          {
            text: "Sample Collected",
            value: "sample collected",
          },
          {
            text: "Sample Received in Lab",
            value: "sample received in lab",
          },
          {
            text: "Reports Sent",
            value: "reports sent",
          }
    ];