import React, { useState, useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Chip,
  Paper,
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import CallIcon from "@material-ui/icons/Call";
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "../components/loader/index";
// import BookingCommentsModal from '../components/Comments/bookingComments'
import CommentsModal2 from "../components/Comments/Comment2";
import { MessageRounded } from "@material-ui/icons";
import StatusUpdate from "../components/Comments/statusUpsate";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import AdditonalFamilyModal from "./AdditionalFamily/AdditionFamilyDetails";
import { getCities } from "../actions/OffLineSupportActions"
import { CALL_DISABLE_TIME } from "../../CallTimeDisable";
import { clickToCallBooking } from "../actions/OffLineSupportActions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem",
      width: "100%",
    },
    table: {
      maxWidth: "100%",
      margin: "auto",
    },
    conlorChange: {
      background: "red",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  })
);
const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);
const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface Props {
  getBookings: any;
  verificationBookingList: any;
  getLeadById: any;
  leadById: any;
  page: any;
  setPage: any;
  bookingId: any;
  setBookingId: any;
  setBookingIdModal: any;
  setopenModal: any;
  openModal: any;
  open2: any;
  setOpen2: any;
  setPaymentId: any;
  paymentId: any;
  getCities: any;
  cities: any;
  openPaymentModal: any;
  setOpenPaymentModal: any;
  loading: boolean;
  getPrescriptionData: any;
  prescription: any;
  prscriptionLead: any;
  setPrescriptionLead: any;
  setAnchorEl: any;
  additionalMemebr: any;
  setAdditionalMemebr: any;
  additionalModal: any;
  setAdditonalModal: any;
  openPhlebotModel: any;
  setOpenPhleboModel: any;
  setLeadId: any;
  bookingListLoader:boolean;
  clickToCallBooking:any;
}

const BookingsTable: React.FC<Props> = ({
  getBookings,
  verificationBookingList,
  page,
  setPage,
  setopenModal,
  openModal,
  getCities,
  getLeadById,
  leadById,
  cities,
  setBookingId,
  setBookingIdModal,
  bookingId,
  open2,
  setOpen2,
  setPaymentId,
  setOpenPaymentModal,
  openPaymentModal,
  paymentId,
  loading,
  getPrescriptionData,
  setPrescriptionLead,
  prscriptionLead,
  prescription,
  setAnchorEl,
  additionalMemebr,
  setAdditionalMemebr,
  additionalModal,
  setAdditonalModal,
  openPhlebotModel,
  setOpenPhleboModel,
  setLeadId,
  bookingListLoader,
  clickToCallBooking
}) => {
  const classes = useStyles();
  const [commentModalOpen, setCommentModalOpen] = useState<boolean>(false);
  const [bookingData, setBookingData] = useState<any>();
  const [lead, setLead] = useState<number>(0);
  const [openUpdate, setOpenUpdate] = React.useState(false);
  const [callButton, setCallButton] = useState<boolean>(true);

  const CATEGORY: any = [
    {
      id: 1,
      text: "Interested/Follow up",
      city: true,
      leadId: false,
    },
    {
      id: 2,
      text: "Not Interested",
      city: true,
      query: false,
    },
    {
      id: 3,
      text: "Order Booked",
      city: true,
      query: false,
    },
    {
      id: 4,
      text: "Ringing",
      city: false,
      query: false,
    },
    {
      id: 5,
      text: "Number Doesn't Exist",
      city: false,
      query: false,
    },
    {
      id: 6,
      text: "By Mistake & Wrong Number",
      city: false,
      query: false,
    },
    {
      id: 7,
      text: "Call Back",
      city: true,
      query: false,
    },
    {
      id: 8,
      text: "Business Realated Call",
      city: true,
      query: false,
    },
    {
      id: 9,
      text: "Spam Call",
      city: false,
      query: false,
    },
    {
      id: 10,
      text: "Enquiry Call",
      city: true,
      query: false,
    },
    {
      id: 11,
      text: "CFresh",
      city: false,
      query: false,
    },
    {
      id: 12,
      text: "Test Not Available",
      city: true,
      query: false,
    },
    {
      id: 13,
      text: "Subscribed to Competitor",
      city: true,
      query: false,
    },
    {
      id: 14,
      text: "Slot Issue",
      city: true,
      query: false,
    },
    {
      id: 15,
      text: "Same Day Slot",
      city: true,
      query: false,
    },
    {
      id: 16,
      text: "Already Booked",
      city: false,
      query: false,
    },
    {
      id: 17,
      text: "Not Contactable",
      city: false,
      query: false,
    },
    {
      id: 18,
      text: "Out of Station",
      city: true,
      query: false,
    },
    {
      id: 19,
      text: "Out of Service Area",
      city: true,
      query: false,
    },
    {
      id: 20,
      text: "Need Time to Think",
      city: true,
      query: false,
    },
    {
      id: 21,
      text: "Email/Whatsapp Package",
      city: true,
      leadId: false,
    },
    {
      id: 22,
      text: "Discount/Price Enquiry",
      city: true,
      leadId: false,
    },
    {
      id: 23,
      text: "CS issue",
      city: false,
      leadId: true,
    },
    {
      id: 24,
      text: "Call Not Reachable",
      city: false,
      leadId: false,
    },
    {
      id: 25,
      text: "Call Later",
      city: true,
      leadId: false,
    },
    {
      id: 26,
      text: "Call drop",
      city: true,
      leadId: false,
    },
    {
      id: 27,
      text: "Call Disconnected",
      city: true,
      leadId: false,
    },
    {
      id: 28,
      text: "Below <5 year patient",
      city: true,
      leadId: false,
    },
    {
      id: 29,
      text: "DND",
      city: false,
      leadId: false,
    },
    {
      id: 30,
      text: "Phlebo's Issue",
      city: false,
      leadId: true,
    },
    {
      id: 31,
      text: "Reports Regarding Issue",
      city: false,
      leadId: true,
    },
    {
      id: 32,
      text: "Health Advice",
      city: false,
      leadId: true,
    },
    {
      id: 33,
      text: "Health Consultation",
      city: false,
      leadId: true,
    },
    {
      id: 34,
      text: "Resampling",
      city: false,
      leadId: false,
    },
    {
      id: 35,
      text: "Language Barrier",
      city: false,
      leadId: false,
    },
  ];

  const handleClick = (bookingid: number) => {
    setopenModal(true);
    setBookingId(bookingid);
    setBookingIdModal(bookingid);
  };
  const handleUpdateBooking = (booking: number) => {
    setOpenUpdate(true);
    setBookingData(booking);
  };
  const handleCallClick = (id: number, type: string) => {
    // setLeadId(id);
    setCallButton(false);
    clickToCallBooking(id, type);
    setTimeout(() => {
      setCallButton(true);
    }, CALL_DISABLE_TIME);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = verificationBookingList.links && verificationBookingList.links.next.split("?")[1];
      getBookings(`${url}`)
    } else if (newPage < page) {
      let url = verificationBookingList.links && verificationBookingList.links.previous.split("?")[1];
      getBookings(`${url}`)
    }
    setPage(newPage as number);
  };

  const handleModal = (leadid: number) => {
    setOpen2(true);
    setLead(leadid);
    setLeadId(leadid);
  };
  const handlePaymentInfo = (id: any) => {
    setPaymentId(id);
    setOpenPaymentModal(true);
    setPaymentId(id);
  };
  const handleAdditionalView = (member: any) => {
    setAdditionalMemebr(member);
    setAdditonalModal(true);
  };

  const handlePrescriptionClick = (
    id: any,
    event: React.MouseEvent<HTMLElement>
  ) => {
    setAnchorEl(event.currentTarget);
    setPrescriptionLead(id);
  };
  const openPhleboJourneyModel = (data: any) => {
    setBookingId(data);
    setBookingIdModal(data);
    setOpenPhleboModel(true);
  };
  return (
    <div className="data-table" style={{ width: "100%" }}>
      <TableContainer
        className={classes.tableContainer}
        component={Paper}
        style={{ maxHeight: "700px" }}
      >
        {bookingListLoader ? (
          <Loader />
        ) : (
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <StyledTableRow>
            
                <StyledTableCell align="center">Action</StyledTableCell>
                <StyledTableCell align="center">Booking Id</StyledTableCell>
                <StyledTableCell align="center">Call</StyledTableCell>
                <StyledTableCell align="center">Lims Id</StyledTableCell>
                <StyledTableCell align="center">Processing Lab</StyledTableCell>
                <StyledTableCell align="center">Collection Center Name</StyledTableCell>
                <StyledTableCell align="center">Collection Center Number</StyledTableCell>
                <StyledTableCell align="center">Booking Status</StyledTableCell>
                <StyledTableCell align="center">Pickup Status</StyledTableCell>
                <StyledTableCell align="center">Roport Status</StyledTableCell>
                <StyledTableCell align="center">Verification Status</StyledTableCell>
                <StyledTableCell align="center">Update Status</StyledTableCell>
                <StyledTableCell align="center">Comment</StyledTableCell>
                {/* <StyledTableCell align="center">Action</StyledTableCell> */}
                <StyledTableCell align="center">Payment Info</StyledTableCell>
                <StyledTableCell align="center">Created At</StyledTableCell>
                <StyledTableCell align="center">Booking Date</StyledTableCell>
                <StyledTableCell align="center">Name</StyledTableCell>
                <StyledTableCell align="center">Agent</StyledTableCell>
                <StyledTableCell align="center">Collection Date</StyledTableCell>
                <StyledTableCell align="center">Booking Time</StyledTableCell>
                <StyledTableCell align="center">Zone Name</StyledTableCell>
                <StyledTableCell align="center">Mobile Number</StyledTableCell>
                <StyledTableCell align="center">Remarks</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {verificationBookingList?.results &&
                verificationBookingList?.results?.length > 0 &&
                verificationBookingList?.results.map((booking: any, index: any) => {
                  const pendingData = booking?.additional_members?.filter(
                    (data: any) => data?.pickup_status === "pending"
                  );
                  const confirmedData = booking?.additional_members?.filter(
                    (data: any) => data?.pickup_status === "confirmed"
                  );
                  return (
                    <StyledTableRow
                      style={{
                        background:
                          booking?.red_booking_color === true ? "red" : "",
                      }}
                      key={index}
                    >
                     
                      <StyledTableCell align="center">
                        <Link
                          to={`/dashboard/os/booking-view/${booking?.id}`}
                          style={{ textDecoration: "none" }}
                        >
                          <Button variant="contained" color="secondary">
                            View
                          </Button>
                        </Link>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {booking?.additional_members?.length > 0 ? (
                          <div>
                            {booking?.id}+
                            <Chip
                              label={
                                "T" +
                                booking?.additional_members?.length +
                                `${confirmedData?.length > 0
                                  ? "C" + confirmedData.length
                                  : ""
                                }` +
                                `${pendingData?.length > 0
                                  ? "P" + pendingData?.length
                                  : ""
                                }`
                              }
                              style={{
                                background:
                                  pendingData?.length > 0
                                    ? "#e91e63"
                                    : "#1b5e20",
                              }}
                              onClick={() =>
                                handleAdditionalView(
                                  booking?.additional_members
                                )
                              }
                            />
                          </div>
                        ) : (
                          booking?.id
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Button
                          disabled={!callButton}
                          className="addDeck"
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            handleCallClick(booking?.id, "call")
                          }
                          startIcon={<CallIcon />}
                        ></Button>
                      </StyledTableCell>
                     
                      <StyledTableCell align="center">
                        {booking?.bill_id || "N/A"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {booking?.centre_address?.lab[0] || "N/A"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {booking?.centre_address?.name || "N/A"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {booking?.centre_address?.mobile || "N/A"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Chip
                          label={`${booking?.booking_status}`}
                          style={{
                            background:
                              booking?.booking_status === "pending"
                                ? "#ffee58"
                                : booking?.booking_status === "confirmed"
                                  ? "#00e676"
                                  : booking?.booking_status === "cancelled"
                                    ? "#ff3d00"
                                    : "#d500f9",
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Chip
                          label={`${booking?.pickup_status}`}
                          style={{
                            background:
                              booking?.pickup_status === "pending"
                                ? "#ffee58"
                                : booking?.pickup_status === "confirmed"
                                  ? "#00e676"
                                  : booking?.pickup_status === "cancelled"
                                    ? "#ff3d00"
                                    : "#d500f9",
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Chip
                          label={`${booking?.report_status}`}
                          style={{
                            background:
                              booking?.report_status === "pending"
                                ? "#ffee58"
                                : booking?.report_status === "consolidate"
                                  ? "#00e676"
                                  : booking?.report_status === "partial"
                                    ? "#d500f9"
                                    : "#ff3d00",
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Chip
                          label={`${booking?.verification_status}`}
                          style={{
                            background:
                              booking?.verification_status === "pending"
                                ? "#ffee58"
                                : booking?.verification_status === "confirmed"
                                  ? "#00e676"
                                  : booking?.verification_status === "cancelled"
                                    ? "#ff3d00"
                                    : "#d500f9",
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <div>
                          <MessageRounded
                            onClick={() => handleUpdateBooking(booking)}
                          />
                        </div>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <div>
                          <MessageRounded
                            onClick={() => handleClick(booking?.id)}
                          />
                        </div>
                      </StyledTableCell>
                      {/* <StyledTableCell align="center">
                        <div>
                          <MessageRounded
                            onClick={() => handleModal(booking?.lead)}
                          />
                        </div>
                      </StyledTableCell> */}
                      <StyledTableCell align="center">
                        <LocalAtmIcon
                          onClick={() => handlePaymentInfo(booking?.id)}
                        />
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        {new Date(booking?.created_at).toLocaleString()}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {booking?.booking_date}
                      </StyledTableCell>
                      <StyledTableCell align="center">{`${booking?.designation || ""
                        } ${booking?.customer_name}`}</StyledTableCell>
                      <StyledTableCell align="center">
                        {(booking?.agent) || "NA"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {booking?.collection_date}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {" "}
                        {new Date(booking?.created_at).toLocaleString()}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {booking?.customer_areapincode?.area}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {booking?.customer_phonenumber}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {booking?.remarks || "NA"}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
            <TableFooter>
              <StyledTableRow>
                <TablePagination
                  rowsPerPageOptions={[]}
                  colSpan={3}
                  count={verificationBookingList?.count || 0}
                  rowsPerPage={verificationBookingList?.page_size}
                  page={page}
                  onPageChange={handleChangePage}
                />
              </StyledTableRow>
            </TableFooter>
          </Table>
        )}
      </TableContainer>

      {verificationBookingList?.results && verificationBookingList?.results?.length === 0 && (
        <h6 style={{ textAlign: "center" }}>No Data Found</h6>
      )}
      {/* <CommentsModal2
        open={open2}
        setOpen={setOpen2}
        leadId={lead}
        getCities={getCities}
        cities={cities}
      /> */}
      <StatusUpdate
        openUpdate={openUpdate}
        setOpenUpdate={setOpenUpdate}
        bookingData={bookingData}
      />
      <AdditonalFamilyModal
        additionalMemebr={additionalMemebr}
        additionalModal={additionalModal}
        setAdditonalModal={setAdditonalModal}
        loading={loading}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  cities: state.OffLineSupporteReducer.cities,
});

export default connect(mapStateToProps, {
  getCities,
  clickToCallBooking
})(BookingsTable);
