import { AccessAlarm } from "@material-ui/icons";

export const ACTIONS = {
  CHANGE_PASSWORD: "change_password",
  GET_PIN_CODE: "os/get_pin_code",
  GET_CITIES: "os/get_cities",
  GET_AREA: "os/get_area",
  GET_AGENTS: "os/get_agents",
  GET_PACKAGES: "os/get_packages",
  GET_PACKAGES_TAGS: "os/get_packages_tags",
  GET_PACKAGES_CATEGORY: "os/get_packages_category",
  GET_FAMILY_PACKAGES: "os/get_family_packages",
  GET_FAMILY_PACKAGES_BOOKING: "os/get_family_packages_booking",
  CREATE_FAMILY_PACKAGES_BOOKING: "os/create_family_packages_booking",
  GET_PARTNERS: "os/get_partners",
  GET_COUPONS: "os/get_coupons",
  CREATE_BOOKING: "os/create_booking",
  CREATE_IMAGING_BOOKING: "os/CREATE_IMAGING_BOOKING",
  CREATE_WHATSAPP_TEMPLATE: "os/create_whatsapp_template",
  CREATE_ADDITIONAL_BOOKING: "os/create_additional_booking",
  GET_PHLEBOS: "os/get_phlebos",
  SEARCH_CALL: "os/serach",
  UPDATE_ADDITIONAL_BOOKING: "os/update_additional_booking",
  UPDATE_BOOKING: "os/updatel_booking",
  GET_BOOKING_LIST: "os/get_booking_list",
  GET_LEAD_DETAILS: "os/get_lead_details",
  GET_LEAD_BY_ID: "os/get_lead_by_id",
  GET_LTV_LEAD: "os/get_ltv_lead",
  LEAD_DETAILS_RESET: "os/lead_details_reset",
  BOOKING_RESET: "os/booking_reset",
  GET_LEADS: "os/get_leads",
  GET_BOOKING_BY_ID: "os/get_booking_by_id",
  GET_ZONE: "os/get_zone",
  GET_NEW_BOOKING_LIST: "os/get_new_booking_list",
  CLICK_TO_CALL: "os/click_to_call",
  GET_POSTIVE_CALLS_COMMENTS: "os/get_postive_calls_commnets",
  ADD_POSTIVE_CALLS_COMMENT: "os/add_comments",
  SET_COMMENTS: "os/set_commnets",
  ADD_COMMENT: "positive_calls/add_comment",
  CREATE_CALL: "os/createcall",
  GET_LEAD_SOURCE: "os/getleadsource",
  GET_RECORDINGS: "os/get_recordings",
  GET_BOOKINGS_COMMENTS: "os/get_bookings_commnets",
  ADD_BOOKINGS_COMMENT: "os/add_bookings_comments",
  GET_WHATSAPP_COMMENTS: "os/get_whatsapp_comments",
  GET_WHATSAPP_COMMENTS_BY_LEAD: "os/get_whatsapp_comments_by_lead",
  SEND_WHATSAPP_COMMENTS: "os/send",
  GET_CALL_STATS: "os/get_call_stats",
  GET_USER_STATS: "os/get_call_stats",
  RAISE_COMPLAINT: "os/raise_complaint",
  GET_COMPLAINTS: "os/get_complaints",
  ASSIGN_DOCTOR: "os/assign_doctor",
  SEND_FEEDBACK: "os/send_feedback",
  SET_LOADING: "os/set_loading",
  SET_LEAD_ADDRESS_LOADER: "os/set_lead_address_loader",
  SET_BOOKING_ID_LOADER: "os/set_booking_id_loader",
  SET_BIOMARKER_LOADER: "os/set_biomarker_loader",
  LOCATION_VERIFIED: "os/location_verified",
  SET_LOADING_WHATSAPP: "os/set_loading_whatsapp",
  GET_AVAILABLE_SLOTS: "os/get_available_slots",
  GET_ALL_LEADS: "os/get_all_leads",
  GET_LATEST_LEAD: "os/get_latest_lead",
  GET_SYNC_DATA: "os/get_sync_data",
  SAME_NUMBER_BOOKING: "os/same_number_booking",
  GET_BREAK_RESPONSE: "os/get_break_response",
  GET_PANEL_BREAK: "os/get_panel_break",
  GET_PAYMENT_SEND_LINK: "os/get_payment_send_link",
  GET_PAYMENT_RESEND_LINK: "os/get_payment_resend_link",
  GET_PAYMENT_CANCEL_LINK: "os/get_payment_cancel_link",
  GET_SMS_DETAILS: "os/get_sms_details",
  GET_EMAIL_DETAILS: "os/get_email_details",
  GET_SMS_REPORT_SEND: "os/get_sms_report_sms",
  GET_CENTER_INFORMATION: "os/get_center_information",
  GET_SMS_LINK: "cs/send_sms_link",
  GET_NOTIFICATION_MESSAGE: "os/get_notification_message",
  GET_REPORT_STATUS: "os/get_report_status",
  GET_UNMASKED_NUMBER: "os/get_unmasked_number",
  GET_USER_DETAILS: "se/get_user_details",
  GET_FIREBASE_NOTIFICATION: "os/get_firebase_notification",
  GET_LAB_DETAILS: "os/get_lab_details",
  GET_USER_DATA: "os/get_users_data",
  GET_NOTIFICATION_TYPE: "os/get_notification_type",
  CHECK_GEOFENCE_AREA: "os/checking-geofence-area",
  CHECK_GEOFENCE_AREA_MAMPMYINDIA: "os/checking-geofence-area-mapmyindia",
  GET_MAMPMYINDIA_ACCESS_TOKEN: "os/get-mapmyindia-access-token",
  GET_ADDRESS_DETAILS_LAT_LONG: "os/get-address-details-lat-long",
  GET_TODAYS_MISSED_CALLS: "os/get_today_missed_calls",
  GET_REPEATED_LEAD: "os/get_repeated_list",
  GET_REPORT_DOWNLOAD: "os/get_report_download",
  GET_REPEATED_LEADS: "os/get_repeted_lead",
  UPDATED_REPEATED_LIST: "os/updated_repeated_lead",
  SEND_NOTIFICATION: "os/send_notification",
  GET_TIMESLOTS: "os/get_timeslot",
  GET_TOP_RC_FRESH_LEADS: "os/get_top_rc_fresh_leads",
  GET_TOP_C_FRESH_LEADS: "os/get_top_c_fresh_leads",
  GET_RED_COIN: "os/get_red_coin",
  GET_SUB_CENTER_INFORMATION: "os/get_sub_center_information",
  GET_IMAGING_TIMESLOTS: "os/get_imaging_timeslots",
  GET_MULTIPLE_PACKAGES: "os/get_multiple_package",
  GET_DISPLAY_CITIES: "os/get_display_cities",
  GET_LEAD_STATUS: "os/get_lead_status",
  GET_PRESCRIPTION_DATA: "os/get_prescription_data",
  GET_DOCTOR_INFO_BY_ID: "os/get_doctor_info",
  POST_IMAGING_BOOKING_DISPOSITION: "os/lead_imaging_disposition",
  GET_IMAGING_TICKET: "os/get_imaging_ticket",
  GET_IMAGING_TICKET_COUNT: "os/get_imaging_ticket_count",
  GET_IMAGING_RAISE_TICKET: "os/get_imaging_raise_ticket",
  SEND_INITIAL_WHATSAPP_MESSAGE: "os/send_initial_whatsapp_message",
  CREATE_IMAGING_BOOKING_CUSTOM: "os/imaging_booking_custom",
  UPDATE_IMAGING_TICKET: "os/update_imaging_ticket",
  GET_TICKET_DATA: "os/get_ticket_data",
  GET_TICKET_CATEGORIES: "os/get_ticket_category",
  GET_TICEKT_SUB_CATEGORIES: "os/get_ticket_categories",
  CREATE_TASK_TICKET: "os/create_task_ticket",
  GET_RED_TECH_COMMENT: "os/redtech_comments/",
  POST_RED_TECH_COMMENT: "os/postredtech_comments/",
  GET_HOLD_CANCEL_BOOKING: "os/get_cancel_hold_booking_list",
  GET_QUALITY_SCORING_REPORT: "ve/get_quality_scoring_report/",
  GET_QUALITY_SCORING_REPORT_FOR_BACKUP: "ve/get_quality_scoring_report_for_backup/",
  GET_CE_CENTER_FOR_CC: "os/get_ce_center_for_cc",
  CREATE_PILOT_BOOKING: "os/create_pilot_booking",
  SET_GEO_LOADING: "os/set_geo_loading",
  SET_GEO_CHECK_ERROR: "os/set_geo_check_error",
  SET_GEO_CHECK_CITYID: "os/set_geo_check_cityid",
  SET_GEO_CHECK_LATITUDE: "os/set_geo_check_latitude",
  SET_GEO_CHECK_LONGITUDE: "os/set_geo_check_longitude",
  SET_GEO_CHECK_ZONEID: "os/set_geo_check_zoneid",
  GET_BOOKING_COMPLAINTS: "os/get_booking_complaints",
  UPDATE_DISPOSITION: "os/update_disposition",
  UPDATE_TICKET_STATUS: "os/update_ticekt_status",
  GET_TICKET_COMMENTS: "os/ticket_comments",
  SET_ALL_BOOKING_LOADING: "os/set_all_booking_loading",
  SET_QUALITYSCORE_LOADING: "os/set_qualityscore_loader",
  SET_QUALITYSCOREBACKUP_LOADING: "os/set_qualityscorebackup_loader",
  SET_CSTICKET_LOADING: "os/set_csticket_loading",
  CHECK_GEOFENCE_AREA_MAMPMYINDIA_FOR_CUSTOMER_ADDRESS: "os/checking-geofence-area-mapmyindia_for_customer",
  SET_GEO_LOADING_FOR_CUSTOMER: "os/set_geo_loading_for_customer",
  SET_GEO_ERROR_FOR_CUSTOMER: "os/set_geo_check_error_for_customer",
  GET_WHATSAPP_TEMPLATE: "os/get_whatsapp_template",
  GET_BIOMARKER_SCREENING: "os/biomarker_screening",
  GET_BIOMARKER_ULTASOUND: "os/biomarker_ultasound",
  GET_ADDITIONAL_MEMBER: "os/additional_member",
  BIOMARKER_RESET: "os/biomarker_reset",
  GET_SEND_LOCATION_REQUEST: "os/communication/on-whatsapp",
  SEND_LOCATION_REQUEST: "os/communication/send-location-request/",
  GET_CRM_LEAD_ADDRESS: "os/get_auth/address-crm/",
  POST_CRM_LEAD_ADDRESS: "os/post_auth/address-crm/",
  GET_WHATSAPP_MESSAGE_STATUS: "os/get_whatsapp_message_status",
  GET_LEAD_DISPOSITION_LIST: "os/plead/status-category/",
  POST_DISPOSITION_DATA: "os/lead/lead-status-update/",
  INITIATE_REFUND: "se/initiate_refund",
  GET_REFERRAL_CURRENT_BALANCE: "os/get_referral_current_balance/",
  GET_COUPON_REFERRAL_VALIDATION: "os/get_coupon_referral_validation/",
  GET_LEAD_SUBSCRIPTIONS_DATA: "os/get-lead-subscriptions-data",
  SET_LOADING_INDIVIDUAL_CHAT: "ce_booking/set_loading_individual_chat",
  GET_INDIVIDUAL_WHATSAPP_CHAT: "os/get_individual_whatsapp_chat",
  GET_FAILED_REPORT_DATA: "os/report_failed/",
  LATEST_BOOKING_USING_LEAD: "os/latest_booking_using_lead",
  QUEUE_NUMBER: "os/queue_number",
  SEND_LAST_REPORT_TO_LEAD: "os/send_last_report_to_lead",
  SIGN_OUT_IN_WHATSAPP_CHAT: "os/sign_out_in_whatsapp_chat",
  UPDATE_LEADS: "os/update_leads",
  SEND_REPORT_BY_ID: "os/send_report",
  GET_FAILED_REPORT_DATA_BY_ID: "os/report_failed_data_by_id/",
  SENT_REPORT_DATA_BY_ID: "os/sent_report_data_by_id",
  GET_REPORT_SEND_MANUAL: "os/get_report_send_manual",
  SWITCH_CHAT_AGENT_IN_WHATSAPP_CHAT: "os/switch_chat_agent_in_whatsapp_chat",
  HOLD_THE_CHAT: "os/hold_the_chat",
  REDTECH_TICKETS_USING_LEAD: "os/redtech_tickets_using_lead",
  GET_DASHBOARD_PRESCRIPTION: 'os/get_dashboard_prescription',
  SET_PRESCRIPTION_DATA: 'os/set_prescription_data',
  UPDATE_PRESCRIPTION_DISPOSITION: 'os/update_prescription_disposition',
  START_WHATSAPP_CHAT: 'os/start_whatsapp_chat',
  ADDRESS_JSON_POST: 'os/address_json',
  GET_NEW_LEAD_DETAILS: "os/lead_details",
  RAISE_NEW_RAISE_TICEKT_DISPOSITION: "os/cstech_create-ticekt",
  GET_LEAD_PRESCRIPTION_DATA: "os/booking/lead_prescription",
  SET_LEAD_PRESCRIPTION_LOADING: "os/booking/lead_prescription_loading",
  SET_AUTOSELECTED_LOADING: "os/booking/autoselected_login",
  GET_PACKAGES_AUTO_SELECTED: "os/core/packages/crmdata",
  SET_HELTHDATA_LOADING: "os/get_report_data_loading",
  GET_REPORT_DATA: "os/get_report_data",
  GET_HEALTH_TEST_PARAMETER: "os/get_health_test_parameter",
  GET_PARAMETER_COUNT: "os/get_parameter_count",
  ADD_REMARK_ON_CALL: "os/add_remark_on_call",
  ALOT_TO_PHA_BOOKING: "os/booking/alot-to-pha",
  CLICK_TO_CALL_LEAD: "os/lead/clicktocall",
  GET_ADDITIONAL_BOOKING_LIST_WITH_TAT_TIME: "os/get_additional_booking_list_with_tat_time",
  GET_BOOKING_LIST_WITH_TAT_TIME: "os/get_booking_list_with_tat_time",
  GET_AGENTS_NEW_DATA: "os/get_agents_new_data",
  GET_ALL_BOOKING_EXPORTS: "os/export/booking",
  GET_BOOKING_VERIFICATION_LIST: "os/get_booking_verification_list",
  GET_BOOKING_JOURNEY_DETAILS: "os/booking_journey_details",
  GET_BOOKING_OVERVIEW: "os/booking_overview",
  GET_CC_PACKAGES: "os/get_cc_packages",
  GET_CENTER: "os/get_center",
  GET_LAB_CREDENTIAL: "os/get_lab_cred",
  GET_PAYMENT_INFORMATION: "os/get_payment_information",
  GET_COLLECTION_SLOT: "os/get_collection_slot",
  GET_VERIFIED_BY_LIST: "os/get_verified_by_list",
  GET_PHLEBO_TIMELINE: "os/timeline",
  GET_DISPOSITIONS: "os/get_dispositions",
  GET_HOTLEADS: "os/get_hotleads",
  GET_PANEL_USER_LIST_DATA: "os/get_panel_user_list_data",
  GET_PANEL_USER_LIST_DATA_TEAM_LEAD: "os/get_panel_user_list_data_team_lead",
  GET_PERFORMANCE_DATA: "os/get_performance_data",
  SET_PERFORMANCE_LOADING: "os/set_performance_loading",
  GET_PAYMENT_REFUND_LIST: "os/get_payment_refund_list",
  SET_REFUND_LOADING: "os/refund_loading",
  GET_TICKET_COMMENTS_FOR_DOCUMENTS: "os/ticket_comments_for_documents",
  GET_VERIFICATION_BOOKING_DETAILS: "os/get_verification_booking_details",
  GET_SF_VIEWS: 'os/get_views',
  GET_SF_VIEWS_DROPDOWN: 'os/get_sf_views_dropdown',
  LEAD_TRANSFER_BY_COMMA: "os/set_comments",
  POST_ADD_REMOVE_TEST: "os/post_add_remove_test",
  UPDATE_FAILED_REPORT_STATUS: "os/update_report_failed/",
  UPDATE_HOTLEADS: "os/update_hotleads",
  UPDATE_INITIATED_REFUND: "os/update_initiate_refund",
  GET_BOOKING_CALLS: "os/get_booking_call",
  GET_PACKAGES_DATA: "os/get_packages_data",
  GET_GRIEVANCE:"os/get_grievance",
  UPDATE_GRIVANCE_DATA:"os/update_grivance_data",
  UPDATE_RECORDING_DISPOSTION:"os/update_recording_disposition"

};

interface ChangePassword {
  type: typeof ACTIONS.CHANGE_PASSWORD;
  payload: Object;
}
interface UpdateRecordingDisposition {
  type: typeof ACTIONS.UPDATE_RECORDING_DISPOSTION;
  payload: Object;
}
interface SetCsLoading {
  type: typeof ACTIONS.SET_CSTICKET_LOADING;
  payload: Object;
}
interface GetGrievanceData {
  type: typeof ACTIONS.UPDATE_GRIVANCE_DATA;
  payload: Object;
}
interface GetGrievance {
  type: typeof ACTIONS.GET_GRIEVANCE;
  payload: Array<any>;
}
interface GetReferralCurrentBalance {
  type: typeof ACTIONS.GET_REFERRAL_CURRENT_BALANCE;
  payload: Array<any>;
}
interface GetCouponReferralValidation {
  type: typeof ACTIONS.GET_COUPON_REFERRAL_VALIDATION;
  payload: Array<any>;
}
interface CheckGeoFenceAreaMapMyIndiaForCustomer {
  type: typeof ACTIONS.CHECK_GEOFENCE_AREA_MAMPMYINDIA_FOR_CUSTOMER_ADDRESS;
  payload: Array<any>;
}
interface GeoLoadingForCustomer {
  type: typeof ACTIONS.SET_GEO_LOADING_FOR_CUSTOMER;
  payload: boolean;
}
interface CheckGeoCheckErrorForCustomer {
  type: typeof ACTIONS.SET_GEO_ERROR_FOR_CUSTOMER;
  payload: boolean;
}
interface GeoLoading {
  type: typeof ACTIONS.SET_GEO_LOADING;
  payload: boolean;
}
interface GetLeadDispostion {
  type: typeof ACTIONS.GET_LEAD_DISPOSITION_LIST;
  payload: Object;
}
interface CheckGeoCheckError {
  type: typeof ACTIONS.SET_GEO_CHECK_ERROR;
  payload: boolean;
}
interface CheckGeoCheckCityId {
  type: typeof ACTIONS.SET_GEO_CHECK_CITYID;
  payload: boolean;
}
interface CheckGeoCheckLatitude {
  type: typeof ACTIONS.SET_GEO_CHECK_LATITUDE;
  payload: number;
}
interface CheckGeoCheckLongitude {
  type: typeof ACTIONS.SET_GEO_CHECK_LONGITUDE;
  payload: number;
}
interface CheckGeoCheckZoneId {
  type: typeof ACTIONS.SET_GEO_CHECK_ZONEID;
  payload: number;
}
interface CreatePilotBooking {
  type: typeof ACTIONS.CREATE_PILOT_BOOKING;
  payload: boolean;
}
interface allRedtechTicketsUsingLead {
  type: typeof ACTIONS.REDTECH_TICKETS_USING_LEAD;
  payload: boolean;
}
interface GeoLoading {
  type: typeof ACTIONS.SET_GEO_LOADING;
  payload: boolean;
}
interface GetUserStats {
  type: typeof ACTIONS.GET_USER_STATS;
  payload: Object;
}

interface GetTopRcFreshLeads {
  type: typeof ACTIONS.GET_TOP_RC_FRESH_LEADS;
  payload: Array<any>;
}
interface GetCeCenterForCc {
  type: typeof ACTIONS.GET_CE_CENTER_FOR_CC;
  payload: Array<any>;
}
interface GetCancelHoldBooking {
  type: typeof ACTIONS.GET_HOLD_CANCEL_BOOKING;
  payload: Array<any>;
}
interface CreateTaskTicket {
  type: typeof ACTIONS.CREATE_TASK_TICKET;
  payload: Array<any>;
}
interface GetRedTechComment {
  type: typeof ACTIONS.GET_RED_TECH_COMMENT;
  payload: Array<any>;
}
interface UpdateImagingTicket {
  type: typeof ACTIONS.UPDATE_IMAGING_TICKET;
  payload: Array<any>;
}
interface SendInititalWhatsapp {
  type: typeof ACTIONS.SEND_INITIAL_WHATSAPP_MESSAGE;
  payload: Array<any>;
}
interface GetPrescriptionData {
  type: typeof ACTIONS.GET_PRESCRIPTION_DATA;
  payload: Array<any>;
}
interface GetDisplayCities {
  type: typeof ACTIONS.GET_DISPLAY_CITIES;
  payload: Array<any>;
}
interface GetMultiplepackage {
  type: typeof ACTIONS.GET_MULTIPLE_PACKAGES;
  payload: Array<any>;
}
interface GetImagingTimeSlots {
  type: typeof ACTIONS.GET_IMAGING_TIMESLOTS;
  payload: Array<any>;
}
interface GetSubCenterInformation {
  type: typeof ACTIONS.GET_SUB_CENTER_INFORMATION;
  payload: Array<any>;
}
interface GetRedCoin {
  type: typeof ACTIONS.GET_RED_COIN;
  payload: Array<any>;
}
interface GetTopCFreshLeads {
  type: typeof ACTIONS.GET_TOP_C_FRESH_LEADS;
  payload: Array<any>;
}
interface PostDispositionData {
  type: typeof ACTIONS.POST_DISPOSITION_DATA;
  payload: Array<any>;
}
interface GetAllLeads {
  type: typeof ACTIONS.GET_ALL_LEADS;
  payload: Object;
}
interface GetLatestLeads {
  type: typeof ACTIONS.GET_LATEST_LEAD;
  payload: Object;
}
interface SearchCall {
  type: typeof ACTIONS.SEARCH_CALL;
  payload: Object;
}
interface GetRecordings {
  type: typeof ACTIONS.GET_RECORDINGS;
  payload: Object;
}
interface PostCrmLeadAddress {
  type: typeof ACTIONS.POST_CRM_LEAD_ADDRESS;
  payload: Object;
}

interface GetPinCode {
  type: typeof ACTIONS.GET_PIN_CODE;
  payload: Array<any>;
}
interface UpdatedRepeatedLead {
  type: typeof ACTIONS.UPDATED_REPEATED_LIST;
  payload: Array<any>;
}
interface GetTimeSlot {
  type: typeof ACTIONS.GET_TIMESLOTS;
  payload: Array<any>;
}
interface SendNotification {
  type: typeof ACTIONS.SEND_NOTIFICATION;
  payload: Array<any>;
}
interface GetReportDownload {
  type: typeof ACTIONS.GET_REPORT_DOWNLOAD;
  payload: Array<any>;
}
interface GetRepeatedList {
  type: typeof ACTIONS.GET_REPEATED_LEAD;
  payload: Array<any>;
}
interface ChangePassword {
  type: typeof ACTIONS.CHANGE_PASSWORD;
  payload: Object;
}
interface CreateCall {
  type: typeof ACTIONS.CREATE_CALL;
  payload: Object;
}
interface AddComment {
  type: typeof ACTIONS.ADD_COMMENT;
  payload: Object;
}
interface GetLeadSource {
  type: typeof ACTIONS.GET_LEAD_SOURCE;
  payload: Object;
}
interface GetCities {
  type: typeof ACTIONS.GET_CITIES;
  payload: Array<any>;
}
interface imaginBookingDisposition {
  type: typeof ACTIONS.POST_IMAGING_BOOKING_DISPOSITION;
  payload: Array<any>;
}
interface GetNotificationType {
  type: typeof ACTIONS.GET_NOTIFICATION_TYPE;
  payload: Array<any>;
}
interface getHoldTheChat {
  type: typeof ACTIONS.HOLD_THE_CHAT;
  payload: boolean;
}

interface GetArea {
  type: typeof ACTIONS.GET_AREA;
  payload: Array<any>;
}
interface GetPhlebo {
  type: typeof ACTIONS.GET_PHLEBOS;
  payload: Array<any>;
}

interface getCrmLeadAddress {
  type: typeof ACTIONS.GET_CRM_LEAD_ADDRESS;
  payload: Array<any>;
}
interface GetZone {
  type: typeof ACTIONS.GET_ZONE;
  payload: Object;
}
interface GetAgents {
  type: typeof ACTIONS.GET_AGENTS;
  payload: Array<any>;
}
interface GetCommunicationOnWhatsapp {
  type: typeof ACTIONS.GET_SEND_LOCATION_REQUEST;
  payload: Array<any>;
}

interface SetComments {
  type: typeof ACTIONS.SET_COMMENTS;
  payload: Object;
}

interface GetPositiveCallsComments {
  type: typeof ACTIONS.GET_POSTIVE_CALLS_COMMENTS;
  payload: Object;
}

interface AddPositiveCallsComments {
  type: typeof ACTIONS.ADD_POSTIVE_CALLS_COMMENT;
  payload: Object;
}
interface GetLeadDispostion {
  type: typeof ACTIONS.GET_LEAD_DISPOSITION_LIST;
  payload: Object;
}

interface GetBookingsComments {
  type: typeof ACTIONS.GET_BOOKINGS_COMMENTS;
  payload: Object;
}

interface AddBookingsComments {
  type: typeof ACTIONS.ADD_BOOKINGS_COMMENT;
  payload: Object;
}

interface ClickToCall {
  type: typeof ACTIONS.CLICK_TO_CALL;
  payload: Array<any>;
}

interface GetPackages {
  type: typeof ACTIONS.GET_PACKAGES;
  payload: Array<any>;
}
interface GetPackagesTags {
  type: typeof ACTIONS.GET_PACKAGES_TAGS;
  payload: Array<any>;
}
interface GetPackagesCategory {
  type: typeof ACTIONS.GET_PACKAGES_CATEGORY;
  payload: Array<any>;
}
interface GetFamilyPackages {
  type: typeof ACTIONS.GET_FAMILY_PACKAGES;
  payload: Array<any>;
}

interface GetFamilyPackagesBooking {
  type: typeof ACTIONS.GET_FAMILY_PACKAGES_BOOKING;
  payload: Array<any>;
}

interface CreateFamilyPackageBooking {
  type: typeof ACTIONS.CREATE_FAMILY_PACKAGES_BOOKING;
  payload: Object;
}

interface GetPartners {
  type: typeof ACTIONS.GET_PARTNERS;
  payload: Array<any>;
}

interface GetCoupons {
  type: typeof ACTIONS.GET_COUPONS;
  payload: Array<any>;
}

interface LocationVerified {
  type: typeof ACTIONS.LOCATION_VERIFIED;
  payload: boolean;
}
interface SetLoading {
  type: typeof ACTIONS.SET_LOADING;
  payload: boolean;
}
interface SetLeadAddressLoading {
  type: typeof ACTIONS.SET_LEAD_ADDRESS_LOADER;
  payload: boolean;
}
interface SetBookingIdLoader {
  type: typeof ACTIONS.SET_BOOKING_ID_LOADER;
  payload: boolean;
}
interface SetBiomarkerLoader {
  type: typeof ACTIONS.SET_BIOMARKER_LOADER;
  payload: boolean;
}
interface SetLoadingWhatsapp {
  type: typeof ACTIONS.SET_LOADING_WHATSAPP;
  payload: boolean;
}
interface CreateBooking {
  type: typeof ACTIONS.CREATE_BOOKING;
  payload: boolean;
}
interface UpdateLeads {
  type: typeof ACTIONS.UPDATE_LEADS;
  payload: Array<any>;
}
interface CreateImagingBooking {
  type: typeof ACTIONS.CREATE_IMAGING_BOOKING;
  payload: boolean;
}
interface CreateWhatsappTemplate {
  type: typeof ACTIONS.CREATE_WHATSAPP_TEMPLATE;
  payload: boolean;
}

interface sendLastReportToLeadId {
  type: typeof ACTIONS.SEND_LAST_REPORT_TO_LEAD;
  payload: boolean;
}

interface UpdateBooking {
  type: typeof ACTIONS.UPDATE_BOOKING;
  payload: boolean;
}

interface CreateImagingBookingCustom {
  type: typeof ACTIONS.CREATE_IMAGING_BOOKING_CUSTOM;
  payload: boolean;
}

interface GetBookings {
  type: typeof ACTIONS.GET_BOOKING_LIST;
  payload: boolean;
}
interface GetBookingsLoader {
  type: typeof ACTIONS.SET_ALL_BOOKING_LOADING;
  payload: boolean;
}

interface GetBookingById {
  type: typeof ACTIONS.GET_BOOKING_BY_ID;
  payload: boolean;
}

interface GetLeads {
  type: typeof ACTIONS.GET_LEADS;
  payload: boolean;
}

interface GetLeadDetails {
  type: typeof ACTIONS.GET_LEAD_DETAILS;
  payload: boolean;
}

interface GetLeadById {
  type: typeof ACTIONS.GET_LEAD_BY_ID;
  payload: boolean;
}
interface GetLtvLead {
  type: typeof ACTIONS.GET_LTV_LEAD;
  payload: boolean;
}
interface ResetLeadDetails {
  type: typeof ACTIONS.LEAD_DETAILS_RESET;
  payload: boolean;
}

interface ResetBooking {
  type: typeof ACTIONS.BOOKING_RESET;
  payload: boolean;
}

interface GetWhatsappComments {
  type: typeof ACTIONS.GET_WHATSAPP_COMMENTS;
  payload: boolean;
}

interface GetWhatsappCommentsByLead {
  type: typeof ACTIONS.GET_WHATSAPP_COMMENTS_BY_LEAD;
  payload: boolean;
}

interface SendWhatsappComments {
  type: typeof ACTIONS.SEND_WHATSAPP_COMMENTS;
  payload: boolean;
}
interface SentReportById {
  type: typeof ACTIONS.SENT_REPORT_DATA_BY_ID;
  payload: boolean;
}

interface GetCallStats {
  type: typeof ACTIONS.GET_CALL_STATS;
  payload: boolean;
}

interface RaiseComplaint {
  type: typeof ACTIONS.RAISE_COMPLAINT;
  payload: boolean;
}

interface GetQueryTickets {
  type: typeof ACTIONS.GET_COMPLAINTS;
  payload: boolean;
}

interface AssignDoctor {
  type: typeof ACTIONS.ASSIGN_DOCTOR;
  payload: boolean;
}

interface SendNewFeedback {
  type: typeof ACTIONS.SEND_FEEDBACK;
  payload: boolean;
}

interface GetAvailabeSlots {
  type: typeof ACTIONS.GET_AVAILABLE_SLOTS;
  payload: Object;
}
interface CreateAdditionalBooking {
  type: typeof ACTIONS.CREATE_ADDITIONAL_BOOKING;
  payload: Object;
}
interface UpdateAdditionalBooking {
  type: typeof ACTIONS.UPDATE_ADDITIONAL_BOOKING;
  payload: Object;
}
interface GetSyncData {
  type: typeof ACTIONS.GET_SYNC_DATA;
  payload: Object;
}
interface SameNumberBooking {
  type: typeof ACTIONS.SAME_NUMBER_BOOKING;
  payload: Object;
}
interface GetBreakResponse {
  type: typeof ACTIONS.GET_BREAK_RESPONSE;
  payload: Object;
}
interface GetPanelBreak {
  type: typeof ACTIONS.GET_PANEL_BREAK;
  payload: Object;
}
interface GetPaymentSendLink {
  type: typeof ACTIONS.GET_PAYMENT_SEND_LINK;
  payload: Object;
}
interface GetPaymentReSendLink {
  type: typeof ACTIONS.GET_PAYMENT_RESEND_LINK;
  payload: Object;
}
interface GetPaymentCancelLink {
  type: typeof ACTIONS.GET_PAYMENT_CANCEL_LINK;
  payload: Object;
}
interface GetSmsDetails {
  type: typeof ACTIONS.GET_SMS_DETAILS;
  payload: Array<any>;
}
interface GetEmailDetails {
  type: typeof ACTIONS.GET_EMAIL_DETAILS;
  payload: Array<any>;
}
interface GetSmsReportSend {
  type: typeof ACTIONS.GET_SMS_REPORT_SEND;
  payload: Object;
}
interface GetCenterInfo {
  type: typeof ACTIONS.GET_CENTER_INFORMATION;
  payload: Array<any>;
}
interface SetLoadingIndividualChat {
  type: typeof ACTIONS.SET_LOADING_INDIVIDUAL_CHAT;
  payload: boolean;
}
interface SendSmsLink {
  type: typeof ACTIONS.GET_SMS_LINK;
  payload: Object;
}
interface GetNotificationMessage {
  type: typeof ACTIONS.GET_NOTIFICATION_MESSAGE;
  payload: Array<any>;
}
interface GetReportStatus {
  type: typeof ACTIONS.GET_REPORT_STATUS;
  payload: Array<any>;
}
interface CheckGeoFenceArea {
  type: typeof ACTIONS.CHECK_GEOFENCE_AREA;
  payload: Array<any>;
}
interface CheckGeoFenceAreaMapMyIndia {
  type: typeof ACTIONS.CHECK_GEOFENCE_AREA_MAMPMYINDIA;
  payload: Array<any>;
}
interface GetMapMyIndiaAccessToken {
  type: typeof ACTIONS.GET_MAMPMYINDIA_ACCESS_TOKEN;
  payload: Array<any>;
}
interface GetAddressDetailsLatLong {
  type: typeof ACTIONS.GET_ADDRESS_DETAILS_LAT_LONG;
  payload: Array<any>;
}
interface GetWhatsappMessageStatus {
  type: typeof ACTIONS.GET_WHATSAPP_MESSAGE_STATUS;
  payload: Array<any>;
}

interface GetUnmaskedNumber {
  type: typeof ACTIONS.GET_UNMASKED_NUMBER;
  payload: Array<any>;
}
interface GetLabDetails {
  type: typeof ACTIONS.GET_LAB_DETAILS;
  payload: Array<any>;
}
interface GetUserDetails {
  type: typeof ACTIONS.GET_USER_DETAILS;
  payload: Object;
}
interface GetFireBaseNotification {
  type: typeof ACTIONS.GET_FIREBASE_NOTIFICATION;
  payload: Object;
}
interface GetUsersData {
  type: typeof ACTIONS.GET_USER_DATA;
  payload: Object;
}
interface GetTodayMissedCalls {
  type: typeof ACTIONS.GET_TODAYS_MISSED_CALLS;
  payload: Object;
}
interface GetRepetedLead {
  type: typeof ACTIONS.GET_REPEATED_LEADS;
  payload: Array<any>;
}
interface PostDispositionData {
  type: typeof ACTIONS.POST_DISPOSITION_DATA;
  payload: Array<any>;
}
interface GetLeadStatus {
  type: typeof ACTIONS.GET_LEAD_STATUS;
  payload: Object;
}
interface SendLocationRequest {
  type: typeof ACTIONS.SEND_LOCATION_REQUEST;
  payload: Object;
}
interface getDoctorInfoById {
  type: typeof ACTIONS.GET_DOCTOR_INFO_BY_ID;
  payload: object;
}
interface GetImagingTicketCount {
  type: typeof ACTIONS.GET_IMAGING_TICKET_COUNT;
  payload: Object;
}
interface GetImagingTicket {
  type: typeof ACTIONS.GET_IMAGING_TICKET;
  payload: Object;
}
interface GetImagingRaiseTicket {
  type: typeof ACTIONS.GET_IMAGING_RAISE_TICKET;
  payload: Object;
}

interface GetNewBookings {
  type: typeof ACTIONS.GET_NEW_BOOKING_LIST;
  payload: boolean;
}
interface GetTicketData {
  type: typeof ACTIONS.GET_TICKET_DATA;
  payload: Object;
}
interface GetTicketCategory {
  type: typeof ACTIONS.GET_TICKET_CATEGORIES;
  payload: Object;
}
interface GetTicketSubCategories {
  type: typeof ACTIONS.GET_TICEKT_SUB_CATEGORIES;
  payload: Object;
}
interface PostRedTechComment {
  type: typeof ACTIONS.POST_RED_TECH_COMMENT;
  payload: Object;
}

interface GetQualityScoringReport {
  type: typeof ACTIONS.GET_QUALITY_SCORING_REPORT;
  payload: Array<any>;
}

interface GetQualityScoringReportForBackup {
  type: typeof ACTIONS.GET_QUALITY_SCORING_REPORT_FOR_BACKUP;
  payload: Array<any>;
}

interface GetBookingQueryTickets {
  type: typeof ACTIONS.GET_BOOKING_COMPLAINTS;
  payload: boolean;
}
interface updateActionDisposition {
  type: typeof ACTIONS.UPDATE_DISPOSITION;
  payload: Array<any>;
}
interface UpdateTicketStatus {
  type: typeof ACTIONS.UPDATE_TICKET_STATUS;
  payload: Array<any>;
}
interface GetTicketComments {
  type: typeof ACTIONS.GET_TICKET_COMMENTS;
  payload: Array<any>;
}
interface SetQualityScoreLoader {
  type: typeof ACTIONS.SET_QUALITYSCORE_LOADING;
  payload: Array<any>;
}
interface InitiateRefund {
  type: typeof ACTIONS.INITIATE_REFUND;
  payload: Array<any>;
}
interface SetQualityScoreBackupLoader {
  type: typeof ACTIONS.SET_QUALITYSCOREBACKUP_LOADING;
  payload: Array<any>;
}
interface GetWhatsappTemplate {
  type: typeof ACTIONS.GET_WHATSAPP_TEMPLATE;
  payload: Array<any>;
}
interface BiomarkerReset {
  type: typeof ACTIONS.BIOMARKER_RESET;
  payload: boolean;
}
interface GetBiomarkerScreening {
  type: typeof ACTIONS.GET_BIOMARKER_SCREENING;
  payload: Object;
}
interface GetAdditionalMember {
  type: typeof ACTIONS.GET_ADDITIONAL_MEMBER;
  payload: Array<any>;
}
interface GetBiomarkerUltasound {
  type: typeof ACTIONS.GET_BIOMARKER_ULTASOUND;
  payload: Object;
}
interface GetLeadSubscriptionsData {
  type: typeof ACTIONS.GET_LEAD_SUBSCRIPTIONS_DATA;
  payload: Array<any>;
}
interface GetIndividualWhatsappChat {
  type: typeof ACTIONS.GET_INDIVIDUAL_WHATSAPP_CHAT;
  payload: Array<any>;
}
interface GetFailedReportData {
  type: typeof ACTIONS.GET_FAILED_REPORT_DATA;
  payload: Array<any>;
}
interface latestBookingUsingLead {
  type: typeof ACTIONS.LATEST_BOOKING_USING_LEAD;
  payload: boolean;
}
interface queueNumber {
  type: typeof ACTIONS.QUEUE_NUMBER;
  payload: boolean;
}
interface GetReportSentManual {
  type: typeof ACTIONS.GET_REPORT_SEND_MANUAL;
  payload: Object;
}
interface SignOutInWhatsappChat {
  type: typeof ACTIONS.SIGN_OUT_IN_WHATSAPP_CHAT;
  payload: boolean;
}
interface SendReportById {
  type: typeof ACTIONS.SEND_REPORT_BY_ID;
  payload: Object;
}
interface GetFailedReportBYId {
  type: typeof ACTIONS.GET_FAILED_REPORT_DATA_BY_ID;
  payload: Object;
}
interface SwitchChatAgentInWhatsappChat {
  type: typeof ACTIONS.SWITCH_CHAT_AGENT_IN_WHATSAPP_CHAT;
  payload: boolean;
}
interface GetDashboardPrescription {
  type: typeof ACTIONS.GET_DASHBOARD_PRESCRIPTION;
  payload: any;
}

interface SetPrescriptionData {
  type: typeof ACTIONS.SET_PRESCRIPTION_DATA;
  payload: any;
}

interface UpdatePrescriptionDisposition {
  type: typeof ACTIONS.UPDATE_PRESCRIPTION_DISPOSITION;
  payload: any;
}

interface StartWhatsappChat {
  type: typeof ACTIONS.START_WHATSAPP_CHAT;
  payload: any;
}
interface AddressJsonPost {
  type: typeof ACTIONS.ADDRESS_JSON_POST;
  payload: any;
}
interface GetNewLeadDetails {
  type: typeof ACTIONS.GET_NEW_LEAD_DETAILS;
  payload: any;
}
interface RaiseNewTicektDisposition {
  type: typeof ACTIONS.RAISE_NEW_RAISE_TICEKT_DISPOSITION;
  payload: Array<any>;
}
interface GetLeadPrescriptionData {
  type: typeof ACTIONS.GET_LEAD_PRESCRIPTION_DATA;
  payload: Array<any>;
}
interface GetPackageAutoSelected {
  type: typeof ACTIONS.GET_PACKAGES_AUTO_SELECTED;
  payload: Array<any>;
}
interface SetPrescriptionLoader {
  type: typeof ACTIONS.SET_LEAD_PRESCRIPTION_LOADING;
  payload: boolean;
}
interface SetAutoSelected {
  type: typeof ACTIONS.SET_AUTOSELECTED_LOADING;
  payload: boolean;
}
interface GetPackageAutoSelected {
  type: typeof ACTIONS.GET_PACKAGES_AUTO_SELECTED;
  payload: Array<any>;
}
interface SetHelthDataLoader {
  type: typeof ACTIONS.SET_HELTHDATA_LOADING;
  payload: boolean;
}

interface GetReportData {
  type: typeof ACTIONS.GET_REPORT_DATA;
  payload: Array<any>;
}
interface GetParameterCount {
  type: typeof ACTIONS.GET_PARAMETER_COUNT;
  payload: Array<any>;
}
interface GetHealthTestParameter {
  type: typeof ACTIONS.GET_HEALTH_TEST_PARAMETER;
  payload: Array<any>;
}
interface AddRemarkOnCall {
  type: typeof ACTIONS.ADD_REMARK_ON_CALL;
  payload: Object;
}
interface alotToPhaBooking {
  type: typeof ACTIONS.ALOT_TO_PHA_BOOKING;
  payload: boolean;
}
interface ClickToCallLead {
  type: typeof ACTIONS.CLICK_TO_CALL_LEAD;
  payload: Array<any>;
}
interface GetAdditionalBookingsTatTime {
  type: typeof ACTIONS.GET_ADDITIONAL_BOOKING_LIST_WITH_TAT_TIME;
  payload: boolean;
}
interface GetBookingsTatTime {
  type: typeof ACTIONS.GET_BOOKING_LIST_WITH_TAT_TIME;
  payload: boolean;
}

interface GetAgentNewData {
  type: typeof ACTIONS.GET_AGENTS_NEW_DATA;
  payload: Array<any>;
}
interface getAllBookingsExports {
  type: typeof ACTIONS.GET_ALL_BOOKING_EXPORTS;
  payload: Array<any>;
}
interface GetVerificationBookings {
  type: typeof ACTIONS.GET_BOOKING_VERIFICATION_LIST;
  payload: boolean;
}
interface GetBookingJourney {
  type: typeof ACTIONS.GET_BOOKING_JOURNEY_DETAILS;
  payload: Array<any>;
}
interface GetBookingOverview {
  type: typeof ACTIONS.GET_BOOKING_OVERVIEW;
  payload: Array<any>;
}
interface getCCPackage {
  type: typeof ACTIONS.GET_CC_PACKAGES;
  payload: Array<any>;
}
interface GetCentre {
  type: typeof ACTIONS.GET_CENTER;
  payload: Array<any>;
}
interface GetLabCredential {
  type: typeof ACTIONS.GET_LAB_CREDENTIAL;
  payload: Array<any>;
}
interface GetPaymentInfro {
  type: typeof ACTIONS.GET_PAYMENT_INFORMATION;
  payload: Array<any>;
}

interface GetCollectionSlot {
  type: typeof ACTIONS.GET_COLLECTION_SLOT;
  payload: Array<any>;
}
interface GetVerifiedByList {
  type: typeof ACTIONS.GET_VERIFIED_BY_LIST;
  payload: Object;
}
interface GetPhleboTimeline {
  type: typeof ACTIONS.GET_PHLEBO_TIMELINE;
  payload: Object;
}
interface GetDispositions {
  type: typeof ACTIONS.GET_DISPOSITIONS;
  payload: Array<any>;
}
interface GetHotleads {
  type: typeof ACTIONS.GET_HOTLEADS;
  payload: Array<any>;
}
interface GetPanelUserList {
  type: typeof ACTIONS.GET_PANEL_USER_LIST_DATA;
  payload: Array<any>;
}
interface GetPanelUserDataTeamLead {
  type: typeof ACTIONS.GET_PANEL_USER_LIST_DATA_TEAM_LEAD;
  payload: Array<any>;
}
interface GetPerformanceData {
  type: typeof ACTIONS.GET_PERFORMANCE_DATA;
  payload: boolean;
}
interface GetPerformanceLoading {
  type: typeof ACTIONS.SET_PERFORMANCE_LOADING;
  payload: boolean;
}
interface GetPaymentRefundList {
  type: typeof ACTIONS.GET_PAYMENT_REFUND_LIST;
  payload: Array<any>;
}
interface GetPaymentRefundLoader {
  type: typeof ACTIONS.SET_REFUND_LOADING;
  payload: Array<any>;
}
interface GetTicketCommentsForDocuments {
  type: typeof ACTIONS.GET_TICKET_COMMENTS_FOR_DOCUMENTS;
  payload: Array<any>;
}
interface GetVerificationBookingDetails {
  type: typeof ACTIONS.GET_VERIFICATION_BOOKING_DETAILS;
  payload: Array<any>;
}
interface GetViews {
  type: typeof ACTIONS.GET_SF_VIEWS;
  payload: Array<any>;
}
interface GetSfViewsDropdown {
  type: typeof ACTIONS.GET_SF_VIEWS_DROPDOWN;
  payload: any;
}
interface LeadTransferByCouma {
  type: typeof ACTIONS.LEAD_TRANSFER_BY_COMMA;
  payload: Array<any>;
}
interface PostAddRemoveTest {
  type: typeof ACTIONS.POST_ADD_REMOVE_TEST;
  payload: Array<any>;
}
interface PostAddRemoveTest {
  type: typeof ACTIONS.POST_ADD_REMOVE_TEST;
  payload: Array<any>;
}
interface UpdateHotleads {
  type: typeof ACTIONS.UPDATE_HOTLEADS;
  payload: Array<any>;
}interface UpdateInitiateRefund {
  type: typeof ACTIONS.UPDATE_INITIATED_REFUND;
  payload: Array<any>;
}
interface GetBookingCall {
  type: typeof ACTIONS.GET_BOOKING_CALLS;
  payload: Array<any>;
}
interface GetPackagesData {
  type: typeof ACTIONS.GET_PACKAGES_DATA;
  payload: Array<any>;
}

export type BookingTypes =
  | ChangePassword
  | SearchCall
  | GetPinCode
  | ChangePassword
  | GetCities
  | GetArea
  | GetAgents
  | GetPhlebo
  | GetPackages
  | GetFamilyPackages
  | GetFamilyPackagesBooking
  | CreateFamilyPackageBooking
  | CreateBooking
  | UpdateBooking
  | CreateAdditionalBooking
  | UpdateAdditionalBooking
  | GetRecordings
  | GetPartners
  | GetCoupons
  | LocationVerified
  | GetBookings
  | GetBookingById
  | SetLoading
  | GetLeadDetails
  | GetLeads
  | GetLeadById
  | ResetLeadDetails
  | ResetBooking
  | ClickToCall
  | GetPositiveCallsComments
  | AddPositiveCallsComments
  | SetComments
  | AddComment
  | GetLeadSource
  | GetBookingsComments
  | AddBookingsComments
  | GetWhatsappComments
  | GetWhatsappCommentsByLead
  | SendWhatsappComments
  | GetCallStats
  | RaiseComplaint
  | GetQueryTickets
  | AssignDoctor
  | SendNewFeedback
  | GetAvailabeSlots
  | GetAllLeads
  | GetLatestLeads
  | CreateCall
  | GetSyncData
  | SameNumberBooking
  | GetBreakResponse
  | GetPanelBreak
  | GetPaymentSendLink
  | GetPaymentReSendLink
  | GetLtvLead
  | GetPaymentCancelLink
  | GetSmsDetails
  | GetEmailDetails
  | GetSmsReportSend
  | GetCenterInfo
  | SendSmsLink
  | GetNotificationMessage
  | GetReportStatus
  | GetUnmaskedNumber
  | GetUserDetails
  | GetFireBaseNotification
  | GetLabDetails
  | GetZone
  | GetUsersData
  | GetLeadDispostion
  | GetNotificationType
  | CheckGeoFenceArea
  | CheckGeoFenceAreaMapMyIndia
  | GetMapMyIndiaAccessToken
  | GetTodayMissedCalls
  | GetRepeatedList
  | GetReportDownload
  | GetRepetedLead
  | UpdatedRepeatedLead
  | SendNotification
  | GetTimeSlot
  | CreateImagingBooking
  | CreateWhatsappTemplate
  | GetTopRcFreshLeads
  | GetTopCFreshLeads
  | GetRedCoin
  | GetSubCenterInformation
  | GetImagingTimeSlots
  | GetMultiplepackage
  | GetDisplayCities
  | GetLeadStatus
  | GetPrescriptionData
  | getDoctorInfoById
  | imaginBookingDisposition
  | GetImagingTicket
  | GetUserStats
  | SendInititalWhatsapp
  | GetImagingRaiseTicket
  | GetImagingTicketCount
  | CreateImagingBookingCustom
  | GetImagingTicketCount
  | UpdateImagingTicket
  | GetTicketData
  | GetTicketCategory
  | GetTicketSubCategories
  | CreateTaskTicket
  | GetRedTechComment
  | PostRedTechComment
  | GetCancelHoldBooking
  | GetQualityScoringReport
  | SetLoadingWhatsapp
  | GetQualityScoringReportForBackup
  | GetCeCenterForCc
  | CreatePilotBooking
  | GeoLoading
  | CheckGeoCheckError
  | GetBookingQueryTickets
  | updateActionDisposition
  | UpdateTicketStatus
  | GetTicketComments
  | GetBookingsLoader
  | SetQualityScoreLoader
  | SetQualityScoreBackupLoader
  | SetCsLoading
  | CheckGeoFenceAreaMapMyIndiaForCustomer
  | GeoLoadingForCustomer
  | CheckGeoCheckErrorForCustomer
  | GetWhatsappTemplate
  | BiomarkerReset
  | GetBiomarkerScreening
  | GetAdditionalMember
  | GetBiomarkerUltasound
  | GetNewBookings
  | GetCommunicationOnWhatsapp
  | SendLocationRequest
  | getCrmLeadAddress
  | PostCrmLeadAddress
  | GetPackagesTags
  | InitiateRefund
  | GetPackagesCategory
  | GetAddressDetailsLatLong
  | SetLeadAddressLoading
  | SetBookingIdLoader
  | GetLeadDispostion
  | SetBiomarkerLoader
  | GetWhatsappMessageStatus
  | PostDispositionData
  | InitiateRefund
  | GetReferralCurrentBalance
  | GetCouponReferralValidation
  | GetLeadSubscriptionsData
  | SetLoadingIndividualChat
  | GetFailedReportData
  | latestBookingUsingLead
  | queueNumber
  | sendLastReportToLeadId
  | SignOutInWhatsappChat
  | UpdateLeads
  | SendReportById
  | GetIndividualWhatsappChat
  | GetReportSentManual
  | GetFailedReportBYId
  | SentReportById
  | getHoldTheChat
  | SwitchChatAgentInWhatsappChat
  | allRedtechTicketsUsingLead
  | GetDashboardPrescription
  | SetPrescriptionData
  | UpdatePrescriptionDisposition
  | StartWhatsappChat
  | GetNewLeadDetails
  | RaiseNewTicektDisposition
  | GetLeadPrescriptionData
  | SetPrescriptionLoader
  | CheckGeoCheckLatitude
  | CheckGeoCheckLongitude
  | AddressJsonPost
  | CheckGeoCheckCityId
  | SetAutoSelected
  | GetPackageAutoSelected
  | CheckGeoCheckLongitude
  | CheckGeoCheckZoneId
  | CheckGeoCheckLatitude
  | SetHelthDataLoader
  | GetReportData
  | GetParameterCount
  | GetHealthTestParameter
  | AddRemarkOnCall
  | alotToPhaBooking
  | ClickToCallLead
  | GetAdditionalBookingsTatTime
  | GetBookingsTatTime
  | GetAgentNewData
  | getAllBookingsExports
  | GetVerificationBookings
  | GetBookingJourney
  | GetBookingOverview
  | getCCPackage
  | GetCentre
  | GetLabCredential
  | GetPaymentInfro
  | GetCollectionSlot
  | GetVerifiedByList
  | GetPhleboTimeline
  | GetDispositions
  | GetHotleads
  | GetPanelUserList
  | GetPanelUserDataTeamLead
  | GetPerformanceData
  | GetPerformanceLoading
  | GetPaymentRefundList
  | GetPaymentRefundLoader
  | GetTicketCommentsForDocuments
  | GetVerificationBookingDetails
  | GetViews
  | GetSfViewsDropdown
  | LeadTransferByCouma
  | PostAddRemoveTest
  | PostAddRemoveTest
  | UpdateHotleads
  | UpdateInitiateRefund
  | GetBookingCall
  | GetPackagesData
  | GetGrievance
  | GetGrievanceData
  | UpdateRecordingDisposition

