import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from '@mui/material/Modal';
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { TableContainer, Table, Chip, TableHead, TableBody, TableRow, TableCell, Paper, TextField, Grid, Select, MenuItem } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";
let width = window.innerWidth;
let isMobile = width <= 500;


const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        marginTop: "2rem",
        padding: "0.5rem 2rem",
        width: "100%",
    },
    table: {
        maxWidth: "100%",
        margin: "auto"
    },
    head: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "2rem",
    },
}));

type ModalProps = {
    additionalMemebr: any;
    additionalModal: any;
    setAdditonalModal: any;
    loading: any;
};

const CreatePhlebo: React.FC<ModalProps> = ({
    additionalMemebr,
    additionalModal,
    setAdditonalModal,
    loading,
}) => {
    const classes = useStyles();
    const timer = useRef<any>(0)

    const [phleboName, setPhleboName] = useState<string>("");
    const [phleboNumber, setPhleboNumber] = useState<string>("");
    const [employeId, setEmployeId] = useState<string>("");
    const [id, setId] = useState<any>(0);
    const [phleboSource, setPhleboSource] = useState<string>("none");
    const [zoneName, setZoneName] = useState<string>("none");
    const handleClose = () => {
        setAdditonalModal(false);
        setId(id + 1);
    };

    //   const CreateNewPhlebo = async () => {
    //     const body: any = {
    //       fullname: phleboName,
    //       phonenumber: phleboNumber,
    //       empid: employeId,
    //       u_type: phleboSource,
    //       zone: zoneName,
    //     };
    //     if (phleboName.length > 50) {
    //       alert("Phlebo Name Can't more then 50");
    //     } else if (phleboNumber.length !== 10) {
    //       alert("Enter Correct Number");
    //     } else {
    //       await createNewPhlebo(body);
    //       setPhleboName("");
    //       setPhleboNumber("");
    //       handleClose();
    //     }
    //   };
    
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={additionalModal}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                key={id}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={additionalModal}>
                    <div className={classes.paper}>
                        <Paper elevation={15} className={classes.paper}>
                            <div className={classes.head}>

                                <h4
                                    style={{
                                        marginBottom: "1rem",
                                    }}
                                    id="transition-modal-title"
                                >
                                    ADDITIONAL FAMILY MEMBER TAT TIME
                                </h4>
                                <CloseIcon onClick={() => setAdditonalModal(false)} />
                            </div>

                            <TableContainer className={classes.table} component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Booking ID</TableCell>
                                            <TableCell align="center">NAME</TableCell>
                                            <TableCell align="center">GENDER</TableCell>
                                            <TableCell align="center">AGE</TableCell>
                                            <TableCell align="center">PICKUP STATUS</TableCell>
                                            <TableCell align="center">Alloted Time</TableCell>
                                            <TableCell align="center">In_TaT</TableCell>
                                            <TableCell align="center">pickup Datetime</TableCell>
                                            <TableCell align="center">Report Consolidated Time</TableCell>
                                            <TableCell align="center">time Difference</TableCell>
                                            <TableCell align="center">Total Time From Pickup</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {additionalMemebr && additionalMemebr.map((data: any, index: any) => {
                                           
                                            return (
                                                <TableRow key={index}>
                                                    <TableCell align="center">{data?.pk || "N/A"}</TableCell>
                                                    <TableCell align="center">{data?.customer_name || "N/A"}</TableCell>
                                                    <TableCell align="center">{data?.customer_gender || "N/A"}</TableCell>
                                                    <TableCell align="center">{data?.customer_age || "N/A"}</TableCell>
                                                    <TableCell align="center">{data?.pickup_status || "N/A"}</TableCell>
                                                    <TableCell align="center">
                                                        {data.intatouttat?.allotted_time.substring(0, 10)}
                                                        {data.intatouttat?.allotted_time.substring(11, 19)}
                                                    </TableCell>
                                                    <TableCell align="center">{data.intatouttat?.intat === true ? "Deliver at TaT" : data.intatouttat?.intat === false ? "Out of TaT" : "Report Is not ready"}</TableCell>                                                <TableCell align="center">
                                                        {data.intatouttat?.pickup_datetime.substring(0, 10)}
                                                        <br />
                                                        {data.intatouttat?.pickup_datetime.substring(11, 19)}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {data.intatouttat?.report_consolidated_time === null ? "" : data.intatouttat?.report_consolidated_time.substring(0, 10)}
                                                        <br />
                                                        {data.intatouttat?.report_consolidated_time === null ? "" : data.intatouttat?.report_consolidated_time.substring(11, 19)}
                                                    </TableCell>
                                                    <TableCell align="center">{Math.ceil(data.intatouttat?.timedifference)}</TableCell>
                                                    <TableCell align="center">{Math.ceil(data.intatouttat?.total_time_from_pickup)}</TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
};

const mapStateToProps = (state: any) => ({

});

export default connect(mapStateToProps, {
})(CreatePhlebo);
