import Auth from '../services/Auth';
import SecureStorage from '../config/SecureStorage'
import snackBarUpdate from '../actions/snackBarActions';
import { ACTIONS } from '../interfaces/actionTypes/loginTypes';
import AXIOS from '../config/Axios';
import Prefix from '../config/ApiPrefix';

export const login = (body: object) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true })
  try {
    const {
      data,
      status
    } = await Auth.login({ user: body });
    let authResponse: any = [];
    if (status === 200 || status === 201) {
      authResponse = {
        data,
        status
      };
      const user = data.user.username;
      const passwordStatus=data.user.password_change
      
      const token = data.user.token;
      const accessToken = data.user.access;
      const refreshToken = data.user.refresh;
      SecureStorage.setItem('token', token);
      SecureStorage.setItem('accessToken', accessToken);
      SecureStorage.setItem('refreshToken', refreshToken);
      localStorage.setItem("tokeninlocal", token);
      SecureStorage.setItem('username', user);
      dispatch({type: ACTIONS.GET_USER, payload: passwordStatus })
      dispatch({ type: ACTIONS.SET_USER, payload: user })
      dispatch({ type: ACTIONS.SET_LOADING, payload: false })
    }
    return authResponse;
  } catch (err:any) {
    let title = ''
    if (err.response) {
      const { status, data: { errors: { error } } } = err.response
      if (status === 400) {
        title = error
      }
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: 'error',
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false })
    throw err;
  }
};
export const getFirebaseNotification = (body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.post(`${Prefix.api}/notification/token_save/`,
            body,
            { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
        );
        dispatch({ type: ACTIONS.GET_FIREBASE_NOTIFICATION, payload: data });
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong!",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};
export const resetPasswordReset = () => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.RESET_PASSWORD, payload: false });
  dispatch({ type: ACTIONS.SEND_OTP, payload: false });
}

export const resetPassword = (body: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.post(`${Prefix.api}/auth/password_reset/confirm/`,
      body,
    );
    dispatch({ type: ACTIONS.RESET_PASSWORD, payload: data });
    snackBarUpdate({
      payload: {
        message: "Password Updated",
        status: true,
        type: "success",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
  } catch (err:any) {
    let title = "";
    if (err?.response?.status === 404) {
      title = "Invalid OTP!"
    } else if (err?.response) {
      title = JSON.stringify(err.response.data?.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong!",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getUserDetails = () => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.get(`${Prefix.api}/auth/shortprofile/`, {
            headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        });
        dispatch({ type: ACTIONS.GET_USER_DETAILS, payload: data });
    } catch (err: any) {
        let title = "";
        if (err.response) {
          title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong!",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.GET_USER_DETAILS, payload: [] });
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};
export const panelUserLogout = () => async (dispatch: Function) => {

  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.post(`${Prefix.api}/auth/users/paneluser/logout/`,
    {},
    { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.PANELUSER_LOGOUT, payload: data });
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
  } catch (err:any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong!",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const sendOtp = (body: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING_OTP, payload: true });
  try {
    const { data } = await AXIOS.post(`${Prefix.api}/auth/password_reset/`,
      body,
    );
    dispatch({ type: ACTIONS.SEND_OTP, payload: data });
    dispatch({ type: ACTIONS.SET_LOADING_OTP, payload: false });
  } catch (err:any) {
    let title = "";
        if (err.response.data?.detail) {
          title = JSON.stringify(err.response.data?.detail).replace(
            /"|{|}|[|]/g,
            ""
          );
        } else if (err.response.data?.errors) {
          title = JSON.stringify(err.response.data?.errors).replace(
            /"|{|}|[|]/g,
            ""
          );
        } else {
          title = "Something went wrong!";
        }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong!",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING_OTP, payload: false });
    throw err;
  }
};

export const logout = () => ({ type: ACTIONS.LOGOUT })

// export const checkUser = () => async (dispatch: Function) => {
//     try {
//         const data = await Auth.checkLogin();
//         let checkUserLoginResponse;
//         if (Object.keys(data.user).length > 0) {
//             checkUserLoginResponse = data;
//             dispatch({ type: ACTIONS.SET_USER, payload: data })
//         }
//         return checkUserLoginResponse;
//     } catch (error) {
//         return error;
//     }
// };

export const checkUser = () => async (dispatch: Function) => {
  try {
    const data = await Auth.checkLogin();
    let checkUserLoginResponse;

    if (Object.keys(data.user).length > 0) {
      checkUserLoginResponse = data;

      dispatch({ type: ACTIONS.SET_USER, payload: data });
    }
    return checkUserLoginResponse;
  } catch (error) {
    return error;
  }
};

export const setProfile = () => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await Auth.setProfile();
    dispatch({ type: ACTIONS.SET_PROFILE, payload: data });
  } catch (error) {
   
  }
};
export const getBreakResponse = (url: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/panel/data/${url ? `?${url}` : ""}`,

      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_BREAK_RESPONSE, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getAddresstypeSeating =
  (url: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(`${Prefix.api}/panel/settings/`, {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      });
      dispatch({ type: ACTIONS.GET_ADDRESS_TYPE_SEATING, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

  export const locationDataFromPusherAPI = (data: any) => async (dispatch: Function) => {
      dispatch({ type: ACTIONS.LOCATION_DATA_FROM_PUSHER, payload: data });
  };

  export const getAgentsNotesData = (uuid: any) => async (dispatch: Function) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/auth/notes/${uuid ? `${uuid}/` : ""}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.GET_AGENTS_NOTES_DATA,
        payload: data,
      });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };

  export const changeAgentsNotesData = (uuid: any, notes: any) => async (dispatch: Function) => {
    // dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.put(
        `${Prefix.api}/auth/notes/${uuid ? `${uuid}/` : ""}`,
        { "notes": notes},
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.CHANGE_AGENTS_NOTES_DATA,
        payload: data,
      });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
    // dispatch({ type: ACTIONS.SET_LOADING, payload: false });
  };

  export const postLeadChatUpdate = () => async (dispatch: Function) => {
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/cron/lead-chat-update/61936e0c-f670-11eb-a655-0a2e993da164/`,
        {},
        { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
      );
      dispatch({ type: ACTIONS.CRON_LEAD_CHAT_UPDATE, payload: data });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title,
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };

  export const getChatAnalyticsAgent = (url: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/analytics/chat-analytics-agent/${url ? `?${url}` : ""}`,
  
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_CHAT_ANALYTICS_AGENT, payload: data });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
  export const getChatAnalyticsChat = (url: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING_DETAILS, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/analytics/chat-analytics-chat/${url ? `?${url}` : ""}`,
  
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_CHAT_ANALYTICS_CHAT, payload: data });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING_DETAILS, payload: false });
      throw err;
    }
  };
  export const getChatAnalyticsReports = (url: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/analytics/chat-analytics-reports/${url ? `?${url}` : ""}`,
  
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_CHAT_ANALYTICS_REPORTS, payload: data });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
  
  export const getChatAnalyticsHourlyReports = (url: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/analytics/chat-analytics-hourly-reports/${url ? `?${url}` : ""}`,
  
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_CHAT_ANALYTICS_HOURLY_REPORTS, payload: data });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
  
  export const postCreateModule = (body: any) => async (dispatch: Function) => {
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/panel/assignment-module-master/`,
        body,
        { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
      );
      snackBarUpdate({
          payload: {
              message: "Module created successfully!",
              status: true,
              type: "success",
          },
      })(dispatch);
      dispatch({ type: ACTIONS.POST_CREATE_MODULE, payload: data });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title,
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };

  export const putEditModule = (id: any, body: any) => async (dispatch: Function) => {
    try {
      const { data } = await AXIOS.put(
        `${Prefix.api}/panel/assignment-module-master/${id}/`,
        body,
        { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
      );
      snackBarUpdate({
          payload: {
              message: "Module edited successfully!",
              status: true,
              type: "success",
          },
      })(dispatch);
      dispatch({ type: ACTIONS.POST_CREATE_MODULE, payload: data });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title,
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };

  export const getCreateModule = (url: any) => async (dispatch: Function) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/panel/assignment-module-master/${url ? `?${url}` : ""}`,
        { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
      );
      // snackBarUpdate({
      //     payload: {
      //         message: "Module created successfully!",
      //         status: true,
      //         type: "success",
      //     },
      // })(dispatch);
      dispatch({ type: ACTIONS.CREATE_MODULE, payload: data });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title,
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };

  export const deleteCreateModule = (id: any) => async (dispatch: Function) => {
    try {
      const { data } = await AXIOS.delete(
        `${Prefix.api}/panel/assignment-module-master/${id}/`,
        { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
      );
      snackBarUpdate({
          payload: {
              message: "Module deleted successfully!",
              status: true,
              type: "success",
          },
      })(dispatch);
      dispatch({ type: ACTIONS.DELETE_CREATE_MODULE, payload: data });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title,
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };

  export const getPerformanceTable = (url: any) => async (dispatch: Function) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/panel/module-performance-report/${url ? url : ""}`,
        { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
      );
      dispatch({ type: ACTIONS.PERFORMANCE_TABLE, payload: data });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title,
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };

  export const getPerformanceTableAll = (url: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true })
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/panel/module-performance-report/?get_all=true${url ? url : ""}`,
        { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
      );
      dispatch({ type: ACTIONS.PERFORMANCE_TABLE_ALL, payload: data });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title,
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false })
      throw err;
    }
  };

  export const getAssignmentQuestion = (url: any) => async (dispatch: Function) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/panel/assignment-question-master/${url ? `?module_id=${url}` : ""}`,
        { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
      );
      dispatch({ type: ACTIONS.ASSIGNMENT_QUESTION_MASTER, payload: data });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title,
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };

  export const postAssignmentAnswerMaster = (body: any) => async (dispatch: Function) => {
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/panel/assignment-panel-user-answer-master/`,
        body,
        { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
      );
      dispatch({ type: ACTIONS.POST_ASSIGNMENT_ANSWER_MASTER, payload: data });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title,
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };

  export const putAssignmentAnswerMaster = (body: any, id: any) => async (dispatch: Function) => {
    try {
      const { data } = await AXIOS.put(
        `${Prefix.api}/panel/assignment-panel-user-answer-master/${id}/`,
        body,
        { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
      );
      dispatch({ type: ACTIONS.PUT_ASSIGNMENT_ANSWER_MASTER, payload: data });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title,
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };

  export const postUpdateModule = (body: any) => async (dispatch: Function) => {
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/panel/module-change-status/`,
        body,
        { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
      );
      snackBarUpdate({
          payload: {
              message: "Status updated successfully!",
              status: true,
              type: "success",
          },
      })(dispatch);
      dispatch({ type: ACTIONS.POST_UPDATE_MODULE, payload: data });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title,
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };

  export const getAgentList = (url: string) => async (dispatch: Function) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/panel/data/${url ? `?${url}` : ""}`,
        { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
      );
      dispatch({ type: ACTIONS.GET_AGENTS, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };

  export const getLatestAddressByLeadId = (id: any) => async (dispatch: Function) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/lead/latest-address/${id}/`,
        { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
      );
      dispatch({ type: ACTIONS.LATEST_LEAD_ADDRESS, payload: data });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title,
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };

  export const getWhatsappStatus = (url: any) => async (dispatch: Function) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/communication/whatsapp_status/${url ? `?msg_id=${url}` : ""}`,
        { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
      );
      dispatch({ type: ACTIONS.WHATSAPP_STATUS_MESSAGE_ID, payload: data });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title,
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };
  export const getCustomerSummaryUsingUHID = (uhid: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING_SUMMARY, payload: true })
    try {
      const { data } = await AXIOS.get(
        `${Prefix.apiv2}/snowflake/customer_history/${uhid ? `?uhid=${uhid}&type=summary` : ""}`,
        { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
      );
      dispatch({ type: ACTIONS.CUSTOMER_SUMMARY_USING_UHID, payload: data });
      dispatch({ type: ACTIONS.SET_LOADING_SUMMARY, payload: false })
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title,
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING_SUMMARY, payload: false })
      throw err;
    }
  };
  export const getCustomerDetailsUsingUHID = (uhid: any, bookingId: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING_DETAILS, payload: true })
    try {
      const { data } = await AXIOS.get(
        `${Prefix.apiv2}/snowflake/customer_history/${uhid ? `?uhid=${uhid}&booking_id=${bookingId}&type=details` : ""}`,
        { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
      );
      dispatch({ type: ACTIONS.CUSTOMER_DETAILS_USING_UHID, payload: data });
      dispatch({ type: ACTIONS.SET_LOADING_DETAILS, payload: false })
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title,
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING_DETAILS, payload: false })
      throw err;
    }
  };
  export const getDoctorAnswerList = (url: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_ALL_BOOKING_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/booking/consultation-answer-list/${url ? `${url}` : ""}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.GET_DOCTOR_ANSWER_LIST,
        payload: data,
      });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_ALL_BOOKING_LOADING, payload: false });
      throw err;
    }
  };
  export const getDoctorConsultationUniqueCustomer = (url: any) => async (dispatch: Function) => {
    // dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/booking/unique-customer-consultation/${url ? `?${url}` : ""}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.GET_DOCTOR_CONSULTATION_UNIQUE_CUSTOMER,
        payload: data,
      });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      // dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
  export const getDoctorConsultationQuestion = (url: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/booking/doctor-consultation-question/${url ? `?${url}` : ""}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.GET_DOCTOR_CONSULTATION_QUESTION,
        payload: data,
      });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.detail;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
  export const getRefDoctor = (url: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/booking/cc_refdoctor/${url ? url : ""}`,
        { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
      );
      dispatch({ type: ACTIONS.GET_REF_DOCTOR, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = String(err.response.data.errors);
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong!",
          status: true,
          type: "error",
        },
      })(dispatch);
  
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
  export const postRefDoctor = (body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/booking/cc_refdoctor/`,
        body,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.POST_REF_DOCTOR, payload: data });
      snackBarUpdate({
        payload: {
          message: "Referring Doctor have been successfully saved!",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Referring Doctor have not been saved!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
 
  export const getReportStatusLive = (url: any) => async (dispatch: Function) => {
    // dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/booking/live-status/${url ? `?billid=${url}` : ""}`,
  
        { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
      );
      dispatch({ type: ACTIONS.GET_REPORT_STATUS_LIVE, payload: data });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      // dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

  export const getRecommendedPackagesWithCityId = (cityId: any, packId: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_ALL_BOOKING_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.apiNew}/snowflake/city_tests/${cityId ? `?city_id=${cityId}` : ""}${packId ? `&package_id=${packId}` : ""}`,
  
        { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
      );
      dispatch({ type: ACTIONS.GET_RECOMMENDED_PACKAGES_WITH_CITY_ID, payload: data });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_ALL_BOOKING_LOADING, payload: false });
      throw err;
    }
  };

  export const getPackageList = (url: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_ALL_BOOKING_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.apiNew}/beta1/core/packages/crmdata${url ? `?${url}` : ""}`,
        { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
      );
      dispatch({ type: ACTIONS.GET_PACKAGES_LIST, payload: data });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_ALL_BOOKING_LOADING, payload: false });
      throw err;
    }
  };

  export const postModuleInteraction = (body: any) => async (dispatch: Function) => {
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/analytics/module-interaction/`,
        body,
        { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
      );
      dispatch({ type: ACTIONS.MODULE_INTERACTION, payload: data });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title,
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };

  export const addTicketsComment = (body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_ALL_BOOKING_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/redtech/create-comment/`,
        body,
        { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
      );
      snackBarUpdate({
        payload: {
          message: "Comment added successfully!",
          status: true,
          type: "success",
        },
      })(dispatch);
      dispatch({
        type: ACTIONS.ADD_TICKETS_COMMENT,
        payload: data,
      });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_ALL_BOOKING_LOADING, payload: false });
      throw err;
    }
  };
  export const getPackageFromTest = (url:any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING_PACKAGE_FROM_TEST, payload: true })
    try {
      const { data } = await AXIOS.get(
        `${Prefix.apiv2}/snowflake/package_from_tests/${url ? `?${url}` : ""}`,
        { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
      );
      dispatch({ type: ACTIONS.GET_PACKAGE_FROM_TEST, payload: data });
      dispatch({ type: ACTIONS.SET_LOADING_PACKAGE_FROM_TEST, payload: false })
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title,
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING_SUMMARY, payload: false })
      throw err;
    }
  };
  export const sendWhatsAppPackage = (body: any) => async (dispatch: Function) => {
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/communication/package_message/`,
        body,
        { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
        
      );
      dispatch({ type: ACTIONS.SEND_WHATSAPP_PACKAGE, payload: data });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title,
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };

  export const postRemoveSubscription = (body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_ALL_BOOKING_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/auth/subscriptions/remove/`,
        body,
        { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
      );
      // snackBarUpdate({
      //   payload: {
      //     message: "Subcription removed successfully!",
      //     status: true,
      //     type: "success",
      //   },
      // })(dispatch);
      dispatch({
        type: ACTIONS.REMOVE_SUBSCRIPTION,
        payload: data,
      });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_ALL_BOOKING_LOADING, payload: false });
      throw err;
    }
  };
  export const getPackageQuestion = (url:any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING_PACKAGE_QUESTION, payload: true })
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/package/package-question/${url ? `?${url}` : ""}`,
        { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
      );
      dispatch({ type: ACTIONS.GET_PACKAGE_QUESTION, payload: data });
      dispatch({ type: ACTIONS.SET_LOADING_PACKAGE_QUESTION, payload: false })
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title,
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING_PACKAGE_QUESTION, payload: false })
      throw err;
    }
  };
  export const postPackageAnswer = (body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING_PACKAGE_QUESTION, payload: true });
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/package/package-answer/`,
        body,
        { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
      );
      snackBarUpdate({
        payload: {
          message: "Answers Saved successfully!",
          status: true,
          type: "success",
        },
      })(dispatch);
      dispatch({
        type: ACTIONS.POST_PACKAGE_ANSWERS,
        payload: data,
      });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING_PACKAGE_QUESTION, payload: false });
      throw err;
    }
  };
  export const getComparePackage = (url:any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING_PACKAGE_COMPARE, payload: true })
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/package/package-comparision/${url ? `?${url}` : ""}`,
        { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
      );
      dispatch({ type: ACTIONS.GET_COMPARE_PACKAGE, payload: data });
      dispatch({ type: ACTIONS.SET_LOADING_PACKAGE_COMPARE, payload: false })
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title,
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING_PACKAGE_COMPARE, payload: false })
      throw err;
    }
  };

  export const getUHIDsList = (url: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_ALL_BOOKING_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.apiNew}/snowflake/uhids_from_lead/${url ? `?${url}` : ""}`,
        { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
      );
      dispatch({ type: ACTIONS.GET_UHIDS_LIST, payload: data });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_ALL_BOOKING_LOADING, payload: false });
      throw err;
    }
  };

  export const postUniqueCustomerManagement = (body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_ALL_BOOKING_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/lead/unique-customer-management/`,
        body,
        { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
      );
      snackBarUpdate({
        payload: {
          message: "UHID Merged Successfully!",
          status: true,
          type: "success",
        },
      })(dispatch);
      dispatch({
        type: ACTIONS.MERGE_UNIQUE_CUSTOMER,
        payload: data,
      });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_ALL_BOOKING_LOADING, payload: false });
      throw err;
    }
  };
  
export const PostBookingIndexing = (body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/booking/booking-indexing/`,
        body,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.POST_BOOKING_INDEXING,
        payload: data,
      });
      snackBarUpdate({
        payload: {
          message: "Refresh Successfully",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
  export const getLeadRepeatedStatus = (url: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/lead/check-lead-repeated-status/${url ? `${url}/` : ""}`,
        { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
      );
      dispatch({ type: ACTIONS.GET_LEAD_REPEATED_STATUS, payload: data });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err?.response?.data?.msg) {
        title = JSON.stringify(err.response.data?.msg).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const getContainerSlots = (collection_date: any, customer_zone: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_ALL_BOOKING_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/booking/booking_slot_collection_date/?collection_date=${collection_date ? collection_date : ""
      }&customer_zone=${customer_zone ? customer_zone : ""}`,
      {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      }
    );
    dispatch({ type: ACTIONS.GET_CONTAINER_SLOTS, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response.data?.detail) {
      title = JSON.stringify(err.response.data?.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_ALL_BOOKING_LOADING, payload: false });
    throw err;
  }
};
export const getChatAnalyticsQueueLog = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING_QUEUE, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/analytics/queue-logs/${url ? `?${url}` : ""}`,

      {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      }
    );
    dispatch({ type: ACTIONS.GET_CHAT_ANALYTICS_QUEUE_LOG, payload: data });
  } catch (err: any) {
    let title = "";
    if (err?.response?.data?.detail) {
      title = JSON.stringify(err.response.data?.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err?.response?.data?.errors) {
      title = JSON.stringify(err.response.data?.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err?.response?.data?.msg) {
      title = JSON.stringify(err.response.data?.msg).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING_QUEUE, payload: false });
    throw err;
  }
};

export const getPhleboBookingSlot = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/phlebo/phlebo-bookings-slots/${url ? `?${url}` : ""}`,

      {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      }
    );
    dispatch({ type: ACTIONS.GET_PHLEBO_BOOKING_SLOT, payload: data });
  } catch (err: any) {
    let title = "";
    if (err?.response?.data?.detail) {
      title = JSON.stringify(err.response.data?.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err?.response?.data?.errors) {
      title = JSON.stringify(err.response.data?.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err?.response?.data?.msg) {
      title = JSON.stringify(err.response.data?.msg).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getPhleboAllBooking = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/phlebo/phlebo-bookings/${url ? `?${url}` : ""}`,

      {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      }
    );
    dispatch({ type: ACTIONS.GET_PHLEBO_ALL_BOOKING, payload: data });
  } catch (err: any) {
    let title = "";
    if (err?.response?.data?.detail) {
      title = JSON.stringify(err.response.data?.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err?.response?.data?.errors) {
      title = JSON.stringify(err.response.data?.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err?.response?.data?.msg) {
      title = JSON.stringify(err.response.data?.msg).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getNearestPhlebo = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/phlebo/nearest-phlebo/${url ? `?${url}` : ""}`,

      {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      }
    );
    dispatch({ type: ACTIONS.GET_NEAREST_PHLEBO, payload: data });
  } catch (err: any) {
    let title = "";
    if (err?.response?.data?.detail) {
      title = JSON.stringify(err.response.data?.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err?.response?.data?.errors) {
      title = JSON.stringify(err.response.data?.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err?.response?.data?.msg) {
      title = JSON.stringify(err.response.data?.msg).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const startWhatsappChat = (leadId: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });

  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/communication/greetings-msg/?lead_id=${leadId}`,

      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.START_WHATSAPP_CHAT, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getUserBookingDetails = (id: any) => async (dispatch: Function) => {
  // dispatch({ type: ACTIONS.SET_ALL_BOOKING_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/communication/whatsapp-message-booking-not-created/?lead=${id}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_USER_BOOKING_DETAILS, payload: data });
  } catch (err: any) {
    let title = "";
    if (err?.response?.data?.detail) {
      title = JSON.stringify(err.response.data?.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err?.response?.data?.errors) {
      title = JSON.stringify(err.response.data?.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err?.response?.data?.msg) {
      title = JSON.stringify(err.response.data?.msg).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title,
        status: true,
        type: "error",
      },
    })(dispatch);
    // dispatch({ type: ACTIONS.SET_ALL_BOOKING_LOADING, payload: false });
    throw err;
  }
};
export const getNotServiceableAreaList = (url: any,) => async (dispatch: Function) => {
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/booking/notserviceablearea/list/${url ? `?${url}` : ""}`,
      {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      }
    );
    dispatch({
      type: ACTIONS.GET_NON_SERVICEABLE_AREA_LIST,
      payload: data,
    });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    throw err;
  }
};