const isSelected = (field) => {
    if (
        field === "all" ||
        field === "" ||
        field === undefined ||
        field === null ||
        field === "none"
    ) {
        return false;
    } else {
        return true;
    }
};

const isSelectedNum = (field) => {
    if (field === 0 || field === "" || field === undefined || field === null || field === "none") {
        return false;
    } else {
        return true;
    }
};

export const generateBookingFilterUrl = (body) => {
    const {
        status,
        verificationStatus,
        source,
        disposition,
        agent,
        phlebo,
        city,
        start_date,
        end_date,
        booking_date,
        collection_date,
        name,
        phone,
        lead,
        slot,
        pickupAmount,
        pickup_status,
        pickup_done,
        bookingId,
        partner,
        email,
        sample_registered,
        report_status,
        labName,
        center,
    } = body;
    const booking_status = isSelectedNum(status) ?
        `&multiple_booking_status=${status}` :
        "";
    const booking_id = isSelectedNum(bookingId) ?
        `&id=${bookingId}` :
        "";
    const booking_collection_slots = isSelectedNum(slot) ? `&multiple_collection_slots=${slot}` : "";
    const lead_id = isSelectedNum(lead) ?
        `&lead=${lead}` :
        "";
    const booking_labName = isSelectedNum(labName) ?
        `&lab=${labName}` :
        "";
        const centerId = isSelected(center) ? `&center__id=${center}` : "";
    const booking_partner = isSelectedNum(partner) ?
        `&multiple_partner=${partner}` :
        "";
    const verification_status = isSelectedNum(verificationStatus) ?
        `&multiple_verification_status=${verificationStatus}` :
        "";
    const booking_source = isSelectedNum(source) ? `&multiple_source=${source}` : "";
    const disposition_source = isSelectedNum(disposition) ? `&disposition=${disposition}` : "";
    const booking_agent = isSelectedNum(agent) ?
        `&multiple_agent=${agent}` :
        "";
    const booking_phlebo = isSelectedNum(phlebo) ?
        `&multiple_phlebo=${phlebo}` :
        "";
    const booking_city = isSelectedNum(city) ?
        `&multiple_city=${city}` :
        "";
    const booking_start_date = isSelected(start_date) ? `&start_date=${start_date}` : "";
    const booking_booking_date = isSelected(booking_date) ? `&booking_date=${booking_date}` : "";
    const booking_end_date = isSelected(end_date) ?
        `&end_date=${end_date}` :
        "";
    const booking_collection_date = isSelected(collection_date) ?
        `&collection_date=${collection_date}` :
        "";
        const booking_email = isSelectedNum(email) ?
        `&email=${email}` :
        "";
    const booking_name = isSelectedNum(name) ? `&customer_name=${name}` : "";
    const booking_phone = isSelectedNum(phone) ? `&phone=${phone}` : "";
    const booking_pickup_status = isSelectedNum(pickup_status) ? `&multiple_pickup_status=${pickup_status}` : "";
    const booking_pickup_done = isSelectedNum(pickup_done) ? `&pickup_done=${pickup_done}` : "";
    const booking_pickup_amount = isSelectedNum(pickupAmount) ? `&pickup_amount=${pickupAmount}` : "";
    const booking_sample_registered = isSelectedNum(sample_registered) ? `&sample_registered=${sample_registered}` : "";

    const booking_report_status = isSelectedNum(report_status) ? `&multiple_report_status=${report_status}` : "";
    const url = `?${booking_collection_slots}${lead_id}${booking_report_status}${booking_sample_registered}${booking_id}${booking_pickup_done}${booking_partner}${booking_pickup_status}${booking_pickup_amount}${booking_agent}${booking_name}${booking_phone}${booking_phlebo}${booking_city}${booking_source}${disposition_source}${booking_start_date}${booking_end_date}${booking_collection_date}${booking_status}${booking_booking_date}${verification_status}${booking_labName}${booking_email}${centerId}`;

    return url;
};

export const generateReportFilterUrl = (body) => {
    const {
        start_date,
        end_date,
        name,
        phone,
        lead,
        bookingId,
        report_status,
    } = body;
   
    const booking_id = isSelectedNum(bookingId) ?
        `&booking_id=${bookingId}` :
        "";
    const lead_id = isSelectedNum(lead) ?
        `&lead=${lead}` :
        "";
    const booking_start_date = isSelected(start_date) ? `&start_date=${start_date}` : "";
    const booking_end_date = isSelected(end_date) ?
        `&end_date=${end_date}` :
        "";
    const booking_name = isSelectedNum(name) ? `&name=${name}` : "";
    const booking_phone = isSelectedNum(phone) ? `&phone=${phone}` : "";
    
    const booking_report_status = isSelectedNum(report_status) ? `&multiple_report_status=${report_status}` : "";
    const url = `?${lead_id}${booking_report_status}${booking_id}${booking_name}${booking_phone}${booking_start_date}${booking_end_date}`;

    return url;
};
export const genrateFailedReportFilter = (body) => {
    const {
       id,
        name,
        report_status,
    } = body;
   
    const booking_id = isSelectedNum(id) ? `&id=${id}` : "";
    const booking_name = isSelectedNum(name) ? `&id=${name}` : "";
    const booking_report_status = isSelectedNum(report_status) ? `&multiple_report_status=${report_status}` : "";
    const url = `?${booking_id}${booking_name}${booking_report_status}`;

    return url;
};

export const genrateTeamFiltr = (body) => {
    const {
        tl,
        sl,
        agent,
        performance_date,
    } = body;

    const panel_sl = isSelected(sl) ? `&sl=${sl}` : "";
    const panel_panelUser = isSelectedNum(tl) ? `&tl=${tl}` : "";
    const panel_agent = isSelectedNum(agent) ? `&agent=${agent}` : "";
    const team_Performance_date = isSelectedNum(performance_date) ? `&performance_date=${performance_date}` : "";
  
    const url = `?${panel_sl}${panel_panelUser}${panel_agent}${performance_date}`;
    return url;
};

export const genrateChatAnalyticsOverAllFilter = (body) => {
    const {
        target_date,
        group_by_panel_user,
        skills
    } = body;
    const chatAnalytics_target_date = isSelected(target_date) ? `&target_date=${target_date}` : "";
    const chatAnalytics_agent = isSelected(group_by_panel_user) ? `&group_by_panel_user=${group_by_panel_user}` : "";
    const chatAnalytics_skills= isSelected(skills) ? `&skills=${skills}` : "";
  
    const url = `?${chatAnalytics_target_date}${chatAnalytics_agent}${chatAnalytics_skills}`;
    return url;
};