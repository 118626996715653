export const ACTIONS = {
  CHANGE_PASSWORD: "change_password",
  SET_USER: 'login/set_user',
  LOGOUT: 'login/logout',
  SET_PROFILE: "login/set_profile",
  RESET_PASSWORD: "login/reset_password",
  SEND_OTP: "login/send_otp",
  SET_LOADING: 'login/set_loading',
  SET_LOADING_OTP: 'login/set_loading_otp',
  PANELUSER_LOGOUT:'login/paneluser_logout',
  GET_FIREBASE_NOTIFICATION:'login/get_leads',
  GET_USER_DETAILS:"login/get_user_details",
  GET_USER:"login/get_user_data",
  GET_BREAK_RESPONSE: "login/get_break_response",
  GET_ADDRESS_TYPE_SEATING: "login/get_address_type_seating",
  GET_USER_PROFILE: "login/get_user_profile",
  LOCATION_DATA_FROM_PUSHER: "login/location_data_from_pusher",
  WHATSAPP_DATA_FROM_PUSHER: "login/whatsapp_data_from_pusher",
  WHATSAPP_DATA_FROM_PUSHER_FOR_SL: "login/whatsapp_data_from_pusher_for_sl",
  WHATSAPP_ADDRESS_ID_DETAIL: "login/whatsapp_address_id_detail",
  GET_AGENTS_NOTES_DATA : "login/get_agents_notes_data",
  CHANGE_AGENTS_NOTES_DATA : "login/change_agents_notes_data",
  CRON_LEAD_CHAT_UPDATE : "login/cron_lead_chat_update",
  CREATE_MODULE : "login/create_module",
  POST_CREATE_MODULE : "login/post_create_module",
  POST_UPDATE_MODULE : "login/post_update_module",
  DELETE_CREATE_MODULE : "login/delete_create_module",
  PERFORMANCE_TABLE : "login/performance_table",
  PERFORMANCE_TABLE_ALL : "login/performance_table_all",
  ASSIGNMENT_QUESTION_MASTER : "login/assignment_question_master",
  PUT_ASSIGNMENT_ANSWER_MASTER : "login/put_assignment_answer_master",
  POST_ASSIGNMENT_ANSWER_MASTER : "login/post_assignment_answer_master",
  GET_AGENTS: "login/get_agents",
  GET_CHAT_ANALYTICS_AGENT: "login/analytics/chat-analytics-agent",
  GET_CHAT_ANALYTICS_CHAT: "login/analytics/chat-analytics-chat",
  GET_CHAT_ANALYTICS_REPORTS: "login/analytics/chat-analytics-reports",
  GET_CHAT_ANALYTICS_HOURLY_REPORTS: "login/analytics/chat-analytics-hourly-reports",
  CHAT_ASSIGNED_FROM_PUSHER: "login/chat_assigned_from_pusher",
  CHAT_UNASSIGNED_FROM_PUSHER: "login/chat_unassigned_from_pusher",
  LATEST_LEAD_ADDRESS: "login/latest_lead_address",
  WHATSAPP_STATUS_MESSAGE_ID: "login/whatsapp_status_message_id",
  CUSTOMER_SUMMARY_USING_UHID: "login/customer_summary_using_uhid",
  CUSTOMER_DETAILS_USING_UHID: "login/customer_details_using_uhid",
  SET_LOADING_SUMMARY: "login/set_loading_summary",
  SET_LOADING_DETAILS: "login/set_loading_details",
  GET_DOCTOR_ANSWER_LIST:'login/consultation-answer-list',
  GET_DOCTOR_CONSULTATION_UNIQUE_CUSTOMER:'login/booking/unique-customer-consultation/',
  GET_DOCTOR_CONSULTATION_QUESTION:'login/booking/doctor-consultation-question/',
  SET_ALL_BOOKING_LOADING: "login/set_all_booking_loading",
  GET_REF_DOCTOR: "login/get_ref_doctor",
  POST_REF_DOCTOR: "login/post_ref_doctor",
  GET_REPORT_STATUS_LIVE: "login/get_report_status_live",
  GET_RECOMMENDED_PACKAGES_WITH_CITY_ID: "login/get_recommended_packages_with_city_id",
  GET_PACKAGES_LIST: "login/get_packages_list",
  MODULE_INTERACTION: "login/module_interaction",
  ADD_TICKETS_COMMENT: "login/add_tickets_comment",
  GET_PACKAGE_FROM_TEST:"login/snowflake/package_from_tests",
  SET_LOADING_PACKAGE_FROM_TEST:"login/snowflake/package_from_tests/loader",
  SEND_WHATSAPP_PACKAGE:"login/notification/send_whatsapp",
  REMOVE_SUBSCRIPTION: "login/remove_subscription",
  SET_LOADING_PACKAGE_QUESTION:"login/set_loading_question",
  GET_PACKAGE_QUESTION:"login/package/package-question",
  SET_LOADING_PACKAGE_COMPARE:"login/set_loading_package_compare",
  GET_COMPARE_PACKAGE:"login/package/package-comparision",
  POST_PACKAGE_ANSWERS:"login/package/package-answer/",
  GET_UHIDS_LIST: "login/get_uhids_list",
  MERGE_UNIQUE_CUSTOMER: "login/merge_unique_customer",
  GET_LEAD_REPEATED_STATUS:"login/lead/check-lead-repeated-status/",
  GET_CONTAINER_SLOTS: "login/get_container_slots",
  POST_BOOKING_INDEXING:"login/booking/booking-indexing/",
  GET_CHAT_ANALYTICS_QUEUE_LOG:"login/analytics/queue-logs/",
  SET_LOADING_QUEUE:"login/analytics/queue-los_loader/",
  GET_PHLEBO_BOOKING_SLOT:"login/phlebo/phlebo-bookings-slots",
  GET_PHLEBO_ALL_BOOKING:"login/phlebo/phlebo-bookings",
  GET_NEAREST_PHLEBO:"login/phlebo/nearest-phlebo/",
  START_WHATSAPP_CHAT: 'login/start_whatsapp_chat',
  GET_USER_BOOKING_DETAILS: "login/get_user_booking_details",
  GET_NON_SERVICEABLE_AREA_LIST: "loging/notserviceablearea/list",
};

interface SetUser {
  type: typeof ACTIONS.SET_USER
  payload: Array<string | number>
}
interface LocationDataFromPusher {
  type: typeof ACTIONS.LOCATION_DATA_FROM_PUSHER
  payload: Array<any>
}
interface StartWhatsappChat {
  type: typeof ACTIONS.START_WHATSAPP_CHAT;
  payload: any;
}

interface GetUserBookingDetails {
  type: typeof ACTIONS.GET_USER_BOOKING_DETAILS;
  payload: Array<any>;
}

interface GetUserProfile {
  type: typeof ACTIONS.GET_USER_PROFILE
  payload: Array<string | number>
}
interface GetAddressTypeSeating {
  type: typeof ACTIONS.GET_ADDRESS_TYPE_SEATING;
  payload: Object;
}
interface GetBreakResponse {
  type: typeof ACTIONS.GET_BREAK_RESPONSE;
  payload: Object;
}
interface GetUserDetails {
    type: typeof ACTIONS.GET_USER_DETAILS
    payload: Array<any>
}
interface GetUserDeta {
    type: typeof ACTIONS.GET_USER
    payload: Array<any>
}
interface Logout {
  type: typeof ACTIONS.LOGOUT
  payload: any
}
interface PanelUserLogout {
  type: typeof ACTIONS.PANELUSER_LOGOUT
  payload: any
}
interface SetProfile {
  type: typeof ACTIONS.SET_PROFILE;
  payload: object;
}
interface WhatsappDataFromPusher {
  type: typeof ACTIONS.WHATSAPP_DATA_FROM_PUSHER
  payload: Array<any>
}
interface WhatsappDataFromPusherForSL {
  type: typeof ACTIONS.WHATSAPP_DATA_FROM_PUSHER_FOR_SL
  payload: Array<any>
}
interface WhatsappAddressIdDetail {
  type: typeof ACTIONS.WHATSAPP_ADDRESS_ID_DETAIL
  payload: Array<any>
}
interface ResetPassword {
  type: typeof ACTIONS.RESET_PASSWORD;
  payload: object;
}
interface FireBaseToken {
  type: typeof ACTIONS.GET_FIREBASE_NOTIFICATION;
  payload: object;
}
interface SetLoadng {
  type: typeof ACTIONS.SET_LOADING
  payload: boolean
}
interface SetLoadngOTP {
  type: typeof ACTIONS.SET_LOADING_OTP
  payload: boolean
}
interface GetAgentsNotesData {
  type: typeof ACTIONS.GET_AGENTS_NOTES_DATA
  payload: boolean
}
interface ChangeAgentsNotesData {
  type: typeof ACTIONS.CHANGE_AGENTS_NOTES_DATA
  payload: boolean
}
interface CronLeadChatUpdate {
  type: typeof ACTIONS.CRON_LEAD_CHAT_UPDATE
  payload: boolean
}

interface GetChatAnalyticsAgent {
  type: typeof ACTIONS.GET_CHAT_ANALYTICS_AGENT;
  payload: Array<any>;
}

interface GetChatAnalyticsChat {
  type: typeof ACTIONS.GET_CHAT_ANALYTICS_CHAT;
  payload: Array<any>;
}
interface GetChatAnalyticsReports {
  type: typeof ACTIONS.GET_CHAT_ANALYTICS_REPORTS;
  payload: Array<any>;
}
interface GetChatAnalyticsHourlyReports {
  type: typeof ACTIONS.GET_CHAT_ANALYTICS_HOURLY_REPORTS;
  payload: Array<any>;
}
interface CreateModule {
  type: typeof ACTIONS.CREATE_MODULE
  payload: boolean
}
interface PostCreateModule {
  type: typeof ACTIONS.POST_CREATE_MODULE
  payload: boolean
}
interface PostUpdateModule {
  type: typeof ACTIONS.POST_UPDATE_MODULE
  payload: boolean
}
interface DeleteCreateModule {
  type: typeof ACTIONS.DELETE_CREATE_MODULE
  payload: boolean
}
interface PerformanceTable {
  type: typeof ACTIONS.PERFORMANCE_TABLE
  payload: boolean
}
interface PerformanceTableAll {
  type: typeof ACTIONS.PERFORMANCE_TABLE_ALL
  payload: boolean
}
interface AssignmentQuestionMaster {
  type: typeof ACTIONS.ASSIGNMENT_QUESTION_MASTER
  payload: boolean
}
interface PutAssignmentAnswerMaster {
  type: typeof ACTIONS.PUT_ASSIGNMENT_ANSWER_MASTER
  payload: boolean
}
interface PostAssignmentAnswerMaster {
  type: typeof ACTIONS.POST_ASSIGNMENT_ANSWER_MASTER
  payload: boolean
}
interface ChatAssignedFromPusher {
  type: typeof ACTIONS.CHAT_ASSIGNED_FROM_PUSHER
  payload: Array<any>
}
interface ChatUnassignedFromPusher {
  type: typeof ACTIONS.CHAT_UNASSIGNED_FROM_PUSHER
  payload: Array<any>
}
interface GetAgents {
  type: typeof ACTIONS.GET_AGENTS;
  payload: Array<any>;
}
interface GetLatestLeadAddress {
  type: typeof ACTIONS.LATEST_LEAD_ADDRESS;
  payload: Array<any>;
}

interface GetWhatsappStatusMessageId {
  type: typeof ACTIONS.WHATSAPP_STATUS_MESSAGE_ID;
  payload: Array<any>;
}
interface GetCustomerSummaryUsingUHID {
  type: typeof ACTIONS.CUSTOMER_SUMMARY_USING_UHID;
  payload: Array<any>;
}
interface GetCustomerDetailsUsingUHID {
  type: typeof ACTIONS.CUSTOMER_DETAILS_USING_UHID;
  payload: Array<any>;
}
interface SetLoadingSummary {
  type: typeof ACTIONS.SET_LOADING_SUMMARY
  payload: boolean
}
interface SetLoadingDetails {
  type: typeof ACTIONS.SET_LOADING_DETAILS
  payload: boolean
}
interface GetDoctorAnswerList {
  type: typeof ACTIONS.GET_DOCTOR_ANSWER_LIST;
  payload: any;
}
interface GetDoctorConsultationUniqueCustomer {
  type: typeof ACTIONS.GET_DOCTOR_CONSULTATION_UNIQUE_CUSTOMER;
  payload: Array<any>;
}
interface GetDoctorConsultationQuestion {
  type: typeof ACTIONS.GET_DOCTOR_CONSULTATION_QUESTION;
  payload: Array<any>;
}
interface GetBookingsLoader {
  type: typeof ACTIONS.SET_ALL_BOOKING_LOADING;
  payload: boolean;
}
interface GetRefDoctor {
  type: typeof ACTIONS.GET_REF_DOCTOR;
  payload: Array<any>;
}
interface PostRefDoctor {
  type: typeof ACTIONS.POST_REF_DOCTOR;
  payload: Array<any>;
}
interface GetReportStatusLive {
  type: typeof ACTIONS.GET_REPORT_STATUS_LIVE;
  payload: Array<any>;
}
interface GetRecommendedPackagesWithCityId {
  type: typeof ACTIONS.GET_RECOMMENDED_PACKAGES_WITH_CITY_ID;
  payload: Array<any>;
}
interface GetPackagesList {
  type: typeof ACTIONS.GET_PACKAGES_LIST;
  payload: Array<any>;
}
interface PostModuleInteraction {
  type: typeof ACTIONS.MODULE_INTERACTION;
  payload: Array<any>;
}
interface AddTicketsComment {
  type: typeof ACTIONS.ADD_TICKETS_COMMENT;
  payload: Array<any>;
}
interface GetPackageFromTest {
  type: typeof ACTIONS.GET_PACKAGE_FROM_TEST;
  payload: Array<any>;
}
interface SetLoaderForPackageTest {
  type: typeof ACTIONS.SET_LOADING_PACKAGE_FROM_TEST;
  payload: boolean;
}
interface SendWhatsAppPackage {
  type: typeof ACTIONS.SEND_WHATSAPP_PACKAGE;
  payload: Array<any>;
}

interface PostRemoveSubscription {
  type: typeof ACTIONS.REMOVE_SUBSCRIPTION;
  payload: Array<any>;
}

interface GetPackageQuestion {
  type: typeof ACTIONS.GET_PACKAGE_QUESTION;
  payload: Array<any>;
}
interface SetPackageLoading {
  type: typeof ACTIONS.SET_LOADING_PACKAGE_QUESTION;
  payload: boolean;
}
interface SetPackageCompare {
  type: typeof ACTIONS.SET_LOADING_PACKAGE_COMPARE;
  payload: boolean;
}
interface GetComparePackage {
  type: typeof ACTIONS.GET_COMPARE_PACKAGE;
  payload: Array<any>;
}
interface PostPackageAnswer {
  type: typeof ACTIONS.POST_PACKAGE_ANSWERS;
  payload: Array<any>;
}
interface GetUHIDsList {
  type: typeof ACTIONS.GET_UHIDS_LIST;
  payload: Array<any>;
}
interface PostMergeUniqueCustomer {
  type: typeof ACTIONS.MERGE_UNIQUE_CUSTOMER;
  payload: Array<any>;
}
interface GetContainerSlots {
  type: typeof ACTIONS.GET_CONTAINER_SLOTS;
  payload: Array<any>;
}
interface PostBookingIndexing {
  type: typeof ACTIONS.POST_BOOKING_INDEXING;
  payload: Array<any>;
}
interface GetChatAnalyticsQueueLog {
  type: typeof ACTIONS.GET_CHAT_ANALYTICS_QUEUE_LOG;
  payload: Array<any>;
}
interface SetChatAnalyticsQueueLogLoader {
  type: typeof ACTIONS.SET_LOADING_QUEUE;
  payload: Array<any>;
}
interface GetPhleboBookingSlot {
  type: typeof ACTIONS.GET_PHLEBO_BOOKING_SLOT;
  payload: Array<any>;
}
interface GetPhleboAllBooking {
  type: typeof ACTIONS.GET_PHLEBO_ALL_BOOKING;
  payload: Array<any>;
}
interface GetNearestPhlebo {
  type: typeof ACTIONS.GET_NEAREST_PHLEBO;
  payload: Array<any>;
}
interface GetLeadRepeatedStatus {
  type: typeof ACTIONS.GET_LEAD_REPEATED_STATUS;
  payload: Array<any>;
}
interface GetNotServiceableAreaList {
  type: typeof ACTIONS.GET_NON_SERVICEABLE_AREA_LIST;
  payload: Array<any>;
}


export type LoginActionTypes = SetUser | Logout | SetProfile | SetLoadng | SetLoadngOTP | GetBreakResponse | ResetPassword | PanelUserLogout | FireBaseToken | GetUserDetails | GetUserDeta | GetAddressTypeSeating | GetUserProfile | LocationDataFromPusher | WhatsappDataFromPusher | WhatsappDataFromPusherForSL | WhatsappAddressIdDetail | GetAgentsNotesData | ChangeAgentsNotesData | CronLeadChatUpdate | GetChatAnalyticsAgent | GetChatAnalyticsChat | GetChatAnalyticsReports | GetChatAnalyticsHourlyReports | CreateModule | PostCreateModule | DeleteCreateModule | PerformanceTable | AssignmentQuestionMaster | PutAssignmentAnswerMaster | PostAssignmentAnswerMaster | PostUpdateModule | GetAgents | GetLatestLeadAddress | ChatAssignedFromPusher | ChatUnassignedFromPusher | GetWhatsappStatusMessageId | GetCustomerSummaryUsingUHID | GetCustomerDetailsUsingUHID | SetLoadingSummary | SetLoadingDetails|GetDoctorAnswerList|GetDoctorConsultationUniqueCustomer|GetDoctorConsultationQuestion|GetBookingsLoader |GetRefDoctor|PostRefDoctor|GetReportStatusLive | PerformanceTableAll | GetRecommendedPackagesWithCityId | GetPackagesList | PostModuleInteraction | AddTicketsComment|GetPackageFromTest|SetLoaderForPackageTest|SendWhatsAppPackage | PostRemoveSubscription|GetPackageQuestion|SetPackageLoading|SetPackageCompare|GetComparePackage|PostPackageAnswer | GetUHIDsList | PostMergeUniqueCustomer|PostBookingIndexing | GetContainerSlots|GetChatAnalyticsQueueLog|SetChatAnalyticsQueueLogLoader|GetPhleboBookingSlot|GetPhleboAllBooking|GetNearestPhlebo|GetLeadRepeatedStatus|StartWhatsappChat|GetUserBookingDetails|GetNotServiceableAreaList
