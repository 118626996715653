import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@mui/material/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button, TextField, Grid, Select, MenuItem } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  updateBooking,
  getBookingById,
} from "../../actions/OffLineSupportActions";
import { useHistory } from "react-router-dom";
import { BOOKING_DISPOSITION } from "../constant";
let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: isMobile ? "400px" : "1200px",
  },
  head: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "2rem",
  },
}));
interface Props {
  updateBooking: any;
  getBookingById: any;
  loading: boolean;
}
type ModalProps = {
  updateBooking: any;
  openUpdate: boolean;
  getBookingById: any;
  bookingData: any;
  // bookingIdd:number;
  setOpenUpdate: Function;
};

const CreatePhlebo: React.FC<ModalProps> = ({
  openUpdate,
  setOpenUpdate,
  //  bookingIdd,
  bookingData,
  getBookingById,
  updateBooking,
}) => {
  const history = useHistory();
  const classes = useStyles();
  //    const [verification_remark, setVerification_remark] = useState<string>(bookingData&&bookingData.verification_status)
  //     const [verification_status, setVerification_status] = useState<string>(bookingData&&bookingData.verification_status)
  //     const [booking_status, setBooking_status] = useState<string>(bookingData&&bookingData.booking_status)
  //     const [disposition, setDispositon] = useState<string>(bookingData&&bookingData.setDispositon)
  const [verification_remark, setVerification_remark] = useState<string>("");
  const [verification_status, setVerification_status] = useState<string>("");
  const [booking_status, setBooking_status] = useState<string>("");
  const [disposition, setDispositon] = useState<string>("");
  const [phleboName, setPhleboName] = useState<string>("");
  const [phleboNumber, setPhleboNumber] = useState<string>("");
  useEffect(() => {
    if (bookingData) {
      setVerification_remark(bookingData?.verification_remark);
      setVerification_status(bookingData?.verification_status);
      setBooking_status(bookingData?.booking_status);
      setDispositon(bookingData?.disposition_status);
    }
  }, [bookingData]);
  const handleClose = () => {
    setOpenUpdate(false);
  };
  const submitForm = async (e: any) => {
    e.preventDefault();
    const data: any = {
      verification_status: verification_status,
      booking_status: booking_status,
      verification_remark: verification_remark,
      disposition: disposition,
    };
    if (data["disposition"] === 0 || disposition === "") {
      delete data["disposition"];
    }
    await updateBooking(data, bookingData.id);
    history.push(`/dashboard/os/verificationStatusTable`);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openUpdate}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openUpdate}>
          <div className={classes.paper}>
            <div className={classes.head}>
              <h4
                style={{
                  marginBottom: "1rem",
                }}
                id="transition-modal-title"
              >
                UPDATE STATUS
              </h4>
              <CloseIcon onClick={() => setOpenUpdate(false)} />
            </div>
            <Grid
              container
              direction="row"
              alignItems="flex-start"
              justify="space-between"
              spacing={3}
              style={{ margin: "1rem auto" }}
            >
              <Grid
                container
                item
                xs={12}
                md={3}
                spacing={3}
                style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}
              >
                <Grid item xs={12} style={{ padding: "0" }}>
                  <p>Verification Remark</p>
                </Grid>
                <Grid item xs={12} style={{ padding: "0" }}>
                  <TextField
                    name="verification_remark"
                    placeholder="Enter Remark"
                    type="text"
                    value={verification_remark}
                    className="input"
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                    onChange={(e) =>
                      setVerification_remark(e.target.value as string)
                    }
                    required
                  />
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                md={3}
                spacing={3}
                style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}
              >
                <Grid item xs={12} style={{ padding: "0" }}>
                  <p>Verification Status</p>
                </Grid>
                <Grid item xs={12} style={{ padding: "0" }}>
                  <Select
                    className="input"
                    name="verification_status"
                    variant="outlined"
                    value={verification_status}
                    style={{
                      width: "100%",
                      margin: "0",
                      padding: "0",
                      height: "40px",
                    }}
                    disabled={
                      bookingData &&
                      bookingData.verification_status === "confirmed"
                    }
                    onChange={(e) =>
                      setVerification_status(e.target.value as string)
                    }
                    required
                  >
                    <MenuItem value={"pending"}>Pending</MenuItem>
                    <MenuItem value={"confirmed"}>Confirmed</MenuItem>
                    <MenuItem value={"cancelled"}>Cancelled</MenuItem>
                  </Select>
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                md={3}
                spacing={3}
                style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}
              >
                <Grid item xs={12} style={{ padding: "0" }}>
                  <p>Booking Status</p>
                </Grid>
                <Grid item xs={12} style={{ padding: "0" }}>
                  <Select
                    className="input"
                    name="booking_status"
                    variant="outlined"
                    value={booking_status || "pending"}
                    style={{
                      width: "100%",
                      margin: "0",
                      padding: "0",
                      height: "40px",
                    }}
                    onChange={(e) =>
                      setBooking_status(e.target.value as string)
                    }
                    disabled
                  >
                    <MenuItem
                      disabled
                      value={bookingData && bookingData.booking_status}
                    >
                      {bookingData && bookingData.booking_status}
                    </MenuItem>
                    <MenuItem value={"pending"}>Pending</MenuItem>
                    <MenuItem value={"confirmed"}>Confirmed</MenuItem>
                    <MenuItem value={"cancelled"}>Cancelled</MenuItem>
                    <MenuItem value={"rescheduled"}>Rescheduled</MenuItem>
                  </Select>
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                md={3}
                spacing={3}
                style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}
              >
                <Grid item xs={12} style={{ padding: "0" }}>
                  <p>Disposition Type</p>
                </Grid>
                {verification_status === "confirmed"  ? (
                  <>
                    <Grid item xs={12} style={{ padding: "0" }}>
                  <Select
                    className="input"
                    name="disposition"
                    variant="outlined"
                    value={disposition}
                    style={{
                      width: "100%",
                      margin: "0",
                      padding: "0",
                      height: "40px",
                    }}
                    onChange={(e) =>
                      setDispositon(e.target.value as string)
                    }
                    required
                  >
                    <MenuItem value={"214"}>Call Not Connected</MenuItem>
                    <MenuItem value={"213"}>Call Connected</MenuItem>
                  </Select>
                </Grid>
                  </>
                ) : (
                  <Grid item xs={12} style={{ padding: "0" }}>
                  <Select
                    className="input"
                    name="disposition"
                    variant="outlined"
                    value={disposition}
                    style={{
                      width: "100%",
                      margin: "0",
                      padding: "0",
                      height: "40px",
                    }}
                    onChange={(e) =>
                      setDispositon(e.target.value as string)
                    }
                    required
                  >
                    {BOOKING_DISPOSITION.map((item: any) => {
                      return (
                        <MenuItem key={item.id} value={`${item.id}`}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
                )}
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={{ height: "50px" }}
                  disabled={
                    verification_remark === null ||
                    verification_remark === "" ||
                    verification_remark === undefined ||
                    disposition === null ||
                    disposition === "" ||
                    disposition === undefined
                  }
                  onClick={submitForm}
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  // area: state.OffLineSupporteReducer.area,
  booking: state.OffLineSupporteReducer.booking,
  loading: state.OffLineSupporteReducer.loading,
});

export default connect(mapStateToProps, {
  updateBooking,
  getBookingById,
})(CreatePhlebo);
