import { Autocomplete, Button, TextField } from "@mui/material";
import React, { useState } from "react";
import { connect } from "react-redux";
import { getViews } from "../../actions/OffLineSupportActions"

interface IProps {
  selectedView: string;
  proceedToDetails: boolean;
  data: any;
  activeView?: string;
  getViews: any;
}

const Filters: React.FC<IProps> = ({
  selectedView,
  proceedToDetails,
  data,
  activeView,
  getViews
}) => {
  const [filters, setFilters] = useState<any>({});
  const [key, setKey] = useState(1);
  const [resetView, setResetView] = useState<any>(false);

  const resetHandler = () => {
    setFilters({});
    setKey(key + 1);
    setResetView(!resetView);
    getViews();
  };

  const filterTable = () => {
    let filterQuery = "";
    for(const key in filters) {
      if(filters[key]) {
        filterQuery += `&${key}=${filters[key]}`
      }
    }
    getViews(`${activeView}${filterQuery}`);
  }

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(4, 1fr)",
        gap: "5px",
        width: '95%'
      }}
    >
      {selectedView &&
        proceedToDetails &&
        data?.filters?.map((filter: any, index: number) => {
          if (filter?.type === "dropdown") {
            return (
              <Autocomplete
                className="input"
                key={index + filter?.type}
                onChange={(_, newValue) => {
                  if (newValue) {
                    const updatedFilters = {
                      ...filters,
                      [filter?.name]: newValue,
                    };
                    setFilters(updatedFilters);
                  } else {
                    const updatedFilters = {
                      ...filters,
                      [filter?.name]: "",
                    };
                    setFilters(updatedFilters);
                  }
                }}
                id={filter?.name}
                options={filter?.values}
                renderInput={(params) => (
                  <TextField {...params} label={filter?.name} />
                )}
              />
            );
          } else if (filter?.type === "text" || filter?.type === "number" || filter?.type === "date") {
            return (
              <TextField
                className="input"
                value={
                  Object.keys(filters).length !== 0 ? filters[filter?.name] : ""
                }
                key={key + index}
                id={filter?.name}
                label={filter?.name}
                placeholder={filter?.name}
                type={filter?.type}
                onChange={(e) => {
                  const updatedFilters = {
                    ...filters,
                    [filter?.name]: e.target.value,
                  };
                  setFilters(updatedFilters);
                }}
              />
            );
          }
        })}
      <div style={{ display: "flex", gap: "5px" }}>
        <Button
          type="button"
          variant="contained"
          style={{ height: "100%", width: "100%" }}
          onClick={filterTable}
          disabled={Object.keys(filters).length === 0}
        >
          Filter
        </Button>
        <Button
          type="button"
          variant="contained"
          color="secondary"
          style={{ height: "100%", width: "100%" }}
          onClick={resetHandler}
        >
          Reset
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
});

export default connect(mapStateToProps, {
  getViews,
})(Filters);