import React, { useState, useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Grid,
  TextField,
  withStyles,
} from "@material-ui/core";
import Button from '@mui/material/Button';
import { useRef } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "../components/loader/index";
import moment from "moment";
import { MessageRounded } from "@material-ui/icons";
import { getEmailDetails } from "../actions/OffLineSupportActions";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useHistory } from "react-router";
import { generateEmailFilterUrl } from "../../helpers/generateUrl";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem",
      width: "100%",
    },
    table: {
      maxWidth: "100%",
      margin: "auto",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    listStyle: {
      marginLeft: "20px",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  })
);
const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface Props {
  getEmailDetails: any;
  emailDetails: any;
   loading: boolean;
}

const BookingsTable: React.FC<Props> = ({
  getEmailDetails,
  emailDetails,
  loading,
}) => {
  const classes = useStyles();
  useEffect(() => {
    getEmailDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const history = useHistory();
  const [bookingId, setBookingId] = useState<any>("");
  const [email, setEmail] = useState("");
  const [page, setPage] = useState(0);

  const filterBookings = () => {
    const body: any = {
      booking: bookingId,
      email,
    };
    const url = generateEmailFilterUrl(body).substring(2);
    getEmailDetails(`${url}`);
    // setPage(0)
  };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = emailDetails.links && emailDetails.links.next.split("?")[1];
      getEmailDetails(url);
    } else if (newPage < page) {
      let url = emailDetails.links && emailDetails.links.previous.split("?")[1];
      getEmailDetails(url);
    }
    setPage(newPage as number);
  };
  return (
    <div style={{ width: "99%" }}>
      <div className={classes.toolbar} />
      <h3 style={{ fontWeight: "bold" }}>Email Details</h3>
      <Grid container spacing={2} direction="row">
        <Grid item xs={12} sm={4} md={2}>
          <TextField
            className="input"
            name="name"
            type="number"
            label="Booking Id"
            value={bookingId}
            variant="outlined"
            onChange={(e) => setBookingId(e.target.value)}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <TextField
            className="input"
            name="email"
            type="text"
            label="Email"
            value={email}
            variant="outlined"
            onChange={(e) => setEmail(e.target.value as string)}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Button
            variant="contained"
            color="secondary"
            disabled={loading}
            fullWidth
            onClick={filterBookings}
          >
            Filter
          </Button>
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Button
            variant="contained"
            color="primary"
          
            fullWidth
            onClick={() => history.push("/dashboard/os/lead/emailDetails")}
          >
            Reset
          </Button>
        </Grid>
      </Grid>

      <div className={classes.toolbar} />
      {/* <Paper elevation={15} className={classes.paper}> */}
      <TableContainer
        className={classes.tableContainer}
        component={Paper}
        style={{ maxHeight: "700px" }}
      >
        {loading ? (
          <Loader />
        ) : (
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell align="center">Booking Id</StyledTableCell>
                <StyledTableCell align="center">Created At</StyledTableCell>
                <StyledTableCell align="center">Email Id</StyledTableCell>
                <StyledTableCell align="center">Message</StyledTableCell>
                {/* <TableCell align="center">SMS Status</TableCell> */}
                <StyledTableCell align="center">Delivered At</StyledTableCell>
                <StyledTableCell align="center">View</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {emailDetails.results &&
                emailDetails.results.length > 0 &&
                emailDetails.results.map((data: any, index: any) => {
                  return (
                    <StyledTableRow key={index}>
                      <StyledTableCell
                        style={{ height: "50px" }}
                        align="center"
                      >
                        {data.booking.pk}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ height: "50px" }}
                        align="center"
                      >
                        {new Date(data.created_at).toLocaleString()}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ height: "50px" }}
                        align="center"
                      >
                        {data.email}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ height: "50px" }}
                        align="center"
                      >
                        {data.message}
                      </StyledTableCell>
                      {/* <TableCell style={{height:"50px"}} align="center">{data.sms_status}</TableCell> */}
                      <StyledTableCell
                        style={{ height: "50px" }}
                        align="center"
                      >
                        {data.delivered_at}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Link
                          to={`/dashboard/os/booking-view/${data.booking.pk}`}
                          style={{ textDecoration: "none" }}
                        >
                          <Button variant="contained" color="secondary">
                            View
                          </Button>
                        </Link>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
            <TableFooter>
              <StyledTableRow>
                <TablePagination
                  rowsPerPageOptions={[]}
                  colSpan={3}
                  count={emailDetails.count || 0}
                  rowsPerPage={emailDetails.page_size || 0}
                  page={page}
                  onPageChange={handleChangePage}
                />
              </StyledTableRow>
            </TableFooter>
          </Table>
        )}
      </TableContainer>
      {/* </Paper> */}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  emailDetails: state.OffLineSupporteReducer.emailDetails,
});

export default connect(mapStateToProps, {
  getEmailDetails,
})(BookingsTable);
