import React, { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { connect } from "react-redux";
import View from "../components/Views/View";
import { getViews } from "../actions/OffLineSupportActions";
import SelectedView from "../components/Views/SelectedView";
import Filters from "../components/Views/Filters";
import ViewTable from "../components/Views/Table";
import loadingImg from "../../images/loading.png";

// import data from "./data";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Grid } from "@material-ui/core";

interface IProps {
  getViews: any;
  views: any;
  viewsDropdown: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Views: React.FC<IProps> = ({ getViews, views, viewsDropdown }) => {
  const [selectedView, setSelectedView] = useState<string>("");
  const [proceedToDetails, setProceedToDetails] = useState<boolean>(false);
  const [changeViewModal, setChangeViewModal] = useState<boolean>(false);
  const [dropdownOption, setDropdownOption] = useState<any>();
  const [activeView, setActiveView] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    getViews();
  }, []);

  const proccedToView = () => {
    setProceedToDetails(true);
    setOpen(false);
    getViews(activeView);
    setLoading(true);
  };

  useEffect(() => {
    if (loading) {
      setLoading(false);
    }
  }, [views]);

  return (
    <div style={{ marginTop: "70px", width: "100%" }}>
      <h2>Views</h2>
      {loading && <img src={loadingImg} alt="loading image" />}
      {!proceedToDetails && (
        <View
          dropdownOptions={viewsDropdown}
          setSelectedView={setSelectedView}
          selectedView={selectedView}
          proccedToView={proccedToView}
          setActiveView={setActiveView}
        />
      )}
      {!loading && selectedView && proceedToDetails && (
        <Grid container spacing={1}>
          <Grid item xs={12} sm={2} md={4}></Grid>
          <Grid item xs={12} sm={2} md={2}></Grid>
          <Grid item xs={12} sm={2} md={6}>
            <SelectedView
              count={views?.count}
              selectedView={selectedView}
              setOpen={setOpen}
              open={open}
            />
          </Grid>
        </Grid>
      )}
      {!loading &&
        selectedView &&
        proceedToDetails &&
        views?.results &&
        views?.results?.length > 0 && (
          <Filters
            data={views || []}
            proceedToDetails={proceedToDetails}
            selectedView={selectedView}
            activeView={activeView}
          />
        )}
      {/* Table */}
      {!loading && selectedView && proceedToDetails && (
        <ViewTable data={views || []} activeView={activeView} />
      )}
      {/* change view modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {/* ts-ignore */}
          <View
            dropdownOptions={viewsDropdown || []}
            setSelectedView={setSelectedView}
            proccedToView={proccedToView}
            selectedView={selectedView}
            style={{ width: "100%", padding: "0" }}
            setActiveView={setActiveView}
            isModalOpen={open}
          />
        </Box>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.OffLineSupporteReducer.loading,
  views: state.OffLineSupporteReducer.views,
  viewsDropdown: state.OffLineSupporteReducer.viewsDropdown,
});

export default connect(mapStateToProps, {
  getViews,
})(Views);
