import { ACTIONS, LoginActionTypes } from '../interfaces/actionTypes/loginTypes';
import SecureStorage from "../config/SecureStorage";

type LoginInitialState = {
  user: object;
  status: boolean;
  success: boolean;
  otp: any;
  userDetails: Array<any>;
  passwordStatus: boolean;
  notificationMessage: Array<any>;
  locationDataFromPusher: Array<any>;
  loading: boolean;
  loadingOTP: boolean;
  breakResponse: Array<any>;
  addressType: Array<any>;
  userProfile: Array<any>;
  whatsappDataFromPusher: Array<any>;
  whatsappDataFromPusherForSL: Array<any>;
  whatsappAddressIdDetail: Array<any>;
  agentsNotesData: Array<any>;
  cronLeadChatUpdate: Array<any>;
  createModuleData: Array<any>;
  postCreateModuleData: Array<any>;
  postUpdateModuleData: Array<any>;
  deleteCreateModuleData: Array<any>;
  performanceTableData: Array<any>;
  performanceTableDataAll: Array<any>;
  assignmentQuestionMasterData: Array<any>;
  assignmentAnswerMasterPutData: Array<any>;
  assignmentAnswerMasterPostData: Array<any>;
  chatAnalyticsAgentData: Array<any>;
  chatAnalyticsChatData: Array<any>;
  chatAnalyticsReportsData: Array<any>;
  chatAnalyticsHourlyReportsData: Array<any>;
  chatAssignedFromPusher: Array<any>;
  chatUnassignedFromPusher: Array<any>;
  agentsList: Array<any>;
  latestLeadAddress: Array<any>;
  whatsappStatusMessageId: Array<any>;
  customerSummaryUsingUHID: Array<any>;
  customerDetailsUsingUHID: Array<any>;
  loadingSummary: boolean;
  loadingDetails: boolean;
  doctorAnswerList: Array<any>;
  consultationQuestionReducer: Array<any>;
  consultationQuestionUniqueCustomer: Array<any>;
  bookingListLoader: boolean;
  analyticsQueueLogLoader: boolean;
  refDoctor: Array<any>;
  analyticsQueueLog: Array<any>;
  reportStatusLiveData: Array<any>;
  postRefReducer: Array<any>;
  recommendedPackagesWithCityId: Array<any>;
  packagesList: Array<any>;
  moduleInteraction: Array<any>;
  ticketsCommentAdded: Array<any>;
  packageFromTest: Array<any>;
  packageFromTestLoader: boolean;
  packageQuestionLoader: boolean;
  packageCompareLoader: boolean;
  packageQuestion: Array<any>;
  packageCompare: Array<any>;
  postPackageAnswerReducer: Array<any>;
  uhidsList: Array<any>;
  mergeUniqueCustomer: Array<any>;
  leadRepeatedStatus: Array<any>;
  containerSlots: Array<any>;
  phleboBookingSlot: Array<any>;
  phleboAllBooking: Array<any>;
  nearestPhlebo: Array<any>;
  userBookingDetailsData: Array<any>;
  notServiceableArea: Array<any>;
  startWhatsappChatMessage: Array<any>;

}

const initialState: LoginInitialState = {
  user: {
    username: "",
    email: "",
    age: 0
  },
  status: false,
  success: false,
  otp: false,
  userDetails: [],
  passwordStatus: true,
  loading: false,
  loadingOTP: false,
  notificationMessage: [],
  locationDataFromPusher: [],
  breakResponse: [],
  addressType: [],
  userProfile: [],
  whatsappDataFromPusher: [],
  whatsappDataFromPusherForSL: [],
  whatsappAddressIdDetail: [],
  agentsNotesData: [],
  chatAnalyticsAgentData: [],
  cronLeadChatUpdate: [],
  createModuleData: [],
  postCreateModuleData: [],
  postUpdateModuleData: [],
  deleteCreateModuleData: [],
  performanceTableData: [],
  performanceTableDataAll: [],
  assignmentQuestionMasterData: [],
  assignmentAnswerMasterPutData: [],
  assignmentAnswerMasterPostData: [],
  agentsList: [],
  chatAnalyticsReportsData: [],
  chatAnalyticsChatData: [],
  chatAnalyticsHourlyReportsData: [],
  chatAssignedFromPusher: [],
  chatUnassignedFromPusher: [],
  latestLeadAddress: [],
  whatsappStatusMessageId: [],
  customerSummaryUsingUHID: [],
  customerDetailsUsingUHID: [],
  loadingSummary: false,
  loadingDetails: false,
  doctorAnswerList: [],
  consultationQuestionUniqueCustomer: [],
  consultationQuestionReducer: [],
  bookingListLoader: false,
  analyticsQueueLogLoader: false,
  refDoctor: [],
  analyticsQueueLog: [],
  postRefReducer: [],
  reportStatusLiveData: [],
  recommendedPackagesWithCityId: [],
  packagesList: [],
  moduleInteraction: [],
  ticketsCommentAdded: [],
  packageFromTest: [],
  packageFromTestLoader: false,
  packageQuestion: [],
  packageQuestionLoader: false,
  packageCompareLoader: false,
  packageCompare: [],
  postPackageAnswerReducer: [],
  uhidsList: [],
  mergeUniqueCustomer: [],
  leadRepeatedStatus: [],
  containerSlots: [],
  phleboBookingSlot: [],
  phleboAllBooking: [],
  nearestPhlebo: [],
  userBookingDetailsData: [],
  startWhatsappChatMessage: [],
  notServiceableArea:[],

};

const userReducer = (state = initialState, action: LoginActionTypes) => {
  switch (action.type) {
    case ACTIONS.SET_USER:
      return {
        ...state,
        user: action.payload,
        status: true
      };
    case ACTIONS.LOCATION_DATA_FROM_PUSHER:
      return {
        ...state,
        locationDataFromPusher: action.payload,
      };
      case ACTIONS.START_WHATSAPP_CHAT:
        return {
          ...state,
          startWhatsappChatMessage: false,
          loading: false,
        };
      case ACTIONS.GET_USER_BOOKING_DETAILS:
        return {
          ...state,
          userBookingDetailsData: action.payload,
        };  
    case ACTIONS.GET_USER:
      return {
        ...state,
        passwordStatus: action.payload,
        status: true
      };
    case ACTIONS.GET_USER_PROFILE:
      return {
        ...state,
        userProfile: action.payload,
        status: true
      };
    case ACTIONS.RESET_PASSWORD:
      return {
        ...state,
        success: action.payload,
      };
    case ACTIONS.GET_BREAK_RESPONSE:
      return {
        ...state,
        breakResponse: action.payload,
        loading: false,
      };
    case ACTIONS.SEND_OTP:
      return {
        ...state,
        otp: action.payload,
      };
    case ACTIONS.LOGOUT:
      SecureStorage.removeItem("token");
      window.location.href = '/';
      return {
        ...state,
        ...initialState
      };
    case ACTIONS.SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case ACTIONS.GET_CHAT_ANALYTICS_AGENT:
      return {
        ...state,
        chatAnalyticsAgentData: action.payload,
        loading: false,
      };
    case ACTIONS.SET_LOADING_OTP:
      return {
        ...state,
        loadingOTP: action.payload
      };
    case ACTIONS.GET_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_FIREBASE_NOTIFICATION:
      return {
        ...state,
        notificationMessage: action.payload,
        loading: false,
      };
    case ACTIONS.WHATSAPP_DATA_FROM_PUSHER:
      return {
        ...state,
        whatsappDataFromPusher: action.payload,
      };
    case ACTIONS.WHATSAPP_DATA_FROM_PUSHER_FOR_SL:
      return {
        ...state,
        whatsappDataFromPusherForSL: action.payload,
      };
    case ACTIONS.WHATSAPP_ADDRESS_ID_DETAIL:
      return {
        ...state,
        whatsappAddressIdDetail: action.payload,
      };
    case ACTIONS.GET_ADDRESS_TYPE_SEATING:
      return {
        ...state,
        addressType: action.payload,
        loading: false,
      };
    case ACTIONS.GET_AGENTS_NOTES_DATA:
      return {
        ...state,
        agentsNotesData: action.payload,
      };
    case ACTIONS.CRON_LEAD_CHAT_UPDATE:
      return {
        ...state,
        cronLeadChatUpdate: action.payload,
      };
    case ACTIONS.CREATE_MODULE:
      return {
        ...state,
        createModuleData: action.payload,
      };
    case ACTIONS.POST_CREATE_MODULE:
      return {
        ...state,
        postCreateModuleData: action.payload,
      };
    case ACTIONS.POST_UPDATE_MODULE:
      return {
        ...state,
        postUpdateModuleData: action.payload,
      };
    case ACTIONS.DELETE_CREATE_MODULE:
      return {
        ...state,
        deleteCreateModuleData: action.payload,
      };
    case ACTIONS.PERFORMANCE_TABLE:
      return {
        ...state,
        performanceTableData: action.payload,
      };
    case ACTIONS.PERFORMANCE_TABLE_ALL:
      return {
        ...state,
        performanceTableDataAll: action.payload,
        loading: false,
      };
    case ACTIONS.ASSIGNMENT_QUESTION_MASTER:
      return {
        ...state,
        assignmentQuestionMasterData: action.payload,
      };
    case ACTIONS.PUT_ASSIGNMENT_ANSWER_MASTER:
      return {
        ...state,
        assignmentAnswerMasterPutData: action.payload,
      };
    case ACTIONS.POST_ASSIGNMENT_ANSWER_MASTER:
      return {
        ...state,
        assignmentAnswerMasterPostData: action.payload,
      };
    case ACTIONS.GET_AGENTS:
      return {
        ...state,
        agentsList: action.payload,
      };
    case ACTIONS.GET_CHAT_ANALYTICS_CHAT:
      return {
        ...state,
        chatAnalyticsChatData: action.payload,
        loadingDetails: false,
      };
    case ACTIONS.GET_CHAT_ANALYTICS_REPORTS:
      return {
        ...state,
        chatAnalyticsReportsData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CHAT_ANALYTICS_HOURLY_REPORTS:
      return {
        ...state,
        chatAnalyticsHourlyReportsData: action.payload,
        loading: false,
      };
    case ACTIONS.CHAT_ASSIGNED_FROM_PUSHER:
      return {
        ...state,
        chatAssignedFromPusher: action.payload,
      };
    case ACTIONS.CHAT_UNASSIGNED_FROM_PUSHER:
      return {
        ...state,
        chatUnassignedFromPusher: action.payload,
      };
    case ACTIONS.LATEST_LEAD_ADDRESS:
      return {
        ...state,
        latestLeadAddress: action.payload,
      };
    case ACTIONS.WHATSAPP_STATUS_MESSAGE_ID:
      return {
        ...state,
        whatsappStatusMessageId: action.payload,
      };
    case ACTIONS.CUSTOMER_SUMMARY_USING_UHID:
      return {
        ...state,
        customerSummaryUsingUHID: action.payload,
        loadingSummary: false,
      };
    case ACTIONS.CUSTOMER_DETAILS_USING_UHID:
      return {
        ...state,
        customerDetailsUsingUHID: action.payload,
        loadingDetails: false,
      };
    case ACTIONS.SET_LOADING_SUMMARY:
      return {
        ...state,
        loadingSummary: action.payload
      };
    case ACTIONS.SET_LOADING_DETAILS:
      return {
        ...state,
        loadingDetails: action.payload
      };
    case ACTIONS.GET_DOCTOR_ANSWER_LIST:
      return {
        ...state,
        doctorAnswerList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_DOCTOR_CONSULTATION_UNIQUE_CUSTOMER:
      return {
        ...state,
        consultationQuestionUniqueCustomer: action.payload,
        loading: false,
      };
    case ACTIONS.GET_DOCTOR_CONSULTATION_QUESTION:
      return {
        ...state,
        consultationQuestionReducer: action.payload,
        loading: false,
      };
    case ACTIONS.SET_ALL_BOOKING_LOADING:
      return {
        ...state,
        bookingListLoader: action.payload,
      };
    case ACTIONS.GET_REF_DOCTOR:
      return {
        ...state,
        refDoctor: action.payload,
        loading: false,
      };
    case ACTIONS.POST_REF_DOCTOR:
      return {
        ...state,
        postRefReducer: action.payload,
        loading: false,
      };
    case ACTIONS.GET_REPORT_STATUS_LIVE:
      return {
        ...state,
        reportStatusLiveData: action.payload,
      };
    case ACTIONS.GET_RECOMMENDED_PACKAGES_WITH_CITY_ID:
      return {
        ...state,
        recommendedPackagesWithCityId: action.payload,
        bookingListLoader: false,
      };
    case ACTIONS.GET_PACKAGES_LIST:
      return {
        ...state,
        packagesList: action.payload,
        bookingListLoader: false,
      };
    case ACTIONS.MODULE_INTERACTION:
      return {
        ...state,
        moduleInteraction: action.payload,
      };
    case ACTIONS.ADD_TICKETS_COMMENT:
      return {
        ...state,
        ticketsCommentAdded: action.payload,
        bookingListLoader: false,
      };
    case ACTIONS.GET_PACKAGE_FROM_TEST:
      return {
        ...state,
        packageFromTest: action.payload,
        packageFromTestLoader: false,
      };
    case ACTIONS.SET_LOADING_PACKAGE_FROM_TEST:
      return {
        ...state,
        packageFromTestLoader: action.payload,
      };
    case ACTIONS.GET_PACKAGE_QUESTION:
      return {
        ...state,
        packageQuestion: action.payload,
        packageQuestionLoader: false,
      };
    case ACTIONS.SET_LOADING_PACKAGE_QUESTION:
      return {
        ...state,
        packageQuestionLoader: action.payload,
      };
    case ACTIONS.SET_LOADING_PACKAGE_COMPARE:
      return {
        ...state,
        packageCompareLoader: action.payload,
      };
    case ACTIONS.GET_COMPARE_PACKAGE:
      return {
        ...state,
        packageCompare: action.payload,
        packageCompareLoader: false,
      };
    case ACTIONS.POST_PACKAGE_ANSWERS:
      return {
        ...state,
        postPackageAnswerReducer: action.payload,

      };
    case ACTIONS.GET_UHIDS_LIST:
      return {
        ...state,
        uhidsList: action.payload,
        bookingListLoader: false,
      };
    case ACTIONS.MERGE_UNIQUE_CUSTOMER:
      return {
        ...state,
        mergeUniqueCustomer: action.payload,
        bookingListLoader: false,
      };
    case ACTIONS.GET_LEAD_REPEATED_STATUS:
      return {
        ...state,
        leadRepeatedStatus: action.payload,
      };
    case ACTIONS.GET_CONTAINER_SLOTS:
      return {
        ...state,
        containerSlots: action.payload,
        bookingListLoader: false,
      };
    case ACTIONS.GET_CHAT_ANALYTICS_QUEUE_LOG:
      return {
        ...state,
        analyticsQueueLog: action.payload,
        analyticsQueueLogLoader: false,
      };
    case ACTIONS.SET_LOADING_QUEUE:
      return {
        ...state,
        analyticsQueueLogLoader: action.payload,
      };
    case ACTIONS.GET_PHLEBO_BOOKING_SLOT:
      return {
        ...state,
        phleboBookingSlot: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PHLEBO_ALL_BOOKING:
      return {
        ...state,
        phleboAllBooking: action.payload,
        loading: false,
      };
    case ACTIONS.GET_NEAREST_PHLEBO:
      return {
        ...state,
        nearestPhlebo: action.payload,
        loading: false,
      };
      case ACTIONS.GET_NON_SERVICEABLE_AREA_LIST:
        return {
          ...state,
          notServiceableArea:action.payload,
          loading: false,
        };
     
    default:
      return state;
  }
};

export default userReducer;
