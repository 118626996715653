import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
} from "@material-ui/core/styles";
import { useTheme } from '@mui/material/styles';
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import "../react-sliding-pane.css";
import {
  LOCALITY,
  ADDRESS_LINE1,
  ADDRESS_LINE2,
  LANDMARK,
} from "../../constant";
import SearchIcon from "@mui/icons-material/Search";
import ImageTwoToneIcon from "@material-ui/icons/ImageTwoTone";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@material-ui/icons/Close";
import MessageIcon from "@material-ui/icons/Message";
import EditIcon from "@material-ui/icons/Edit";
import { useDispatch } from "react-redux";
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import Chip from '@mui/material/Chip';
// import { ACTIONS as ACTIONS1 } from '../../../../interfaces/actionTypes/loginTypes';
import {
  Card,
  Checkbox,
  Grid,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Dialog,
  DialogActions,
  InputAdornment,
  DialogContent,
  DialogTitle,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import DialogContentText from '@mui/material/DialogContentText';
import Autocomplete from "@material-ui/lab/Autocomplete";
import CommentsModal2 from "../../Comments/Comment2";
import CommentsModal from "../../Comments/comment";
import {
  checkGeoFenceArea,
  getPinCode,
  getPackage,
  createNewBooking,
  getCoupons,
  getLeads,
  getLeadDetails,
  resetLeadDetails,
  getAvailableSlots,
  getRedCoing,
  checkGeoFenchMapMyIndia,
  checkGeoFenchMapMyIndiaPilot,
  getMapMyIndiaAccessToken,
  getCenterInfo,
  getceCentre,
  getTimeslots,
  createPilotNewBooking,
  getBreakResponse,
  resetBookings,
  getLeadById,
  getCities,
  checkGeoFenchMapMyIndiaPilotForCustomerAddress,
  getCommunicationOnWhatsapp,
  SendLocationRequest,
  getCrmLeadAddress,
  postCrmLeadAddress,
  getNewPackage,
  getWhatsappMessageStatus,
  getReferralCurrentBalance,
  getCouponReferralValidation,
  getLeadSubscriptionsData,
  addressJsonPost,
  getLeadPrescriptionData,
  getPackageAutoSelected,
} from "../../../actions/OffLineSupportActions";
import Button from "@mui/material/Button";
import "./index.sass";
import { connect } from "react-redux";
import moment from "moment";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { Link, useHistory } from "react-router-dom";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { GENDER_CHOICE } from "../../constant";
import { ACTIONS } from "../../../interfaces/actionTypes/OffLineSupportType";
import WhatsAppModal from "../../Comments/whatsappModal";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import Loader from '../../Loader2'
import Loader80 from '../../Loader80'
import ReplayIcon from '@mui/icons-material/Replay';
// import { mappls } from "mappls-web-maps";
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import {
  getLatestAddressByLeadId,
  getCustomerSummaryUsingUHID,
  getCustomerDetailsUsingUHID,
  postModuleInteraction,
  getPackageQuestion,
  postPackageAnswer,
} from "../../../../actions/loginActions";
import Paper from "@mui/material/Paper";
import { getRefDoctor, postRefDoctor, getRecommendedPackagesWithCityId, getPackageList, getContainerSlots } from "../../../../actions/loginActions"
import DataTable from "../../../../components/DataTable";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: "60vh",
      width: "50vh",
    },
  },
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    slotContainer: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
    },
    slot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      margin: "0.5rem",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      width: "100%",
    },
    head: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: "2rem",
    },
    selectedSlot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
      cursor: "pointer",
      margin: "0.5rem",
    },
    chips: {
      display: "flex",
    },
    chip: {
      margin: 2,
      background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
      maxWidth: "130px",
    },
    table: {
      maxWidth: "100%",
      margin: "auto",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "100%",
      flexGrow: 21,
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 40px 10px",
    },
    leftContentHeader: {
      width: "50%",
      float: "left",
    },
    rightContentHeader: {
      width: "50%",
      float: "right",
      textAlign: "right",
      paddingRight: "20px",
    },
    contentTable: {
      width: "100%",
      padding: "20px 10px 10px 10px",
      background: "#ffffff",
      margin: "10px",
      boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
    },
    mapCSS: {
      height: "300px",
      width: "100%",
    },
    packageLink: {
      color: "#ffffff",
      textDecoration: "none",
    },
    dialog: {
      zIndex: 2,
    },
    tableContainer: {
      marginTop: "1rem",
      position: "sticky",
      top: "200px",
    },
  })
);

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
      fontWeight: 600,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface Props {
  lead: any;
  getPinCode: any;
  pin_code: any;
  getPackage: any;
  packageList: any;
  couponList: any;
  getCoupons: any;
  createNewBooking: any;
  getLeads: any;
  leads: any;
  getLeadDetails: any;
  lead_details: any;
  booking_slots: any;
  resetLeadDetails: any;
  createPilotBooking: any;
  getAvailableSlots: any;
  loading: boolean;
  sameNumberBooking: boolean;
  checkGeoFenceArea: any;
  getRedCoing: any;
  redCoin: any;
  checkGeoFenchMapMyIndia: any;
  checkGeoFenchMapMyIndiaPilot: any;
  geoFencesMapMyIndia: any;
  mapmyindiaAccessToken: any;
  getMapMyIndiaAccessToken: any;
  getCenterInfo: any;
  centerInfo: any;
  ceCenterForCC: any;
  getceCentre: any;
  getTimeslots: any;
  timeslots: any;
  createPilotNewBooking: any;
  userDetails: any;
  breakResponse: any;
  getBreakResponse: any;
  resetBookings: any;
  geoLoading: boolean;
  geoCheckError: string;
  geoCheckCityId: string;
  getLeadById: any;
  leadById: any;
  getCities: any;
  cities: any;
  getCommunicationOnWhatsapp: any;
  communicationWhatsapp: any;
  SendLocationRequest: any;
  checkGeoFenchMapMyIndiaPilotForCustomerAddress: any;
  geoFencesMapMyIndiaForCustomer: any;
  getCrmLeadAddress: any;
  crmLeadAddress: any;
  postCrmLeadAddress: any;
  crmPostLeadAddress: any;
  locationDataFromPusher: any;
  getNewPackage: any;
  leadAddressLoading: any;
  whatsappAddressIdDetail: any;
  getWhatsappMessageStatus: any;
  whatsappMessageStatus: any;
  getReferralCurrentBalance: any;
  getCouponReferralValidation: any;
  referralCurrentBalance: any;
  couponReferralValidation: any;
  getLeadSubscriptionsData: any;
  leadSubscriptionsData: any;
  bookingFrom: any;
  addressJsonPost: any;
  getLatestAddressByLeadId: any;
  latestLeadAddress: any;
  getCustomerSummaryUsingUHID: any;
  customerSummaryUsingUHID: any;
  getCustomerDetailsUsingUHID: any;
  customerDetailsUsingUHID: any;
  loadingSummary: any;
  loadingDetails: any;
  getLeadPrescriptionData: any;
  leadPrescription: any;
  Prescriptionloader: any;
  getRefDoctor: any;
  postRefDoctor: any;
  refDoctor: any;
  postRefReducer: any;
  geoCheckLatitude: number;
  geoCheckZoneId: number;
  geoCheckLongitude: number
  getRecommendedPackagesWithCityId: any;
  recommendedPackagesWithCityId: any;
  getPackageList: any;
  packagesList: any;
  bookingListLoader: any;
  postModuleInteraction: any;
  getPackageAutoSelected: any;
  autoSelectedPackageReducer: any;
  getPackageQuestion: any;
  packageQuestion: any;
  postPackageAnswer: any;
  postPackageAnswerReducer: any;
  getContainerSlots: any;
  containerSlots: any;
}

const BookingWithLocalitySearch: React.FC<Props> = ({
  lead,
  getPinCode,
  pin_code,
  packageList,
  getPackage,
  couponList,
  getCoupons,
  createNewBooking,
  getLeads,
  leads,
  getLeadDetails,
  lead_details,
  booking_slots,
  resetLeadDetails,
  createPilotBooking,
  getAvailableSlots,
  sameNumberBooking,
  checkGeoFenceArea,
  getRedCoing,
  redCoin,
  loading,
  checkGeoFenchMapMyIndia,
  checkGeoFenchMapMyIndiaPilot,
  geoFencesMapMyIndia,
  mapmyindiaAccessToken,
  getMapMyIndiaAccessToken,
  getCenterInfo,
  centerInfo,
  getceCentre,
  ceCenterForCC,
  getTimeslots,
  timeslots,
  createPilotNewBooking,
  userDetails,
  breakResponse,
  getBreakResponse,
  resetBookings,
  geoLoading,
  geoCheckError,
  geoCheckCityId,
  getLeadById,
  leadById,
  cities,
  getCities,
  getCommunicationOnWhatsapp,
  communicationWhatsapp,
  SendLocationRequest,
  checkGeoFenchMapMyIndiaPilotForCustomerAddress,
  geoFencesMapMyIndiaForCustomer,
  getCrmLeadAddress,
  crmLeadAddress,
  postCrmLeadAddress,
  crmPostLeadAddress,
  locationDataFromPusher,
  getNewPackage,
  leadAddressLoading,
  whatsappAddressIdDetail,
  getWhatsappMessageStatus,
  whatsappMessageStatus,
  getReferralCurrentBalance,
  getCouponReferralValidation,
  referralCurrentBalance,
  couponReferralValidation,
  getLeadSubscriptionsData,
  leadSubscriptionsData,
  bookingFrom,
  addressJsonPost,
  getLatestAddressByLeadId,
  latestLeadAddress,
  getCustomerSummaryUsingUHID,
  customerSummaryUsingUHID,
  getCustomerDetailsUsingUHID,
  customerDetailsUsingUHID,
  loadingSummary,
  loadingDetails,
  getLeadPrescriptionData,
  leadPrescription,
  Prescriptionloader,
  getRefDoctor,
  postRefDoctor,
  refDoctor,
  postRefReducer,
  geoCheckLatitude,
  geoCheckZoneId,
  geoCheckLongitude,
  getRecommendedPackagesWithCityId,
  recommendedPackagesWithCityId,
  getPackageList,
  packagesList,
  bookingListLoader,
  postModuleInteraction,
  getPackageAutoSelected,
  autoSelectedPackageReducer,
  getPackageQuestion,
  packageQuestion,
  postPackageAnswer,
  postPackageAnswerReducer,
  getContainerSlots,
  containerSlots,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  let dispatch = useDispatch();
  const history = useHistory();
  const [inputList, setInputList] = useState<any>([
    {
      customerId: "",
      customerDesignation: "",
      designation: "",
      customerName: "",
      customerAge: "",
      customerGender: "",
      customerPackage: [],
      packagePrice: 0,
      dateOfBirth: "",
      is_prescribed: "",
      bookingFor: "",
      passport: "",
      aadhar: "",
      oldNameUHID: "",
    },
  ]);
  const [cType, setCType] = useState<any>("none");
  const [cValue, setCValue] = useState<any>("");
  const [collection_date, setCollection_date] = useState<String>("");
  const [containerDate, setContainerDate] = useState<String>("");
  const [containerSlot, setContainerSlot] = useState<String>("");
  const [checkedContainerDeliverySlot, setCheckedContainerDeliverySlot] = useState<any>("");
  const [urineContainer, setUrineContainer] = useState<any>(false);
  const [pincode, setPinCode] = useState<string>("");
  const [zoneName, setZoneName] = useState<String>("");
  const [timeSlots, setTimeSlots] = useState<Array<{ "available_slots": number }>>([]);
  const [selectedSlot, setSelectedSlot] = useState<Number>(0);
  const [findSelectedSlot, setFindSelectedSlot] = useState<any>("");
  const [customer_phonenumber, setCustomer_phonenumber] = useState<String>("");
  const [customer_alternatenumber, setCustomer_alternatenumber] =
    useState<String>("");
  const [customer_whatsapppnumber, setCustomer_whatsapppnumber] =
    useState<String>("");
  const [customer_address, setCustomer_address] = useState<any>("");
  const [customer_email, setCustomer_email] = useState<String>("");
  const [customer_landmark, setCustomer_landmark] = useState<string>("");
  const [customer_landmark1, setCustomer_landmark1] = useState<string>("");
  const [customerLatitude, setCustomerLatitude] = useState<any>();
  const [customerLongitude, setCustomerLongitude] = useState<any>();
  const [customer_areapincode, setCustomer_areapincode] = useState<string>("");
  const [customer_areapincode_id, setCustomer_areapincode_id] =
    useState<Number>(0);
  //const [customer_areaname, setCustomer_areaname] = useState<String>("");
  const [customer_city, setCustomer_city] = useState<String>("");
  const [customer_aadhar, setCustomer_aadhar] = useState<String>("");
  const [passport, setPassport] = useState<String>("");
  const [price, setPrice] = useState<any>(0);
  const [discount, setDiscount] = useState<any>(0);
  const [discountDueSmartPackage, setDiscountDueSmartPackage] =
    useState<any>(0);
  const [totalPrice, setTotalPrice] = useState<any>(0);
  const [coupon, setCoupon] = useState<Number>(0);
  const [couponCode, setCouponCode] = useState<string>("");
  const [extraCharge, setExtraCharge] = useState<boolean>(false);
  const [cityId, setCityId] = useState<any>("");
  const [is_pincode, setIsPincode] = useState<boolean>(true);
  const [packageKey, setPackageKey] = useState<any>(0);
  const [defaultEmail, setDefaultEmail] = useState<any>(
    "customer.reports@redcliffelabs.com"
  );
  const [mobileValidation, setMobileValidation] = useState({
    customer_alternatenumber: true,
    customer_phonenumber: true,
    customer_whatsapppnumber: true,
  });
  const [readology, setReadology] = useState<any>(false);
  const [search, setSearch] = useState<String>("");
  const [zoneId, setZoneId] = useState<any>("");
  const timer = useRef<any>(0);
  const handleSearch = (e: React.ChangeEvent<{ value: unknown }>) => {
    setAllUHIDCustomer([]);
    setSearch(e.target.value as string);
  };
  const [addressLine2, setAddressLine2] = useState<string>();
  const [favoritePlace, setFavoritePlace] = useState<any>();
  const [openLocationModal, setOpenLocationModal] = useState<boolean>(false);
  const [switchEditButton, setSwitchEditButton] = useState<boolean>(false);
  const [bookingEnabled, setBookingEnabled] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isPaneOpen, setPaneOpen] = useState<boolean>(false);
  const [singlePackage, setSinglePackage] = useState<any>({});
  const [mobError, setMobError] = React.useState<string>("");
  const [altError, setAltError] = React.useState<string>("");
  const [whatsError, setWhatsError] = React.useState<string>("");
  const [tncPerCentage, setTnPercentage] = useState<string>("");
  const [couponMessage, setCouponMessage] = useState<string>("");
  const [tncMessage, setTncMessage] = useState<string>("");
  const [redCoinMessage, setRedCoinMessage] = useState<any>("");
  const [redCashCheck, setRedCashCheck] = useState<boolean>(false);
  const [totalRedCoin, setTotalRedCoin] = useState<any>("");
  const [loading1, setLoading1] = useState<any>(false);
  const [redDisc, setRedDisc] = useState<any>(0);
  const [redCashDiscountPercentage, setRedCashDiscountPercentage] = useState<any>(0);
  const [error, setError] = useState("");
  // const [couponType, setCouponType] = useState<boolean>(false);
  const [redCouponDiscount, setRedCouponDiscount] = useState<number>(0);
  const [lead_customer_phonenumber, setLeadCustomer_phonenumber] =
    useState<string>("");
  const [lead_customer_whatsapppnumber, setLeadCustomer_whatsapppnumber] =
    useState<string>("");

  const [lead_customer_alter, setLeadCustomer_alter] = useState<string>("");
  // const [mapData, setMapData] = useState<any>([]);
  // const [mapOpen, setMapOpen] = useState<boolean>(false);
  const [backSpaceDetect, setbackSpaceDetcet] = useState<boolean>(false);
  const [couponKey, setCouponKey] = useState<any>(0);
  //Uhid Implementation start
  const [uhidModalOpen, setUhidmodalOpen] = useState<boolean>(false);
  const [selectPatientOpen, setSelectPatientOpen] = useState<boolean>(false);
  const [collectionCenterId, setCollectionCenterId] = useState<any>("");
  const [collectionKey, setCollectionkey] = useState<any>(0);
  const [collectionType, setCollectionType] = useState<any>("homecollection");
  const [zoneKey, setZoneKey] = useState<any>(0);
  const [leadData, setLeadData] = useState<any>(0);
  const [cityName, setCityName] = useState<string>("");
  const [dummyValue, setDummyValue] = useState<any>("XXXXXXXXXX");
  const [dummyValueMain, setDummyValueMain] = useState<boolean>(false);
  const [dummyValueAlter, setDummyValueAlter] = useState<boolean>(false);
  const [dummyValueWhats, setDummyValueWhats] = useState<boolean>(false);
  const [phleboCost, setPhleboCost] = useState<any>(0);
  const [minAmountPhleboCost, setMinAmountPhleboCost] = useState<any>(0);
  const [valCoin, setValCoin] = useState<boolean>(false);
  const [commentModalOpen, setCommentModalOpen] = useState<boolean>(false);
  const [openM, setOpenM] = useState<boolean>(false);
  const [openWhatsapp, setOpenWhatsapp] = useState<boolean>(false);
  const [showMessage, setShowMessage] = useState<boolean>(false)
  const [hitApiForSendWhatsappMessage, setHitApiForSendWhatsappMessage] = useState<boolean>(false)
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false)
  const [addressExeData, setAddressExeData] = useState<any>("")
  const [isPackageTextPresent, setIsPackageTextPresent] = useState<boolean>(false);
  const [dateSelected, setDateSelected] = useState<any>(-1);
  const [addressObjData, setAddressObjData] = useState<any>("")
  const [addressCheckFlag, setAddressCheckFlag] = useState<boolean>(false)
  const [distanceState, setDistanceState] = useState<any>({
    destinationLat: "",
    destinationLong: "",
    distance: null,
    startLat: "",
    startLong: "",
  });
  const [nameValidation, setNameValidation] = useState<any>(true);
  const [checkedWay, setCheckedWay] = useState<any>("");
  const verifyName = () => {
    const nameRegex = /^([a-zA-Z0-9 ]){2,500}$/;
    if (search && !nameRegex.test(search.toString())) {
      setNameValidation(false);
    }
  };
  const [whatsappLeadSearch, setWhatsappLeadSearch] = useState<any>("");
  const [phleboRemarks, setPhleboRemarks] = useState<any>();
  const [openVerifiedLocationModal, setOpenVerifiedLocationModal] =
    useState<boolean>(false);
  const [bookingIdLocationModal, setBookingIdLocationModal] =
    useState<number>(0);
  const [leadIdLocationModal, setLeadIdLocationModal] = useState<number>(0);
  const [apiHitCount, setApiHitCount] = useState<number>(0);
  const [customerLatitudeModal, setCustomerLatitudeModal] = useState<any>();
  const [customerLongitudeModal, setCustomerLongitudeModal] = useState<any>();
  const [customerLatitude1Modal, setCustomerLatitude1Modal] = useState<any>();
  const [customerLongitude1Modal, setCustomerLongitude1Modal] = useState<any>();
  const [cityIdModal, setCityIdModal] = useState<any>("");
  const [addressLine2Modal, setAddressLine2Modal] = useState<string>();
  const [favoritePlaceModal, setFavoritePlaceModal] = useState<any>();
  const [customer_addressModal, setCustomer_addressModal] =
    useState<String>("");
  const [customer_landmark1Modal, setCustomer_landmark1Modal] =
    useState<string>("");
  const [verifyKey, setVerifyKey] = useState<boolean>(false);
  const [addressId, setAddressId] = useState<string>("");
  const [addressIdCheck, setAddressIdCheck] = useState<number>(0);
  const [locationMessageStatus, setLocationMessageStatus] = useState<string>("");
  const [saveOldAddress, setSaveOldAddress] = useState<any>("");
  const [openQestionModel, setQuestionModel] = useState<any>(false);
  const [manualCouponCode, setManualCouponCode] = useState<any>("");
  const [referralPointsDiscount, setReferralPointsDiscount] = useState<any>(0);
  const [referralPointsApplied, setReferralPointsApplied] = useState<any>(false);
  const [isHitReferralCurrentBalanceAPI, setIsHitReferralCurrentBalanceAPI] = useState(false);
  const [referralPoints, setReferralPoints] = useState<any>("");
  const [openLoyaltyModal, setOpenLoyaltyModal] = useState<boolean>(false);
  const [openInformationModal, setOpenInformationModal] = useState<boolean>(false);
  const [redProKeyOneYear, setRedProKeyOneYear] = useState<boolean>(false);
  const [redProKeyThreeYear, setRedProKeyThreeYear] = useState<boolean>(false);
  const [alreadyARedPro, setAlreadyARedPro] = useState<any>(false);
  const [redProMembershipDiscount, setRedProMembershipDiscount] = useState<any>(0);
  const [redProMembershipDiscountPercentage, setRedProMembershipDiscountPercentage] = useState<any>(0);
  const [proMembershipPrice, setProMembershipPrice] = useState<any>(0);
  const [requestedForHardCopy, setRequestedForhHardCopy] = useState<any>(false);
  const [hardCopyPrice, setHardCopyPrice] = useState<any>(0);
  const [disableCreateBtn, setDisableCreateBtn] = useState<boolean>(false);
  const [partnerCodeDiscount, setPartnerCodeDiscount] = useState<boolean>(false);
  const [deniedEmailCheck, setDeniedEmailCheck] = useState<boolean>(false);
  const [percentageApplyFlag, setPercentageApplyFlag] = useState<boolean>(false)
  const [saveAddressData, setSaveAddressData] = useState<any>("")
  const [key, setKey] = React.useState<any>(false);
  const [openHealth, setOpenHealth] = React.useState<any>(false);
  const [openHealthInner, setOpenHealthInner] = React.useState<any>(false);
  const [loaderPatientDetails, setLoaderPatientDetails] = React.useState<any>(false);
  const [tabData, setTabData] = React.useState<any>([]);
  const [recommendedPackageId, setRecommendedPackageId] = React.useState<any>([]);
  const [tabLeadUHID, setTabLeadUHID] = React.useState(0);
  const [tabLeadName, setTabLeadName] = React.useState<any>("");
  const [checkSendLocationMessageHit, setCheckSendLocationMessage] = useState<boolean>(false)
  const [checkButtonDisableFlag, setCheckButtonDisableFlag] = useState<boolean>(true)
  const [checkAutomaticsMessageSendFlag, setCheckAutomaticsMessageSendFlag] = useState<boolean>(true)
  const [referringDoctor, setReferringDoctor] = useState<any>("");
  const [referringDoctorName, setReferringDoctorName] = useState<any>("");
  const [createNewRefDoctor, setCreateNewRedDoctor] = useState<any>("")
  const [doctorDef, setDoctorDef] = useState<any>("");
  const [showName, setShowName] = useState<any>(0);
  const [image, setImage] = useState<any>("");
  const [viewImg, setViewImg] = useState<boolean>(false);
  const min = 1;
  const max = 100;
  const random = Math.floor(min + Math.random() * (max - min));

  const [open, setOpen] = React.useState(false);
  const [personName, setPersonName] = React.useState<string[]>([]);
  const [createPackageModel, setCreatePackageModel] = useState<boolean>(false)
  const [packageIdValue, setPackageIdValue] = useState<any>("")
  const [totalPackagePrice, setTotalPackagePrice] = useState<any>("")
  const [packageName, setPackageName] = useState<any>("")
  const [testName, setTestName] = useState<any>("")
  const [createPackage, setCreatePackage] = useState<any>("")
  const [packageCode, setPackageCode] = useState<any>("")
  const [testCode, setTestCode] = useState<any>([])
  const [totalPacCode, setTotalPacCode] = useState<any>("")
  const [autoSelectPackage, setAutoSelectedPackage] = useState<any>("")
  const [customerLatitudeForCC, setcustomerLatitudeForCC] = useState<any>("")
  const [customerLongitudeForCC, setCustomerLongitudeForCC] = useState<any>("")
  const [openAllergyModel, setOpenAllergyModel] = useState<any>(false)
  const [checkAllergyQuestion, setCheckAllergyQuestion] = useState<any>("")
  const [allergyQuestionId, setAllergyQuestionId] = useState<any>("")
  const [allergyQuestionAnswer, setAllergyQuestionAnswer] = useState<any>([])
  const [allergyFlag, setAllergyFlag] = useState<any>(false)
  const [packagePerviousData, setPackagePerivousData] = useState<any>([])
  const [checkBlankAsnwerFlag, setCheckBlankAnswerFlag] = useState<any>(false)
  const [selectPackageId01, setSelectPackageId01] = useState<any>("")
  const [selectPackageObj01, setSelectPackageObj01] = useState<any>([])
  const [selectPackageId02, setSelectPackageId02] = useState<any>("")
  const [selectPackageObj02, setSelectPackageObj02] = useState<any>([])
  const [buttonSelectOne, setButtonSelectOne] = useState<boolean>(false)
  const [buttonSelectTwo, setButtonSelectTwo] = useState<boolean>(false)
  const [overallDiscountLimit, setOverallDiscountLimit] = useState<any>(userDetails?.profile?.overall_discount_limit)

  const handleClose = () => setOpen(false);

  const handleOpen = () => setOpen(true);

  //Uhid Implementation end

  useEffect(() => {
    setOverallDiscountLimit(userDetails?.profile?.overall_discount_limit);
  }, [userDetails])

  useEffect(() => {
    resetBookings();
    resetLeadDetails();
    getMapMyIndiaAccessToken();
    getLeadById();
    getCoupons("?org_type=homedx");
    dispatch({ type: ACTIONS.GET_RED_COIN, payload: [] });
    dispatch({ type: ACTIONS.GET_LEAD_DETAILS, payload: {} });
  }, []);

  useEffect(() => {
    if (
      leads?.count > 0 &&
      locationDataFromPusher?.lead_id === leads?.results[0]?.id
    ) {
      setCustomerLatitudeModal(
        locationDataFromPusher?.booking_location?.latitude
      );
      setCustomerLatitude1Modal(
        locationDataFromPusher?.customer_location?.latitude
      );
      setCustomerLongitudeModal(
        locationDataFromPusher?.booking_location?.longitude
      );
      setCustomerLongitude1Modal(
        locationDataFromPusher?.customer_location?.longitude
      );
      setLeadIdLocationModal(leadData);
      setCityIdModal(cityId);
      setAddressLine2Modal(addressLine2);
      setFavoritePlaceModal(favoritePlace);
      setCustomer_addressModal(customer_address);
      setCustomer_landmark1Modal(customer_landmark1);
      setOpenVerifiedLocationModal(true);
      setAddressId(locationDataFromPusher?.address_id);
      // handleClickLocation(locationDataFromPusher?.pk)
    }
  }, [locationDataFromPusher])

  // useEffect(() => {
  //   if (whatsappAddressIdDetail?.address_id > 0) {
  //     if (crmPostLeadAddress?.id === whatsappAddressIdDetail?.address_id) {
  //       setAddressIdCheck(whatsappAddressIdDetail?.address_id)
  //       setLocationMessageStatus(whatsappAddressIdDetail?.reciept)
  //     }
  //   }
  // }, [whatsappAddressIdDetail])
  useEffect(() => {
    if (requestedForHardCopy === "true") {
      setHardCopyPrice(150)

    }
    else {
      setHardCopyPrice(0)
    }

  }, [requestedForHardCopy])
  useEffect(() => {
    setRedCashDiscountPercentage(redCoin?.redcash_percentage)
  }, [redCoin]);

  useEffect(() => {
    if (crmPostLeadAddress?.id === whatsappMessageStatus?.id) {
      setLocationMessageStatus(whatsappMessageStatus?.location_request_status)
    }
  }, [whatsappMessageStatus])

  const searchLead = () => {
    resetBookings();
    resetLeadDetails();
    resetAddressComponent();
    setIsHitReferralCurrentBalanceAPI(false)
    setReferralPoints("")
    setMapAddressObject({});
    setCustomer_address("");
    setAddressLine2("");
    setFavoritePlace("");
    setAddressExeData("");
    setCityId("");
    setCustomer_landmark("");
    setApiHitCount(0);
    setCollection_date("");
    setLoaderPatientDetails(true);
    setInputList([
      {
        customerId: "",
        customerDesignation: "",
        designation: "",
        customerName: "",
        customerAge: "",
        customerGender: "",
        customerPackage: [],
        packagePrice: 0,
        dateOfBirth: "",
        is_prescribed: "",
        bookingFor: "",
        passport: "",
        aadhar: "",
        oldNameUHID: "",
      },
    ]);
    let body = {
      search_by: "call_id",
      value: search,
    };
    getLeads(body);
  };

  // useEffect(() => {
  //   const unloadCallback = (event: { preventDefault: () => void; returnValue: string; }) => {
  //     event.preventDefault();
  //     event.returnValue = "";
  //     return "ha";
  //   };

  //   window.addEventListener("beforeunload", unloadCallback);
  //   return () => window.removeEventListener("beforeunload", unloadCallback);
  // }, []);

  const selectLead = (id: any) => {
    setValCoin(true);
    // setLeadIdLocationModal(id)
    if (id !== 0 && id !== null && id !== undefined && id !== "undefined" && bookingFrom === "Main") {
      getLeadDetails(`${id}`, "true");
      getLeadPrescriptionData(`?lead=${id}`);
    }
  };

  useEffect(() => {
    if (lead && bookingFrom === "Main") {
      setSearch(lead);
      getLeads({
        search_by: "call_id",
        value: lead,
      });
      selectLead(lead);
    } else if (lead && bookingFrom === "FillInfo") {
      getLeads({
        search_by: "call_id",
        value: lead,
      });
    }
  }, [lead]);

  const [numCheck, setNumCheck] = useState({
    altCheck: false,
    whatsCheck: false,
    altCheck1: false,
    whatsCheck1: false,
  });
  const [changeNumType, setChangeNumType] = useState<any>("");
  const [changeNumber, setChangeNumber] = useState({
    altNumChange: false,
    whatsappNumChange: false,
  });

  const [changeWhatsapp, setChangeWhatsapp] = useState<boolean>(false);
  const [sameWhatsapp, setSameWhatsapp] = useState<boolean>(false);
  const [changeAlternate, setChangeAlternate] = useState<boolean>(false);
  const [sameAlternate, setSameAlternate] = useState<boolean>(false);
  const [packageHindi, setPackageHindi] = useState(false);

  const handleChangeWhatsapp = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: any
  ) => {
    setChangeWhatsapp(event.target.checked);
    setChangeNumType(type);
  };
  const handleSameWhatsapp = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: any
  ) => {
    setSameWhatsapp(event.target.checked);
  };
  const handleChangeAlternate = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: any
  ) => {
    setChangeAlternate(event.target.checked);
    setChangeNumType(type);
  };
  const handleSameAlternate = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: any
  ) => {
    setSameAlternate(event.target.checked);
  };

  const handleNumSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNumCheck({ ...numCheck, [event.target.name]: event.target.checked });
  };

  const handleNumSelectChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: any
  ) => {
    setChangeNumber({
      ...changeNumber,
      [event.target.name]: event.target.checked,
    });
    setChangeNumType(type);
  };
  useEffect(() => {
    if (changeNumType === "changeWhatsapp" && changeWhatsapp) {
      setDummyValueWhats(false);
    } else if (changeNumType === "changeAlternate" && changeAlternate) {
      setDummyValueAlter(false);
    }
  }, [changeNumType]);
  // useEffect(() => {
  //   if (changeNumType === "whats" && changeNumber.whatsappNumChange) {
  //     setDummyValueWhats(false)
  //   }
  //   else if (changeNumType === "alt" && changeNumber.altNumChange) {
  //     setDummyValueAlter(false)
  //   }
  // }, [changeNumType])

  useEffect(() => {
    if (sameWhatsapp) {
      setDummyValueWhats(true);
      lead_details.whatsapp_contact !== null &&
        lead_details.whatsapp_contact !== undefined
        ? setLeadCustomer_whatsapppnumber(lead_customer_whatsapppnumber)
        : lead_details.mobile !== null && lead_details.mobile !== undefined
          ? setLeadCustomer_whatsapppnumber(lead_customer_phonenumber as any)
          : setLeadCustomer_whatsapppnumber(customer_phonenumber as any);
    }
    if (!sameWhatsapp) {
      setDummyValueWhats(false);
    }
  }, [sameWhatsapp]);

  useEffect(() => {
    if (sameAlternate) {
      setDummyValueAlter(true);
      lead_details.mobile !== null && lead_details.mobile !== undefined
        ? setLeadCustomer_alter(lead_customer_phonenumber)
        : lead_details.mobile !== null && lead_details.mobile !== undefined
          ? setLeadCustomer_alter(lead_customer_phonenumber as any)
          : setLeadCustomer_alter(customer_phonenumber as any);
    }
    if (!sameAlternate) {
      setDummyValueAlter(false);
    }
  }, [sameAlternate]);

  // useEffect(() => {
  //   if (numCheck.altCheck) {
  //     setDummyValueAlter(true)
  //     lead_details.mobile !== null && lead_details.mobile !== undefined ? setLeadCustomer_alter(lead_customer_phonenumber) : lead_details.mobile !== null && lead_details.mobile !== undefined ? setLeadCustomer_alter(lead_customer_phonenumber as any) : setLeadCustomer_alter(customer_phonenumber as any)
  //   }
  //   if (numCheck.whatsCheck) {
  //     setDummyValueWhats(true)
  //     lead_details.whatsapp_contact !== null && lead_details.whatsapp_contact !== undefined ? setLeadCustomer_whatsapppnumber(lead_customer_whatsapppnumber) : lead_details.mobile !== null && lead_details.mobile !== undefined ? setLeadCustomer_whatsapppnumber(lead_customer_phonenumber as any) : setLeadCustomer_whatsapppnumber(customer_phonenumber as any)
  //   }
  //   if (!numCheck.altCheck) {
  //     setDummyValueAlter(false)
  //   }

  //   if (!numCheck.whatsCheck) {
  //     setDummyValueWhats(false)
  //   }
  //   // }
  // }, [numCheck])

  useEffect(() => {
    setCustomer_address(lead_details.address as String);
    lead_details.mobile !== null && lead_details.mobile !== undefined
      ? setLeadCustomer_phonenumber(lead_details.mobile as string)
      : setLeadCustomer_phonenumber("");
    lead_details.mobile !== null && lead_details.mobile !== undefined
      ? setDummyValueMain(true)
      : setDummyValueMain(false);
    lead_details.whatsapp_contact !== null &&
      lead_details.whatsapp_contact !== undefined
      ? setLeadCustomer_whatsapppnumber(lead_details.whatsapp_contact as string)
      : setLeadCustomer_whatsapppnumber("");
    lead_details.whatsapp_contact !== null &&
      lead_details.whatsapp_contact !== undefined
      ? setDummyValueWhats(true)
      : setDummyValueWhats(false);
    lead_details.content_number !== null &&
      lead_details.content_number !== undefined
      ? setLeadCustomer_alter(lead_details.content_number as string)
      : setLeadCustomer_alter("");
    lead_details.content_number !== null &&
      lead_details.content_number !== undefined
      ? setDummyValueAlter(true)
      : setDummyValueAlter(false);
    setLeadData(lead_details?.id);
    setInputList([
      {
        customerDesignation: lead_details && lead_details?.calling_gender?.toLowerCase() === "male" || lead_details && lead_details?.calling_gender?.toLowerCase() === "Male" ? "Mr" : (lead_details?.calling_gender?.toLowerCase() === "female" || lead_details?.calling_gender?.toLowerCase() === "Female" ? "Ms" : (lead_details?.calling_gender === "other" || lead_details?.calling_gender === "Other") ? "Mr" : ""),
        designation: lead_details && lead_details?.calling_gender?.toLowerCase() === "male" || lead_details && lead_details?.calling_gender?.toLowerCase() === "Male" ? "Mr" : (lead_details?.calling_gender?.toLowerCase() === "female" || lead_details?.calling_gender?.toLowerCase() === "Female" ? "Ms" : (lead_details?.calling_gender === "other" || lead_details?.calling_gender === "Other") ? "Mr" : ""),
        customerName: lead_details?.patient_name,
        customerAge: lead_details?.patient_age,
        customerGender: lead_details?.calling_gender === "Other" || lead_details?.calling_gender === "other" ? "male" : lead_details?.calling_gender,
        customerPackage: [],
        packagePrice: 0,
        dateOfBirth: "",
        customerId: "",
        is_prescribed: "",
        bookingFor: "",
        passport: "",
        aadhar: "",
        oldNameUHID: lead_details?.patient_name,
      },
    ]);
  }, [lead_details]);

  const clearPackage = () => {
    let list = inputList;
    list &&
      list.length > 0 &&
      list.map((val: any, index: any) => {
        list[index]["customerPackage"] = [];
        list[index]["packagePrice"] = 0;
      });
    setTotalPrice(0);
    setInputList(list);
  };

  useEffect(() => {
    const slots = booking_slots && booking_slots?.results;
    setTimeSlots(slots && slots[0].zone_data && slots[0].zone_data.time_slots);
  }, [booking_slots, collection_date]);

  useEffect(() => {
    if (selectedSlot > 0 && timeSlots !== undefined) {
      const findingSelectedSlot = timeSlots.filter(
        (x: any) => x.id === selectedSlot
      );
      setFindSelectedSlot(findingSelectedSlot)
    }
  }, [selectedSlot, timeSlots])

  useEffect(() => {
    if (findSelectedSlot && findSelectedSlot?.length > 0 && findSelectedSlot[0]?.id > -1) {
      setPhleboCost(findSelectedSlot[0]?.phlebo_cost)
      setMinAmountPhleboCost(findSelectedSlot[0]?.minimum_amount_phlebo_cost)
    }
  }, [findSelectedSlot])

  useEffect(() => {
    if (pincode && pin_code?.results?.length == 0) {
      // setErrorMessage("We are not providing service on this pincode.");
      resetAddressComponent();
    }
    if (pincode && pin_code?.results?.length > 0) {
      const findPinCode = pin_code?.results.find(
        (code: any) => code.pincode == pincode
      );
      if (findPinCode) {
        setErrorMessage("");
        // getArea(pincode);
        const { id, area, city, cityid } = pin_code.results.find(
          (code: any) => code.pincode === pincode
        );
        getPackage(`city_id=${cityid}&org_type=homedx`);
        // {
        //   userDetails?.profile?.pilot_user ? (
        //     getNewPackage(`city_id=${cityid}&org_type=homedx`)
        //   ) : getPackage(`city_id=${cityid}&org_type=homedx`)
        // }
        setCityId(cityid as String);
        if (Number(cityid) !== Number(cityId)) {
          setPackageKey(random);
          clearPackage();
        }
        // setCustomer_areapincode(id as Number);

        setCustomer_city(city as String);
        setIsPincode(false);
      } else {
        // setErrorMessage("We are not providing service on this pincode.");
      }
    }
  }, [pin_code, pincode]);

  useEffect(() => {
    if (cityId !== "" && cityId !== 0 && cityId !== null) getRecommendedPackagesWithCityId(cityId, recommendedPackageId?.toString());
    if (packageIdValue !== "") {
      getPackageQuestion(`package_id=${packageIdValue}`)
    }
  }, [cityId, recommendedPackageId])

  useEffect(() => {
    if (cityId !== null && cityId !== "" && cityId !== undefined) {
      getPackage(`city_id=${cityId}&org_type=homedx`);
      // {
      //   userDetails?.profile?.pilot_user ? (
      //     getNewPackage(`city_id=${cityId}&org_type=homedx`)
      //   ) : getPackage(`city_id=${cityId}&org_type=homedx`)
      // }
    }
  }, [cityId]);

  useEffect(() => {
    if (collectionType === "collectioncentre") {
      getTimeslots();
    }
  }, [collectionType]);

  useEffect(() => {
    if (zoneId !== null && zoneId !== "" && zoneId !== undefined) {
      if (collection_date !== "") {
        getTimeslots(collection_date, zoneId);
      }
    }
  }, [zoneId]);

  const resetAddressComponent = () => {
    setZoneId("");
    setCityId("");
    //setCustomer_areaname("");
    setIsPincode(true);
    setPackageKey(0);
    // setZoneName("");
    setCustomer_city("");
    setPinCode("");
  };
  useEffect(() => {
    if (cityId) {
      getCenterInfo(`?city=${cityId}&optimise=true`);
    }
  }, [cityId]);

  useEffect(() => {
    if (
      customerLatitude !== "" &&
      customerLatitude !== undefined &&
      customerLatitude !== "undefined" &&
      customerLatitude !== null
    ) {
      if (cityId !== "") {
        getceCentre(
          `?customer_latitude=${customerLatitude}&customer_longitude=${customerLongitude}&city=${cityId}`
        );
      }
    }
  }, [customerLatitude, cityId]);

  useEffect(() => {
    if (!alreadyARedPro) {
      if (totalPrice < minAmountPhleboCost && minAmountPhleboCost > 0) {
        setExtraCharge(true);
      } else {
        setPhleboCost(0);
        setExtraCharge(false);
      }
    } else {
      setPhleboCost(0);
      setExtraCharge(false);
    }
    if (checkedWay !== "") {
      setPhleboCost(0);
      setExtraCharge(false);
    }
  }, [phleboCost, checkedWay])

  useEffect(() => {
    if (extraCharge) {
      setTotalPrice(
        Math.floor(price - discount - discountDueSmartPackage)
      );
    } else {
      setTotalPrice(Math.floor(price - discount - discountDueSmartPackage));
    }
  }, [price, discount, discountDueSmartPackage, extraCharge]);

  useEffect(() => {
    if (totalPrice < 0) {
      setTotalPrice(0);
    }
  }, [totalPrice]);

  const applyCoupon = () => {
    if (couponCode === "") {
      // setCouponCode("");
      alert("Please select a valid coupon.");
      setCoupon(0);
      setDiscount(0);
      setTnPercentage("");
      setCouponMessage("");
      setTncMessage("");
      return false;
    }
    const appliedCoupon = couponList.results.find((coup: any) => coup.id === coupon) || couponList.results.find((coup: any) => coup.code === couponCode);
    if (appliedCoupon && appliedCoupon.minimum_price < totalPrice) {
      setCoupon(appliedCoupon.id);
      if (appliedCoupon.discount_type.toLowerCase() === "percentage") {
        if (appliedCoupon.is_red_cash) {
          setDiscount(0);
        } else {
          // if (appliedCoupon?.upper_limit_percentage === null) {
          //   let percentageDiscountValue = 0;
          //   if (redProMembershipDiscount > 0) {
          //     percentageDiscountValue = overallDiscountLimit - 10;
          //   } else {
          //     percentageDiscountValue = overallDiscountLimit;
          //   }
          //   setDiscount(Math.floor(((price - redProMembershipDiscount) * percentageDiscountValue) / 100));
          // } else {
          //   let percentageDiscountValue = 0;
          //   if (redProMembershipDiscount > 0) {
          //     if (appliedCoupon?.discount >= appliedCoupon?.upper_limit_percentage) {
          //       percentageDiscountValue = appliedCoupon?.upper_limit_percentage - 10;
          //     } else if (appliedCoupon?.discount + 10 >= appliedCoupon?.upper_limit_percentage) {
          //       percentageDiscountValue = appliedCoupon?.upper_limit_percentage - 10;
          //     } else {
          //       percentageDiscountValue = appliedCoupon?.discount;
          //     }
          //   } else {
          //     percentageDiscountValue = appliedCoupon?.discount > appliedCoupon?.upper_limit_percentage ? appliedCoupon?.upper_limit_percentage : appliedCoupon?.discount;
          //   }
          //   setDiscount(Math.floor(((price - redProMembershipDiscount) * percentageDiscountValue) / 100));
          // }
          setDiscount(Math.floor(((price - redProMembershipDiscount) * appliedCoupon?.discount) / 100));
        }
        setTnPercentage(appliedCoupon.tnc !== null ? appliedCoupon.tnc : "");
        setCouponMessage(
          `Coupon ${appliedCoupon.code}  has been applied successfully`
        );
        setTncMessage(
          `${appliedCoupon.tnc !== null ? `TNC : ${appliedCoupon.tnc}` : ""}`
        );
      }
      if (appliedCoupon.discount_type.toLowerCase() === "amount") {
        if (appliedCoupon.is_red_cash) {
          setDiscount(0);
        } else {
          setDiscount(Math.floor(appliedCoupon.discount));
        }
        setTnPercentage(appliedCoupon.tnc !== null ? appliedCoupon.tnc : "");
        setCouponMessage(
          `Coupon ${appliedCoupon.code}  has been applied successfully`
        );
        setTncMessage(
          `${appliedCoupon.tnc !== null ? `TNC : ${appliedCoupon.tnc}` : ""}`
        );
      }
    } else {
      // setCouponCode("");
      setCoupon(0);
      setDiscount(0);
      setTnPercentage("");
      setCouponMessage("");
      setTncMessage("");
      alert("Please select a valid coupon.");
      return false;
    }
  };

  const applyCouponManually = () => {
    if (search.length > 0 && manualCouponCode?.length > 0 && leads?.count > 0) {
      setDiscount(0)
      getCouponReferralValidation(manualCouponCode, leads?.results[0]?.mobile)
    }
  }

  useEffect(() => {
    if (leads?.count > 0) {
      getLatestAddressByLeadId(leads?.results[0]?.id);
    }
  }, [leads])

  const applyCouponPerc = () => {
    setPercentageApplyFlag(true)
    if (cValue === "") {
      // setCouponCode("");
      alert("Please select a valid coupon.");
      setCoupon(0);
      setDiscount(0);
      return false;
    }
    const appliedCoupon = { cType: cType, cValue: cValue };
    if (appliedCoupon.cValue < totalPrice) {
      if (appliedCoupon.cType === "percentage") {
        if (appliedCoupon.cValue > 100) {
          alert("Please Select Valid Number");
        }
        setDiscount(Math.floor(((price - redProMembershipDiscount) * appliedCoupon.cValue) / 100));
      }
      if (appliedCoupon.cType === "amount") {
        setDiscount(Math.floor(appliedCoupon.cValue));
      }
    } else {
      // setCouponCode("");
      alert("Please select a valid coupon.");
      setCoupon(0);
      setDiscount(0);
      return false;
    }
  };
  const SelectLeaddetails = async (leadId: any) => {
    if (
      leadId !== 0 &&
      leadId !== null &&
      leadId !== undefined &&
      leadId !== "undefined"
    ) {
      // setLeadIdLocationModal(leadId)
      selectLead(leadId);
      setLeadData(leadId);
      getCommunicationOnWhatsapp(`lead_id=${leadId}`);
    }
    setShowMessage(true);
  };

  useEffect(() => {
    if (leads?.count > 0) {
      SelectLeaddetails(leads?.results[0]?.id);
    }
  }, [leads])

  const SendLocationMessage = async () => {
    let body = {
      lead_id: leadData,
      address_id: addressExeData,
      customer_whatsapp_phonenumber: customer_whatsapppnumber,
      attempt: "first",
    };
    if (
      crmPostLeadAddress?.customer_verified_latitude === null ||
      crmPostLeadAddress?.customer_verified_longitude === null ||
      crmPostLeadAddress?.customer_verified_latitude === "" ||
      crmPostLeadAddress?.customer_verified_longitude === "" ||
      crmPostLeadAddress?.customer_verified_latitude === 0 ||
      crmPostLeadAddress?.customer_verified_longitude === 0 ||
      crmPostLeadAddress?.customer_verified_latitude === undefined ||
      crmPostLeadAddress?.customer_verified_longitude === undefined
    ) {
      setApiHitCount(2);
      if (customer_address !== "" || addressLine2 !== "" || favoritePlace !== "") {
        await SendLocationRequest(body);
      }
      setCheckSendLocationMessage(true)
      setCheckButtonDisableFlag(false)
    } else {
      alert("We have already customer verified address.");
    }
  };
  const HandleOpenLocation = async () => {
    setOpenLocationModal(true);
    setSwitchEditButton(true);
  };

  useEffect(() => {
    if (leadData !== 0 && leadData !== undefined && leadData !== null) {
      getCrmLeadAddress(`lead_id=${leadData}`);
    }
  }, [leadData])

  const verifyMobile = (type: any) => {
    if (type === "mobile") {
      if (
        parseInt(customer_phonenumber && customer_phonenumber[0]) < 6 ||
        (customer_phonenumber && customer_phonenumber.length < 10)
      ) {
        setMobileValidation((prev: any) => ({
          ...prev,
          customer_phonenumber: false,
        }));
      }
    }
    if (type === "alternate") {
      if (
        parseInt(customer_alternatenumber && customer_alternatenumber[0]) < 6 ||
        (customer_alternatenumber && customer_alternatenumber.length < 10)
      ) {
        setMobileValidation((prev: any) => ({
          ...prev,
          customer_alternatenumber: false,
        }));
      }
    }
    if (type === "whatsapp") {
      if (
        parseInt(customer_whatsapppnumber && customer_whatsapppnumber[0]) < 6 ||
        (customer_whatsapppnumber && customer_whatsapppnumber.length < 10)
      ) {
        setMobileValidation((prev: any) => ({
          ...prev,
          customer_whatsapppnumber: false,
        }));
      }
    }
  };

  const submitForm = async (e: any) => {
    e.preventDefault();

    let packagesIds: any = [];
    inputList &&
      inputList[0]?.customerPackage.map((item: any) => {
        packagesIds.push(item.id);
      });

    let additionalPacakges: any = [];
    inputList &&
      inputList.length > 1 &&
      inputList.map((item: any, index: number) => {
        if (index > 0) {
          if (item.customerName !== item.oldNameUHID) item.customerId = "";
          additionalPacakges.push(item);
        }
      });
    const data: any = {
      center: collectionCenterId,
      is_intrested_in_imaging: readology,
      booking_date: moment(new Date()).format("YYYY-MM-DD"),
      collection_date: collection_date,
      collection_slot: selectedSlot,
      customer_name: inputList ? inputList[0]?.customerName : "",
      customer_age: inputList ? inputList[0]?.customerAge : "0",
      customer_gender: inputList ? (inputList[0]?.customerGender === "other" || inputList[0]?.customerGender === "Other") ? "Male" : inputList[0]?.customerGender : "",
      customerid: inputList
        ? inputList[0]?.oldNameUHID === inputList[0]?.customerName
          ? inputList[0]?.customerId
          : ""
        : "",
      book_for: inputList ? inputList[0]?.bookingFor : "",
      is_prescription: inputList ? inputList[0]?.is_prescribed : "",
      customer_aadhar: inputList ? inputList[0]?.aadhar : "",
      passport_number: inputList ? inputList[0]?.passport : "",
      customer_email: customer_email === "" ? defaultEmail : customer_email,
      customer_phonenumber: dummyValueMain
        ? lead_customer_phonenumber
        : customer_phonenumber,
      customer_altphonenumber: dummyValueAlter
        ? lead_customer_alter
        : customer_alternatenumber,
      customer_whatsapppnumber: dummyValueWhats
        ? lead_customer_whatsapppnumber
        : customer_whatsapppnumber,
      customer_address: customer_address,
      customer_landmark:
        addressObjData === "" ? customer_landmark1 : customer_landmark,
      customer_pincode: pincode,
      customer_longitude: customerLongitude === undefined || customerLongitude === null ? customerLongitudeForCC : customerLongitude,
      customer_latitude: customerLatitude === undefined || customerLatitude === null ? customerLatitudeForCC : customerLatitude,
      // customer_city: cityName,
      packages: packagesIds,
      coupon_amount: cType === "referral" ? couponReferralValidation?.message?.discount : Number(cValue),
      coupon_type: cType,
      coupon_applied: cType === "referral" ? couponReferralValidation?.message?.id : coupon,
      designation: inputList ? (inputList[0]?.designation === "other" || inputList[0]?.designation === "Other") ? "Male" : inputList[0]?.designation : "",
      dob: inputList ? inputList[0]?.dateOfBirth : "",
      cityid: cityId,
      additional_member: additionalPacakges,
      address_line2: addressLine2,
      landmark: favoritePlace,
      is_redcash_applied: redCashCheck ? "true" : "false",
      customer_zone: zoneId !== "" ? zoneId : geoCheckZoneId,
      phlebo_remarks: phleboRemarks,
      customer_verified_longitude: customerLongitude1Modal,
      customer_verified_latitude: customerLatitude1Modal,
      address_verified: verifyKey,
      customer_booking_address: addressExeData,
      is_referral_applied: referralPointsApplied === true ? "true" : "false",
      subscription: checkedWay,
      is_customer_email_deny: deniedEmailCheck,
      requested_for_hard_copy: requestedForHardCopy,
      ref_doctor: referringDoctor,
      delivery_date: containerDate,
      delivery_collection_slot: containerSlot,
      // smart_discounted_amount: discountDueSmartPackage,
    };

    if (!urineContainer || checkedContainerDeliverySlot === "") {
      delete data.delivery_date;
      delete data.delivery_collection_slot;
    }

    // if (!bookingEnabled) {
    //   alert(
    //     "There is no sericable area or not a valid address. Please try again."
    //   );
    // }
    if (addressExeData === 0 || addressExeData === "") {
      delete data.customer_booking_address
    }
    if (checkedWay === "") {
      delete data.subscription;
    }
    if (pincode === "") {
      delete data.customer_pincode;
    }
    if (redCashCheck) {
      delete data.coupon_amount;
      delete data.coupon_type;
      delete data.coupon_applied;
    }
    if (cType === "" || cType === "none") {
      delete data.coupon_amount;
      delete data.coupon_type;
      delete data.coupon_applied;
    }

    if (((cValue === "" || cValue === 0) && (cType === "percentage")) || ((cType === "code") && data["coupon_applied"] === 0)) {
      delete data.coupon_amount;
      delete data.coupon_type;
      delete data.coupon_applied;
    }

    if (referringDoctor === "") {
      delete data["ref_doctor"];
    }


    if (cType === "referral") {
      delete data.coupon_amount;
    }
    if (data["coupon_applied"] === 0) {
      delete data["coupon_applied"];
    }
    if (data["center"] === "") {
      delete data["center"];
    }
    if (data["dob"] === "") {
      delete data["dob"];
    }
    if (
      mobileValidation.customer_alternatenumber &&
      mobileValidation.customer_phonenumber &&
      mobileValidation.customer_whatsapppnumber
    ) {
      if (
        ageCheck !== undefined ||
        data.customer_age == undefined ||
        data.customer_age === "" ||
        data.customer_age === null
      ) {
        alert("Please Provide Customer Age");
      } else if (packageCheck !== undefined) {
        alert("Customer Package can't be Blank");
      }
      else if (allergyFlag === true && postPackageAnswerReducer?.length <= 0) {
        alert("You have Selected Allergy Related Package Please Fill Related Question")
      }
      else if (collectionType === "collectioncentre" && collectionCenterId.length > 0 || String(geoCheckCityId)?.length > 0 && collectionCenterId.length > 0) {
        alert("Please Provide Valid Center Name")
      }
      else {
        setDisableCreateBtn(true);
        await createPilotNewBooking(data);
        resetAddressComponent();
      }
    } else {
      if (customer_phonenumber.length !== 10) {
        setMobError("Mobile Number Must be 10 Digit");
      } else if (customer_alternatenumber.length !== 10) {
        setAltError("Alternate Number must be 10 Digit");
      } else if (customer_whatsapppnumber.length !== 10) {
        setWhatsError("Whatsapppnumber must be 10 Digit");
      }
    }
  };
  useEffect(() => {
    setMobError("");
    setAltError("");
    setWhatsError("");
  }, [
    customer_phonenumber,
    customer_alternatenumber,
    customer_whatsapppnumber,
  ]);

  useEffect(() => {
    if (createPilotBooking.pk) {
      history.push(`/dashboard/os/booking-view/${createPilotBooking.pk}`);
      dispatch({ type: ACTIONS.CREATE_BOOKING, payload: "" });
      dispatch({ type: ACTIONS.CREATE_PILOT_BOOKING, payload: "" });
      dispatch({ type: ACTIONS.GET_PIN_CODE, payload: [] });
    }
    dispatch({ type: ACTIONS.GET_RED_COIN, payload: [] });
    dispatch({ type: ACTIONS.CHECK_GEOFENCE_AREA_MAMPMYINDIA, payload: [] });
  }, [createPilotBooking]);

  const changeCollectionDate = (e: any, index: any) => {
    setSelectedSlot(0);
    setPhleboCost(0);
    setMinAmountPhleboCost(0);
    setDateSelected(index);
    const today = moment(new Date().setDate(new Date().getDate() + index + dateGap)).format('YYYY-MM-DD');
    setCollection_date(today);
    if (zoneId !== "") {
      getTimeslots(today, zoneId);
      getPackage(`city_id=${cityId}&org_type=homedx`);
     
    }
  };

  const handleBookingForChange = (index: number) => {
    const list = [...inputList];
    list[index]["bookingFor"] = "";
    setInputList(list);
  }

  const handleInputChange = (e: any, index: number) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    if (e.target.name == "designation") {
      const findGender = GENDER_CHOICE.filter(
        (el: any) => el.display_name == e.target.value
      );
      list[index]["customerGender"] = findGender[0]?.gender;
      list[index]["customerDesignation"] = findGender[0]?.db_name;
      getPackage(
        `city_id=${cityId}&org_type=homedx&gender=${findGender[0]?.gender}`
      );
    }
    if (e.target.name == "dateOfBirth") {
      let today = new Date();
      let birthDate = new Date(e.target.value);
      let age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      list[index]["customerAge"] = age;
    }
    setInputList(list);
  };


  // const handleInputChange = (e: any, index: number) => {
  //   const { name, value } = e.target;
  //   if (name === "customerName") {
  //     const newValue = e.target.value;

  //     if (!newValue.match(/[@!#^*%&<>()_+=1234567890:~`.\\$'"]/)) {
  //       setError("");
  //     } else {
  //       setError("Forbidden character: @!#^*%&<>()_+=1234567890:~`.\\$'\"");
  //     }
  //   }
  //   if (name === "customerName") {
  //     const newValue = e.target.value;

  //     if (!newValue.match(/[@!#^*%&<>()_+=1234567890:~`.\\$'"]/)) {
  //       setError("");
  //     } else {
  //       setError("Forbidden character: @!#^*%&<>()_+=1234567890:~`.\\$'\"");
  //     }
  //   }
  //   const list = [...inputList];
  //   if (name === "passport") {
  //     const result = value.replace(/[^A-Z0-9]/, "");
  //     list[index][name] = result;
  //   } else if (name === "customerName") {
  //     const result = value.replace(/[^a-zA-Z ]/g, "");
  //     list[index][name] = result;
  //   } else {
  //     list[index][name] = value;
  //   }
  //   if (e.target.name == "designation") {
  //     const findGender = GENDER_CHOICE.filter(
  //       (el: any) => el.display_name == e.target.value
  //     );
  //     list[index]["customerGender"] = findGender[0]?.gender;
  //     list[index]["customerDesignation"] = findGender[0]?.db_name;
  //     getPackage(
  //       `city_id=${cityId}&org_type=homedx&gender=${findGender[0]?.gender}`
  //     );
  //   }
  //   if (e.target.name == "dateOfBirth") {
  //     let today = new Date();
  //     let birthDate = new Date(e.target.value);
  //     let age = today.getFullYear() - birthDate.getFullYear();
  //     var m = today.getMonth() - birthDate.getMonth();
  //     if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
  //       age--;
  //     }
  //     list[index]["customerAge"] = age;
  //   }
  //   setInputList(list);

  // };

  const handleRemoveClick = (index: any) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  // const checkNormalPackage = (packages: any) => {
  //   let isNormalPackage = false;
  //   packages &&
  //     packages.length > 0 &&
  //     packages.map((item: any) => {
  //       if (!item.is_addon) {
  //         isNormalPackage = true;
  //       }
  //     });
  //   return isNormalPackage;
  // };
  // const checkBuyOneGetOneFreePackage = (index: number, packages: any) => {
  //   if (
  //     inputList &&
  //     inputList?.length == 1 &&
  //     packages &&
  //     packages?.length > 0
  //   ) {
  //     packages.map((item: any) => {
  //       if (item && item.code == "PL50" && item?.code == "PL50") {
  //         // if use select buy one get one package
  //         setInputList([
  //           ...inputList,
  //           {
  //             customerDesignation: "",
  //             designation: "",
  //             customerName: "",
  //             customerAge: "",
  //             customerGender: "",
  //             customerPackage: [item],
  //             packagePrice: item?.offer_price,
  //             dateOfBirth: "",
  //             customerId: "",
  //             is_prescribed: "",
  //             bookingFor: "",
  //             passport: "",
  //             aadhar: "",
  //           },
  //         ]);
  //       }
  //     });
  //   }
  // };

  const checkBuyOneGetOneFreePackage = (index: number, packages: any) => {
    if (
      inputList &&
      inputList?.length > 0 &&
      packages &&
      packages?.length > 0
    ) {
      packages.map((item: any) => {
        if (item && item?.no_patients !== null) {
          // if use select buy one get one package
          let obj = {
            customerDesignation: "",
            designation: "",
            customerName: "",
            customerAge: "",
            customerGender: "",
            customerPackage: [item],
            packagePrice: item?.offer_price,
            dateOfBirth: "",
            customerId: "",
            is_prescribed: "",
            bookingFor: "",
            passport: "",
            aadhar: "",
            oldNameUHID: "",
          };
          if (item?.no_patients > 1 && inputList?.length == 1) {
            for (let x = 0; x < item?.no_patients; x++) {
              setInputList([...inputList]);
              const b = JSON.parse(JSON.stringify(obj));
              inputList.push(b);
            }
            let DiscountMoneyNumber = item?.no_patients - 1;
            if (item?.package_city_prices === null) {
              let totDiscount = DiscountMoneyNumber * item?.offer_price;

              setDiscountDueSmartPackage(totDiscount);
            }
            if (item?.package_city_prices !== null) {
              let totDiscount = DiscountMoneyNumber * item?.package_city_prices;

              setDiscountDueSmartPackage(totDiscount);
            }
            // if (item?.no_patients==2) setInputList([ ...inputList, obj ]);
            // if (item?.no_patients==3) setInputList([ ...inputList, obj, obj ]);
            // if (item?.no_patients==4) setInputList([ ...inputList, obj, obj, obj ]);
            // if (item?.no_patients==5) setInputList([ ...inputList, obj, obj, obj, obj ]);
          } else if (item?.no_patients > 1 && inputList?.length > 1) {
            let x = item?.no_patients;
            let y = inputList.length;
            if (x <= y) {
              x--;
              for (let i = 0; i < item?.no_patients; i++) {
                if (x && index !== i) {
                  x--;
                  inputList[i].customerPackage.push(item);
                }
              }
            } else {
              for (let i = 0; i < y; i++) {
                if (index !== i) {
                  inputList[i].customerPackage.push(item);
                }
              }
              for (let j = y; j <= x; j++) {
                setInputList([...inputList]);
                const b = JSON.parse(JSON.stringify(obj));
                inputList.push(b);
              }
            }
            let DiscountMoneyNumber = item?.no_patients - 1;
            if (item?.package_city_prices === null) {
              let totDiscount = DiscountMoneyNumber * item?.offer_price;

              setDiscountDueSmartPackage(totDiscount);
            }
            if (item?.package_city_prices !== null) {
              let totDiscount = DiscountMoneyNumber * item?.package_city_prices;

              setDiscountDueSmartPackage(totDiscount);
            }
          }
        }
      });
    }
  };

  const checkBuyOneGetOneFreePackages = (
    index: number,
    packages: any,
    totPack: any
  ) => {
    if (
      inputList &&
      inputList?.length > 1 &&
      packages &&
      packages?.length > 0
    ) {
      let len = packages.length;
      let len1 = 1;
      packages.map((item: any) => {
        if (item && item?.no_patients !== null) {
          // if use select buy one get one package
          let obj = {
            customerDesignation: "",
            designation: "",
            customerName: "",
            customerAge: "",
            customerGender: "",
            customerPackage: [item],
            packagePrice: item?.offer_price,
            dateOfBirth: "",
            customerId: "",
            is_prescribed: "",
            bookingFor: "",
            passport: "",
            aadhar: "",
          };
          if (item?.no_patients !== null) {
            let x = item?.no_patients;
            let y = inputList.length;
            if (x <= y) {
              x--;
              for (let i = 0; i < item?.no_patients; i++) {
                if (x && index !== i) {
                  x--;
                  inputList[i].customerPackage.push(item);
                }
              }
            } else {
              for (let i = 0; i < y; i++) {
                if (index !== i) {
                  inputList[i].customerPackage.push(item);
                }
              }
              for (let j = y; j <= x; j++) {
                setInputList([...inputList]);
                inputList.push(obj);
              }
            }
          }
        }
      });
    }
  };

  const getNoofPackageBuyOneGetOne = () => {
    let noOfBuyOneGetOne = 0;
    inputList &&
      inputList.map((rowItem: any) => {
        if (rowItem?.customerPackage && rowItem?.customerPackage.length > 0) {
          rowItem?.customerPackage.map((packageItem: any) => {
            if (packageItem?.code == "PL50") {
              noOfBuyOneGetOne = noOfBuyOneGetOne + 1;
            }
          });
        }
      });
    return noOfBuyOneGetOne;
  };

  const buyOneGetOnePackagePrice = () => {
    let packagePrice = 0;
    inputList &&
      inputList.map((rowItem: any) => {
        if (rowItem?.customerPackage && rowItem?.customerPackage.length > 0) {
          rowItem?.customerPackage.map((packageItem: any) => {
            if (packageItem?.code == "PL50") {
              packagePrice = packageItem?.offer_price;
            }
          });
        }
      });
    return packagePrice;
  };

  const [lastBookedPackage, setLastBookedPackage] = useState<any>([]);
  const [lastBookedPackageLength, setLastBookedPackageLength] = useState<any>(0);
  const [lastPackageShow, setLastPackageShow] = useState<any>(false);
  const [assignNumber, setAssignNumber] = useState<any>(0);

  useEffect(() => {
    if (assignNumber < 2) {
      if (packageList?.count > 0 && assignNumber === 0) {
        setAssignNumber(1);
        setLastBookedPackage(packageList?.results);
      } else if (assignNumber === 1) {
        setAssignNumber(2);
        let array = [];
        array = packageList?.results?.filter((pack: any) => pack?.code !== lastBookedPackage[0]?.code).filter((pack: any) => pack?.code !== lastBookedPackage[1]?.code)
        if (array !== undefined) setLastBookedPackage([...lastBookedPackage, ...array]);
      }
    } else {
      setAssignNumber(3);
      setLastBookedPackage([]);
    }
  }, [packageList])



  useEffect(() => {
    if (Number(checkedContainerDeliverySlot) === 1) {
      setDateGap(0);
      setCollection_date("");
      setDateSelected(-1);
    }
  }, [checkedContainerDeliverySlot])

  const handlePackage = (
    index: number,
    customerPackages: any,
    lastPack: any
  ) => {

    const list = [...inputList];

    if (index === 0) {
      if (customerPackages?.length > 0) setRecommendedPackageId(customerPackages && customerPackages.length > 0 && customerPackages?.map((p: any) => p?.id));
    }

    list[index]["customerPackage"] = customerPackages;
    // inputList[index].customerPackage.push(lastPack)
    setInputList(list);

    let accumulatedPrice: any = 0;
    if (customerPackages && customerPackages.length > 0) {
      // let atLeastOneNormalPackage = checkNormalPackage(customerPackages);
      customerPackages.map((item: any) => {
        if (item?.package_city_prices === null) {
          accumulatedPrice = accumulatedPrice + item.offer_price;
        } else {
          accumulatedPrice =
            accumulatedPrice + item?.package_city_prices?.offer_price;
        }
        // }
      });
    }
    // set price and package for row
    list[index]["packagePrice"] = accumulatedPrice;
    setInputList(list);

    //call function for buy one get one free
    if (inputList?.length && discountDueSmartPackage === 0) {
      // checkBuyOneGetOneFreePackage(index, customerPackages);
    }
    let allPackagePrice = 0;
    inputList &&
      inputList.map((rowItem: any) => {
        allPackagePrice = allPackagePrice + rowItem?.packagePrice;
      });
    //Minus By One Get One Price
    let pl50Count = inputList?.length > 0 ? getNoofPackageBuyOneGetOne() : 0;
    if (pl50Count > 1 && inputList?.length > 1) {
      let noOfFreePackage = 0;
      if (pl50Count === 2) {
        noOfFreePackage = 1;
      } else if (pl50Count === 3) {
        noOfFreePackage = 1;
      } else if (pl50Count === 4) {
        noOfFreePackage = 2;
      } else if (pl50Count === 5) {
        noOfFreePackage = 2;
      } else if (pl50Count === 6) {
        noOfFreePackage = 3;
      } else {
        noOfFreePackage = 0;
      }
      if (noOfFreePackage > 0) {
        let buyOneGetOnePrice = buyOneGetOnePackagePrice();
        let freePackagepPrice = noOfFreePackage * buyOneGetOnePrice;
        allPackagePrice = allPackagePrice - freePackagepPrice;
      }
    }
    setPrice(Math.floor(allPackagePrice));
    setDiscount(0);
    setSelectedSlot(0);
    setPhleboCost(0);
    setMinAmountPhleboCost(0)
    setReferralPointsDiscount(0);
    setReferralPointsApplied(false);
    setKey(!key)
    setTotalPrice(Math.floor(allPackagePrice));
    setCoupon(0);
    setCouponCode("");
    setRedCashCheck(false);
  };

  useEffect(() => {
    if (packagesList?.count > 0 && recommendedPackagesWithCityId?.length > 0) {
      // setBlinkTextOther(false);
      handlePackage(0, [...packagesList?.results, ...inputList[0]?.customerPackage], 0);
      setPackageKey(packageKey + 1)
    }
  }, [packagesList])
  useEffect(() => {
    if (packagesList?.count > 0 && recommendedPackagesWithCityId?.length > 0) {
      // setBlinkTextOther(false);
      handlePackage(0, [...packagesList?.results, ...inputList[0]?.customerPackage], 0);
      setPackageKey(packageKey + 1)
    }
  }, [packagesList])

  useEffect(() => {
    if (referralPointsDiscount === 0) {
      const tempPrice = totalPrice;
      const maxRedDiscount = (((totalPrice - redProMembershipDiscount)) / 100) * redCashDiscountPercentage;
      const redCashDeduct =
        maxRedDiscount - redCoin?.coins >= 0 ? redCoin?.coins : maxRedDiscount;
      if (redCashCheck) {
        setRedDisc(redCashDeduct);
      } else {
        setRedDisc(0);
      }
    } else if (referralPointsDiscount > 0 && totalPrice > 0) {
      const maxReferralPointsDiscount = (totalPrice);
      if (totalPrice > 0) {
        // const leftAmount = maxReferralPointsDiscount - referralPointsDiscount;
        const maxRedDiscount = (((totalPrice - redProMembershipDiscount)) / 100) * redCashDiscountPercentage;
        const redCashDeduct = maxRedDiscount - redCoin?.coins >= 0 ? redCoin?.coins : maxRedDiscount;
        if (redCashCheck) {
          if (totalPrice - referralPointsDiscount >= redCashDeduct) {
            setRedDisc(redCashDeduct);
          } else {
            setRedDisc(totalPrice - referralPointsDiscount)
          }
        } else {
          setRedDisc(0);
        }
      }
    }
  }, [redCashCheck]);

  useEffect(() => {
    if (redCashCheck === true) {
      setDiscount(0);
      setCoupon(0);
      setCouponCode("");
    } else {
      setRedDisc(0);
    }
  }, [redCashCheck])

  useEffect(() => {
    let tempTotalPrice = (totalPrice - redProMembershipDiscount);
    if (couponReferralValidation?.already_applied === false) {
      const discountPercentage = couponReferralValidation?.message?.discount;
      const manualCouponDiscount = (tempTotalPrice / 100) * discountPercentage; // Discount if Manual Coupon is Valid
      setRedDisc(0);
      setRedCashCheck(false);
      setDiscount(Math.floor(manualCouponDiscount));
    }
  }, [couponReferralValidation])



  useEffect(() => {
    if (disableCreateBtn) {
      setTimeout(() => {
        setDisableCreateBtn(false);
      }, 10000)
    }
  }, [disableCreateBtn])

  useEffect(() => {
    if (cType === "code" || cType === "percentage" || cType === "referral") {
      setReferralPointsDiscount(0);
      setReferralPointsApplied(false);
    } else {
      setDiscount(0);
      setCoupon(0);
      setCouponCode("");
    }
  }, [cType])

  useEffect(() => {
    if (redCashCheck === true) {
      setDiscount(0);
      setCoupon(0);
      setCouponCode("");
    } else {
      setRedDisc(0);
    }
  }, [redCashCheck])

  const handleRedCheck = (event: any) => {
    setRedCashCheck(event.target.checked);
    setDiscount(0);
    setCouponKey(couponKey + 1);
  };

  const handleAddClick = () => {
    setInputList([
      ...inputList,
      {
        customerId: "",
        customerDesignation: "",
        designation: "",
        customerName: "",
        customerAge: "",
        customerGender: "",
        customerPackage: [],
        packagePrice: 0,
        dateOfBirth: "",
        is_prescribed: "",
        bookingFor: "",
        passport: "",
        aadhar: "",
        oldNameUHID: "",
      },
    ]);
  };
  const ageCheck = inputList.find((data: any) => data.customerAge === "");
  const packageCheck = inputList.find(
    (data: any) => data?.customerPackage.length === 0
  );

  useEffect(() => {
    if (geoFencesMapMyIndia?.latitude && geoFencesMapMyIndia?.longitude) {
      const city_id = geoFencesMapMyIndia?.cityid;
      const zone_Id = geoFencesMapMyIndia?.customer_zone;
      setCustomerLatitude(geoFencesMapMyIndia?.latitude);
      setCustomerLongitude(geoFencesMapMyIndia?.longitude);
      if (city_id) {
        setCityId(city_id);
        if (Number(city_id) !== Number(cityId)) {
          setPackageKey(random);
          clearPackage();
        }
        setZoneId(zone_Id);
      }
    }
    if (
      geoFencesMapMyIndiaForCustomer?.latitude &&
      geoFencesMapMyIndiaForCustomer?.longitude
    ) {
      setDistanceState({
        destinationLat: geoFencesMapMyIndiaForCustomer?.latitude,
        destinationLong: geoFencesMapMyIndiaForCustomer?.longitude,
        startLat: geoFencesMapMyIndia?.latitude,
        startLong: geoFencesMapMyIndia?.longitude,
      });
      setCustomerLatitude(geoFencesMapMyIndiaForCustomer?.latitude);
      setCustomerLongitude(geoFencesMapMyIndiaForCustomer?.longitude);
      const zone_Id = geoFencesMapMyIndiaForCustomer?.customer_zone;
      const city_id = geoFencesMapMyIndiaForCustomer?.cityid;
      const area_id = geoFencesMapMyIndiaForCustomer?.area_id;
      if (city_id) {
        setZoneId(zone_Id);
        setCityId(city_id);
        if (Number(city_id) !== Number(cityId)) {
          setPackageKey(random);
          clearPackage();
        }
        setCustomer_city(geoFencesMapMyIndiaForCustomer?.zone_name);
        setIsPincode(false);
        setCustomer_areapincode(pincode as string);
        setCustomer_areapincode_id(area_id as Number);
        getPackage(`city_id=${city_id}&org_type=homedx`);

        setBookingEnabled(true);
      } else {
        setBookingEnabled(false);
      }
    }
  }, [
    geoFencesMapMyIndia,
    geoFencesMapMyIndiaForCustomer,
    customer_landmark1,
    customer_landmark,
  ]);

  //mapmyindia
  const [suggestedAddress, setSuggestedAddres] = useState<any>([]);
  const [mapAddressObject, setMapAddressObject] = useState<any>({});
  const [mapSubAddressObject, setMapSubAddressObject] = useState<any>({});
  const [accessToken, setAccessToken] = useState<any>("");


  useEffect(() => {
    if (mapmyindiaAccessToken?.accesstoken) {
      setAccessToken(mapmyindiaAccessToken?.accesstoken);

      setLoading1(true);
      setAccessToken(mapmyindiaAccessToken?.accesstoken);
    }
  }, [mapmyindiaAccessToken, openLocationModal]);

  useEffect(() => {
    if (mapAddressObject?.eLoc) {
      resetAddressComponent();
      if (mapAddressObject?.addressTokens?.pincode !== "") {
        setPinCode(mapAddressObject?.addressTokens?.pincode);
        const displayAddress = `${mapAddressObject?.placeName}, ${mapAddressObject?.placeAddress}`;
        setCustomer_landmark1(displayAddress);
        setSaveOldAddress(displayAddress);

        checkGeoFenchMapMyIndiaPilot(mapAddressObject?.eLoc, mapAddressObject?.addressTokens?.city, mapAddressObject?.placeAddress);
        setErrorMessage("");
      } else {
        setErrorMessage(
          "Please select complete locality from dorpdown with pincode"
        );
      }
    }
  }, [mapAddressObject]);
  useEffect(() => {
    if (mapSubAddressObject?.eLoc) {
      if (
        mapAddressObject?.customer_zone === mapSubAddressObject?.customer_zone
      ) {
        const displayAddress = `${mapSubAddressObject?.placeName}, ${mapSubAddressObject?.placeAddress}`;
        setCustomer_landmark(displayAddress);

        if (mapSubAddressObject?.addressTokens?.pincode !== "") {
          setErrorMessage("");
          checkGeoFenchMapMyIndiaPilotForCustomerAddress(
            mapSubAddressObject?.eLoc
          );
        } else {
          setErrorMessage(
            "Please select complete locality from dorpdown with pincode"
          );
        }
      } else {
        setErrorMessage("Zone and Customer address not match");
      }
    }
  }, [mapSubAddressObject]);

  const locationAutoCompleteSearch = (val: string, e: any) => {
    if (val.length > 2) {
      const urlForMap = `https://atlas.mapmyindia.com/api/places/search/json?query=${val}&access_token=${accessToken}&tokenizeAddress=true`;
      return new Promise(function (resolve, reject) {
        const id = "_" + Math.round(10000 * Math.random());
        const callbackName = "jsonp_callback_" + "data" + id;
        window[callbackName] = (data: any) => {
          delete window[callbackName];
          const ele = document.getElementById(id);
          ele?.parentNode?.removeChild(ele);
          const suggestedData = data?.suggestedLocations;
          if (suggestedData.length > 0) {
            const removePincode = data?.suggestedLocations.filter(
              (data: any) => data.type !== "PINCODE"
            );
            setSaveAddressData(suggestedData)
            setZoneKey(suggestedData.length);
            setSuggestedAddres(removePincode);
          }
        };
        const src = urlForMap + "&callback=" + callbackName;
        const script = document.createElement("script");
        script.src = src;
        script.id = id;
        script.addEventListener("error", reject);
        (
          document.getElementsByTagName("head")[0] ||
          document.body ||
          document.documentElement
        ).appendChild(script);
      });
    }
  };

  //UHID Start
  const [allUHIDCustomer, setAllUHIDCustomer] = useState<any>([]);
  // const [allCustomer, setAllCustomer] = useState<any>([]);
  useEffect(() => {
    if (customer_phonenumber) {
      if (
        customer_phonenumber !== undefined &&
        customer_phonenumber.length === 10
      ) {
        getRedCoing(customer_phonenumber);
      }
    }
  }, [customer_phonenumber]);

  const handleCloseUHID = () => {
    setUhidmodalOpen(false);
  };

  useEffect(() => {
    if (
      redCoin?.uniquecustomer?.length === undefined ||
      redCoin?.uniquecustomer?.length === 0
    ) {
      setUhidmodalOpen(false);
    }
  }, [customer_phonenumber, redCoin]);

  useEffect(() => {
    if (valCoin === true && lead_customer_phonenumber) {
      getRedCoing(lead_customer_phonenumber);
    }
    if (bookingFrom === "FillInfo" && lead_customer_phonenumber) {
      getRedCoing(lead_customer_phonenumber);
    }
  }, [lead_customer_phonenumber]);

  useEffect(() => {
    if (redCoin) {
      if (redCoin?.coins == 0) {
        setRedCoinMessage(`You have ${redCoin.coins} Red Cash`);
      } else {
        setRedCoinMessage(`${redCoin.coins} Red Cash Available`);
      }
      setTotalRedCoin(redCoin.coins);
    }
  }, [redCoin.coins]);

  useEffect(() => {
    const allUHID: any = [];
    if (
      redCoin &&
      redCoin?.uniquecustomer &&
      redCoin.uniquecustomer.length > 0
    ) {
      redCoin.uniquecustomer.map((data: any) => {
        allUHID.push({
          customerId: data?.id,
          lead: data?.lead,
          customerDesignation: (data?.gender?.trim()?.toLowerCase() === "female" || data?.designation?.trim()?.toLowerCase() == "ms") ? "Ms" : "Mr",
          designation: (data?.gender?.trim()?.toLowerCase() === "female" || data?.designation?.trim()?.toLowerCase() == "ms") ? "Ms" : "Mr",
          customerName: data?.display_name,
          customerAge: data?.age !== null ? data?.age : "",
          customerGender: (data?.gender?.trim()?.toLowerCase() === "female" || data?.designation?.trim()?.toLowerCase() == "ms") ? "female" : "male",
          customerPackage: [],
          packagePrice: 0,
          dateOfBirth: data?.dob !== null ? data?.dob : "",
          isSelected: false,
          is_prescribed: "",
          bookingFor: "",
          passport: "",
          aadhar: "",
          oldNameUHID: data?.display_name,
        });
      });
      if (bookingFrom === "Main") {
        // setUhidmodalOpen(true);
        // setSelectPatientOpen(true);
      }
    }
    setAllUHIDCustomer(allUHID);
  }, [redCoin?.uniquecustomer]);

  useEffect(() => {
    if (leads?.count >= 0) {
      setSelectPatientOpen(true);
    }
  }, [leads?.count])

  useEffect(() => {
    const selectedCustomer: any = [];
    const selectedCustomerName: any = [];
    const selectedCustomerId: any = [];
    allUHIDCustomer.map((data: any, index: any) => {
      if (data.isSelected) {
        selectedCustomer.push(data);
      }
    });
    allUHIDCustomer.map((data: any, index: any) => {
      if (data.isSelected || !data.isSelected) {
        selectedCustomerName.push(data?.customerName);
        selectedCustomerId.push(data?.customerId);
      }
    });
    if (selectedCustomer.length !== 0) {
      setInputList(selectedCustomer);
      setShowName(1);
    } else {
      setShowName(0);
    }
    setPersonName(selectedCustomerName);
    setTabData(selectedCustomerId);
    if (selectedCustomerId?.length > 0) {
      setTabLeadUHID(selectedCustomerId[0]);
      setTabLeadName(selectedCustomerName[0]);
    }
  }, [allUHIDCustomer]);

  const handleUHIDAddCustomer = (data: any, index: any) => {
    const list = [...allUHIDCustomer];
    list[index]["isSelected"] = true;
    // list[index]["designation"] = true;
    // list[index]["customerGender"] = true;
    setAllUHIDCustomer(list);
  };
  const handleUHIDRemoveCustomer = (data: any, index: any) => {
    const list = [...allUHIDCustomer];
    list[index]["isSelected"] = false;
    setAllUHIDCustomer(list);
  };
  //UHID End


  const handleCollectionCentre = () => {
    setCollectionType("collectioncentre");
  };


  document.querySelectorAll("#noTyping").forEach((el) => {
    el.addEventListener("keydown", function (e) {
      e.preventDefault();
    });
  });

  useEffect(() => {
    setReferringDoctor(postRefReducer?.id)
  }, [postRefReducer])


  useEffect(() => {
    if (inputList[0]?.bookingFor !== "") setHitApiForSendWhatsappMessage(true);
  }, [inputList])

  const handleAddressModel = async () => {
    setOpenLocationModal(false);
    let body = {
      lead_id: leadData,
      street_name: addressLine2,
      house_number: customer_address,
      address_line1:
        addressObjData === "" ? customer_landmark1 : saveOldAddress,
      address_line2: addressLine2,
      latitude: customerLatitude,
      longitude: customerLongitude,
      city: cityId !== "" ? cityId : geoCheckCityId,
    };
    if (
      apiHitCount === 0 &&
      leadData !== undefined &&
      leadData !== null &&
      leadData !== 0
    ) {
      await postCrmLeadAddress(body, leadData);
    }
    setShowMessage(true);
  };

  useEffect(() => {
    if (
      crmPostLeadAddress?.id > 0 &&
      leadData !== 0 &&
      leadData !== undefined &&
      leadData !== null
    ) {
      let data = {
        lead_id: leadData,
        address_id: crmPostLeadAddress.id,
        attempt: "first",
      }
      if (openVerifiedLocationModal === false) {
        setApiHitCount(1)
        if (apiHitCount === 0) {
          setTimeout(() => {
            getWhatsappMessageStatus(`${crmPostLeadAddress.id}`);
          }, 3500)
          if (checkAutomaticsMessageSendFlag === true) {
            SendLocationRequest(data)
          }
          setCheckAutomaticsMessageSendFlag(false)
        }
      }
      setAddressExeData(crmPostLeadAddress?.id);
    }
  }, [crmPostLeadAddress]);

  useEffect(() => {
    if (saveAddressData !== "") {
      let body = {
        data: saveAddressData
      }
      addressJsonPost(body);
    }

  }, [saveAddressData])

  const [isHitLeadSubscriptionsDataAPI, setIsHitLeadSubscriptionsDataAPI] = useState(false);

  useEffect(() => {
    if (isHitLeadSubscriptionsDataAPI === true) {
      getLeadSubscriptionsData();
    }
  }, [isHitLeadSubscriptionsDataAPI]);

  useEffect(() => {
    if (checkedWay === "") {
      setRedProMembershipDiscount(0);
    } else {
      setReferralPointsDiscount(0);
      setReferralPointsApplied(false);
      setDiscount(0);
      setCoupon(0);
      setCouponCode("");
      setRedDisc(0);
      setRedProMembershipDiscount(Math.floor((price / 100) * redProMembershipDiscountPercentage));
    }
  }, [checkedWay, price])

  useEffect(() => {
    if (lead_details && lead_details?.subscriptions?.length > 0 && lead_details?.subscriptions[0]?.discount > 0) {
      setAlreadyARedPro(true)
      if (lead_details?.booking_count > 10) {
        setRedProMembershipDiscount(0);
      } else {
        setRedProMembershipDiscount(Math.floor((price / 100) * lead_details?.subscriptions[0]?.discount));
      }
    } else {
      setAlreadyARedPro(false)
      setRedProMembershipDiscount(0);
      setCheckedWay("");
      setRedProMembershipDiscountPercentage(0);
      setProMembershipPrice(0);
    }
  }, [lead_details, price])

  useEffect(() => {
    setReferralPoints(referralCurrentBalance?.current_point)
  }, [referralCurrentBalance])

  useEffect(() => {
    if (cType !== "referral") {
      dispatch({ type: ACTIONS.GET_COUPON_REFERRAL_VALIDATION, payload: [] });
    }
  }, [cType])

  useEffect(() => {
    if (isHitReferralCurrentBalanceAPI === true) {
      if (bookingFrom === "Main") {
        if (search.length > 0 && leads?.count > 0) getReferralCurrentBalance(leads?.results[0]?.content_number);
        else setIsHitReferralCurrentBalanceAPI(false)
      }
      if (bookingFrom === "FillInfo") {
        if (leads?.count > 0) getReferralCurrentBalance(leads?.results[0]?.content_number);
        else setIsHitReferralCurrentBalanceAPI(false)
      }
    }
  }, [isHitReferralCurrentBalanceAPI]);

  // adding the partner code discount
  useEffect(() => {
    if (lead_details && lead_details?.partner_code && price > 0) {
      const discountPrice = (price * 10) / 100;
      setDiscount(discountPrice);
      setPartnerCodeDiscount(true);
      setTotalPrice((previousPrice: any) => previousPrice - discountPrice);
    }
  }, [price])

  const dateArray = [
    moment(Date.now()).format('Do MMM'),
    moment(new Date().setDate(new Date().getDate() + 1)).format('Do MMM'),
    moment(new Date().setDate(new Date().getDate() + 2)).format('Do MMM'),
    moment(new Date().setDate(new Date().getDate() + 3)).format('Do MMM')
  ]

  const [dateGap, setDateGap] = useState<any>(0)

  const dateArrayAnother = [
    moment(new Date().setDate(new Date().getDate() + dateGap)).format('Do MMM'),
    moment(new Date().setDate(new Date().getDate() + dateGap + 1)).format('Do MMM'),
    moment(new Date().setDate(new Date().getDate() + dateGap + 2)).format('Do MMM'),
    moment(new Date().setDate(new Date().getDate() + dateGap + 3)).format('Do MMM')
  ]

  useEffect(() => {
    if (latestLeadAddress?.length !== 0) {
      if (latestLeadAddress?.packages?.length > 0) {
        setLastBookedPackageLength(latestLeadAddress?.packages?.length);
        setLastPackageShow(true);
        getPackage(`codes=${latestLeadAddress?.packages.toString()}`);
      } else {
        setLastBookedPackageLength(latestLeadAddress?.packages?.length);
        setLastPackageShow(false);
      }
    }
  }, [latestLeadAddress])

  const hideNavigation = window.location.href.indexOf("hide_navigation") !== -1;

  useEffect(() => {
    if (hideNavigation) {
      setSelectPatientOpen(true);
      setLoaderPatientDetails(true);
    }
  }, [hideNavigation])



  useEffect(() => {
    if (geoCheckLatitude !== 0 || geoCheckLongitude !== 0 || geoCheckCityId !== "") {
      if (geoCheckLatitude !== undefined || geoCheckLongitude !== undefined || geoCheckCityId !== undefined) {
        if (geoCheckLatitude !== null || geoCheckLongitude !== null || geoCheckCityId !== null) {
          if (geoCheckCityId !== null) {
            if(geoCheckLatitude!==0&&geoCheckLongitude!==0)
            {
              getceCentre(
                `?customer_latitude=${geoCheckLatitude}&customer_longitude=${geoCheckLongitude}&city=${geoCheckCityId}`
              );
            }
            
            getPackage(`city_id=${geoCheckCityId}&org_type=homedx`);
            getTimeslots()
          }
        }
      }
    }
  }, [geoCheckCityId, geoCheckLatitude, geoCheckLongitude]);

  useEffect(() => {
    if (packageCode !== undefined && packageCode !== null) {
      getPackageAutoSelected(
        `city_id=${cityId}&code=${packageCode},${testCode}&org_type=homedx`
      );

    }

  }, [packageCode])
  useEffect(() => {
    if (autoSelectedPackageReducer && autoSelectedPackageReducer?.results?.length > 0) {
      let len = autoSelectedPackageReducer?.results.length;
      handlePackage(0, [...packagePerviousData, ...autoSelectedPackageReducer?.results], autoSelectedPackageReducer?.results[len - 1]);
    }

  }, [autoSelectedPackageReducer])

  const [containerSlotsArray, setContainerSlotsArray] = useState<Array<{ "available_slots": number }>>([]);
  const [dateKey, setDateKey] = useState<any>(false);

  useEffect(() => {
    const slots = containerSlots && containerSlots?.results;
    setContainerSlotsArray(slots && slots[0]?.zone_data && slots[0]?.zone_data?.time_slots?.filter((item: {
      available_slots: number; start_time: any;
    }) => {
      var dateNow = moment(new Date()).format("DD/MM/YYYY HH:mm:ss");
      var dateThen = moment(new Date()).format(`DD/MM/YYYY ${item?.start_time}`);
      var timeDiff = moment(dateThen, "DD/MM/YYYY HH:mm:ss").diff(moment(dateNow, "DD/MM/YYYY HH:mm:ss"));
      if (containerDate === moment().format("YYYY-MM-DD")) return timeDiff > 5400000 && item?.available_slots > 0;
      else return item?.available_slots > 0;
    }));
  }, [containerSlots]);

  useEffect(() => {
    if (containerDate && zoneId === "") alert("Please select locality to select the 'Container Delivery Slot'.")
    if (containerDate && zoneId !== "") {
      setContainerSlot("");
      setDateKey(!dateKey);
      getContainerSlots(containerDate, zoneId);
    }
  }, [containerDate, zoneId])

  useEffect(() => {
    if (Number(checkedContainerDeliverySlot) === 1) {
      setCollection_date("");
      setDateSelected(-1);
      setUrineContainer(true);
    }
  }, [checkedContainerDeliverySlot])

  useEffect(() => {
    if (buttonSelectOne == true || packagesList?.count > 0 && selectPackageObj01?.length > 0) {
      handlePackage(0, [...packagePerviousData, ...selectPackageObj01], 0);
      setPackageKey(packageKey + 1)

    }
  }, [packagesList, buttonSelectOne])
  useEffect(() => {
    if (buttonSelectTwo === true || packagesList?.count > 0 && selectPackageObj02?.length > 0) {
      handlePackage(0, [...packagePerviousData, ...selectPackageObj02], 0);
      setPackageKey(packageKey + 1)

    }
  }, [packagesList, buttonSelectTwo])

  return (
    <main className={classes.content}>
      {bookingFrom === "Main" ?
        <div className={classes.contentTable}>
          {leads.results && leads.results.length > 0 && (
            <TableContainer className={classes.table}>
              <Table aria-label="simple table" style={{ marginTop: "10px" }}>
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell align="center">Lead ID</StyledTableCell>
                    <StyledTableCell align="center">Name</StyledTableCell>
                    <StyledTableCell align="center">Age</StyledTableCell>
                    <StyledTableCell align="center">Gender</StyledTableCell>
                    <StyledTableCell align="center">Mobile Number</StyledTableCell>
                    <StyledTableCell align="center">Address</StyledTableCell>
                    <StyledTableCell align="center">Action</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {leads.results.map((lead: any) => {
                    return (
                      <TableRow>
                        <TableCell align="center">{lead.id || ""}</TableCell>
                        <TableCell align="center">
                          {lead.patient_name || ""}
                        </TableCell>
                        <TableCell align="center">
                          {lead.patient_age || ""}
                        </TableCell>
                        <TableCell align="center">
                          {lead.calling_gender || ""}
                        </TableCell>
                        <TableCell align="center">{(lead?.mobile === null || lead?.mobile === undefined) ? "" : `XXXXXX${lead?.mobile.slice(-4)}`}</TableCell>
                        <TableCell align="center">
                          {(lead?.whatsapp_contact === null || lead?.whatsapp_contact === undefined) ? "" : `XXXXXX${lead?.whatsapp_contact.slice(-4)}`}
                        </TableCell>
                        <TableCell align="center">{lead.address || ""}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div> : ""
      }
      <div className={classes.contentTable}>
        <p style={{ color: "red", fontWeight: "bold", textAlign: "right" }}>
          {geoCheckError}
        </p>
        <form onSubmit={submitForm}>
          {(bookingFrom === "FillInfo") ?
            <Grid>
              <Button
                color="secondary"
                variant="contained"
                size="small"
                onClick={() => setUhidmodalOpen(true)}
              >
                SELECT UNIQUE CUSTOMER
              </Button>
            </Grid> : ""
          }
          <Grid container spacing={3} style={{ margin: "1rem auto" }}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4} style={{ paddingTop: "15px" }}>
                  <p style={{ marginBottom: "0px" }}>Check Serviceability</p>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Autocomplete
                    id="checkservice"
                    // key={saveOldAddress}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        if (suggestedAddress?.length !== 0) {
                          setMapAddressObject(obj);
                          // setSaveOldAddress(obj?.address_line1);
                          setAddressExeData(0);
                          setAddressObjData("");
                          setAddressLine2("");
                          setCustomer_address("");
                          setFavoritePlace("");
                          setCustomer_landmark("");
                        } else {
                          setMapAddressObject(obj?.address_line1);
                          setCustomer_address(obj?.house_number);
                          setCustomer_landmark(obj?.address_line1);
                          setAddressLine2(obj?.street_name);
                          setFavoritePlace(obj?.address_line1);
                          setAddressExeData(obj?.id);
                          setCityId(obj?.city?.id);
                          setZoneId(obj?.city?.customer_zone);
                          setAddressObjData(obj);
                          setCustomerLatitude(obj?.latitude);
                          setCustomerLongitude(obj?.longitude);
                          setSaveOldAddress(obj?.address_line1);
                        }
                      }
                    }}
                    options={suggestedAddress?.length !== 0 ? suggestedAddress : (latestLeadAddress?.last_saved_address === undefined ? [] : [latestLeadAddress?.last_saved_address])}
                    freeSolo
                    aria-required
                    getOptionLabel={(option: any) => {
                      if (option?.address_line1) {
                        return `${option?.address_line1}`;
                      }
                      return `${option?.placeName}${option?.placeAddress ? String("," + option?.placeAddress) : ""}`
                    }}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <div style={{ display: 'flex' }}>
                          {option?.address_line1 ?
                            <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row" }}>
                              <p>{option?.address_line1}</p>
                              <b><i>Last Booked</i></b>
                            </div>
                            : `${option?.placeName}${option?.placeAddress ? String("," + option?.placeAddress) : ""}`
                          }
                        </div>
                      </React.Fragment>
                    )}
                    filterOptions={(option: any) => option}
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        locationAutoCompleteSearch(newInputValue, event);
                      }, 500);
                      if (newInputValue.length === 0) {
                        setAddressExeData(0);
                        setAddressObjData("");
                        setAddressLine2("");
                        setCustomer_address("");
                        setFavoritePlace("");
                        setCustomer_landmark("");
                      }
                    }}
                    // onKeyDown={(e)=>handleKeyDown(e)}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        placeholder="Search Locality"
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                        //
                        value={saveOldAddress}
                        inputProps={{
                          ...params.inputProps,
                          onKeyDown: (e) => {
                            if (e.key === "Enter") {
                              e.stopPropagation();
                            }
                          },
                        }}
                      />
                    )}
                  />
                  <p style={{ color: "red" }}>{errorMessage}</p>
                </Grid>
                <Grid item xs={12} md={4}>
                  <p>Booking Date</p>
                </Grid>
                <Grid item xs={12} md={8}>
                  <input
                    className="input"
                    name="collection_date"
                    type="date"
                    value={`${new Date().getFullYear()}-${new Date().getMonth() + 1 < 10
                      ? `0${new Date().getMonth() + 1}`
                      : new Date().getMonth() + 1
                      }-${new Date().getDate() < 10
                        ? `0${new Date().getDate()}`
                        : new Date().getDate()
                      }`}
                    style={{
                      width: "100%",
                      margin: "0",
                      padding: "18.5px 14px",
                      height: "40px",
                    }}
                    required
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <p>Sample Collection Date</p>
                </Grid>
                <Grid item xs={12} md={8}>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    {
                      (Number(checkedContainerDeliverySlot) === 1) ?
                        dateArrayAnother?.map((x: any, index: any) => (
                          <Button
                            style={{ background: dateSelected == index ? "#2b5276" : "#4483bc80", color: "white", fontSize: "10px", marginRight: "5px", textTransform: "none", height: "32px" }}

                            onClick={(e) => changeCollectionDate(e, index)}
                          >
                            {x}
                          </Button>
                        ))
                        :
                        dateArray?.map((x: any, index: any) => (
                          <Button
                            style={{ background: dateSelected == index ? "#2b5276" : "#4483bc80", color: "white", fontSize: "10px", marginRight: "5px", textTransform: "none", height: "32px" }}

                            onClick={(e) => changeCollectionDate(e, index)}
                          >
                            {x}
                          </Button>
                        ))
                    }
                  </div>
                  <div style={{ marginTop: "15px" }}>
                    <Button
                      variant="contained"
                      color="primary"

                      style={{ fontSize: "12px" }}
                      onClick={() => HandleOpenLocation()}
                      disabled={collection_date === ""}
                    >
                      Select Address
                    </Button>

                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid
                container
                spacing={0}
                style={{
                  padding: "10px",
                  background: "#e1f5fe",
                  marginLeft: "-10px",
                }}
              >
                <Grid item xs={12} md={12}>
                  <p>
                    Locality : <strong>{addressObjData === "" ? customer_landmark1 : saveOldAddress}</strong>
                  </p>
                </Grid>
                <Grid item xs={12} md={12}>
                  <p>
                    House / Flat / Shop Number : <strong>{customer_address}</strong>
                  </p>
                </Grid>
                <Grid item xs={12} md={12}>
                  <p>
                    Apartment / Building / Street Number : <strong>{addressLine2}</strong>
                  </p>
                </Grid>
                <Grid item xs={12} md={12}>
                  <p>
                    Landmark : <strong>{favoritePlace}</strong>
                  </p>
                </Grid>
                {addressLine2 === "" ||
                  customer_address === "" ||
                  favoritePlace === "" ? (
                  <p style={{ color: "red", alignItems: "center" }}>
                    All Field are mandatory for Proceed booking
                  </p>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
            <Grid container spacing={3} style={{ margin: "1rem auto" }}>
              <Grid item xs={12} md={12}>
                <Button
                  variant="contained"
                  fullWidth
                  style={{
                    background:
                      collectionType === "collectioncentre" ? "#cddc39" : "",
                  }}
                  onClick={handleCollectionCentre}
                >
                  Collection Centre
                </Button>
              </Grid>
            </Grid>
            {ceCenterForCC && ceCenterForCC?.length > 0 ? (
              <>
                {collectionType === "collectioncentre" ? (
                  <>
                    <Grid item xs={12} md={12} sm={12}>
                      <Autocomplete
                        id="centre"
                        key={collectionKey}
                        onChange={(event, newValue) => {
                          let leadIds: any = "";
                          if (newValue) {
                            let obj = JSON.parse(
                              JSON.stringify(newValue, null, " ")
                            );
                            leadIds = obj.id;
                          }
                          setCollectionCenterId(leadIds);
                        }}
                        options={ceCenterForCC || []}
                        freeSolo
                        blurOnSelect
                        loading={loading}
                        aria-required
                        limitTags={1}
                        onInputChange={(event, newInputValue) => {
                          clearTimeout(timer.current);
                          timer.current = setTimeout(() => {
                            getCenterInfo(`?name_or_code=${newInputValue}`);
                          }, 500);
                        }}
                       
                        getOptionLabel={(option: any) =>
                          "Centre : (" +
                          option?.center_code +
                          ") " +
                          option?.display_name +
                          " Distance : " +
                          Math?.round((option?.distance + Number.EPSILON) * 100) / 100 +
                          "KM" +
                          " Address : " +
                          option?.address
                        }
                        disableClearable
                        disableCloseOnSelect
                        renderInput={(params) => (
                          <TextField
                            className="input"
                            {...params}
                            label="Collection Centre"
                            variant="outlined"
                            style={{ width: "100%", margin: "0", padding: "0" }}
                            inputProps={{
                              ...params.inputProps,
                              onKeyDown: (e) => {
                                if (e.key === "Enter") {
                                  e.stopPropagation();
                                }
                              },
                            }}
                          />
                        )}
                      />
                      <p style={{ color: "#e91e63", alignItems: "center" }}>
                        You Can Only Do Booking within the 3 KM...
                      </p>
                    </Grid>
                  </>
                ) : String(geoCheckCityId)?.length > 0 && geoCheckCityId !== null ? (
                  <>
                    <Grid item xs={12} md={12} sm={12}>
                      <Autocomplete
                        id="centre"
                        key={collectionKey}
                        onChange={(event, newValue) => {
                          let leadIds: any = "";
                          let objData: any;
                          if (newValue) {
                            let obj = JSON.parse(
                              JSON.stringify(newValue, null, " ")
                            );
                            objData = obj
                            leadIds = obj.id;
                          }
                          setCollectionCenterId(leadIds);
                          setCustomerLongitudeForCC(objData?.logitude)
                          setcustomerLatitudeForCC(objData?.latitude)
                        }}
                        options={ceCenterForCC || []}
                        freeSolo
                        blurOnSelect
                        loading={loading}
                        aria-required
                        limitTags={1}
                        onInputChange={(event, newInputValue) => {
                          clearTimeout(timer.current);
                          timer.current = setTimeout(() => {
                            if (String(geoCheckCityId)?.length > 0) {
                              getceCentre(
                                `?customer_latitude=${geoCheckLatitude}&customer_longitude=${geoCheckLongitude}&city=${geoCheckCityId}`
                              )
                            }
                            else {
                              getceCentre(`?name_or_code=${newInputValue}`);
                            }

                          }, 500);
                        }}
                        getOptionLabel={(option: any) =>
                          "Centre : (" +
                          option?.center_code +
                          ") " +
                          option?.display_name +
                          " Distance : " +
                          Math?.round((option?.distance + Number.EPSILON) * 100) / 100
                          +
                          "KM" +
                          " Address : " +
                          option?.address
                        }
                       
                        disableClearable
                        disableCloseOnSelect
                        renderInput={(params) => (
                          <TextField
                            className="input"
                            {...params}
                            label="Collection Centre"
                            variant="outlined"
                            style={{ width: "100%", margin: "0", padding: "0" }}
                            inputProps={{
                              ...params.inputProps,
                              onKeyDown: (e) => {
                                if (e.key === "Enter") {
                                  e.stopPropagation();
                                }
                              },
                            }}
                          />
                        )}
                      />
                      <p style={{ color: "#e91e63", alignItems: "center" }}>
                        You Can Only Do Booking within the 3 KM...
                      </p>
                    </Grid>
                  </>
                ) : ""}
              </>
            ) : (
              ""
            )}
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-end"
            spacing={3}
            style={{ margin: "1rem auto", background: "#eff" }}
          >
            <Grid item md={3}>
              <p>Mobile Number</p>
              {dummyValueMain ? (
                <>
                  <TextField
                    className="input"
                    name="customer_phonenumber"
                    type="text"
                    onWheel={(e: any) => e.target.blur()}
                    disabled
                    value={dummyValue}
                    placeholder="Enter Mobile Number"
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                  />

                </>
              ) : (
                <>
                  <TextField
                    className="input"
                    name="customer_phonenumber"
                    type="number"
                    onWheel={(e: any) => e.target.blur()}
                    onInput={(e: any) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 10);
                    }}
                    value={customer_phonenumber || ""}
                    placeholder="Enter Mobile Number"
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                    onChange={(e) =>
                      setCustomer_phonenumber(e.target.value as String)
                    }
                    onBlur={() => verifyMobile("mobile")}
                    onFocus={() => {
                      setMobileValidation((prev: any) => ({
                        ...prev,
                        customer_phonenumber: true,
                      }));
                    }}
                    helperText={
                      !mobileValidation.customer_phonenumber &&
                      "Incorrect Phone Number"
                    }
                    required
                  />
                </>
              )}
            </Grid>
            <p style={{ color: "red" }}>{mobError}</p>
            <Grid item md={3}>
              <p>Alternate Mobile Number</p>
              {dummyValueAlter ? (
                <TextField
                  className="input"
                  name="customer_alternatenumber"
                  type="text"
                  onWheel={(e: any) => e.target.blur()}
                  disabled
                  value={dummyValue}
                  placeholder="Enter Alternate Mobile Number"
                  variant="outlined"
                  style={{ margin: "0", width: "100%" }}
                />
              ) : (
                <TextField
                  className="input"
                  name="customer_alternatenumber"
                  type="number"
                  onInput={(e: any) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }}
                  onWheel={(e: any) => e.target.blur()}
                  value={customer_alternatenumber}
                  placeholder="Enter Alternate Mobile Number"
                  variant="outlined"
                  style={{ margin: "0", width: "100%" }}
                  onChange={(e) =>
                    setCustomer_alternatenumber(e.target.value as String)
                  }
                  onBlur={() => verifyMobile("alternate")}
                  onFocus={() => {
                    setMobileValidation((prev: any) => ({
                      ...prev,
                      customer_alternatenumber: true,
                    }));
                  }}
                  helperText={
                    !mobileValidation.customer_alternatenumber &&
                    "Incorrect Alternate Phone Number"
                  }
                  required
                />
              )}
            </Grid>
            <p style={{ color: "red" }}>{altError}</p>
            <Grid item md={3}>
              <p>Whatsapp Number</p>
              {dummyValueWhats ? (
                <TextField
                  className="input"
                  name="customer_whatsapppnumber"
                  type="text"
                  placeholder="Enter Whatsapp Number"
                  disabled
                  value={dummyValue}
                  variant="outlined"
                  style={{ margin: "0", width: "100%" }}
                />
              ) : (
                <TextField
                  className="input"
                  name="customer_whatsapppnumber"
                  type="number"
                  onInput={(e: any) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }}
                  onWheel={(e: any) => e.target.blur()}
                  placeholder="Enter Whatsapp Number"
                  value={customer_whatsapppnumber || ""}
                  variant="outlined"
                  style={{ margin: "0", width: "100%" }}
                  onChange={(e) =>
                    setCustomer_whatsapppnumber(e.target.value as String)
                  }
                  required
                  onBlur={() => verifyMobile("whatsapp")}
                  onFocus={() => {
                    setMobileValidation((prev: any) => ({
                      ...prev,
                      customer_whatsapppnumber: true,
                    }));
                  }}
                  helperText={
                    !mobileValidation.customer_whatsapppnumber &&
                    "Incorrect Whatsapp Number"
                  }
                />
              )}
            </Grid>
            <p style={{ color: "red" }}>{whatsError}</p>
            <Grid item md={3}>
              <p>Email</p>
              <TextField
                className="input"
                name="customer_email"
                type="email"
                placeholder="customer.reports@redcliffelabs.com"
                value={customer_email}
                variant="outlined"
                style={{ margin: "0", width: "100%" }}
                disabled={deniedEmailCheck}
                onChange={(e) => setCustomer_email(e.target.value as String)}
              />
            </Grid>
            <Grid container spacing={2} style={{ display: "flex" }}>
              <Grid item xs={12} sm={6} md={3}></Grid>
              {lead_customer_alter !== "" ? (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  style={{ paddingLeft: "20px" }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={changeAlternate}
                        onChange={(e: any) =>
                          handleChangeAlternate(e, "changeAlternate")
                        }
                        name="altNumChange"
                      />
                    }
                    label="Change Alternate Number"
                  />
                </Grid>
              ) : (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  style={{ paddingLeft: "20px" }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={sameAlternate}
                        disabled={
                          customer_phonenumber === "" &&
                          dummyValueMain === false
                        }
                        onChange={(e: any) =>
                          handleSameAlternate(e, "sameAlternate")
                        }
                        name="altCheck"
                      />
                    }
                    label="Same Alternate Number"
                  />
                </Grid>
              )}
              {lead_customer_whatsapppnumber !== "" ? (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  style={{ paddingLeft: "20px" }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={changeWhatsapp}
                        onChange={(e: any) =>
                          handleChangeWhatsapp(e, "changeWhatsapp")
                        }
                        name="whatsappNumChange"
                      />
                    }
                    label="Change Whatsapp Number"
                  />
                </Grid>
              ) : (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  style={{ paddingLeft: "20px" }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={sameWhatsapp}
                        onChange={(e: any) =>
                          handleSameWhatsapp(e, "sameWhatsapp")
                        }
                        disabled={
                          customer_phonenumber === "" &&
                          dummyValueMain === false
                        }
                        name="whatsCheck"
                      />
                    }
                    label="Same Whatsapp Number"
                  />
                </Grid>
              )}
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                style={{ paddingLeft: "20px" }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={deniedEmailCheck}
                      onChange={(e: any) =>
                        setDeniedEmailCheck(e?.target?.checked)
                      }
                      disabled={customer_email.trim().length > 0}
                      name="deniedEmailCheck"
                    />
                  }
                  label="Customer denied email"
                />
              </Grid>
            </Grid>
          </Grid>
          {inputList.map((x: any, index: number) => {
            let showFemale;
            if (x.designation === "Ms" || x.customerGender === "female" || x.customerGender === "Female") showFemale = true;
            if (x.designation === "Mr" || x.customerGender === "male" || x.customerGender === "Male" || x.customerGender === "other") showFemale = false;
            if (showFemale === true && (x.bookingFor === "father_in_law" || x.bookingFor === "son_in_law")) handleBookingForChange(index);
            if (showFemale === false && (x.bookingFor === "mother_in_law" || x.bookingFor === "daughter_in_law")) handleBookingForChange(index);
            return (
              <>
                <Grid item md={1}>
                  {inputList.length !== 1 && (
                    <HighlightOffIcon
                      style={{
                        marginBottom: "1rem",
                        height: "40px",
                        width: "40px",
                        borderRadius: "50%",
                      }}
                      onClick={() => handleRemoveClick(index)}
                    />
                  )}
                  {inputList.length - 1 === index && inputList.length < 5 && (
                    <AddCircleIcon
                      style={{
                        marginBottom: "1rem",
                        height: "40px",
                        width: "40px",
                        borderRadius: "50%",
                      }}
                      onClick={handleAddClick}
                    />
                  )}
                </Grid>
                <p>{x.customerId !== "" ? `UHID : ${x.oldNameUHID === x.customerName ? x.customerId : ""}` : ""}</p>
                <Grid
                  container
                  direction="row"
                  spacing={3}
                  style={{ margin: "1rem auto", background: "#e1f5fe" }}
                >
                  <Grid item md={2}>
                    <p>Title</p>
                    <Select
                      className="input"
                      name="designation"
                      variant="outlined"
                      value={x.designation}
                      style={{ width: "100%", margin: "0" ,height:"40px"}}
                      onChange={(e) => handleInputChange(e, index)}
                      required
                    >
                      <MenuItem value="Mrs">Mrs</MenuItem>
                      <MenuItem value="Miss">Miss</MenuItem>
                      <MenuItem value="Ms">Ms</MenuItem>
                      <MenuItem value="Smt">Smt</MenuItem>
                      <MenuItem value="Mr">Mr</MenuItem>
                      <MenuItem value="Baby Boy">Baby Boy</MenuItem>
                      <MenuItem value="Baby Girl">Baby Girl</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item md={2}>
                    <p>Customer Name</p>
                    <TextField
                      className="input"
                      name="customerName"
                      type="text"
                      placeholder="Enter Name"
                      value={x.customerName || ""}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{ width: "100%", margin: "0" }}
                      helperText={error} // error message
                      error={!!error}
                      onChange={(e) => handleInputChange(e, index)}
                      required
                    />
                  </Grid>
                  <Grid item md={2}>
                    <p>DOB</p>
                    <input
                      className="input"
                      name="dateOfBirth"
                      type="date"
                      value={x.dateOfBirth}
                      onChange={(e) => handleInputChange(e, index)}
                      min={`${new Date().getFullYear() - 150}-${new Date().getMonth() + 1 < 10
                        ? `0${new Date().getMonth() + 1}`
                        : new Date().getMonth() + 1
                        }-${new Date().getDate() < 10
                          ? `0${new Date().getDate()}`
                          : new Date().getDate()
                        }`}
                      max={`${new Date().getFullYear()}-${new Date().getMonth() + 1 < 10
                        ? `0${new Date().getMonth() + 1}`
                        : new Date().getMonth() + 1
                        }-${new Date().getDate() < 10
                          ? `0${new Date().getDate()}`
                          : new Date().getDate()
                        }`}
                      style={{
                        width: "100%",
                        padding: "15px 14px",
                        background: "#eff",
                        height: "40px",
                      }}
                    />
                  </Grid>
                  <Grid item md={1}>
                    <p>Age</p>
                    <TextField
                      className="input"
                      name="customerAge"
                      type="number"
                      placeholder="Enter Age"
                      value={x.customerAge}
                      disabled={x.dateOfBirth !== ""}
                      variant="outlined"
                      style={{ width: "100%", margin: "0" }}
                      inputProps={{ min: 0, max: 100 }}
                      onChange={(e) => {
                        let ageValue = parseInt(e.target.value);
                        if (ageValue > 150) ageValue = 150;
                        if (ageValue < 0) ageValue = 0;
                        e.target.value = ageValue.toString();
                        handleInputChange(e, index);
                      }}
                    />
                  </Grid>
                  <Grid item md={2}>
                    <p>Gender</p>
                    <TextField
                      className="input"
                      name="customerGender"
                      variant="outlined"
                      placeholder="Select Gender"
                      value={
                        (x.customerGender === "male" || x.customerGender === "Male")
                          ? "Male"
                          : (x.customerGender === "female" || x.customerGender === "Female")
                            ? "Female"
                            : x.customerGender === "other" || x.customerGender === "Other" ? "Male" : ""
                      }
                      style={{ width: "100%", margin: "0", height: "40px" }}
                      onChange={(e) => handleInputChange(e, index)}
                      disabled={true}
                      required={true}
                    />
                    <p style={{ color: "red" }}>
                      {inputList[0]?.customerGender === null
                        ? "Please select gender"
                        : ""}
                    </p>
                  </Grid>
                  <Grid item md={3}>
                    <p>Package Name</p>
                    <Autocomplete
                      id="customerPackage"
                      key={packageKey}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          let packs: any = [];

                          newValue.map((val: any) => {
                            let obj = JSON.parse(
                              JSON.stringify(val, null, " ")
                            );
                            packs.push(obj);
                            setPackagePerivousData(packs)

                            setPackageIdValue(obj?.id)
                          });
                          let len = packs.length;
                          setAllergyQuestionId(packs[len - 1])
                          handlePackage(index, packs, packs[len - 1]);

                        }
                        setCValue("")

                      }}
                      // onMouseEnter={() => handlePackage(0, packagesList?.results, 0)}
                      multiple
                      loading={geoLoading}
                      disableClearable
                      options={lastBookedPackage?.length === 0 ? (packageList?.results || []) : (lastBookedPackage || [])}
                      limitTags={0}
                      // disabled={is_pincode}
                      freeSolo
                      disableCloseOnSelect
                      filterOptions={(options, state) => options
                        //   {
                        //   let newOptions: any[] = [];
                        //   options.forEach((element) => {
                        //       newOptions.push(element);
                        //   });
                        //   packagesList?.results && packagesList?.results?.length && newOptions.push(packagesList?.results[0]);
                        //   return newOptions;
                        // }
                      }
                      aria-required
                      onInputChange={(event, newInputValue) => {
                        if (newInputValue.trim().length > 0) {
                          setIsPackageTextPresent(true);
                        } else {
                          setIsPackageTextPresent(false);
                        }
                        clearTimeout(timer.current);
                        timer.current = setTimeout(() => {
                          getPackage(
                            `city_id=${cityId}&search=${newInputValue}&org_type=homedx${x?.customerGender ? `&gender=${x?.customerGender}` : ""}`
                          );
                        }, 1000);
                      }}
                      value={
                        x.customerPackage
                      }
                      // value={autoSelectPackage}
                      getOptionLabel={(option: any) => {
                        return option?.name && option?.package_city_prices
                          ? option?.name.toString() +
                          ", " +
                          option?.package_city_prices?.tat_time +
                          ", Rs. " +
                          option?.package_city_prices?.offer_price +
                          "/-"
                          : option?.name +
                          ", " +
                          option?.tat_time +
                          ", Rs. " +
                          option?.offer_price +
                          "/-";
                      }}
                      getOptionDisabled={(option: any) => {
                        const packID = x?.customerPackage.map(
                          (pack: any) => pack?.id
                        );
                        return packID.includes(option?.id);
                      }}
                      renderOption={(option, { selected }) => (
                        <React.Fragment>
                          <Checkbox
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            style={{ marginRight: 8 }}
                            checked={x?.customerPackage?.length > 0 && x?.customerPackage.filter((pack: any) => pack?.id === option?.id).length === 1 ? true : false}
                          />
                          <div style={{ display: 'flex' }}>
                            {option?.package_city_prices
                              ? ((option?.highlight?.name && option?.highlight?.name.length > 0) ? <div dangerouslySetInnerHTML={{ __html: `${String(option?.highlight?.name)}, ${option?.package_city_prices?.tat_time}, Rs. ${option?.package_city_prices?.offer_price}/- <br /> ${option?.package_or_test === "test" ? option?.also_known_as ? `Also known as: ${option?.also_known_as}` : option?.alias?.length > 0 ? `Also known as: ${option?.alias[0].name}` : "" : option?.highlight?.['test.name'] ? `Includes: ${option?.highlight?.['test.name'][0]}` : ""}` }}></div> : <div dangerouslySetInnerHTML={{ __html: `${option?.name.toString()}, ${option?.package_city_prices?.tat_time}, Rs. ${option?.package_city_prices?.offer_price} <br /> ${option?.package_or_test === "test" ? option?.also_known_as ? `Also known as: ${option?.also_known_as}` : option?.alias?.length > 0 ? `Also known as: ${option?.alias[0].name}` : "" : option?.highlight?.['test.name'] ? `Includes: ${option?.highlight?.['test.name'][0]}` : ""}` }} />) : (option?.highlight?.name && option?.highlight?.name.length > 0) ? <div dangerouslySetInnerHTML={{ __html: `${String(option?.highlight?.name)}, ${option?.tat_time ? `${option?.tat_time},` : ""} ${"Rs. " + option?.offer_price + "/-" || ""} <br /> ${option?.package_or_test === "test" ? option?.also_known_as ? `Also known as: ${option?.also_known_as}` : option?.alias?.length > 0 ? `Also known as: ${option?.alias[0].name}` : "" : option?.highlight?.['test.name'] ? `Includes: ${option?.highlight?.['test.name'][0]}` : ""}` }}></div> : option?.name.toString() +
                                ", " +
                                option?.tat_time +
                                ", Rs." +
                                option?.offer_price +
                                "/-"}
                            {/* {option?.package_city_prices && !option?.highlight?.name
                            ? 
                            option?.package_city_prices?.tat_time +
                            ", Rs. " +
                            option?.package_city_prices?.offer_price +
                            "/-"
                            :  ""} */}
                          </div>
                        </React.Fragment>
                      )}
                      renderInput={(params) => (
                        <TextField
                          className="input"
                          {...params}
                          placeholder="Package"
                          variant="outlined"
                          style={{ width: "100%", margin: "0", padding: "0" }}
                          inputProps={{
                            ...params.inputProps,
                            onKeyDown: (e) => {
                              if (e.key === "Enter") {
                                e.stopPropagation();
                              }
                            },
                          }}
                        />
                      )}
                    />
                    <p style={{ color: "red" }}>
                      {geoCheckError !== "" && String(geoCheckCityId)?.length < 0
                        ? "Please select correct location"
                        : ""}
                    </p>
                  </Grid>
                  <Grid item md={3}>
                    <p>Passport Number</p>
                    <TextField
                      className="input"
                      name="passport"
                      type="text"
                      placeholder="Enter Passport Number"
                      value={x.passport || ""}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{ width: "100%", margin: "0" }}
                      onInput={(e: any) => {
                        e.target.value = e.target.value.toString().slice(0, 10);
                      }}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <p>Aadhaar Number</p>
                    <TextField
                      className="input"
                      name="aadhar"
                      type="number"
                      onWheel={(e: any) => e.target.blur()}
                      onInput={(e: any) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 12);
                      }}
                      placeholder="Enter Aadhaar Number"
                      value={x.aadhar || ""}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{ width: "100%", margin: "0" }}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                  </Grid>
                  <Grid item md={2}>
                    <p>Booking For</p>
                    <Select
                      className="input"
                      name="bookingFor"
                      variant="outlined"
                      value={x.bookingFor}
                      style={{ width: "100%", margin: "0", height: "40px" }}
                      required
                      displayEmpty={true}
                      renderValue={x.bookingFor !== "" ? undefined : () => "Select Booking For"}
                      onChange={(e) => handleInputChange(e, index)}
                    >
                      <MenuItem value="self">Self</MenuItem>
                      <MenuItem value="father_in_law" disabled={showFemale}>Father/Father in law</MenuItem>
                      <MenuItem value="mother_in_law" disabled={showFemale === false}>Mother/Mother in law</MenuItem>
                      <MenuItem value="son_in_law" disabled={showFemale}>Son/Son in law</MenuItem>
                      <MenuItem value="daughter_in_law" disabled={showFemale === false}>Daughter/Daughter in law</MenuItem>
                      <MenuItem value="spouse">Spouse</MenuItem>
                      <MenuItem value="family">Family</MenuItem>
                      <MenuItem value="friend">Friend</MenuItem>
                      <MenuItem value="other">Others</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item md={2}>
                    <p>Is Prescribed</p>
                    <Select
                      className="input"
                      name="is_prescribed"
                      variant="outlined"
                      value={x.is_prescribed}
                      displayEmpty={true}
                      style={{ width: "100%", margin: "0", height: "40px" }}
                      onChange={(e) => handleInputChange(e, index)}
                      renderValue={x.is_prescribed !== "" ? undefined : () => "Select Prescribed"}
                    >
                      <MenuItem value="true">Yes</MenuItem>
                      <MenuItem value="false">No</MenuItem>
                    </Select>
                  </Grid>
                  {+inputList[0]?.customerAge < 5 && inputList[0]?.customerAge !== "" && <p style={{ textAlign: 'center', width: '100%', color: 'red' }}>Age cannot be less than 5 years.</p>}
                </Grid>
              </>
            );
          })}
          <Grid container spacing={3} style={{ margin: "0" }}>
            {!switchEditButton ? (
              <Grid item xs={12} md={6} style={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-start" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>

                 
                </div>
              </Grid>
            ) : (
              <Grid item xs={12} md={8} style={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-start" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ fontSize: "12px", height: "60px", width: "200px" }}
                      onClick={() => setOpenLocationModal(true)}
                      disabled={collection_date === ""}
                    >
                      Edit Address
                    </Button>
                  </div>
                  <div>
                    <p>
                      {communicationWhatsapp?.on_whatsapp === false ? (
                        <p style={{ color: "red" }}>
                          Customer Not Available on WhatsApp Please Take Alternate WhatsApp Number
                        </p>
                      ) : (
                        ""
                      )}
                    </p>
                    {checkSendLocationMessageHit === true ? (
                      <p style={{ color: "green" }}>
                        WhatsApp Message Sent For Location Verification
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </Grid>
            )}
          </Grid>
          <Grid
            container
            spacing={3}
            direction="row"
            justify="center"
            alignItems="center"
            style={{ margin: "1rem auto" }}
          >
           <Grid item xs={12} md={5}>
              <Card
                className="sampleCollectionTimeNewBooking"
                style={{ overflow: "auto", minHeight: "500px" }}
              >
                <p style={{ marginBottom: "1rem" }}>Sample Collection Time</p>
                {geoLoading ? (
                  <Loader />
                ) : (
                  <div className={classes.slotContainer}>
                    {String(geoCheckCityId)?.length > 0 || collectionType === "collectioncentre"
                      ? timeslots &&
                      timeslots?.results &&
                      timeslots.results.length > 0 &&
                      timeslots.results.map((slot: any) => {
                        return (
                          <Card
                            key={slot.id}
                            className={
                              slot.id === selectedSlot
                                ? classes.selectedSlot
                                : classes.slot
                            }
                            onClick={() => setSelectedSlot(slot.id as Number)}
                          >
                            <p
                              style={{
                                textAlign: "center",
                                color: "black",
                                fontSize: "14px",
                              }}
                            >
                              {slot.start_time}-{slot.end_time}
                            </p>
                            <p
                              style={{ textAlign: "center", color: "green" }}
                            ></p>
                          </Card>
                        );
                      })
                      : timeslots &&
                      timeslots?.results &&
                      timeslots.results.length > 0 &&
                      timeslots.results.map((slot: any) => {
                        return (
                          <Card
                            key={slot.id}
                            className={
                              slot.id === selectedSlot
                                ? classes.selectedSlot
                                : classes.slot
                            }
                            onClick={() => setSelectedSlot(slot.id as Number)}
                          >
                            <p
                              style={{
                                textAlign: "center",
                                color: "black",
                                fontSize: "14px",
                              }}
                            >
                              {slot.start_time}-{slot.end_time}
                            </p>
                            <p
                              style={{ textAlign: "center", color: "green" }}
                            ></p>
                          </Card>
                        );
                      })}
                    {timeSlots !== undefined && timeSlots.length === 0 ? (
                      <p style={{ color: "red" }}>
                        "Time Slot is not Active in this Area"
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </Card>
            </Grid>
            <Grid item xs={12} md={7} style={{ padding: "10px" }}>
              <div className="price-details-new-booking">
                <table style={{ width: "100%", margin: "0" }}>
                  <thead>
                    <tr>
                      <td className="cell" align="center">
                        S No.
                      </td>
                      <td className="cell" align="center">
                        Name
                      </td>
                      <td className="cell" align="center">
                        Package
                      </td>
                      <td className="cell" align="center">
                        Price
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {inputList &&
                      inputList.length > 0 &&
                      inputList.map((customerDetails: any, index: number) => {
                        return (
                          <tr>
                            <td className="cell" align="center">
                              {index + 1}
                            </td>
                            <td className="cell" align="left">
                              {(customerDetails.designation === "Other" || customerDetails.designation === "other") ? "Mr" : customerDetails.designation}{" "}
                              {customerDetails.customerName}
                            </td>
                            <td className="cell" align="left">
                              {customerDetails?.customerPackage &&
                                customerDetails?.customerPackage.length > 0 &&
                                customerDetails?.customerPackage.map(
                                  (packageItem: any, index: any) => {
                                    return (
                                      <ul>
                                        <li>
                                          <a
                                            onClick={() => {
                                              setSinglePackage(packageItem);
                                              setPaneOpen(true);
                                            }}
                                            className={classes.packageLink}
                                          >
                                            {packageItem?.name}
                                            <br /> DESCRIPTION :{" "}
                                            {packageItem?.description}
                                            <br />
                                            Report time :{" "}
                                            {packageItem?.package_city_prices
                                              ? packageItem?.package_city_prices
                                                ?.tat_time
                                              : packageItem?.tat_time}
                                            <br />
                                            Smart Report Available:{" "}{packageItem?.smart_report_available === false ? "No" : "Yes"}
                                            <br />
                                            Price :{" "}
                                            {packageItem?.package_city_prices
                                              ? packageItem?.package_city_prices
                                                ?.offer_price
                                              : packageItem?.offer_price}
                                            <br />
                                            Fasting :{" "}
                                            {packageItem?.fasting_time}
                                            <br />
                                          </a>
                                          {packageItem?.sample_report !== null ?
                                            <a
                                              id="linkcolor"
                                              rel="noopener noreferrer"
                                              href={packageItem?.sample_report}
                                              target="_blank"
                                              style={{ color: "white", fontWeight: "800", fontSize: "16px", textDecoration: "underline" }}
                                            >
                                              Sample Report - Download
                                            </a> : ""
                                          }
                                        </li>
                                      </ul>
                                    );
                                  }
                                )}
                            </td>
                            <td className="cell" align="center">
                              ₹ {customerDetails?.packagePrice}
                            </td>
                          </tr>
                        );
                      })}
                    <tr>
                      <td colSpan={4}>
                        <table style={{ width: "100%" }}>
                          <tr>
                            <td
                              className="cell"
                              align="left"
                              rowSpan={5}
                              colSpan={2}
                              style={{ width: "60%" }}
                              valign="top"
                            >
                              <p style={{ fontWeight: "bold", color: "white", margin: "0" }}>
                                {redCoin?.coins ? redCoinMessage : ""}
                                {redCoin && redCoin?.coins && redCoin?.coins >= 0 ? (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={redCashCheck}
                                        onChange={handleRedCheck}
                                        name="Redeem Redcash"
                                        style={{ margin: "0", marginLeft: "10px" }}
                                        disabled={(discount > 0 && (cType === "code" || cType === "percentage")) || redCoin?.coins <= 0}
                                      />
                                    }
                                    label=""
                                  />
                                ) : (
                                  ""
                                )}
                              </p>
                              <Grid container spacing={1} direction="row">
                                <Grid container spacing={1} direction="row">
                                  <Grid item xs={12}>
                                    <p style={{ paddingTop: "24px", marginBottom: "0px" }}>Enter Coupon</p>
                                  </Grid>
                                  <Grid item xs={9}>
                                    <Autocomplete
                                      id="coupon"
                                      key={couponKey}
                                      onChange={(event, newValue) => {
                                        if (newValue) {
                                          let obj = JSON.parse(
                                            JSON.stringify(newValue, null, " ")
                                          );
                                          setCoupon(obj?.id as Number);
                                          setCouponCode(obj?.code as string);
                                          // setCouponType(obj.is_red_cash);
                                        }
                                      }}
                                      inputValue={couponCode}
                                      options={couponList?.results || []}
                                      freeSolo
                                      blurOnSelect
                                      disabled={redCashCheck === true}
                                      onInputChange={(event, newInputValue) => {
                                        clearTimeout(timer.current);
                                        setCouponCode(newInputValue);
                                        timer.current = setTimeout(() => {
                                          getCoupons(
                                            `?code=${newInputValue}&org_type=homedx`
                                          );
                                        }, 1000);
                                        if (newInputValue === "") {
                                          setCoupon(0);
                                          setDiscount(0);
                                        }
                                      }}
                                      getOptionLabel={(option: any) => option?.code}
                                      getOptionDisabled={(option: any) =>
                                        option?.minimum_price > totalPrice - redProMembershipDiscount

                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          className="input"
                                          {...params}
                                          placeholder="Enter Coupon"
                                          variant="outlined"
                                          inputProps={{
                                            ...params.inputProps,
                                            onKeyDown: (e) => {
                                              if (e.key === 'Enter') {
                                                e.stopPropagation();
                                              }
                                            },
                                          }}
                                          style={{
                                            width: "100%",
                                            margin: "0",
                                            padding: "0",
                                          }}
                                        />
                                      )}
                                    />
                                  </Grid>
                                  <Grid item xs={3}>
                                    <Button
                                      variant="contained"
                                      fullWidth
                                      color="primary"
                                      disabled={redCashCheck === true}
                                      onClick={applyCoupon}
                                    >
                                      Apply
                                    </Button>
                                  </Grid>
                                  {redCashCheck === true ? <p style={{ color: "black", fontSize: "12px", paddingLeft: "4px" }}>Oops! Coupon Can't be applied as you are using RedCash.</p> : ""}
                                </Grid>
                              </Grid>
                            </td>
                            <td
                              className="cell"
                              align="right"
                              style={{ width: "20%" }}
                            >
                              Discount
                            </td>
                            <td
                              className="cell"
                              align="center"
                              style={{ width: "20%" }}
                            >
                              ₹ {partnerCodeDiscount ? Math.round(discount) : Math.floor(discount)}
                            </td>
                          </tr>
                          <tr>
                            <td
                              className="cell"
                              align="right"
                              style={{ width: "20%" }}
                            >
                              RedCash Discount
                            </td>
                            <td
                              className="cell"
                              align="center"
                              style={{ width: "20%" }}
                            >
                              ₹ {redDisc > 0 ? Math.floor(redDisc) : 0}
                            </td>
                          </tr>
                          {checkedWay === "" ? "" :
                            <tr>
                              <td
                                className="cell"
                                align="right"
                                style={{ width: "20%" }}
                              >
                                VIP Membership Price
                              </td>
                              <td
                                className="cell"
                                align="center"
                                style={{ width: "20%" }}
                              >
                                ₹ {Math.floor(proMembershipPrice)}
                              </td>
                            </tr>
                          }
                          <tr>
                            <td className="cell" align="right">
                              Phlebo Cost
                            </td>
                            <td className="cell" align="center">
                              ₹ {extraCharge ? `+${phleboCost}` : "0"}
                            </td>
                          </tr>
                          <tr>
                            <td className="cell" align="right">
                              <h4 className="totalPrice">Total Price</h4>
                            </td>
                            <td className="cell" align="center">
                              <h4 className="totalPrice">
                                ₹{" "}
                                {redDisc > 0
                                  ? (Math.floor(totalPrice) - Math.floor(redDisc) - Math.floor(referralPointsDiscount) - Math.floor(redProMembershipDiscount) + Math.floor(phleboCost) > 0 ? Math.floor(totalPrice) - Math.floor(redDisc) - Math.floor(referralPointsDiscount) - Math.floor(redProMembershipDiscount) + Math.floor(proMembershipPrice) + Math.floor(phleboCost) + Math.floor(hardCopyPrice) : (checkedWay !== "" ? Math.floor(proMembershipPrice) : 0))
                                  : (Math.floor(totalPrice) - Math.floor(referralPointsDiscount) - Math.floor(redProMembershipDiscount) + Math.floor(phleboCost) > 0 ? Math.floor(totalPrice) - Math.floor(referralPointsDiscount) - Math.floor(redProMembershipDiscount) + Math.floor(proMembershipPrice) + Math.floor(phleboCost) + Math.floor(hardCopyPrice) : (checkedWay !== "" ? Math.floor(proMembershipPrice) : 0))}
                              </h4>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Grid>
          </Grid>
          <div className={classes.buttonContainer}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              style={{ marginLeft: "1rem", marginBottom: "20px" }}
              disabled={
                collection_date === "" ||
                loading ||
                selectedSlot === 0 ||
                sameNumberBooking === true ||
                addressLine2 === "" ||
                customer_address === "" ||
                favoritePlace === "" ||
                !singlePackage ||
                error !== "" ||
                inputList[0]?.customerGender === null ||
                !mobileValidation.customer_phonenumber ||
                !mobileValidation.customer_alternatenumber ||
                !mobileValidation.customer_whatsapppnumber ||
                leadAddressLoading
                || disableCreateBtn
                || +inputList[0]?.customerAge < 5
              }
            >
              Proceed for booking
            </Button>
            <Dialog
              open={uhidModalOpen}
              // onClose={handleCloseUHID}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>UNIQUE CUSTOMER</div>
                  <div>
                    <CloseIcon onClick={() => handleCloseUHID()} />
                  </div>
                </div>
              </DialogTitle>
              <DialogTitle id="alert-dialog-title">
                {inputList.length === 5 ? "Member Add Limit is 4 only" : ""}
              </DialogTitle>
              <DialogContent>
                <TableContainer className={classes.table}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell align="center">Lead ID</StyledTableCell>
                        <StyledTableCell align="center">Name</StyledTableCell>
                        <StyledTableCell align="center">Gender</StyledTableCell>
                        <StyledTableCell align="center">Action</StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {allUHIDCustomer &&
                        allUHIDCustomer.length > 0 &&
                        allUHIDCustomer.map((data: any, index: any) => {
                          return (
                            <StyledTableRow key={index}>
                              <StyledTableCell align="center">
                                {data.lead || ""}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {data.customerName || ""}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {data.customerGender || ""}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {data.isSelected ? (
                                  ""
                                ) : (
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    disabled={inputList.length === 5}
                                    onClick={() =>
                                      handleUHIDAddCustomer(data, index)
                                    }
                                  >
                                    Add
                                  </Button>
                                )}
                                {data.isSelected ? (
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() =>
                                      handleUHIDRemoveCustomer(data, index)
                                    }
                                  >
                                    Remove
                                  </Button>
                                ) : (
                                  ""
                                )}
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </DialogContent>
            </Dialog>
          </div>
          {/* Location Map Modal  */}
          <Dialog
            open={openLocationModal}
            // onClose={() => setOpenLocationModal(false)}
            aria-labelledby="form-dialog-title"
            className={classes.dialog}
          >
            <DialogTitle id="form-dialog-title">Add New Address</DialogTitle>
            <DialogContent>
              <Grid
                container
                spacing={2}
                style={{
                  margin: "0px 10px 10px 10px",
                  minWidth: "500px",
                  width: "70%",
                }}
                onMouseEnter={() =>
                  setTimeout(() => {
                    setAddressCheckFlag(true)
                  }, 500)
                }
              >
                <Grid item xs={12} md={12}>
                  <p>{LOCALITY}</p>
                  <Autocomplete
                    id="customer_landmark1"
                    key={saveOldAddress}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        setMapAddressObject(obj);
                      }
                    }}
                    options={suggestedAddress ? suggestedAddress : []}
                    freeSolo
                    aria-required
                    defaultValue={{ placeName: saveOldAddress }}
                    getOptionLabel={(option: any) =>
                      `${option?.placeName}${option?.placeAddress
                        ? String("," + option?.placeAddress)
                        : ""
                      }`
                    }
                    disabled={collection_date === ""}
                    filterOptions={(option: any) => option}
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        locationAutoCompleteSearch(newInputValue, event);
                      }, 500);
                    }}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        placeholder="Search Locality"
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                        //
                        value={saveOldAddress}
                        required
                        inputProps={{
                          ...params.inputProps,
                          onKeyDown: (e) => {
                            if (e.key === "Enter") {
                              e.stopPropagation();
                            }
                          },
                        }}
                      />
                    )}
                  />
                  <p style={{ color: "red" }}>{errorMessage}</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <p>{ADDRESS_LINE1}</p>
                  <TextField
                    className="input"
                    name="zone"
                    type="text"
                    value={customer_address}
                    variant="outlined"
                    style={{ width: "100%", margin: "0" }}
                    required
                    inputProps={{ maxLength: 100 }}
                    onChange={(e) => {
                      setHitApiForSendWhatsappMessage(true)
                      setCustomer_address(e.target.value as string)
                    }}
                    placeholder="Type Here..."
                  />
                  <small>Max Length 100</small>
                </Grid>
                <Grid item xs={12} md={6}>
                  <p>{ADDRESS_LINE2}</p>
                  <TextField
                    className="input"
                    name="zone"
                    type="text"
                    value={addressLine2}
                    inputProps={{ maxLength: 100 }}
                    variant="outlined"
                    style={{ width: "100%", margin: "0" }}
                    required
                    onChange={(e) => setAddressLine2(e.target.value as string)}
                    placeholder="Type Here..."
                  />
                  <small>Max Length 100</small>
                </Grid>
                <Grid item xs={12} md={12}>
                  <p>{LANDMARK}</p>
                  <TextField
                    className="input"
                    placeholder="Ex: School/College/Restaurant/Shop/Bank/Government Office"
                    name="zone"
                    type="text"
                    value={favoritePlace || ""}
                    variant="outlined"
                    style={{ width: "100%", margin: "0" }}
                    required
                    inputProps={{ maxLength: 100 }}
                    onChange={(e) => setFavoritePlace(e.target.value as string)}
                  />
                  <small>Max Length 100</small>
                </Grid>
              </Grid>
            </DialogContent>
            {geoLoading ? (
              <Loader />
            ) : (
              <DialogActions>
                <Button
                  onClick={() => {
                    handleAddressModel();
                  }}
                  color="primary"
                  disabled={
                    String(geoCheckCityId)?.length < 0 && geoCheckError !== "" || favoritePlace === "" || addressLine2 === "" || customer_address === "" ||
                    (Object.keys(mapAddressObject).length === 0 &&
                      (addressExeData === "" || addressExeData === 0))
                  }
                >
                  Select
                </Button>
                <Button
                  onClick={() => {
                    setOpenLocationModal(false);
                  }}
                  color="primary"
                >
                  Cancel
                </Button>
              </DialogActions>
            )}
          </Dialog>
        </form>
      </div>
    </main>
  );
};
const mapStateToProps = (state: any) => ({
  pin_code: state.OffLineSupporteReducer.pin_code,
  centerInfo: state.OffLineSupporteReducer.centerInfo,
  packageList: state.OffLineSupporteReducer.packageList,
  couponList: state.OffLineSupporteReducer.couponList,
  createPilotBooking: state.OffLineSupporteReducer.createPilotBooking,
  leads: state.OffLineSupporteReducer.leads,
  lead_details: state.OffLineSupporteReducer.lead_details,
  booking_slots: state.OffLineSupporteReducer.booking_slots,
  sameNumberBooking: state.OffLineSupporteReducer.sameNumberBooking,
  loading: state.OffLineSupporteReducer.loading,
  redCoin: state.OffLineSupporteReducer.redCoin,
  geoFencesMapMyIndia: state.OffLineSupporteReducer.geoFencesMapMyIndia,
  ceCenterForCC: state.OffLineSupporteReducer.ceCenterForCC,
  mapmyindiaAccessToken: state.OffLineSupporteReducer.mapmyindiaAccessToken,
  timeslots: state.OffLineSupporteReducer.timeslots,
  userDetails: state.loginReducer.userDetails,
  breakResponse: state.OffLineSupporteReducer.breakResponse,
  geoLoading: state.OffLineSupporteReducer.geoLoading,
  geoCheckError: state.OffLineSupporteReducer.geoCheckError,
  geoCheckCityId: state.OffLineSupporteReducer.geoCheckCityId,
  leadById: state.OffLineSupporteReducer.leadById,
  cities: state.OffLineSupporteReducer.cities,
  crmLeadAddress: state.OffLineSupporteReducer.crmLeadAddress,
  crmPostLeadAddress: state.OffLineSupporteReducer.crmPostLeadAddress,
  communicationWhatsapp: state.OffLineSupporteReducer.communicationWhatsapp,
  geoFencesMapMyIndiaForCustomer:
    state.OffLineSupporteReducer.geoFencesMapMyIndiaForCustomer,
  leadAddressLoading: state.OffLineSupporteReducer.leadAddressLoading,
  locationDataFromPusher: state.loginReducer.locationDataFromPusher,
  whatsappAddressIdDetail: state.loginReducer.whatsappAddressIdDetail,
  whatsappMessageStatus: state.OffLineSupporteReducer.whatsappMessageStatus,
  referralCurrentBalance: state.OffLineSupporteReducer.referralCurrentBalance,
  couponReferralValidation: state.OffLineSupporteReducer.couponReferralValidation,
  leadSubscriptionsData: state.OffLineSupporteReducer.leadSubscriptionsData,
  leadPrescription: state.OffLineSupporteReducer.leadPrescription,
  Prescriptionloader: state.OffLineSupporteReducer.Prescriptionloader,
  autoSelectedPackageReducer: state.OffLineSupporteReducer.autoSelectedPackageReducer,
  geoCheckLatitude: state.OffLineSupporteReducer.geoCheckLatitude,
  geoCheckZoneId: state.OffLineSupporteReducer.geoCheckZoneId,
  geoCheckLongitude: state.OffLineSupporteReducer.geoCheckLongitude,
  latestLeadAddress: state.loginReducer.latestLeadAddress,
  customerSummaryUsingUHID: state.loginReducer.customerSummaryUsingUHID,
  customerDetailsUsingUHID: state.loginReducer.customerDetailsUsingUHID,
  loadingSummary: state.loginReducer.loadingSummary,
  loadingDetails: state.loginReducer.loadingDetails,
  refDoctor: state.loginReducer.refDoctor,
  postRefReducer: state.loginReducer.postRefReducer,
  recommendedPackagesWithCityId: state.loginReducer.recommendedPackagesWithCityId,
  packagesList: state.loginReducer.packagesList,
  bookingListLoader: state.loginReducer.bookingListLoader,
  packageQuestion: state.loginReducer.packageQuestion,
  postPackageAnswerReducer: state.loginReducer.postPackageAnswerReducer,
  containerSlots: state.loginReducer.containerSlots,
});

export default connect(mapStateToProps, {
  getPinCode,
  getPackage,
  getCoupons,
  createNewBooking,
  getLeads,
  getLeadDetails,
  resetLeadDetails,
  getAvailableSlots,
  checkGeoFenceArea,
  getRedCoing,
  checkGeoFenchMapMyIndia,
  checkGeoFenchMapMyIndiaPilot,
  getMapMyIndiaAccessToken,
  getCenterInfo,
  getceCentre,
  getTimeslots,
  createPilotNewBooking,
  getBreakResponse,
  resetBookings,
  getLeadById,
  getCities,
  getCommunicationOnWhatsapp,
  SendLocationRequest,
  postCrmLeadAddress,
  checkGeoFenchMapMyIndiaPilotForCustomerAddress,
  getCrmLeadAddress,
  getNewPackage,
  getWhatsappMessageStatus,
  getReferralCurrentBalance,
  getCouponReferralValidation,
  getLeadSubscriptionsData,
  addressJsonPost,
  getLatestAddressByLeadId,
  getCustomerSummaryUsingUHID,
  getCustomerDetailsUsingUHID,
  getLeadPrescriptionData,
  getRefDoctor,
  postRefDoctor,
  getRecommendedPackagesWithCityId,
  getPackageList,
  postModuleInteraction,
  getPackageAutoSelected,
  getPackageQuestion,
  postPackageAnswer,
  getContainerSlots,
})(BookingWithLocalitySearch);