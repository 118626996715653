import React, { useEffect, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import "./BookingPage.sass";
import Box from '@mui/material/Box';
import NewBookingWithUHID from './NewBooking/NewBooking'
import { connect } from "react-redux";
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "100%",
      flexGrow: 21,
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 40px 10px",
    },
    leftContentHeader: {
      width: "50%",
      float: "left",
    },
    rightContentHeader: {
      width: "50%",
      float: "right",
      textAlign: "right",
      paddingRight: "20px",
    },
    contentTable: {
      width: "100%",
      padding: "0px 0px 10px 0px",
      marginTop: "40px",
      minHeight: "300px",
    },
  })
);

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  style: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}


interface Props {
  addressType: any;
  match: any;
}

const BookingPage: React.FC<Props> = ({
  addressType,
  match,
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [leadId, setLeadId] = useState<string>("");
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {

    setValue(newValue);
  };
  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const org_type = params.get("org_type");
    const lead = params.get("lead");
    if (org_type==="imagine") {
      setValue(1);
    }
    if (lead) {
      setLeadId(lead)
    }
   
  }, [window.location.search]);


  return (
    <>

     <div className={classes.root} style={{marginTop:"3rem"}}>
      <AppBar  position="static" >
      
      </AppBar>
      
      <TabPanel style={{ width: "100%" }} value={value} index={0} >
       {<NewBookingWithUHID lead={leadId} bookingFrom={"Main"}/>} 
      </TabPanel>
     
      </div>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  addressType: state.loginReducer.addressType,
  userDetails: state.loginReducer.userDetails,
});
export default connect(mapStateToProps, {
})(BookingPage);

