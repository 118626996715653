import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import barcode from "../../images/barcode.png";

import CloseIcon from '@material-ui/icons/Close';
import Modal from '@mui/material/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Button, Paper } from '@material-ui/core';
import ReactToPrint from "react-to-print";
import moment from "moment";
import { getBookingById } from '../../actions/OffLineSupportActions';
import logo from "../../../images/logo1.png";

let width = window.innerWidth;


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  paper: {
    marginTop: "auto",
    padding: "0.2rem ",
    width: "80%",
    marginLeft: "10%"
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  table: {
    maxWidth: "70%",
    margin: "auto"
  },
  scrollComponent: {
    width: "95%",
    height: "100%",
    overflowY: "auto",
  },
  toolbar: {
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  listStyle: {
    marginLeft: "15px",
  }
}));


type PropsCR = {
  getBookingById: any;
  loading: boolean;
  booking: any;
  bookingId: any;
  openInvoiceModel: boolean;
  setOpenInvoiceModel: Function;

};

const CashRecipt: React.FC<PropsCR> = ({
  getBookingById,
  openInvoiceModel,
  bookingId,
  setOpenInvoiceModel,
  booking,
  loading,

}) => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const handleClose = () => {
    setOpenInvoiceModel(false);
  };
  const [data, setData] = useState<any>({
    bId: "",
    iDate: "",
    cDate: "",
    cName: "",
    cAge: "",
    cAddr: "",
    packages: [],
    totalPrice: "",
    totalDiscount: "",
    redcash_discounted_amount: "",
    giftcard_discounted_amount: "",
    centre: "",
    customer_address: "",
    payment: [],
  });
  const [paid, setPaid] = useState<any>("");
  const [addPkg, setAddPkg] = useState<any>([]);
  const [open, setOpen] = React.useState<boolean>();
  let prerequisitesLoaded = false;
  const price_in_words = (price: any) => {
    let sglDigit = [
      "Zero",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
    ],
      dblDigit = [
        "Ten",
        "Eleven",
        "Twelve",
        "Thirteen",
        "Fourteen",
        "Fifteen",
        "Sixteen",
        "Seventeen",
        "Eighteen",
        "Nineteen",
      ],
      tensPlace = [
        "",
        "Ten",
        "Twenty",
        "Thirty",
        "Forty",
        "Fifty",
        "Sixty",
        "Seventy",
        "Eighty",
        "Ninety",
      ],
      handle_tens = function (dgt: number, prevDgt: number) {
        return 0 == dgt
          ? ""
          : " " + (1 == dgt ? dblDigit[prevDgt] : tensPlace[dgt]);
      },
      handle_utlc = function (dgt: number, nxtDgt: number, denom: string) {
        return (
          (0 != dgt && 1 != nxtDgt ? " " + sglDigit[dgt] : "") +
          (0 != nxtDgt || dgt > 0 ? " " + denom : "")
        );
      };

    let str = "";
    let digitIdx: number = 0;
    let digit: number;
    let nxtDigit: number = 0;
    let words = [];
    if (((price += ""), isNaN(parseInt(price)))) str = "";
    else if (parseInt(price) > 0 && price.length <= 10) {
      for (digitIdx = price.length - 1; digitIdx >= 0; digitIdx--)
        switch (
        ((digit = price[digitIdx] - 0),
          (nxtDigit = digitIdx > 0 ? price[digitIdx - 1] - 0 : 0),
          price.length - digitIdx - 1)
        ) {
          case 0:
            words.push(handle_utlc(digit, nxtDigit, ""));
            break;
          case 1:
            words.push(handle_tens(digit, price[digitIdx + 1]));
            break;
          case 2:
            words.push(
              0 != digit
                ? " " +
                sglDigit[digit] +
                " Hundred" +
                (0 != price[digitIdx + 1] && 0 != price[digitIdx + 2]
                  ? " and"
                  : "")
                : ""
            );
            break;
          case 3:
            words.push(handle_utlc(digit, nxtDigit, "Thousand"));
            break;
          case 4:
            words.push(handle_tens(digit, price[digitIdx + 1]));
            break;
          case 5:
            words.push(handle_utlc(digit, nxtDigit, "Lakh"));
            break;
          case 6:
            words.push(handle_tens(digit, price[digitIdx + 1]));
            break;
          case 7:
            words.push(handle_utlc(digit, nxtDigit, "Crore"));
            break;
          case 8:
            words.push(handle_tens(digit, price[digitIdx + 1]));
            break;
          case 9:
            words.push(
              0 != digit
                ? " " +
                sglDigit[digit] +
                " Hundred" +
                (0 != price[digitIdx + 1] || 0 != price[digitIdx + 2]
                  ? " and"
                  : " Crore")
                : ""
            );
        }
      str = words.reverse().join("");
    } else str = "";
    return str;
  };
  const addPrice = (data: any) => {
    let add: number = 0;
    data && data.length > 0 &&
      data.map((val: any) => {
        if (val.link_type !== "link") {
          add = add + val.amount;
          setPaid(add);
        }
      });
  };

  useEffect(() => {
    // getBookingById(`${id}`);
  }, [id]);

  const handleDOBForAge = (dob: any) => {
    var today = new Date();
    var DOB = new Date(dob);
    var totalMonths =
      (today.getFullYear() - DOB.getFullYear()) * 12 +
      today.getMonth() -
      DOB.getMonth();
    totalMonths += today.getDay() < DOB.getDay() ? -1 : 0;
    var years = today.getFullYear() - DOB.getFullYear();
    if (DOB.getMonth() > today.getMonth()) years = years - 1;
    else if (DOB.getMonth() === today.getMonth())
      if (DOB.getDate() > today.getDate()) years = years - 1;

    var days;
    var months;

    if (DOB.getDate() > today.getDate()) {
      months = totalMonths % 12;
      if (months == 0) months = 11;
      var x = today.getMonth();
      switch (x) {
        case 1:
        case 3:
        case 5:
        case 7:
        case 8:
        case 10:
        case 12: {
          var a = DOB.getDate() - today.getDate();
          days = 31 - a;
          break;
        }
        default: {
          var a = DOB.getDate() - today.getDate();
          days = 30 - a;
          break;
        }
      }
    } else {
      days = today.getDate() - DOB.getDate();
      if (DOB.getMonth() === today.getMonth()) months = totalMonths % 12;
      else months = totalMonths % 12;
    }
    if (months !== 0) {
      var age = months + "Months " + days + "Days";
    } else {
      var age = days + "Days";
    }

    return age;
  };
  useEffect(() => {
    if (Object.keys(booking).length !== 0) {
      setData({
        bId: booking?.id === undefined ? booking?.pk : booking?.id,
        iDate: moment(booking?.created_at).format("DD MMM,YYYY"),

        cAge: booking?.customer_age,
        cName: booking?.designation + " " + booking?.customer_name,
        cAddr:
          (booking?.customer_areapincode?.area === undefined
            ? " "
            : booking?.customer_areapincode?.area + ",") +
          (booking?.customer_areapincode?.city_name === undefined
            ? " "
            : booking?.customer_areapincode?.city_name + ", ") +
          (booking?.customer_areapincode?.pincode === undefined
            ? " "
            : "Pin Code:" + booking?.customer_areapincode?.pincode),
        packages: booking?.packages,
        centre: booking?.center?.name,
        customer_address: booking?.center?.address,
        totalPrice: booking?.final_packages_price,
        totalDiscount: booking?.final_discount,
        redcash_discounted_amount: booking?.redcash_discounted_amount,
        giftcard_discounted_amount: booking?.giftcard_discounted_amount,
        payment: booking && booking?.payment_links && booking?.payment_links?.length > 0 ? booking?.payment_links : [{

          "payment_mode": booking?.payment_mode,
          "created_at": booking?.pickup_date,
          "amount": booking?.final_packages_price,

        }],
        paymentMode: booking?.payment_mode,
      });
      addPrice(booking?.payment_links);
    }
  }, [booking]);
  useEffect(() => {
    if (booking && booking?.additional_members?.length > 0) {
      const array: any = [];
      booking?.additional_members.map((data: any) => {
        data.ccpackages && data.ccpackages.length > 0 ? data.ccpackages.map((val: any) => {
          array.push(val)
        }) : data.packages.map((val: any) => {
          array.push(val)
        })
      })
      setAddPkg(array)
    }
  }, [booking])

  const componentRef = useRef<any>(null);
  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);
  const reactToPrintTrigger = React.useCallback(() => {
    return <button style={{ marginLeft: "10px" }}>Print Receipt</button>;
  }, []);
  const handleBack = () => {
    localStorage.removeItem("addonPrice");
    history.push(`/dashboard/os/booking-view/${bookingId}`);
  };

  const onHandleClose = () => {
    // setOpen(false);
    history.push(`/dashboard/os/booking-view/${bookingId}`);
  };


  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openInvoiceModel}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={openInvoiceModel}>
          <div className={classes.scrollComponent}>

            <Paper elevation={15} className={classes.paper} >
              <div
                style={{
                  // margin: "100px auto",

                }}
              >
                <button
                  style={{
                    width: "100px",
                    borderRadius: "5%",
                    backgroundColor: "#ef5350",
                    color: "white",
                    marginLeft: "30px",
                    marginBottom: "11px",
                  }}
                  onClick={handleBack}
                >
                  {"<< Go Back"}
                </button>
                <ReactToPrint
                  content={reactToPrintContent}
                  trigger={reactToPrintTrigger}
                />

                <Button onClick={onHandleClose}  >
                  <CloseIcon style={{ marginLeft: "410px", color: "#ef5350", display: "flex" }} />
                </Button>
                <div
                  style={{
                    // width: "842px",
                    border: "1px solid grey",
                    // marginLeft: "45px",

                  }}
                >
                  <div
                    ref={componentRef}
                    style={{
                      margin: 20,
                    }}
                  >
                    <div>
                      <meta charSet="UTF-8" style={{ fontFamily: "sans-serif" }} />
                      <meta
                        name="viewport"
                        // content="width=device-width, initial-scale=1.0"
                        style={{ fontFamily: "sans-serif" }}
                      />
                      <title style={{ fontFamily: "sans-serif" }}>Invoice</title>
                      <div
                        className="invoiceContainer"
                        style={{
                          fontFamily: "sans-serif",
                          marginInline: "5vw",
                          marginTop: 0,
                        }}
                      >
                        <div id="divToPrint" style={{ fontFamily: "sans-serif" }}>
                          <hr
                            className="headerLine"
                            style={{
                              border: "5px solid #47cacc",
                              fontFamily: "sans-serif",
                              width: "100%",
                            }}
                          />
                          <div
                            className="logoContainer"
                            style={{
                              display: "flex",
                              padding: "1rem",
                            }}
                          >
                            <div style={{ width: "50%" }}>
                              <img
                                className="invoiceImage"
                                src={logo}
                                style={{ fontFamily: "sans-serif", height: "70px" }}
                              />
                            </div>
                            <div style={{ width: "50%" }}>
                              <span
                                className="invoiceHeading"
                                style={{
                                  float: "right",
                                  fontFamily: "sans-serif",
                                  fontSize: "14px",
                                }}
                              >
                                <strong style={{ fontFamily: "sans-serif" }}>
                                  {data?.centre}
                                </strong>
                                <br />
                                CIN No. : U85100UP2021PTC140992
                                <br />
                                Address : {booking?.customer_address}
                              </span>
                            </div>
                          </div>
                          <hr style={{ fontFamily: "sans-serif" }} />
                          <div
                            className="invoiceGrid"
                            style={{ marginTop: "-8px", display: "flex" }}
                          >
                            <div style={{ width: "50%" }}>
                              <span>
                                Patient Name: <b>{data?.cName}</b>
                              </span>
                              <br />
                              <span>
                                Booking Id:<b>{data?.bId}</b>{" "}
                              </span>
                              <br />
                              <span>Address: {data?.cAddr}</span>
                              <br />
                            </div>
                            <div style={{ width: "50%", marginLeft: "15%" }}>
                              <span>
                                Patient Age:{" "}
                                <b>
                                  {data?.cAge === 0
                                    ? handleDOBForAge(data?.cAgeDob)
                                    : data?.cAge + "Years"}
                                </b>
                              </span>
                              <br />
                              <span>Bill Date: {data?.iDate}</span>
                              <br />
                              <span>Invoice No.: {data?.bId}</span>
                              <br />
                            </div>
                          </div>
                          <hr style={{ clear: "left", fontFamily: "sans-serif" }} />

                          <div style={{ fontFamily: "sans-serif" }}>
                            <div
                              className="invoiceTestDescHead"
                              style={{
                                backgroundColor: "#f4f4f4",
                                fontFamily: "sans-serif",
                                fontSize: "16px",
                                fontWeight: 600,

                                padding: "1rem",
                                paddingBottom: "2rem",
                              }}
                            >
                              {/* <div
                                style={{
                                  float: "left",
                                  fontFamily: "sans-serif",
                                  width: "17%",
                                }}
                              >
                                TEST CODE
                              </div> */}
                              <div
                                style={{
                                  float: "left",
                                  fontFamily: "sans-serif",

                                  width: "70%",
                                }}
                              >
                                TEST NAME
                              </div>
                              <div
                                style={{
                                  float: "right",
                                  textAlign: "right",
                                  fontFamily: "sans-serif",
                                  width: "13%",
                                }}
                              >
                                AMOUNT
                              </div>
                            </div>
                            {data?.packages.length > 0 &&
                              data?.packages.map((val: any) => (
                                <div
                                  className="billDetailsTests"
                                  style={{
                                    color: "black !important",
                                    fontFamily: "sans-serif",
                                    fontSize: "16px",
                                    padding: "1rem",
                                    width: "100%",
                                  }}
                                >
                                  {/* <div
                                    style={{
                                      float: "left",
                                      fontFamily: "sans-serif",
                                      width: "17%",
                                    }}
                                  >
                                    <strong style={{ fontFamily: "sans-serif" }}>
                                      {val?.code}
                                    </strong>
                                  </div> */}
                                  <div
                                    style={{
                                      float: "left",
                                      fontFamily: "sans-serif",
                                      width: "70%",
                                    }}
                                  >
                                    <strong style={{ fontFamily: "sans-serif" }}>
                                      {val?.name}
                                    </strong>
                                  </div>
                                  <div
                                    style={{
                                      float: "right",
                                      textAlign: "right",
                                      fontFamily: "sans-serif",
                                      width: "13%",
                                    }}
                                  >

                                    {booking && booking?.center && booking?.center?.center_type === "corporate" ? (
                                      <strong style={{ fontFamily: "sans-serif" }}>
                                        ₹{val.price}
                                      </strong>
                                    ) : (
                                      <strong style={{ fontFamily: "sans-serif" }}>
                                        ₹{val.price
                                          ? val.price
                                          : val.price}
                                      </strong>
                                    )}
                                  </div>
                                </div>
                              ))}
                            {addPkg.length > 0 &&
                              addPkg.map((val: any) => (
                                <div
                                  className="billDetailsTests"
                                  style={{
                                    color: "black !important",
                                    fontFamily: "sans-serif",
                                    fontSize: "16px",
                                    padding: "1rem",
                                    width: "100%",
                                  }}
                                >
                                  {/* <div
                                    style={{
                                      float: "left",
                                      fontFamily: "sans-serif",
                                      width: "17%",
                                    }}
                                  >
                                    <strong style={{ fontFamily: "sans-serif" }}>
                                      {val?.code}
                                    </strong>
                                  </div> */}
                                  <div
                                    style={{
                                      float: "left",
                                      fontFamily: "sans-serif",
                                      width: "70%",
                                    }}
                                  >
                                    <strong style={{ fontFamily: "sans-serif" }}>
                                      {val?.name}
                                    </strong>
                                  </div>
                                  <div
                                    style={{
                                      float: "right",
                                      textAlign: "right",
                                      fontFamily: "sans-serif",
                                      width: "13%",
                                    }}
                                  >
                                    {booking && booking?.center && booking?.center?.center_type === "corporate" ? (
                                      <strong style={{ fontFamily: "sans-serif" }}>
                                        ₹{val.price}
                                      </strong>
                                    ) : (
                                      <strong style={{ fontFamily: "sans-serif" }}>
                                        ₹{val.package_city_prices
                                          ? val.package_city_prices.offer_price
                                          : val.offer_price}
                                      </strong>
                                    )}



                                  </div>
                                </div>
                              ))}
                            <div >
                              <div
                                className="invoiceTestDescTotal"
                                style={{
                                  backgroundColor: "#fbfbfb",
                                  fontFamily: "sans-serif",
                                  fontSize: "16px",
                                  padding: "1rem",
                                  paddingBottom: "1rem",
                                }}
                              >
                                <div style={{ float: "left", fontFamily: "sans-serif" }}>
                                  Discount
                                </div>
                                <div style={{ float: "right", fontFamily: "sans-serif" }}>
                                  ₹{data?.totalDiscount}
                                </div>
                              </div>
                              <div
                                className="invoiceTestDescTotal"
                                style={{
                                  backgroundColor: "#fbfbfb",
                                  fontFamily: "sans-serif",
                                  fontSize: "16px",
                                  padding: "1rem",
                                  paddingBottom: "1rem",
                                }}
                              >
                                <div style={{ float: "left", fontFamily: "sans-serif" }}>
                                  Gift Card
                                </div>
                                <div style={{ float: "right", fontFamily: "sans-serif" }}>
                                  ₹{data?.giftcard_discounted_amount}
                                </div>
                              </div>
                              <div
                                className="invoiceTestDescTotal"
                                style={{
                                  backgroundColor: "#fbfbfb",
                                  fontFamily: "sans-serif",
                                  fontSize: "16px",
                                  padding: "1rem",
                                  paddingBottom: "1rem",
                                }}
                              >
                                <div style={{ float: "left", fontFamily: "sans-serif" }}>
                                  Red Cash
                                </div>
                                <div style={{ float: "right", fontFamily: "sans-serif" }}>
                                  ₹{data?.redcash_discounted_amount}
                                </div>
                              </div>
                              <div
                                className="invoiceTestDescTotal"
                                style={{
                                  backgroundColor: "#fbfbfb",
                                  fontFamily: "sans-serif",
                                  fontSize: "16px",
                                  padding: "1rem",
                                  paddingBottom: "1rem",
                                }}
                              >
                                <div style={{ float: "left", fontFamily: "sans-serif" }}>
                                  Total Amount
                                </div>
                                <div style={{ float: "right", fontFamily: "sans-serif" }}>
                                  ₹{
                                    Math.floor(
                                      booking.final_packages_price
                                    ) - Math.floor(booking.redcash_discounted_amount) - Math.floor(booking?.giftcard_discounted_amount)}
                                </div>
                              </div>
                            </div>
                            <hr style={{ fontFamily: "sans-serif" }} />
                            {/* <div
                              className="invoiceTestDescTotal1"
                              style={{
                                fontFamily: "sans-serif",
                                fontSize: "16px",
                                fontWeight: 600,
                                padding: "0 1rem 1rem 1rem",
                                paddingBottom: "1.3rem",
                              }}
                            >
                              <div style={{ float: "left", fontFamily: "sans-serif" }}>
                                Grand Total
                              </div>
                              <div style={{ float: "right", fontFamily: "sans-serif" }}>
                                ₹{data?.totalPrice}
                              </div>
                            </div> */}
                            <div
                              className="invoiceTestDescTotal2"
                              style={{
                                fontFamily: "sans-serif",
                                fontSize: "16px",
                                fontWeight: 600,
                                padding: "1rem",
                              }}
                            >
                              <div style={{ float: "left", fontFamily: "sans-serif" }}>
                                Paid Amount
                              </div>
                              <div style={{ float: "right", fontFamily: "sans-serif" }}>
                                ₹{
                                  Math.floor(
                                    booking.final_packages_price
                                  ) - Math.floor(booking.redcash_discounted_amount) - Math.floor(booking?.giftcard_discounted_amount)}                             </div>
                            </div>
                          </div>
                          <div
                            className="invoiceSummary"
                            style={{
                              fontFamily: "sans-serif",
                              fontSize: "13px",
                              lineHeight: "1.4",
                              marginTop: "3rem",
                              paddingLeft: "1rem",
                              paddingRight: "1rem",
                              marginBottom: "11rem",
                            }}
                          >
                            <div
                              style={{
                                float: "left",
                                fontFamily: "sans-serif",
                                width: "50%",
                              }}
                            >
                              <strong style={{ fontFamily: "sans-serif" }}>
                                Amount Chargable (in words)
                              </strong>
                              <div style={{ fontFamily: "sans-serif" }}>
                                <i style={{ fontFamily: "sans-serif" }}>
                                  Rupees
                                  {price_in_words(data?.totalPrice)}
                                  Only
                                </i>
                              </div>
                              <div
                                className="blueColor"
                                style={{
                                  color: "#11406b",
                                  fontFamily: "sans-serif",
                                  fontSize: "15px",
                                }}
                              >
                                Company's PAN No.{" "}
                                <span style={{ fontFamily: "sans-serif" }}>
                                  AAKCR7631M
                                </span>
                              </div>
                            </div>
                            <div
                              className="paymentSummary"
                              style={{
                                float: "right",
                                fontFamily: "sans-serif",
                                textAlign: "end",
                                width: "50%",
                              }}
                            >
                              <strong style={{ fontFamily: "sans-serif" }}>
                                Payment Mode Summary
                              </strong>
                              <table
                                className="invoiceSummaryTable"
                                style={{
                                  borderCollapse: "collapse",
                                  fontFamily: "sans-serif",
                                  fontSize: "12px",
                                  marginTop: "1rem",
                                  textAlign: "left",
                                  width: "100%",
                                }}
                              >
                                <tbody>
                                  <tr
                                    style={{
                                      backgroundColor: "#f1f1f1",
                                      fontFamily: "sans-serif",
                                    }}
                                  >
                                    <td
                                      style={{
                                        fontFamily: "sans-serif",
                                        padding: ".6rem",
                                        paddingRight: "1.6rem",
                                      }}
                                    >
                                      Payment Date
                                    </td>
                                    <td
                                      style={{
                                        fontFamily: "sans-serif",
                                        padding: ".6rem",
                                        paddingRight: "1.6rem",
                                      }}
                                    >
                                      Payment Mode
                                    </td>
                                    <td
                                      style={{
                                        fontFamily: "sans-serif",
                                        padding: ".6rem",
                                      }}
                                    >
                                      Amount
                                    </td>
                                  </tr>
                                  {data?.payment.length > 0 &&
                                    data?.payment.map((val: any) => {
                                      return (
                                        <tr
                                          style={{
                                            backgroundColor: "#f9f9f9",
                                            fontFamily: "sans-serif",
                                          }}
                                        >
                                          <td
                                            style={{
                                              fontFamily: "sans-serif",
                                              padding: ".6rem",
                                              paddingRight: "1.6rem",
                                            }}
                                          >
                                            {moment(val?.created_at).format(
                                              "DD MMM,YYYY"
                                            )}
                                          </td>
                                          <td
                                            style={{
                                              fontFamily: "sans-serif",
                                              padding: ".6rem",
                                              paddingRight: "1.6rem",
                                            }}
                                          >
                                            {data?.paymentMode}
                                          </td>
                                          <td
                                            style={{
                                              fontFamily: "sans-serif",
                                              padding: ".6rem",
                                            }}
                                          >
                                            ₹{booking.final_packages_price}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                </tbody>
                              </table>
                              <hr style={{ fontFamily: "sans-serif" }} />
                            </div>
                          </div>
                          <div
                            className="invoiceNotes"
                            style={{
                              clear: "left",
                              backgroundColor: "#fbfbfb",
                              fontFamily: "sans-serif",
                              fontSize: "12px",
                              lineHeight: "1.4",
                              marginBottom: "1rem",
                              marginTop: "1.6rem",
                              padding: ".5rem",
                            }}
                          >
                            <strong style={{ fontFamily: "sans-serif" }}>
                              Important Notes:
                            </strong>
                            <ul style={{ fontFamily: "sans-serif" }}>
                              <li style={{ fontFamily: "sans-serif" }}>
                                This invoice can be used for tax exemption under section
                                80-D (Preventive Health Checkup)
                              </li>
                              <li style={{ fontFamily: "sans-serif" }}>
                                Reports sent on registered mail ID according to the
                                respective TAT and can also be downloaded through
                                Redcliffe Labs Application
                              </li>
                              <li style={{ fontFamily: "sans-serif" }}>
                                All dispute / claims concerning to reports are subject to
                                the courts of Delhi only
                              </li>
                              <li style={{ fontFamily: "sans-serif" }}>
                                Redcliffe Labs assumes no liability towards any delays
                                beyond its control
                              </li>
                              <li style={{ fontFamily: "sans-serif" }}>
                                It is recommended that you consult your treating
                                Doctor/Physician before choosing any treatment based on
                                your report
                              </li>
                              <li style={{ fontFamily: "sans-serif" }}>
                                Maximum liability of the Company shall not exceed the
                                amount charged by the Company for the services availed
                              </li>
                              <li style={{ fontFamily: "sans-serif" }}>
                                This is a computer generated receipt and does not require
                                signature/stamp
                              </li>
                            </ul>
                          </div>
                          <div
                            className="invoiceFooter"
                            style={{
                              backgroundColor: "#47cacc",
                              color: "#0a2d4e",
                              fontFamily: "sans-serif",
                              fontSize: "16px",
                              padding: "1.8rem",
                              paddingBottom: "2.9rem",
                            }}
                          >
                            <div
                              className="invoiceFooterItem"
                              style={{
                                float: "left",
                                width: "33.33%",
                                alignItems: "center",
                                fontFamily: "sans-serif",
                              }}
                            >
                              <span style={{ color: "#e2124f", fontWeight: 700 }}>
                                &nbsp;898-898-8787
                              </span>
                            </div>
                            <div
                              className="invoiceFooterItem"
                              style={{
                                float: "left",
                                width: "33.33%",
                                alignItems: "center",
                                fontFamily: "sans-serif",
                              }}
                            >
                              <span style={{ color: "#e2124f", fontWeight: 700 }}>
                                &nbsp;care@redcliffelabs.com
                              </span>
                            </div>
                            <div
                              className="invoiceFooterItem"
                              style={{
                                float: "right",
                                width: "33.33%",
                                alignItems: "center",
                                fontFamily: "sans-serif",
                              }}
                            >
                              <span style={{ color: "#e2124f", fontWeight: 700 }}>
                                &nbsp;www.redcliffelabs.com
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </Paper>
          </div>



        </Fade>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  // booking: state.OffLineSupporteReducer.booking,
});

export default connect(mapStateToProps, {
  getBookingById,
})(CashRecipt);