import React, { useState, useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Grid,
  TextField,
  withStyles,
} from "@material-ui/core";
import Button from "@mui/material/Button";
import { useRef } from "react";
import { connect } from "react-redux";
import Loader from "../components/loader";
import {
  getCenterInfo,
  getCities,
  getPinCode,
} from "../actions/OffLineSupportActions";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useHistory } from "react-router";
import { TablePagination } from "@material-ui/core";
import { genrateCenterInformationFilter } from "../../helpers/generateUrl";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem",
      width: "100%",
    },
    table: {
      maxWidth: "100%",
      margin: "auto",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    listStyle: {
      marginLeft: "20px",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  })
);
const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface Props {
  getCenterInfo: any;
  centerInfo: any;
  getPinCode: any;
  pin_code: any;
  loading: boolean;
  cities: any;
  getCities: any;
}

const CenterTable: React.FC<Props> = ({
  getCenterInfo,
  centerInfo,
  getCities,
  cities,
  getPinCode,
  pin_code,
  loading,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [bookingId, setBookingId] = useState<any>("");
  const [cityId, setCityId] = useState<any>("");
  const [areaId, setAreaId] = useState<any>("");
  const [page, setPage] = useState(0);
  const timer = useRef<any>(0);
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = centerInfo.links.next.split("?")[1];
      getCenterInfo(`?${url}`);
    } else if (newPage < page) {
      let url = centerInfo.links.previous.split("?")[1];

      getCenterInfo(`${url ? `?${url}` : ""}`);
    }
    setPage(newPage as number);
  };

  useEffect(() => {
    getCenterInfo();
    // getCities()
    // getPinCode()
  }, []);

  const filterCenter = (e: any) => {
    const body: any = {
      city: cityId,
      area: areaId,
    };
    const url = genrateCenterInformationFilter(body).substring(2);
    getCenterInfo(`?${url}`);
  };

  return (
    <div style={{ width: "100%" }}>
      <div className={classes.toolbar} />
      <h2>Collection Center Information</h2>
      <Grid container spacing={1} md={12}>
        <Grid item xs={12} sm={4} md={2}>
          <Autocomplete
            id="city"
            onChange={(event, newValue) => {
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                setCityId(obj.id);
              }
            }}
            options={cities || []}
            freeSolo
            blurOnSelect
            aria-required
            getOptionLabel={(option: any) => option.name}
            onInputChange={(event, newInputValue) => {
              clearTimeout(timer.current);
              timer.current = setTimeout(() => {
                getCities(`${newInputValue}`);
              }, 1000);
              if (newInputValue.length === 0) {
                setCityId("");
              }
            }}
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                placeholder="Select City"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Autocomplete
            id="area"
            onChange={(event, newValue) => {
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                setAreaId(obj.area.id);
              }
            }}
            options={pin_code?.results || []}
            freeSolo
            blurOnSelect
            aria-required
            getOptionLabel={(option: any) =>
              `${option.area.pincode} ${option.area.area} `
            }
            onInputChange={(event, newInputValue) => {
              clearTimeout(timer.current);
              timer.current = setTimeout(() => {
                getPinCode(newInputValue.split(",")[0]);
              }, 1000);
              if (newInputValue.length === 0) {
                setAreaId("");
              }
            }}
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                placeholder="Select Area"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={filterCenter}
          >
            Filter
          </Button>
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => history.push("/dashboard/os/centerInfo")}
          >
            Reset
          </Button>
        </Grid>
      </Grid>
      <TableContainer
        className={classes.tableContainer}
        component={Paper}
        style={{ maxHeight: "700px" }}
      >
        {loading ? (
          <Loader />
        ) : (
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell align="center">Name</StyledTableCell>
                <StyledTableCell align="center">Address</StyledTableCell>
                <StyledTableCell align="center">Area</StyledTableCell>
                <StyledTableCell align="center">City Name</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {centerInfo &&
                centerInfo?.results &&
                centerInfo?.results?.length > 0 &&
                centerInfo?.results?.map((data: any, index: any) => {
                  return (
                    <StyledTableRow key={index}>
                      <StyledTableCell
                        style={{ height: "50px" }}
                        align="center"
                      >
                        {data?.name}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ height: "50px" }}
                        align="center"
                      >
                        {data?.address}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ height: "50px" }}
                        align="center"
                      >
                        {data?.area?.area}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ height: "50px" }}
                        align="center"
                      >
                        {data?.area?.city_name}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={3}
                  count={centerInfo?.count || 0}
                  rowsPerPageOptions={[]}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        )}
      </TableContainer>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  centerInfo: state.OffLineSupporteReducer.centerInfo,
  pin_code: state.OffLineSupporteReducer.centerInfo,
  cities: state.OffLineSupporteReducer.cities,
});

export default connect(mapStateToProps, {
  getCenterInfo,
  getCities,
  getPinCode,
})(CenterTable);
