import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Container,
  Grid,
  TextField,
  Select,
  MenuItem,
} from "@material-ui/core";
import MailIcon from "@material-ui/icons/Mail";
import { TransitionProps } from "@mui/material/transitions";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Menu from "@material-ui/core/Menu";
import Button from "@mui/material/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { generateBookingFilterUrl } from "../healpers/generateUrl";
import { connect } from "react-redux";
import {
  getLabCredential,
  getAllVerificationBookings,
  getCities,
  getPaymentInfo,
  getPrescriptionData,
  getPhleboTimeline,
  getCenterInfo,
  getAllBookingsExports,
} from "../actions/OffLineSupportActions";
import "./filter.sass";
import { useHistory } from "react-router-dom";
import VerificationTable from "../tables/verificationTable";
import Loader from "../components/Loader2";
import PaymentModal from "../components/Comments/paymentforModal";
import Fade from "@material-ui/core/Fade";
import { BOOKING_STATUS } from "../../booking_status";
import BookingCommentsModal from "../components/Comments/bookingComments";
import PhleboJourneysModel from "../components/Comments/phleboJourneysModel";
import Slide from "@mui/material/Slide";
import CommentsModal2 from "../components/Comments/Comment2";
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,

    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    card: {
      height: "100%",
      padding: theme.spacing(1),
    },
    card_grid: {
      justifyContent: "space-between",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  })
);

interface Props {
  getLabCredential: any;
  labCredential: any;
  children: any;
  verificationBookingList: any;
  getLeadById: any;
  leadById: any;
  getAllVerificationBookings: any;
  getCities: any;
  cities: any;
  getPaymentInfo: any;
  paymentInformation: any;
  loading: boolean;
  getPrescriptionData: any;
  prescription: any;
  getPhleboTimeline: any;
  phleboTimeline: any;
  bookingListLoader: any;
  getCenterInfo: any;
  centerInfo: any;
  getAllBookingsExports: any;
  get_all_booking_exports: any;
}

const CustomerLeadFilterPage: React.FC<Props> = ({
  getLabCredential,
  labCredential,
  getLeadById,
  leadById,
  getAllVerificationBookings,
  verificationBookingList,
  getCities,
  cities,
  getPaymentInfo,
  paymentInformation,
  loading,
  getPrescriptionData,
  prescription,
  getPhleboTimeline,
  phleboTimeline,
  bookingListLoader,
  getCenterInfo,
  centerInfo,
  getAllBookingsExports,
  get_all_booking_exports,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [openModal, setopenModal] = useState<boolean>(false);
  const [open2, setOpen2] = useState(false);
  const [status, setStatus] = useState<any>([]);
  const [source, setSource] = useState<any>([]);
  const [partner, setPartner] = useState<any>([]);
  const [agent, setAgent] = useState<any>([]);
  const [city, setCity] = useState<any>([]);
  const [start_date, setStart_date] = useState("");
  const [end_date, setEnd_date] = useState("");
  const [booking_date, setBooking_date] = useState("");
  const [collection_date, setCollection_date] = useState("");
  const [verificationStatus, setVerificationStatus] = useState<any>([]);
  const [pickup_status, setPickup_status] = useState<any>([]);
  const [VerifiedbyList, setVerifiedbyList] = useState<any>([]);
  const [name, setName] = useState("");
  const [lead, setLeadId] = useState<any>("");
  const [lab, setLab] = React.useState<any>("");
  const [editButton, setEditButton] = useState<boolean>(false);
  const [labName, setLabName] = React.useState<any>("");
  const [phlebo, setPhlebo] = useState<any>([]);
  const [phone, setPhone] = useState("");
  const [bookingId, setBookingId] = useState<any>("");
  const [bookingIdModal, setBookingIdModal] = useState<any>("");
  const [disposition, setDisposition] = useState<any>([]);
  const [sample_registered, setSampleRegisterd] = useState<any>("none");
  const [report_status, setReportStatus] = useState<any>("none");
  const [paymentId, setPaymentId] = useState<any>(0);
  const [openPaymentModal, setOpenPaymentModal] = useState<any>(false);
  const [page, setPage] = useState(0);
  const [slot, setSlot] = useState<any>([]);
  const [prscriptionLead, setPrescriptionLead] = useState<any>("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openPrescription = Boolean(anchorEl);
  const [openPdf, setOpenPdf] = useState<boolean>(false);
  const [fileType, setFileType] = useState<any>("");
  const [pLink, setPlink] = useState<any>("");
  const timer = useRef<any>(0);
  const [open, setOpen] = React.useState(false);
  const [additionalMemebr, setAdditionalMemebr] = useState<any>();
  const [additionalModal, setAdditonalModal] = useState<boolean>(false);
  const [openPhlebotModel, setOpenPhleboModel] = useState<boolean>(false);
  const [email, setEmail] = useState<any>("");
  const [center, setCentre] = useState<any>("");

  const handleClose1 = () => setOpen(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (history.action !== "POP") {
      // getAllVerificationBookings();
      // getCities();
      // getCenterInfo();
    }
  }, []);

  useEffect(() => {
      getAllVerificationBookings("verification_status=pending");
  }, []);

  const filterBookings = () => {
    const body: any = {
      status: status.join(","),
      verificationStatus: verificationStatus.join(","),
      source: source.join(","),
      agent: agent.join(","),
      city: city.join(","),
      start_date,
      end_date,
      booking_date,
      slot: slot.join(","),
      collection_date,
      pickup_status: pickup_status.join(","),
      verified_by: VerifiedbyList ? VerifiedbyList : "",
      name,
      lead,
      phlebo: phlebo.join(","),
      partner: partner.join(","),
      disposition: disposition.join(","),
      phone,
      bookingId,
      sample_registered,
      report_status,
      labName: lab,
      email,
      center,
    };
    const url = generateBookingFilterUrl(body).substring(2);
    getAllVerificationBookings(url? `${url}&verification_status=pending&page=1` : "verification_status=pending&page=1");
    setPage(0);
  };

  const exportBooking = () => {
    const body: any = {
      status: status.join(","),
      verificationStatus: verificationStatus.join(","),
      source: source.join(","),
      agent: agent.join(","),
      city: city.join(","),
      start_date,
      end_date,
      booking_date,
      slot: slot.join(","),
      collection_date,
      pickup_status: pickup_status.join(","),
      verified_by: VerifiedbyList ? VerifiedbyList : "",
      name,
      lead,
      phlebo: phlebo.join(","),
      partner: partner.join(","),
      disposition: disposition.join(","),
      phone,
      bookingId,
      sample_registered,
      report_status,
      labName: lab,
      email,
      center,
    };
    const url = generateBookingFilterUrl(body).substring(2);
    getAllBookingsExports(
      url ? `${url}&download=true&page=1` : "&download=true&page=1"
    );
    setPage(0);
  };

  useEffect(() => {
    if (paymentId != 0) {
      getPaymentInfo(`?booking=${paymentId}`);
    }
  }, [paymentId]);
  const handleCross = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (prscriptionLead !== "") {
      getPrescriptionData(`?lead=${prscriptionLead}`);
    }
  }, [prscriptionLead]);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFrameView = (data: any) => {
    if (data.base64file !== null && data.image === "") {
      setFileType("b64");
      setPlink(`data:application/pdf;base64,${data.base64file}`);
    } else if (data.base64file === null && data.image !== "") {
      let pieces: any = data.image.split(".").pop();
      const last: any = pieces;
      setFileType(last);
      setPlink(data.image);
    }
    setOpenPdf(true);
  };
  const handlePdfModelClose = () => {
    setOpenPdf(false);
  };

  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={1} style={{ marginBottom: "30px" }}>
          <Grid item xs={12} sm={8}>
            <h4 style={{ fontWeight: "bold" }}>VERIFICATION LIST </h4>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          direction="row"
          style={{ alignItems: "left" }}
        >
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="name"
              type="number"
              label="Booking Id"
              value={bookingId}
              variant="outlined"
              onChange={(e) => setBookingId(e.target.value)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              onChange={(event, newValue) => {
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  setLab(obj.id);
                }
              }}
              disabled={editButton === true}
              inputValue={labName}
              onInputChange={(event, newInputValue) => {
                setLabName(newInputValue);

                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getLabCredential(`?name=${newInputValue}`);
                }, 1000);

                if (newInputValue.length === 0) {
                  setLab("");
                }
              }}
              options={labCredential.results || []}
              freeSolo
              blurOnSelect
              aria-required
              getOptionLabel={(option: any) => `${option?.name}`}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className="input"
                  label="Lab"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="name"
              type="text"
              label="Name"
              value={name}
              variant="outlined"
              onChange={(e) => setName(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="phone"
              type="number"
              label="Phone"
              value={phone}
              variant="outlined"
              onChange={(e) => setPhone(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="email"
              type="text"
              label="Email"
              value={email}
              variant="outlined"
              onChange={(e) => setEmail(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="city"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.id);
                }
                setCity(sourceIds);
              }}
              options={cities || []}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              multiple
              getOptionLabel={(option: any) => option.name}
              getOptionDisabled={(option: any) => {
                return city.includes(option.id);
              }}
              disableClearable
              disableCloseOnSelect
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getCities(newInputValue);
                }, 1000);
                if (newInputValue.length === 0) {
                  setCity([]);
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="City"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="status"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.value);
                }
                setStatus(sourceIds);
              }}
              options={BOOKING_STATUS || []}
              blurOnSelect
              aria-required
              limitTags={1}
              multiple
              getOptionLabel={(option: any) => option.text}
              getOptionDisabled={(option: any) => {
                return status.includes(option.value);
              }}
              disableClearable
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  placeholder="Booking Status"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="center"
              onChange={(event, newValue) => {
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  setCentre(obj.id);
                }
              }}
              freeSolo
              blurOnSelect
              options={centerInfo?.results || []}
              disableCloseOnSelect
              getOptionLabel={(option: any) =>
                (option?.display_name === null
                  ? option?.name
                  : option?.display_name) +
                "(" +
                option?.center_code +
                ")"
              }
              loading={loading}
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getCenterInfo(`?name_or_code=${newInputValue}`);
                }, 1000);
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Centre"
                  variant="outlined"
                  fullWidth
                  required
                />
              )}
            />
          </Grid>
          {/* <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="city"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.value);
                }
                setVerificationStatus(sourceIds);
              }}
              options={[
                {
                  text: "Pending",
                  value: "pending",
                },
                {
                  text: "Confirmed",
                  value: "confirmed",
                },
                {
                  text: "Cancelled",
                  value: "cancelled",
                },
              ]}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              multiple
              getOptionLabel={(option: any) => option.text}
              getOptionDisabled={(option: any) => {
                return verificationStatus.includes(option.value);
              }}
              disableClearable
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  placeholder="Verification Status"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid> */}
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="start_date"
              type="date"
              label="Start Date"
              value={
                booking_date === "" || collection_date === "" ? start_date : ""
              }
              variant="outlined"
              disabled={booking_date !== "" || collection_date !== ""}
              onChange={(e) => setStart_date(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              name="end_date"
              type="date"
              value={
                booking_date === "" || collection_date === "" ? end_date : ""
              }
              className="input"
              label="End Date"
              variant="outlined"
              disabled={booking_date !== "" || collection_date !== ""}
              onChange={(e) => setEnd_date(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              name="booking_date"
              type="date"
              value={start_date === "" || end_date === "" ? booking_date : ""}
              className="input"
              label="Booking Date"
              variant="outlined"
              disabled={start_date !== "" || end_date !== ""}
              onChange={(e) => setBooking_date(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              name="collection_date"
              type="date"
              value={
                start_date === "" || end_date === "" ? collection_date : ""
              }
              className="input"
              label="Collection Date"
              variant="outlined"
              disabled={start_date !== "" || end_date !== ""}
              onChange={(e) => setCollection_date(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Select
              className="input"
              name="sampleregisterd"
              variant="outlined"
              value={report_status}
              style={{ width: "100%", margin: "0", height: "40px" }}
              onChange={(e) => setReportStatus(e.target.value as String)}
            >
              <MenuItem disabled value={"none"}>
                Report Status
              </MenuItem>
              <MenuItem value={"na"}>None</MenuItem>
              <MenuItem value={"pending"}>Pending</MenuItem>
              <MenuItem value={"partial"}>Partial</MenuItem>
              <MenuItem value={"consolidate"}>Consolidate</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="secondary"
              disabled={bookingListLoader}
              fullWidth
              onClick={filterBookings}
            >
              Filter
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() =>
                history.push("/dashboard/os/verificationStatusTable")
              }
            >
              Reset
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              style={{ background: "#9c27b0", height: "40px" }}
              fullWidth
              onClick={exportBooking}
            >
              <MailIcon /> Export Booking
            </Button>
          </Grid>
        </Grid>
        <PaymentModal
          openModalP={openPaymentModal}
          setOpenModalP={setOpenPaymentModal}
          paymentInformation={paymentInformation}
        />
        <br />
        {bookingListLoader ? (
          <Loader />
        ) : (
          verificationBookingList?.results &&
          verificationBookingList?.results?.length > 0 && (
            <Box>
              <Container maxWidth={false}>
                <Grid container spacing={3}>
                  <VerificationTable
                    open2={open2}
                    setOpen2={setOpen2}
                    setopenModal={setopenModal}
                    openModal={openModal}
                    page={page}
                    setPage={setPage}
                    loading={loading}
                    getBookings={getAllVerificationBookings}
                    verificationBookingList={verificationBookingList}
                    paymentId={paymentId}
                    setPaymentId={setPaymentId}
                    openPaymentModal={openPaymentModal}
                    setOpenPaymentModal={setOpenPaymentModal}
                    getPrescriptionData={getPrescriptionData}
                    prescription={prescription}
                    prscriptionLead={prscriptionLead}
                    setBookingId={setBookingId}
                    setBookingIdModal={setBookingIdModal}
                    bookingId={bookingId}
                    setPrescriptionLead={setPrescriptionLead}
                    setAnchorEl={setAnchorEl}
                    // getCities={getCities}
                    // cities={cities}
                    setLeadId={setLeadId}
                    getLeadById={getLeadById}
                    leadById={leadById}
                    additionalMemebr={additionalMemebr}
                    setAdditionalMemebr={setAdditionalMemebr}
                    additionalModal={additionalModal}
                    setAdditonalModal={setAdditonalModal}
                    openPhlebotModel={openPhlebotModel}
                    setOpenPhleboModel={setOpenPhleboModel}
                    bookingListLoader={bookingListLoader}
                  />
                </Grid>
              </Container>
            </Box>
          )
        )}
        <Menu
          id="fade-menu"
          anchorEl={anchorEl}
          keepMounted
          open={openPrescription}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          {prescription &&
            prescription?.results &&
            prescription?.results?.length > 0 &&
            prescription?.results.map((data: any, index: any) => {
              let pieces: any = data.image.split(".").pop();
              const last: any = pieces;
              return (
                <>
                  {data?.base64file !== null && data.image === "" ? (
                    <MenuItem onClick={() => handleFrameView(data)}>
                      {" "}
                      Name :{data?.name}, Age:{data?.age}, Created Date :
                      {new Date(data.created_at).toLocaleString()}
                    </MenuItem>
                  ) : data?.base64file === null && data.image !== "" ? (
                    last === "png" || last === "jpg" || last === "jpeg" ? (
                      <MenuItem>
                        <a
                          href={data?.image}
                          rel="noreferrer noopener"
                          target="_blank"
                        >
                          Name :{data?.name}, Age:{data?.age}, Created Date :
                          {new Date(data.created_at).toLocaleString()}
                        </a>
                      </MenuItem>
                    ) : (
                      <MenuItem>
                        <a
                          href={data?.image}
                          rel="noreferrer noopener"
                          target="_blank"
                        >
                          Name :{data?.name}, Age:{data?.age}, Created Date :
                          {new Date(data.created_at).toLocaleString()}
                        </a>
                      </MenuItem>
                    )
                  ) : (
                    ""
                  )}
                </>
              );
            })}
        </Menu>

        {verificationBookingList?.results && verificationBookingList?.results?.length === 0 && (
          <h6 style={{ textAlign: "center" }}>No Data Found</h6>
        )}
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose1}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{"Note:"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <div style={{ margin: "1rem" }}>
                <div
                  style={{
                    alignItems: "left",
                    display: "inline-block",
                    margin: "1rem",
                  }}
                >
                  <small>
                    <ul style={{ paddingLeft: "15px" }}>
                      <li>
                        You can filter by booking date or start and end date.
                      </li>
                      <li>T: Total Additional Booking count</li>
                      <li>P: Pending count for additional member</li>
                      <li>C: Confirmed count for additional member</li>
                      <li>
                        For B2B booking first select Booking Type b2b then apply
                        any filter obtion
                      </li>
                    </ul>
                  </small>
                </div>
                <div
                  style={{
                    alignItems: "left",
                    display: "inline-block",
                    margin: "1rem",
                  }}
                >
                  <small>
                    <div className="r-cl" style={{ display: "flex" }}>
                      <div
                        className="boxStyle"
                        style={{
                          border: "1px solid",
                          width: "17px",
                          height: "17px",
                          background: "#ffe082",
                        }}
                      ></div>
                      <div style={{ marginLeft: "5px" }}>
                        B2B booking and Payable Amount is Zero
                      </div>
                    </div>
                    <div className="r-cl" style={{ display: "flex" }}>
                      <div
                        className="boxStyle"
                        style={{
                          border: "1px solid",
                          width: "17px",
                          height: "17px",
                          background: "#f48fb1",
                        }}
                      ></div>
                      <div style={{ marginLeft: "5px" }}>
                        B2B booking and Payable Amount is not Zero
                      </div>
                    </div>
                    <div className="r-cl" style={{ display: "flex" }}>
                      <div
                        className="boxStyle"
                        style={{
                          border: "1px solid",
                          width: "17px",
                          height: "17px",
                          background: "#64ffda",
                        }}
                      ></div>
                      <div style={{ marginLeft: "5px" }}>
                        B2C booking and Payable Amount is Zero
                      </div>
                    </div>
                    <div className="r-cl" style={{ display: "flex" }}>
                      <div
                        className="boxStyle"
                        style={{
                          border: "1px solid",
                          width: "17px",
                          height: "17px",
                          background: "default",
                        }}
                      ></div>
                      <div style={{ marginLeft: "5px" }}>
                        B2C booking and Payable Amount is not Zero
                      </div>
                    </div>
                    <div className="r-cl" style={{ display: "flex" }}>
                      <div
                        className="boxStyle"
                        style={{
                          border: "1px solid",
                          width: "17px",
                          height: "17px",
                          background: "#4caf50",
                        }}
                      ></div>
                      <div style={{ marginLeft: "5px" }}>Phlebo Reached</div>
                    </div>
                  </small>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </main>
      <BookingCommentsModal
        openModal={openModal}
        setopenModal={setopenModal}
        bookingId={bookingId}
        setBookingId={setBookingId}
      />
      <CommentsModal2
        open={open2}
        setOpen={setOpen2}
        leadId={lead}
        getCities={getCities}
        cities={cities}
      />
      <PhleboJourneysModel
        setOpenPhleboModel={setOpenPhleboModel}
        openPhlebotModel={openPhlebotModel}
        bookingId={bookingIdModal}
        getPhleboTimeline={getPhleboTimeline}
        phleboTimeline={phleboTimeline}
        loading={loading}
      />
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    verificationBookingList: state.OffLineSupporteReducer.verificationBookingList,
    cities: state.OffLineSupporteReducer.cities,
    bookingListLoader: state.OffLineSupporteReducer.bookingListLoader,
    paymentInformation: state.OffLineSupporteReducer.paymentInformation,
    loading: state.OffLineSupporteReducer.loading,
    prescription: state.OffLineSupporteReducer.prescription,
    labCredential: state.OffLineSupporteReducer.labCredential,
    phleboTimeline: state.OffLineSupporteReducer.phleboTimeline,
    centerInfo: state.OffLineSupporteReducer.centerInfo,
    get_all_booking_exports:
      state.OffLineSupporteReducer.get_all_booking_exports,
  };
};

export default connect(mapStateToProps, {
  getLabCredential,
  getCities,
  getPaymentInfo,
  getAllVerificationBookings,
  getPrescriptionData,
  getPhleboTimeline,
  getCenterInfo,
  getAllBookingsExports,
})(CustomerLeadFilterPage);
