import React, { useEffect } from "react";
import { Grid, TextField } from "@material-ui/core";
import { connect } from "react-redux";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import BarChartTwoToneIcon from "@mui/icons-material/BarChartTwoTone";
import {
  getBookingOverview,
} from "../../actions/OffLineSupportActions";
import Avatar from "@mui/material/Avatar";
import CollectionsIcon from "@mui/icons-material/Collections";
import PaidIcon from "@mui/icons-material/Paid";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import moment from "moment";
import "./dashboard.css";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      height: "100%",
      padding: "20px",
    },
    cardContent: {
      textAlign: "center",
    },
    center: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    text: { color: "black" },
  })
);

interface Props {
  getBookingOverview: any;
  bookingoverview: any;
}

const Dashboard: React.FC<Props> = ({
  getBookingOverview,
  bookingoverview,

}) => {
  const classes = useStyles();
  const isEmpty = Object.keys(bookingoverview).length === 0;
  useEffect(() => {
    // getBookingOverview("");
  }, []);

  return (
    <div style={{ width: "90%", margin: "60px auto" }}>
      <Grid
        container
        spacing={1}
        direction="row"
        style={{ alignItems: "right", display: "flex" }}
      >
        <Grid item xs={12} sm={8}>
          <h4 style={{ fontWeight: "bold" }}>DASHBOARD</h4>
        </Grid>
        <Grid item xs={12} sm={4} style={{ textAlign: "right" }}>
          <TextField
            type="date"
            defaultValue={moment().format("YYYY-MM-DD")}
            onChange={(e) =>
              getBookingOverview(
                `overview_date=${moment(e.target.value).format("YYYY-MM-DD")}`
              )
            }
          />
        </Grid>
      </Grid>
      <div style={{ margin: "10px 0px 0px 0px" }}>
        <h6>Batches</h6>
        <div className="cardsAppointment">
          <div>
            <Grid container>
              <Grid item sm={3}>
                <Avatar
                  sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                  aria-label="recipe"
                >
                  <BarChartTwoToneIcon style={{ color: "#fa4d83" }} />
                </Avatar>
              </Grid>
              <Grid item sm={9}>
                <h5 style={{ color: "#ffffff" }}>
                  {bookingoverview?.total_batch}
                </h5>
                <span style={{ color: "#ffffff" }}>Verification Pending</span>
              </Grid>
            </Grid>
          </div>
          <div style={{ marginLeft: "6%" }}>
            <Grid container>
              <Grid item sm={3}>
                <Avatar
                  sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                  aria-label="recipe"
                >
                  <BarChartTwoToneIcon style={{ color: "#fa4d83" }} />
                </Avatar>
              </Grid>
              <Grid item sm={9}>
                <h5 style={{ color: "#ffffff" }}>
                  {bookingoverview?.lab_received_batch}
                </h5>
                <span style={{ color: "#ffffff" }}>Verification Done</span>
              </Grid>
            </Grid>
          </div>
          <div style={{ marginLeft: "6%" }}>
            <Grid container>
              <Grid item sm={3}>
                <Avatar
                  sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                  aria-label="recipe"
                >
                  <BarChartTwoToneIcon style={{ color: "#fa4d83" }} />
                </Avatar>
              </Grid>
              <Grid item sm={9}>
                <h5 style={{ color: "#ffffff" }}>
                  {bookingoverview?.pending_batch}
                </h5>
                <span style={{ color: "#ffffff" }}>Cancelled</span>
              </Grid>
            </Grid>
          </div>
          <div style={{ marginLeft: "6%" }}>
            <Grid container>
              <Grid item sm={3}>
                <Avatar
                  sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                  aria-label="recipe"
                >
                  <BarChartTwoToneIcon style={{ color: "#fa4d83" }} />
                </Avatar>
              </Grid>
              <Grid item sm={9}>
                <h5 style={{ color: "#ffffff" }}>
                  {bookingoverview?.non_city_batches}
                </h5>
                <span style={{ color: "#ffffff" }}>
                  Total booking count with VE performance
                </span>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      {/* <Grid container spacing={3}>
        <Grid item xs={12}>
          <div style={{ display: "flex", width: "100%" }}>
            <h5>Payment Info</h5>

            <hr style={{ width: "100%", marginTop: 10 }} />
          </div>
        </Grid>
        <div className="cardsAppointment">
          <div>
            <Grid container>
              <Grid item sm={3}>
                <Avatar
                  sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                  aria-label="recipe"
                >
                  <PaidIcon style={{ color: "#fa4d83" }} />
                </Avatar>
              </Grid>
              <Grid item sm={9}>
                <h5 style={{ color: "white" }}>{isEmpty ? 0 : bookingoverview?.total_batch}</h5>
                <span style={{ color: "white" }}>Total</span>
              </Grid>
            </Grid>
          </div>
          <div style={{ marginLeft: "6%" }}>
            <Grid container>
              <Grid item sm={3}>
                <Avatar
                  sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                  aria-label="recipe"
                >
                  <CollectionsIcon style={{ color: "#fa4d83" }} />
                </Avatar>
              </Grid>
              <Grid item sm={9}>
                <h5 style={{ color: "white" }}>{isEmpty ? 0 : bookingoverview?.pending_batch}</h5>
                <span style={{ color: "white" }}>Amount Collected</span>
              </Grid>
            </Grid>
          </div>
          <div style={{ marginLeft: "6%" }}>
            <Grid container>
              <Grid item sm={3}>
                <Avatar
                  sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                  aria-label="recipe"
                >
                  <AccountBalanceIcon style={{ color: "#fa4d83" }} />
                </Avatar>
              </Grid>
              <Grid item sm={9}>
                <h5 style={{ color: "white" }}>{isEmpty ? 0 : (bookingoverview?.bank_transfer === null ? 0 : bookingoverview?.bank_transfer)}</h5>
                <span style={{ color: "white" }}>Bank Transfer</span>
              </Grid>
            </Grid>
          </div>
        </div>
      </Grid> */}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  bookingoverview: state.OffLineSupporteReducer.bookingoverview,
  loading: state.OffLineSupporteReducer.loading,
});

export default connect(mapStateToProps, {
  getBookingOverview,
})(Dashboard);
