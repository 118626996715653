import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@mui/material/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useHistory } from "react-router-dom";
import Button from '@mui/material/Button';
import {  Paper, Grid, TextField, MenuItem } from "@material-ui/core";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { sendReportById, getFailedReportDataById, sentReportDataById } from "../../actions/OffLineSupportActions";

let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "scroll",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: isMobile ? "400px" : "800px",
    maxHeight: "600px",
    overflow: "scroll",
    position: "sticky",
  },
  head: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "2rem",
  },
}));

type ModalProps = {
  setOpenReportModel: Function;
  openReportModel: any;
  sendReportById: any;
  customerDetails: any;
  setCustomerDetails: any;
  objId: any;
  getFailedReportDataById: any;
  failedReportById: any;
  sentReportDataById: any;
  setFilterUrl: any;
  filterUrl: any;
  getFailedReportData: any;
};

const CommentsModal2: React.FC<ModalProps> = ({
  sendReportById,
  setOpenReportModel,
  openReportModel,
  customerDetails,
  setCustomerDetails,
  objId,
  getFailedReportDataById,
  failedReportById,
  sentReportDataById,
  setFilterUrl,
  filterUrl,
  getFailedReportData,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [disposition, setDisposition] = useState<any>("");
  const [file1, setFile1] = React.useState<any>([]);
  const [comment, setComment] = useState<any>("");
  const [buttonType, setButtonType] = useState<boolean>(false);
  const [id, setId] = useState(0);
  const [customer_email, setCustomer_email] = useState<String>("");
  const [remark, setRemark] = useState<any>("");
  // const [checked, setChecked] = React.useState(false);
  const [reportSentOnWhatsapp, setReportSentOnWhatsApp] = useState<String>("");
  const [reportSentOnEmail, setReportSentOnEmail] = useState<String>("");
  const [mobileValidation, setMobileValidation] = useState<any>(true);
  const [emailValidation, setEmailValidation] = useState<any>(true);
  const [checkedWay, setCheckedWay] = useState<any>("");

  const handleClose = () => {
    setOpenReportModel(false);
    setButtonType(false);
    setCustomerDetails("");
    setCheckedWay("");
  };

  useEffect(() => {
    if (openReportModel === false) {
      setCustomerDetails("");
      setEmailValidation(true)
      setMobileValidation(true)
      setReportSentOnEmail("")
      setReportSentOnWhatsApp("")
      setCheckedWay("")
    }
  }, [openReportModel])

  const openCommentFun = () => {
    setButtonType(false);
  };

  const fileSelectedHandler1 = (e: React.ChangeEvent<{ files: any }>) => {
    setFile1(e.target.files[0]);
  };

  const handleSendEmail = async (id: any) => {
    // await sendReportById(
    //   customerDetails?.add_member_booking !== null
    //     ? customerDetails?.add_member_booking.id
    //     : customerDetails?.booking?.id,
    //   `?email=${customer_email}&failed_report_id=${objId}&remarks=${remark}&disposition=${disposition}`,
    //   customerDetails?.add_member_booking !== null ? "add_booking" : "booking"
    // );
    const body: any = {
      email: reportSentOnEmail,
      id: customerDetails?.id,
      send_via: checkedWay,
      lead_id: customerDetails?.booking?.id,
      whatsapp_contact: reportSentOnWhatsapp,
    }

    await sentReportDataById(body, customerDetails?.id);
    // alert("Report Send Successfully");
    // history.push(`/dashboard/os/report_not_deliverd`);
    await getFailedReportData(filterUrl);
    setCustomerDetails("");
    setEmailValidation(true);
    setMobileValidation(true);
    setReportSentOnEmail("");
    setReportSentOnWhatsApp("");
    setOpenReportModel(false);
    setButtonType(false);
  };

  const handleCanelEmail = () => {
    history.push(`/dashboard/os/report_not_deliverd`);
    setButtonType(false);
  };
  const verifyMobile = () => {
    const mobileRegEx = /^[6-9]\d{9}$/;

    if (reportSentOnWhatsapp && !mobileRegEx.test(reportSentOnWhatsapp.toString())) {
      setMobileValidation(false);
    }
  };
  const verifyEmail = () => {
    const nameRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (reportSentOnEmail && !nameRegex.test(reportSentOnEmail.toString())) {
      setEmailValidation(false);
    }
  };
  useEffect(() => {
    if (customerDetails !== '' && (customerDetails?.id !== 0 || customerDetails?.id !== null || customerDetails?.id !== undefined)) {
      getFailedReportDataById(customerDetails?.id);
    }
  }, [customerDetails])

  useEffect(() => {
    if (failedReportById?.email !== "" || failedReportById?.email !== 0 || failedReportById?.email !== null || failedReportById?.email !== undefined) {
      setReportSentOnEmail(failedReportById?.email);
    }
    if (failedReportById?.whatsapp_contact !== "" || failedReportById?.whatsapp_contact !== 0 || failedReportById?.whatsapp_contact !== null || failedReportById?.whatsapp_contact !== undefined) {
      setReportSentOnWhatsApp(failedReportById?.whatsapp_contact);
    }
  }, [failedReportById])

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={openReportModel}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openReportModel}>
        <div className={classes.paper}>
          <>
            <h3>Send Report From Here</h3>
            <Grid container spacing={3}>
            <Grid item xs={12}>
                <TextField
                  className="input"
                  name="name"
                  type="number"
                  label="Booking Id"
                  value={
                    customerDetails?.add_member_booking !== null
                      ? customerDetails?.add_member_booking?.id
                      : customerDetails?.booking?.id
                  }
                  variant="outlined"
                  //  onChange={(e) => setBookingId(e.target.value)}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12}>
              <div className="checkList" style={{ display: "flex" }}>
                <div style={{ fontSize: "large", fontWeight: "bolder" }}>Send Report On:</div>
                <div style={{ display: "flex" }}>
                  <div style={{ paddingLeft: "20px" }}>
                    <input value={"whatsapp"} type="checkbox" checked={checkedWay === "whatsapp" ? true : false} onChange={(e) => {!e.target.checked ? setCheckedWay("") : setCheckedWay(e.target.value);}} />
                    <span style={{ paddingLeft: "4px" }}>Whatsapp</span>
                  </div>
                  <div style={{ paddingLeft: "20px" }}>
                    <input value={"email"} type="checkbox" checked={checkedWay === "email" ? true : false} onChange={(e) => {!e.target.checked ? setCheckedWay("") : setCheckedWay(e.target.value);}} />
                    <span style={{ paddingLeft: "4px" }}>Email</span>
                  </div>
                  <div style={{ paddingLeft: "20px" }}>
                    <input value={"both"} type="checkbox" checked={checkedWay === "both" ? true : false} onChange={(e) => {!e.target.checked ? setCheckedWay("") : setCheckedWay(e.target.value);}} />
                    <span style={{ paddingLeft: "4px" }}>Both</span>
                  </div>
                </div>
              </div>
              </Grid>
              { checkedWay === "both" || checkedWay === "whatsapp" ? <Grid item xs={12}>
                <TextField
                  className="input"
                  name="whatsapp_name"
                  type="number"
                  label="Whatsapp Number"
                  value={reportSentOnWhatsapp}
                  variant="outlined"
                  onWheel={(e: any) => e.target.blur()}
                  onInput={(e: any) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }}
                  onBlur={() => verifyMobile()}
                  onFocus={() => {
                    setMobileValidation(true);
                  }}
                  onChange={(e) => setReportSentOnWhatsApp(e.target.value)}
                  style={{ width: "100%" }}
                />
                <sub style={{ color: "red" }}>
                  {!mobileValidation ? "Please enter valid mobile number" : ""}
                </sub>
              </Grid> : ""}
              { checkedWay === "both" || checkedWay === "email" ? <Grid item xs={12}>
                <TextField
                  className="input"
                  name="email"
                  type="text"
                  label="Email ID"
                  value={reportSentOnEmail}
                  variant="outlined"
                  onBlur={() => verifyEmail()}
                  onFocus={() => {
                    setEmailValidation(true);
                  }}
                  onChange={(e) => setReportSentOnEmail(e.target.value)}
                  style={{ width: "100%" }}
                />
                <sub style={{ color: "red" }}>
                  {!emailValidation ? "Please enter valid e-mail id" : ""}
                </sub>
              </Grid> : ""}
              {/* <Grid item xs={12}>
                <TextField
                  className="input"
                  label="Select disposition"
                  value={disposition}
                  select
                  variant="outlined"
                  onChange={(e) => setDisposition(e.target.value)}
                  style={{ width: "100%" }}
                >
                  <MenuItem value="Customer_is_not_responding_to_the_calls">
                    Customer is not responding to the calls
                  </MenuItem>
                  <MenuItem value="Customer_has_already_received_the_reports">
                    Customer has already received the reports
                  </MenuItem>
                  <MenuItem value="Reports_shared_over_whatsapp">
                    Reports shared over whatsapp
                  </MenuItem>
                  <MenuItem value="Reports_shared">Reports shared</MenuItem>
                </TextField>
              </Grid>
              {disposition !== "Reports_shared" ? (
                ""
              ) : (
                <Grid item xs={12}>
                  <TextField
                    name="customer_email"
                    type="email"
                    label="Enter Customer Email"
                    value={customer_email}
                    disabled={disposition !== "Reports_shared"}
                    className="input"
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                    onChange={(e) =>
                      setCustomer_email(e.target.value as String)
                    }
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <TextField
                  name="remark"
                  className="input"
                  // label="Remark"
                  label="Enter Remark"
                  variant="outlined"
                  style={{ margin: "0", width: "100%", marginTop: "1rem" }}
                  value={remark}
                  onChange={(e) => setRemark(e.target.value)}
                />
              </Grid> */}

              <Button
                variant="contained"
                style={{
                  marginTop: "20px",
                }}
                color="primary"
                onClick={() => handleSendEmail(id)}
                disabled={checkedWay === "" || 
                  (checkedWay === "whatsapp" ? (reportSentOnWhatsapp === "" || reportSentOnWhatsapp === null || reportSentOnWhatsapp === undefined || !mobileValidation) : false) ||
                  (checkedWay === "email" ? (reportSentOnEmail === "" || reportSentOnEmail === null || reportSentOnEmail === undefined || !emailValidation) : false) || 
                  (checkedWay === "both" ? (reportSentOnEmail === "" || reportSentOnEmail === null || reportSentOnEmail === undefined || !emailValidation || reportSentOnWhatsapp === "" || reportSentOnWhatsapp === null || reportSentOnWhatsapp === undefined || !mobileValidation) : false)
                }
              >
                SEND REPORT
              </Button>
              <Button
                variant="contained"
                style={{
                  marginTop: "20px",
                  marginLeft: "10px",
                }}
                color="secondary"
                onClick={handleCanelEmail}
              >
                Cancel
              </Button>
            </Grid>
          </>
        </div>
      </Fade>
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.OffLineSupporteReducer.loading,
  user: state.loginReducer.user,
  failedReportById: state.OffLineSupporteReducer.failedReportById,
});

export default connect(mapStateToProps, {
  sendReportById,
  getFailedReportDataById,
  sentReportDataById,
})(CommentsModal2);
