import { Button, Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@mui/material";
import React from "react";

interface IProps {
  setSelectedView: React.Dispatch<React.SetStateAction<string>>;
  selectedView: string;
  proccedToView: () => void;
  dropdownOptions: any[];
  style?: object;
  isModalOpen?: boolean;
  setActiveView?: any;
}

const View: React.FC<IProps> = ({
  setSelectedView,
  selectedView,
  proccedToView,
  dropdownOptions,
  style,
  setActiveView,
  isModalOpen,
}) => {
  return (
    <div style={style}>
      <p>Please select an option from below: </p>
      <Grid container>
        <Grid item xs={12} sm={6} md={isModalOpen ? 12 : 4}>
          <Autocomplete
            className="input"
            onChange={(event, newValue) => {
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                setActiveView(obj?.view_id as number);
                setSelectedView(obj.name as string);
              }
            }}
            options={dropdownOptions || []}
            // inputValue={selectedView}
            getOptionLabel={(option: any) => option?.name || ""}
            id="options-dropdown"
            disableClearable
            renderInput={(params) => <TextField {...params} variant="outlined" label="Select" />}
          />
          <Button
            type="button"
            variant="contained"
            onClick={proccedToView}
            style={{
              background: selectedView ? "#9a40a2" : "#c6c2c2",
              color: selectedView ? "#fff" : "#666",
              width: "100%",
              marginTop: "20px",
            }}
            disabled={!selectedView}
          >
            Proceed
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default View;