import React from 'react'
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Chip, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, withStyles } from '@material-ui/core';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from '../components/loader';
import { MessageRounded } from '@material-ui/icons';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },

        paper: {
            marginTop: "2rem",
            padding: "0.5rem 2rem",
            width: "100%",
        },
        table: {
            maxWidth: "100%",
            margin: "auto"
        },
        tableContainer: {
            marginTop: "2rem",
            position: "sticky",
            top: "200px",
        },
    })
);

const StyledTableCell:any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#924A91",
            color: theme.palette.common.white,
            fontSize: "14px",
        },
        body: {
            fontSize: 14,
        },
    })
)(TableCell);

const StyledTableRow:any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);

interface Props {
    getBookings: any;
    bookingList: any;
    page: any;
    setPage: any;
    setopenModal: any;
    openModal: any;
    bookingCall: any;
    getAgentList: any;
    getAllLeads: any;
    leadTransferByComma: any;
    agentBYUserGroup: any;
     loading: boolean;
    selectedLeads: any;
    setSelectedLeads: any;
    panel_user: any;
    setPaymentId: any;
    paymentId: any;
    openPaymentModal: any;
    setOpenPaymentModal: any;
    additionalBookingListWithTatTime: any;
    setAdditionalMemberId: any;
    setAdditonalModal: any;
    setPanelUser: any;
    bookingId:any
    setBookingId:any
}

const BookingsTable: React.FC<Props> = ({
    getBookings,
    bookingList,
    bookingId,
    setBookingId,
    page,
    setPage,
    setopenModal,
    getAgentList,
    agentBYUserGroup,
    leadTransferByComma,
    getAllLeads,
    openModal,
    bookingCall,
    selectedLeads,
    setSelectedLeads,
    panel_user,
    setPanelUser,
    setPaymentId,
    setOpenPaymentModal,
    openPaymentModal,
    paymentId,
    additionalBookingListWithTatTime,
    setAdditionalMemberId,
    setAdditonalModal,
    loading
}) => {
    const classes = useStyles()
    const handleClick = (id: number) => {
        setopenModal(true);
        setBookingId(id);
    };

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        if (newPage > page) {
            let url = bookingList.links && bookingList.links.next.split("?")[1];
            getBookings(url);
        } else if (newPage < page) {
            let url = bookingList.links && bookingList.links.previous.split("?")[1];
            getBookings(url);
        }
        setPage(newPage as number);
    };

    const handleAdditionalView = (id: any) => {
        setAdditionalMemberId(id)
        setAdditonalModal(true)
    }
    return (
        <div style={{ width: "100%" }}>

            {/* <Paper elevation={15} className={classes.paper}> */}
            <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "540px" }}>
                {loading ? <Loader /> : (
                    <Table stickyHeader aria-label="simple table">
                        <TableHead>
                            <StyledTableRow>
                                {/* <TableCell align="center">
                                        <input
                                            type="checkbox"
                                            className="input"
                                            name="selectall"
                                            id="selectall"
                                            onChange={(e) =>
                                                e.target.checked
                                                    ? setSelectedLeads(
                                                        bookingList.results &&
                                                        bookingList.results.map((lead: any) => {
                                                            return lead.lead && lead.lead;
                                                        })
                                                    )
                                                    : setSelectedLeads([])
                                            }
                                        />
                                    </TableCell> */}
                                <StyledTableCell align="center">Action</StyledTableCell>
                                <StyledTableCell align="center">Booking Id</StyledTableCell>
                                <StyledTableCell align="center">Comment</StyledTableCell>
                                <StyledTableCell align="center">Booking Date</StyledTableCell>
                                <StyledTableCell align="center">Name</StyledTableCell>
                                <StyledTableCell align="center">Age</StyledTableCell>
                                <StyledTableCell align="center">Gender</StyledTableCell>
                                <StyledTableCell align="center">Email</StyledTableCell>
                                <StyledTableCell align="center">Collection Date</StyledTableCell>
                                <StyledTableCell align="center">Alloted Time</StyledTableCell>
                                <StyledTableCell align="center">In_TaT</StyledTableCell>
                                <StyledTableCell align="center">Pickup DateTime</StyledTableCell>
                                <StyledTableCell align="center">Report Consolidated Time</StyledTableCell>
                                <StyledTableCell align="center">Time Difference</StyledTableCell>
                                <StyledTableCell align="center">Total Time From Pickup</StyledTableCell>
                                <StyledTableCell align="center">Mobile Number</StyledTableCell>
                                <StyledTableCell align="center">Pickup Status</StyledTableCell>
                                <StyledTableCell align="center">Report Status</StyledTableCell>

                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {
                                bookingList.results && bookingList.results.length > 0 && bookingList.results.map((booking: any, index: any) => {
                                    return (

                                        <StyledTableRow key={index}>

                                            {/* <TableCell align="center">
                                                    <input
                                                        type="checkbox"
                                                        name="selectall"
                                                        id="selectall"
                                                        checked={selectedLeads.find(
                                                            (lead: any) => lead === booking.lead
                                                        )}
                                                        onChange={(e) =>
                                                            !e.target.checked
                                                                ? setSelectedLeads(
                                                                    selectedLeads.filter(
                                                                        (lead: any) => lead !== booking.lead
                                                                    )
                                                                )
                                                                : setSelectedLeads((prev: any) => [
                                                                    ...prev,
                                                                    booking.lead,
                                                                ])
                                                        }
                                                    />
                                                </TableCell> */}
                                            <StyledTableCell align="center">
                                                <Link to={`/dashboard/os/booking-view/${booking.pk}`} style={{ textDecoration: "none" }}>
                                                    <Button variant="contained" color="secondary">View</Button>
                                                </Link>
                                            </StyledTableCell>
                                            <StyledTableCell align="center">{booking.additional_members.length > 0 ? (
                                                <div>
                                                    {booking.pk}+
                                                    <Chip
                                                        label={`${booking.additional_members.length}`}
                                                        style={{ background: "#ffeb3b" }}
                                                        onClick={() => handleAdditionalView(booking.pk)}
                                                    />
                                                </div>

                                            ) : booking.pk}</StyledTableCell>
                                            <StyledTableCell align="center">
                                                <div>
                                                    <MessageRounded onClick={() => handleClick(booking.pk)} />
                                                </div>
                                                {/* {comment.created_at.substring(0, 10)} :{" "}
                                                {comment.created_at.substring(11, 19)} :{" "} */}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">{booking.booking_date}</StyledTableCell>
                                            <StyledTableCell align="center">{`${booking?.designation || ""} ${booking.customer_name}`}</StyledTableCell>
                                            <StyledTableCell align="center">{booking.customer_age}</StyledTableCell>
                                            <StyledTableCell align="center">{booking.customer_gender}</StyledTableCell>
                                            <StyledTableCell align="center">{booking.customer_email}</StyledTableCell>
                                            <StyledTableCell align="center">{booking.collection_date}</StyledTableCell>
                                            <StyledTableCell align="center">
                                                {booking.intatouttat?.allotted_time.substring(0, 10)}
                                                {" "}
                                                {booking.intatouttat?.allotted_time.substring(11, 19)}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">{booking.intatouttat?.intat === true ? "Deliver at TaT" : booking.intatouttat?.intat === false ? "Out of TaT" : "Report Is not ready"}</StyledTableCell>
                                            <StyledTableCell align="center">
                                                {booking.intatouttat?.pickup_datetime.substring(0, 10)}
                                                <br />
                                                {booking.intatouttat?.pickup_datetime.substring(11, 19)}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {booking.intatouttat?.report_consolidated_time === null ? "" : booking.intatouttat?.report_consolidated_time.substring(0, 10)}
                                                <br />
                                                {booking.intatouttat?.report_consolidated_time === null ? "" : booking.intatouttat?.report_consolidated_time.substring(11, 19)}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">{Math.ceil(booking.intatouttat?.timedifference)}</StyledTableCell>
                                            <StyledTableCell align="center">{Math.ceil(booking.intatouttat?.total_time_from_pickup)}</StyledTableCell>
                                            <StyledTableCell align="center">{booking.customer_phonenumber}</StyledTableCell>
                                            <StyledTableCell align="center"><Chip label={`${booking.pickup_status}`} style={{ background: booking.pickup_status === "pending" ? "#ffee58" : booking.pickup_status === "confirmed" ? "#00e676" : booking.pickup_status === "cancelled" ? "#ff3d00" : "#d500f9" }} /></StyledTableCell>
                                            <StyledTableCell align="center"><Chip label={`${booking?.report_status}`} style={{ background: booking?.report_status === "pending" ? "#ffee58" : booking?.report_status === "consolidate" ? "#00e676" : booking?.report_status === "partial" ? "#ff9800" : booking?.report_status === "na" ? "#ffee58" : "#d500f9" }} /></StyledTableCell>
                                        </StyledTableRow>
                                    )
                                })}
                        </TableBody>
                        <TableFooter>
                            <StyledTableRow style={{ textAlign: "center" }}>
                                <TablePagination
                                    rowsPerPageOptions={[]}
                                    colSpan={3}
                                    count={bookingList.count || 0}
                                    rowsPerPage={bookingList.page_size}
                                    page={page}
                                    onPageChange={handleChangePage}

                                />

                            </StyledTableRow>
                        </TableFooter>
                    </Table>
                )}
            </TableContainer>
            {bookingList.results && bookingList.results.length === 0 && (
                <h6 style={{ textAlign: "center" }}>No Data Found</h6>
            )}
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    bookingCall: state.OffLineSupporteReducer.bookingCall,

})

export default connect(mapStateToProps, {

})(BookingsTable);
