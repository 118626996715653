import React, { useState, useEffect, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    Grid,
    TextField,
    withStyles,
    Select,
    MenuItem,
    FormControl,
    InputLabel
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from '@mui/material/Button';
import { connect } from "react-redux";
import MessageIcon from "@material-ui/icons/Message";
import { useHistory } from "react-router";
import EditIcon from '@mui/icons-material/Edit';
import { getFailedReportData, updateFailedReportStatus } from "../actions/OffLineSupportActions";
import { genrateFailedReportFilter } from "../healpers/generateUrl";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        paper: {
            marginTop: "2rem",
            width: "100%",
        },
        table: {
            maxWidth: "100%",
        },
        tableHead: {
            background: "rgb(146, 74, 145)!important",
        },
        toolbar: {
            alignItems: "center",
            justifyContent: "flex-end",
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        tableHeadCell: {
            color: "#fff",
            fontWeight: "bold",
            textAlign: "center",
        },
        tableContainer: {
            marginTop: "2rem",
            position: "sticky",
            top: "200px",
            width: "98%",
        },
        input: {
            padding: "1px",
        },
    })
);
const StyledTableCell: any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#924A91",
            color: theme.palette.common.white,
            fontSize: "14px",
        },
        body: {
            fontSize: 14,
        },
    })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);

interface SampleHandOverProps {
   
    updateFailedReportStatus: any;
    getFailedReportData: any;
    failedReport: any;
     loading: boolean;

}

const BookingsTable: React.FC<SampleHandOverProps> = ({
    getFailedReportData,
    updateFailedReportStatus,
    failedReport,
    loading,
}) => {
    const classes = useStyles();
    const history = useHistory();
    const timer = useRef<any>(0);
    const [bookingId, setBookingId] = useState<any>("");
    const [batchId, setBatchId] = useState<any>("");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const [customer_name, setCustomerName] = useState<any>("");
    const [editStatus, setEditStatus] = useState<any>({})
    const [status, setStatus] = useState<any>({})

  

    const handleEditStatus = (status: any) => {
        setEditStatus(status)
    }


    const handleStatus = () => {
        let body: any = {
            ...editStatus,
            status: true
        }
        updateFailedReportStatus(body)
        setEditStatus({})


    }



    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        if (newPage > page) {
            let url =
                failedReport.links && failedReport.links.next.split("?")[1];
            getFailedReportData(url);
        } else if (newPage < page) {
            let url =
                failedReport.links &&
                failedReport.links.previous.split("?")[1];
            getFailedReportData(url);
        }
        setPage(newPage as number);
    };


    const filterTatTableData = (e: any) => {
        const body: any = {
            id: bookingId,
            customer_name: customer_name,



        };
        const url = genrateFailedReportFilter(body).substring(2);
        getFailedReportData(url);
        setPage(0);
    };
    const onKeyDown = (e: any) => {
        if (e.key === "Enter") {
            filterTatTableData(e);
        }
    };
    return (
        <div style={{ width: "99%" }}>
            <div className={classes.toolbar} />
            <h3 style={{ fontWeight: "bold" }}>FAILED REPORT</h3>
            <Grid
                container
                spacing={2}
                direction="row"
            >

                <Grid item xs={12} sm={4} md={2}>
                    <TextField
                        className="input"
                        name="bookingId"
                        type="number"
                        label="Booking Id"
                        value={bookingId}
                        variant="outlined"
                        onChange={(e) => setBookingId(e.target.value)}
                        style={{ width: "100%" }}
                        onKeyDown={onKeyDown}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                    <TextField
                        className="input"
                        name="customer_name"
                        type="text"
                        label="Customer Name "
                        value={customer_name}
                        variant="outlined"
                        onChange={(e) => setCustomerName(e.target.value)}
                        style={{ width: "100%" }}
                        onKeyDown={onKeyDown}
                    />
                </Grid>


                <Grid item xs={12} sm={4} md={2}>
                    <Button
                        variant="contained"
                        color="secondary"
                        disabled={loading}
                        fullWidth
                        onClick={filterTatTableData}
                    >
                        Filter
                    </Button>
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                    <Button
                        variant="contained"
                        color="primary"
                      
                        fullWidth
                        onClick={() => history.push("/dashboard/os/booking-tat-report")}
                    >
                        Reset
                    </Button>
                </Grid>
            </Grid>

            <TableContainer
                className={classes.tableContainer}
                component={Paper}
                style={{ maxHeight: "700px" }}
            >
                <Table stickyHeader aria-label="simple table">
                    <TableHead className={classes.tableHead}>
                        <StyledTableRow>
                            <StyledTableCell className={classes.tableHeadCell}>
                                Booking Id's
                            </StyledTableCell>
                            <StyledTableCell className={classes.tableHeadCell}>
                                Patient Name
                            </StyledTableCell>
                            <StyledTableCell className={classes.tableHeadCell}>
                                Reason
                            </StyledTableCell>
                            <StyledTableCell className={classes.tableHeadCell}>
                                Remarks
                            </StyledTableCell>
                            <StyledTableCell className={classes.tableHeadCell}>
                                Report Status
                            </StyledTableCell>

                            <StyledTableCell className={classes.tableHeadCell}>
                                Create Date
                            </StyledTableCell>
                            <StyledTableCell className={classes.tableHeadCell}>
                                Edit
                            </StyledTableCell>

                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {failedReport &&
                            failedReport?.results &&
                            failedReport.results.map((data: any, index: any) => {
                                return (
                                    <StyledTableRow key={index}>
                                        <StyledTableCell align="center">
                                            {data?.booking?.id ? data?.booking?.id : "NA"}
                                        </StyledTableCell>

                                        <StyledTableCell align="center">
                                            {data?.booking?.name ? data?.booking?.name : "NA"}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {data?.reason ? data?.reason : "NA"}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {data?.remarks ? data?.remarks : "NA"}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {data?.report_sent_status ? data?.report_sent_status : "NA"}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">

                                            {new Date(data?.created_at).toLocaleString()}
                                        </StyledTableCell>
                                       

                                           

                                    </StyledTableRow>
                                );
                            })}
                    </TableBody>
                    <TableFooter>
                        <StyledTableRow>
                            <TablePagination
                                colSpan={3}
                                count={failedReport?.count || 0}
                                rowsPerPageOptions={[]}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                            />
                        </StyledTableRow>
                    </TableFooter>
                </Table>
            </TableContainer>


            {failedReport?.results && failedReport?.results?.length === 0 && (
                <h6 style={{ textAlign: "center" }}>No Data Found</h6>
            )}
        </div>
    );
};

const mapStateToProps = (state: any) => ({
    failedReport: state.OffLineSupporteReducer.failedReport,
    loading: state.OffLineSupporteReducer.loading,
});
export default connect(mapStateToProps, {
    updateFailedReportStatus,
    getFailedReportData,
})(BookingsTable);
