import React, { useState, useEffect, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Grid,
  TextField,
  withStyles,
  Select,
  MenuItem
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { connect } from "react-redux";
import GrivianceModel from "../Grivance/model";
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import {
  getGrievance, getCenterInfo,
} from "../../actions/OffLineSupportActions";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
      marginTop: "50px",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem",
      width: "100%",
      overflow: "scroll",
      position: "sticky",
    },
    imageSM: {
      width: "60px",
      height: "60px",
      border: "2px solid #000 ",
      borderRadius: "50%",
    },
    table: {
      maxWidth: "100%",
      overflow: "scroll",
    },
    tableHead: {
      background: "rgb(146, 74, 145)!important",
    },
    imagePaper: {
      position: "absolute",
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[1],
    },
    rightContentHeader: {
      width: "30%",
      float: "right",
      textAlign: "right",
      paddingRight: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    tableHeadCell: {
      color: "#fff",
      fontWeight: "bold",
      textAlign: "center",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  })
);

const Input = styled("input")({
  display: "none",
});

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);
function rand() {
  return Math.round(Math.random() * 20) - 10;
}
function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
interface Props {
  loading: boolean;
  getGrievance: any;
  fetchGrivance: any;
  getCenterInfo: any;
  centerInfo: any;
}

const RaiseTicket: React.FC<Props> = ({
  loading,
  getGrievance,
  fetchGrivance,
  getCenterInfo,
  centerInfo,
}) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const history = useHistory();
  const [openImage, setImageOpen] = React.useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [modalStyle] = React.useState(getModalStyle);
  const [scrolldown, setScrolldown] = useState<boolean>(false);
  const [start_date, setStart_date] = useState<any>("");
  const [status, setStatus] = useState<any>("");
  const [end_date, setEnd_date] = useState<any>("");
  const [cc_code, setCc_code] = useState<any>("");
  const [center, setCentre] = useState<any>("");
  const [open, setOpen] = useState(false);
  const [grevianceId, setGrevianceId] = useState<any>(null)
  const timer = useRef<any>(0);
  const scrollFunction = () => {
    setScrolldown(!scrolldown);
  };

  useEffect(() => {
    getGrievance();
    getCenterInfo();
  }, []);


  const handleDeskModal = (e: any) => {
    setImageUrl(e);
    setImageOpen(true);
  };
  const handleImageClose = () => {
    setImageOpen(false);
  };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = fetchGrivance && fetchGrivance.next.split("?")[1];
      getGrievance(`?${url}`);
    } else if (newPage < page) {
      let url = fetchGrivance && fetchGrivance.previous.split("?")[1];
      getGrievance(`?${url}`);
    }
    setPage(newPage as number);
  };
  const body = (
    <div style={modalStyle} className={classes.imagePaper}>
      <img
        src={imageUrl}
        alt="popup"
        style={{
          width: "1050px",
          height: "700px",
          alignItems: "center",
          marginBottom: "50px",
        }}
      />
    </div>
  );

  // const downloadImage = () => {
  //   var element = document.createElement("a");
  //   var file = new Blob([fetchGrivance?.screen_shot], { type: "image/*" });
  //   element.href = URL.createObjectURL(file);
  //   element.download = "image.png";
  //   element.click();
  // };
  const filterBookings = () => {
    getGrievance(`?cc_code=${cc_code}&start_date=${start_date}&end_date=${end_date}&status=${status}&center_id=${center}`)
    setPage(0);
  };
  useEffect(() => {
    if (!open) {
      getGrievance();
    }
  }, [open]);

  const handleClick = (bookingid: number) => {
    setOpen(true);
    setGrevianceId(bookingid)
  };
  return (
    <>
      <div style={{ width: "100%", marginTop: "60px" }}>
        <form>
          <Paper className={classes.paper} elevation={15}>
            <Grid container>
              <Grid xs={10} style={{ textAlign: "center" }}><h3 style={{ fontWeight: "bold" }}>GRIEVANCE</h3></Grid>
            </Grid>
            <Grid container
              spacing={1}
              direction="row"
              style={{ alignItems: "left", marginTop: "5px" }}>
              <Grid item xs={12} sm={6} md={3}>
                <Autocomplete
                  id="center"
                  onChange={(event, newValue) => {
                    if (newValue) {
                      let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                      setCentre(obj?.id);
                    }
                  }}
                  freeSolo
                  blurOnSelect
                  options={centerInfo?.results || []}
                  disableCloseOnSelect
                  getOptionLabel={(option: any) =>
                    (option?.display_name === null
                      ? option?.name
                      : option?.display_name) +
                    "(" +
                    option?.center_code +
                    ")"
                  }
                  loading={loading}
                  onInputChange={(event, newInputValue) => {
                    clearTimeout(timer.current);
                    timer.current = setTimeout(() => {
                      getCenterInfo(`?name_or_code=${newInputValue}`);
                    }, 1000);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Centre/Code"
                      variant="outlined"
                      fullWidth
                      className="input"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  className="input"
                  name="name"
                  type="date"
                  onWheel={(e: any) => e.target.blur()}
                  label="Start Date"
                  value={start_date}
                  variant="outlined"
                  onChange={(e) => setStart_date(e.target.value as string)}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  className="input"
                  name="End Date"
                  type="date"
                  label="End Date"
                  value={end_date}
                  variant="outlined"
                  onChange={(e) => setEnd_date(e.target.value as string)}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Select
                  variant="outlined"
                  fullWidth
                  value={status ? status : "none"}
                  style={{ height: "38px" }}
                  onChange={(e) => setStatus(e.target.value as string)}
                >
                  <MenuItem value="none" disabled>Select Status</MenuItem>
                  <MenuItem value="Done">Done</MenuItem>
                  <MenuItem value="In Progress">In Progress</MenuItem>
                  <MenuItem value="Assigned to agent">Assigned to agent</MenuItem>
                </Select>
              </Grid>
              <Grid container
                spacing={2}
                direction="row"
                style={{ alignItems: "left", marginTop: "3px" }}>
                <Grid item xs={12} md={6} sm={3} style={{ textAlign: "right" }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    style={{ width: "170px" }}
                    onClick={filterBookings}
                  >
                    Filter
                  </Button>
                </Grid>
                <Grid item xs={12} md={6} sm={3}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ width: "170px" }}
                    onClick={() => history.push("/dashboard/os/grievance")}
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </form>
        <Paper className={classes.paper} elevation={15} style={{ marginTop: "10px" }}>
          <TableContainer
            className={classes.tableContainer}
            component={Paper}
            style={{ maxHeight: "700px" }}
          >
            <Table stickyHeader aria-label="simple table">
              <TableHead className={classes.tableHead}>
                <StyledTableRow>
                  <StyledTableCell className={classes.tableHeadCell}>
                    Grievance type
                  </StyledTableCell>
                  <StyledTableCell className={classes.tableHeadCell}>
                    CC Code
                  </StyledTableCell>
                  <StyledTableCell className={classes.tableHeadCell}>
                    CC Name
                  </StyledTableCell>
                  <StyledTableCell className={classes.tableHeadCell}>
                    Created date
                  </StyledTableCell>
                  <StyledTableCell className={classes.tableHeadCell}>
                    Description
                  </StyledTableCell>
                  <StyledTableCell className={classes.tableHeadCell}>
                    Closer Remarks
                  </StyledTableCell>
                  <StyledTableCell className={classes.tableHeadCell}>
                    Status
                  </StyledTableCell>
                  <StyledTableCell className={classes.tableHeadCell}>
                    Image Of Grievance
                  </StyledTableCell>
                  <StyledTableCell className={classes.tableHeadCell}>
                    Action
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {fetchGrivance &&
                  fetchGrivance?.results?.length > 0 &&
                  fetchGrivance?.results?.flatMap((data: any) => {
                    return (
                      <StyledTableRow >
                        <StyledTableCell
                          align="center"
                        >
                          {data?.grievance_type_name}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {data?.cc_details[0]?.center_code}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {data?.cc_details[0]?.name}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {data?.created_at.substring(0, 10)}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {data?.description}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {data?.remark}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {data?.status}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {data?.screen_shot ?
                            <a href={data?.screen_shot} target="_blank" rel="noreferrer">View</a>
                            : "N/A"}
                        </StyledTableCell>
                        {data?.status !== "Done" ?
                          <StyledTableCell align="center">
                            <ReplyAllIcon
                              style={{ color: "#0f636e" }}
                              onClick={() => handleClick(data?.id)}
                            />
                          </StyledTableCell> :
                          <StyledTableCell align="center">
                            Ticket Closed
                          </StyledTableCell>}
                      </StyledTableRow>
                    );

                  })}
              </TableBody>
              <TableFooter>
                <TablePagination
                  rowsPerPageOptions={[]}
                  count={(fetchGrivance && fetchGrivance.count) || 0}
                  rowsPerPage={fetchGrivance && fetchGrivance.page_size}
                  page={page}
                  onPageChange={handleChangePage}
                />
              </TableFooter>
            </Table>
          </TableContainer>
        </Paper>
        <GrivianceModel
          open={open}
          setOpen={setOpen}
          setGrevianceId={setGrevianceId}
          grevianceId={grevianceId}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  fetchGrivance: state.OffLineSupporteReducer.fetchGrivance,
  centerInfo: state.OffLineSupporteReducer.centerInfo,

});

export default connect(mapStateToProps, {
  getGrievance,
  getCenterInfo,
})(RaiseTicket);
