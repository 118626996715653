export const GENDER_CHOICE = [
  {
    display_name: "Mrs",
    db_name: "Mrs",
    gender: "female",
  },
  {
    display_name: "Miss",
    db_name: "Miss",
    gender: "female",
  },
  {
    display_name: "Ms",
    db_name: "Ms",
    gender: "female",
  },
  {
    display_name: "Smt",
    db_name: "Smt",
    gender: "female",
  },
  {
    display_name: "Mr",
    db_name: "Mr",
    gender: "male",
  },
  {
    display_name: "Baby Boy",
    db_name: "Baby Boy",
    gender: "male",
  },
  {
    display_name: "Baby Girl",
    db_name: "Baby Girl",
    gender: "female",
  },
];

export const CITY = "City";
export const LOCALITY = "Locality";
export const ADDRESS_LINE1 = "House No./Plot No./Flat No./Door No./Shop/ c/o";
export const ADDRESS_LINE2 = "Apartment/Building /Colony/ Block/Sector/Street/ Gali/Road/Chawl";
export const LANDMARK = "Landmark/Sublocality";
export const PINCODE = "Pin Code";


export const BOOKING_DISPOSITION: any = [
  {
    id: 84,
    name: "Customer did not picked the call",
  },
  {
    id: 85,
    name: "Customer Out Of Station",
  },
  {
    id: 86,
    name: "Customer Not Interested",
  },
  {
    id: 87,
    name: "Collection Center Facing Tech Problem while Updating Status",
  },
  {
    id: 88,
    name: "Sample will be Collected Today",
  },
  {
    id: 90,
    name: "Booking Need To Reschedule",
  },
  {
    id: 91,
    name: "Collection Center Behaviour was not appropriate with customer",
  },
  {
    id: 92,
    name: "Collection Center was not operational at Sample Collection Time",
  },
  {
    id: 93,
    name: "Sample Collected by Another Lab",
  },
  {
    id: 94,
    name: "Invalid/Wrong Cont. No",
  },
];