import React from "react";
import { Button } from "@mui/material";
import Chip from '@mui/material/Chip';

interface IProps {
    selectedView: string;
    count: number;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
    open: boolean;
}

const SelectedView:React.FC<IProps> = ({ selectedView, count, setOpen, open }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px', width: '90%' }}>
      <Chip label={`Selected View: ${selectedView}`} color="primary" style={{ fontWeight: 'bold' }} />
      <Chip label={`Total Count: ${count}`} color="success" style={{ fontWeight: 'bold' }} />
      <p></p>
      <Button
        type="button"
        variant="contained"
        color="primary"
        onClick={() => setOpen(true)}
      >
        Change view
      </Button>
    </div>
  );
};

export default SelectedView;