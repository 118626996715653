import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import "./view.sass";
import { Button, TableFooter, TablePagination } from "@material-ui/core";
import {
  getViews,
  clickToCall,
  getViewPagination,
  clickToCallLead,
} from "../../actions/OffLineSupportActions";
import CallIcon from "@mui/icons-material/Call";
import { connect } from "react-redux";

interface IProps {
  data: any;
  getViews: any;
  clickToCall: any;
  getViewPagination: any;
  clickToCallLead: any;
  activeView: string;
}

const ViewTable: React.FC<IProps> = ({
  data,
  getViews,
  clickToCall,
  getViewPagination,
  clickToCallLead,
  activeView,
}) => {
  const [page, setPage] = useState<number>(0);

  const handleChangePage = async (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      getViewPagination(data?.next);
    }
    if (newPage < page) {
      getViewPagination(data?.previous);
    }
    setPage(newPage);
  };


  return (
    <div>
      <TableContainer className="tableContainer">
        <Table className="table" stickyHeader>
          <TableHead className="tableHead">
            <TableRow className="tableRow">
              <TableCell className="tableCell" align="center">
                Call
              </TableCell>
              {data?.columns?.map((column: string, index: number) => (
                <TableCell className="tableCell" key={index} align="center">
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className="tableBody">
            {data?.results?.map((row: any, index: number) => (
              <TableRow className="tableRow" key={index}>
                {row?.id ? (
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        clickToCallLead(`${row?.id}/${activeView}`, "call")
                      }
                    >
                      <CallIcon />
                    </Button>
                  </TableCell>
                ) : (
                  "-"
                )}
                {data.columns.map((column: string, i: number) => (
                  <TableCell
                    className="tableCell"
                    key={i + column}
                    align="center"
                  >
                    {row[column] || "-"}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[]}
                colSpan={14}
                count={data?.count || 0}
                rowsPerPage={data?.page_size}
                page={page}
                onPageChange={handleChangePage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
};

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {
  getViews,
  clickToCall,
  getViewPagination,
  clickToCallLead,
})(ViewTable);
